import React from "react";
import { Switch, Route } from "react-router-dom";
import { routePaths } from "../../routes/routeConstants";
// import Settings from "../Settings/Settings";

function HomePageRoutes() {
  return (
    <div>
      <Switch>
        <React.Fragment>
          <Route path={routePaths.home__settings} exact>
            {/* <Settings /> */}
          </Route>
        </React.Fragment>
      </Switch>
    </div>
  );
}

export default HomePageRoutes;
