import React from "react";
import { Row, Col, Label, InputBox, Select } from "witmeg-ui-system";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import "./BasicInformation.css";
import moment from "moment";
// import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
function BasicInformation({ setField, errors, selectedProduct, form, selectedLocation, phonefaxValidation, emailValidation }) {

  // const { RangePicker } = DatePicker  ;
  // const filterID = orgIDS.filter(x => selectedLocation?.id == x.id)
  // console.log("SELECTEDPRODUCT2===> ", orgIDS);
  // console.log("SELECTEDPRODUCT2===> ", filterID);

  const format = 'HH:mm';
  const locations = selectedLocation?.LocationDetails?.map((data) => {
    return ({
      value: data.LocationID,
      label: data.Name
    })
  })

  const setTimes = (timeStrings) => {

    console.log('Time==>> ', timeStrings)
    setField("opening_hours", timeStrings)
  }

  console.log('Time==>> form ', form)

  return (
    <div>
      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Company Name </Label>

          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            placeholder='Enter your company name'
            noBorder backgroundOnly
            value={form?.companyName}
            onChange={(e) => setField("companyName", e.target.value)}
            error={errors.companyName}
          />

          {/* {errors.companyName && (
            <span className='help-text-with-errors'>{errors.companyName}</span>
          )} */}
        </Col>
      </Row>
      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Address </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            noBorder backgroundOnly
            placeholder='Enter your Address 1'
            value={form?.address}
            onChange={(e) => setField("address", e.target.value)}
            error={errors.address}
          />
        </Col>
      </Row>
      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label>Address 2</Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            noBorder backgroundOnly
            placeholder='Enter your Address 2'
            value={form?.address2}
            onChange={(e) => setField("address2", e.target.value)}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>City </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            noBorder backgroundOnly
            placeholder='Enter your city'
            value={form?.city}
            onChange={(e) => setField("city", e.target.value)}
            error={errors.city}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>State </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            noBorder backgroundOnly
            placeholder='Enter your state'
            value={form?.state}
            onChange={(e) => setField("state", e.target.value)}
            error={errors.state}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>ZIP </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            placeholder='Enter your zip code'
            noBorder backgroundOnly
            value={form?.zip}
            onChange={(e) => setField("zip", e.target.value)}
            error={errors.zip}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Country </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            placeholder='Enter your country'
            noBorder backgroundOnly
            value={form?.country}
            onChange={(e) => setField("country", e.target.value)}
            error={errors.country}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Phone </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='number'
            placeholder='Enter your phone number'
            noBorder backgroundOnly
            maxLength='10'
            minValue={0}
            value={form?.phone}
            minErrorMessage='Value cannot be negative'
            onChange={(e) => setField("phone", e.target.value)}
            error={errors.phone || phonefaxValidation}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label>Fax</Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='number'
            placeholder='Enter your fax number'
            noBorder backgroundOnly
            value={form?.fax}
            minValue={0}
            minErrorMessage='Value cannot be negative'
            onChange={(e) => setField("fax", e.target.value)}
            error={phonefaxValidation}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Email </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            placeholder='Enter your email address'
            noBorder backgroundOnly
            value={form?.email}
            onChange={(e) => setField("email", e.target.value)}
            error={errors.email || emailValidation}
          />
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Opening Hours </Label>
          </div>
        </Col>
        <Col span={16} style={{ margin: '8px 0' }} >
          <TimePicker.RangePicker
            style={{ background: '#F2F2F2', border: 'none', outline: 'none', height: '35px' }}
            format={format}
            getPopupContainer={triggerNode => {
              return triggerNode.parentNode;
            }}
            value={[moment(form?.opening_hours?.[0] ? form?.opening_hours?.[0] : '00.00', format), moment(form?.opening_hours?.[1] ? form?.opening_hours?.[1] : '00.00', format)]}
            onChange={(time, timeStrings) => setTimes(timeStrings)}
            // function(dates: [moment, moment], dateStrings: [string, string])
            showNow />
          {/* <InputBox
            type='text'
            placeholder='Enter your opening hours'
            noBorder backgroundOnly
            value={selectedProduct?.OpeningHours}
            onChange={(e) => setField("opening_hours", e.target.value)}
            error={errors.opening_hours}
          /> */}
        </Col>
      </Row>

      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Location </Label>
          </div>
        </Col>
        <Col span={16} style={{ margin: '-5px 0' }}>
          <Select
            noBorder={true}
            type='options-with-link'
            options={locations}
            onChange={(e) => {setField("locationID", e.value)}}
            value={locations.find( location =>form?.locationID === location.value )}
            placeholder='Select Location'
            hasError={errors.locationID}
          />
        </Col>
      </Row>
    </div>
  );
}

export default BasicInformation;
