import React, {useEffect} from 'react'

export default function DndBlankSpace(props) {
    useEffect(() => {
      props.handleAddBlankSpace()
    }, [])
    
  return (
    <div></div>
  )
}
