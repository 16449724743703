import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  InputBox,
  Label,
  Row,
  Col,
  Switch,
  Typography,
  Card,
  TextArea,
  FileUploader,
  Stack,
  StackItem,
  ProgressBar,
  Button,
  Icon,
  Popup,
  Space,
} from "witmeg-ui-system";

function BasicDetails(props) {
  const { formData } = props;

  const [errors, setErrors] = useState({
    pageTitle: false,
  });
  const [form, setForm] = useState();

  const setField = (field, value) => {
    let currentForm = JSON.parse(JSON.stringify(formData));

    setForm(Object.assign(currentForm, { [field]: value }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  useEffect(() => {
    setForm(formData)
  
    return () => {
      
    }
  }, [])
  

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <Row className="form-row">
          <Typography
            type="title"
            level={5}
            pattern="bold"
            className="eco-mb-24"
          >
            Basic Information
          </Typography>
        </Row>
        <Row className="form-row eco-mb-32" align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label required>Page Name</Label>
            </div>
          </Col>
          <Col span={16}>
            <InputBox
              noBorder
              backgroundOnly
              type="text"
              placeholder="Page Title"
              onChange={(e) => setField("DraftPageName", e.target.value)}
              value={form?.DraftPageName}
            />
          </Col>
        </Row>
        <Row className="form-row eco-mb-32" align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Lock for Client Editing</Label>
            </div>
          </Col>
          <Col span={16}>
            <Switch 
            checked={form?.DraftIsLockedForClientEditing}
            onChange={(e) => setField("DraftIsLockedForClientEditing", e)}
            />
          </Col>
        </Row>
      </div>
      <div
        style={{
          padding: "20px",
          borderTop: "1px solid #C6C7D2",
          borderBottom: "1px solid #C6C7D2",
        }}
      >
        <Row className="form-row eco-mb-24" justify="space-between">
          <Typography
            type="title"
            level={5}
            pattern="bold"
            className="eco-mb-24"
          >
            Main Menu
          </Typography>
        </Row>
        <Row className="form-row eco-mb-24" align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Show in Main Menu</Label>
            </div>
          </Col>
          <Col span={16}>
            <Switch />
          </Col>
        </Row>
        <Row className="form-row eco-mb-24" align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>No-link</Label>
            </div>
          </Col>
          <Col span={16}>
            <Switch />
          </Col>
        </Row>
        <Row className="form-row eco-mb-24">
          <Col span={24}>
            <div className="form-lbl-wrapper">
              <Label>Menu Description</Label>
            </div>
          </Col>
          <Col span={24}>
            <Typography type="text" color="gray_lightest" alignment="right">
              20 - 100 characters
            </Typography>
            <TextArea className="description-box" />
          </Col>
        </Row>
        <Row className="form-row" align="middle">
          <Col span={24}>
            <div className="form-lbl-wrapper">
              <Label>Menu Icon</Label>
            </div>
          </Col>
          {/* TODO imaage uploader and image viewer */}
        </Row>
      </div>
      <div style={{ padding: "20px" }}>
        <Row className="form-row" justify="space-between">
          <Typography
            type="title"
            level={5}
            pattern="bold"
            className="eco-mb-24"
          >
            Hide/Show in Navigation
          </Typography>
        </Row>
        <Row className="eco-mb-24">
          <Col span={12}>
            <Row className="form-row" align="middle">
              <Col span={12}>
                <div className="form-lbl-wrapper">
                  <Label>Hide on All</Label>
                </div>
              </Col>
              <Col span={12}>
                <Switch />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className="form-row" align="middle">
              <Col span={12}>
                <div className="form-lbl-wrapper">
                  <Label>Hide on Tab</Label>
                </div>
              </Col>
              <Col span={12}>
                <Switch />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="eco-mb-24">
          <Col span={12}>
            <Row className="form-row" align="middle">
              <Col span={12}>
                <div className="form-lbl-wrapper">
                  <Label>Hide on Desktop</Label>
                </div>
              </Col>
              <Col span={12}>
                <Switch />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row className="form-row" align="middle">
              <Col span={12}>
                <div className="form-lbl-wrapper">
                  <Label>Hide on Mobile</Label>
                </div>
              </Col>
              <Col span={12}>
                <Switch />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

BasicDetails.propTypes = {};

export default BasicDetails;
