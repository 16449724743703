import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Col,
  Label,
  Row,
  Space,
  Button,
  Stack,
} from "witmeg-ui-system";
import Combobox from "react-widgets/Combobox";
import FontPicker from "../../../../Components/FontPicker";

import { useFormContext } from "react-hook-form";

function CardDescriptionForm({data}) {
  const {
    descriptionFontFamily = "Open Sans",
    descriptionFontSize = 0,
    descriptionStyleItalic = false,
    descriptionStyleTextUnderline = false,
    descriptionStyleBold = false,
    descriptionFontAlign = "",
  } = data;

  const { setValue } = useFormContext();
  const [descFontFamily, setDescFontFamily] = useState(descriptionFontFamily);
  const [descFontSize,setDescFontSize] = useState(descriptionFontSize);
  const [descFontAlign, setDescFontAlign] = useState(descriptionFontAlign);

  const [descriptionTextBold, setDescriptionTextBold] =
    useState(descriptionStyleBold);
  const [descriptionTextItalic, setDescriptionTextItalic] = useState(
    descriptionStyleItalic
  );
  const [descriptionTextUnderline, setDescriptionTextUnderline] = useState(
    descriptionStyleTextUnderline
  );
  return (
    <Accordion title="Card Description">
      <div style={{ width: "100%" }}>
        <Row align="middle" className="eco-mb-16">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Family</Label>
            </div>
          </Col>
          <Col span={16}>
            <FontPicker
              id="cardDescriptionFontPicker"
              font={descFontFamily}
              onChange={(val) => { setValue("descriptionFontFamily", val); setDescFontFamily(val)} }
            />
          </Col>
        </Row>
        <Row align="middle" className="eco-mb-16">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Size</Label>
            </div>
          </Col>
          <Col span={8}>
            <Combobox
              value={descFontSize}
              defaultValue={0}
              onChange={(e) => { setValue("descriptionFontSize", e); setDescFontSize(e)}}
              data={[
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "20",
                "24",
                "32",
                "36",
                "40",
                "48",
                "64",
                "96",
                "100",
              ]}
              hideEmptyPopup={true}
            />
          </Col>
        </Row>
        <Row className="eco-mb-16" align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Style</Label>
            </div>
          </Col>
          <Col span={16}>
            <Space size="middle">
              <Button
                className="font-style-btn"
                category="icon"
                children="bold"
                variant={descriptionTextBold ? "primary" : "secondary"}
                color={descriptionTextBold && "white"}
                onClick={() => {
                  setValue("descriptionStyleBold", !descriptionTextBold);
                  setDescriptionTextBold(!descriptionTextBold);
                }}
              />
              <Button
                className="font-style-btn"
                category="icon"
                children="italic"
                variant={descriptionTextItalic ? "primary" : "secondary"}
                color={descriptionTextItalic && "white"}
                onClick={() => {
                  setValue("descriptionStyleItalic", !descriptionTextItalic);
                  setDescriptionTextItalic(!descriptionTextItalic);
                }}
              />
              <Button
                className="font-style-btn"
                category="icon"
                children="underline"
                variant={descriptionTextUnderline ? "primary" : "secondary"}
                color={descriptionTextUnderline && "white"}
                onClick={() => {
                  setValue(
                    "descriptionStyleTextUnderline",
                    !descriptionTextUnderline
                  );
                  setDescriptionTextUnderline(!descriptionTextUnderline);
                }}
              />
            </Space>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Alignment</Label>
            </div>
          </Col>
          <Col span={16}>
            <Stack>
              <Button
                category="icon"
                children="align-left"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "left" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("descriptionFontAlign", "left");
                  setDescFontAlign("left");
                }}
              />
              <Button
                category="icon"
                children="align-center"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "center" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("descriptionFontAlign", "center");
                  setDescFontAlign("center");
                }}
              />
              <Button
                category="icon"
                children="align-right"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "right" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("descriptionFontAlign", "right");
                  setDescFontAlign("right");
                }}
              />
              <Button
                category="icon"
                children="align-justify"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "justify" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("descriptionFontAlign", "justify");
                  setDescFontAlign("justify");
                }}
              />
            </Stack>
          </Col>
        </Row>
      </div>
    </Accordion>
  );
}

CardDescriptionForm.propTypes = {};

export default CardDescriptionForm;
