import React, { useState, useEffect } from 'react'
import { Accordion, Col, ColorPicker, InputBox, InputGroup, Label, Row, Select, Stack, StackItem, Space, Button, Slider, Icon, Switch, Typography } from 'witmeg-ui-system';

import layout1 from "../../../../images/layout-1.png";
import layout2 from "../../../../images/layout-2.png";
import layout3 from "../../../../images/layout-3.png";
import layout4 from "../../../../images/layout-4.png";
import layout5 from "../../../../images/layout-5.png";
import layout6 from "../../../../images/layout-6.png";
import layout7 from "../../../../images/layout-7.png";
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setButtonData } from '../../../../redux/resturantWidgetReducer';
import ButtonSettings from '../ButtonSettings/index'
import CategoriesSettings from '../CategoriesSettings/index'
import Subcategories from '../SubCategories';
import ItemSettings from '../ItemsSettings';
import SpaceingSettings from '../SpaceSettings';
import "./forms.css"
import { maxHeight } from '@material-ui/system';

const DesignForm = ({ paddingValidation,
  setPaddingValidation, setPaddingValidations, paddingValidations,
  errorMsg, handleChangePopupsettings, setSpaceDataForm, selectedCard, missingvalidationFields, errors, spaceFormData, subCatFormData, itemsFormData, setButtonField, categoriesFormData, buttonFormData, setCategoriesField, setSubCategoriesField, setItemsField, setSpaceField, setSelectedLayout, selectedLayout }) => {

  const [fontColor, setFontColor] = useState("");

  const [form, setForm] = useState({});



  //Button Data
  const dispatch = useDispatch();

  const emmbeddedState = useSelector((state) => state.resturantWidget.menuWidgetData.menuSelection)
  // const[selectedLayout,setSelectedLayout]



  //OnClick Function to get the layout
  const hanldeSelectedLayout = (data) => {
    console.log(data)
    setSelectedLayout(data)
  }

  //LAYOUTS
  const content = (
    <Stack direction="column" horizontalAlign="h_center">
      <div className={selectedLayout === "layout1" ? "eco-mb-24 selected-layout" : "eco-mb-24"}>
        <img onClick={() => hanldeSelectedLayout("layout1")} src={layout1} />
      </div>
      <div className={selectedLayout === "layout2" ? "eco-mb-24 selected-layout" : "eco-mb-24"}>
        <img onClick={() => hanldeSelectedLayout("layout2")} src={layout2} />
      </div>
      <div className={selectedLayout === "layout3" ? "eco-mb-24 selected-layout" : "eco-mb-24"}>
        <img onClick={() => hanldeSelectedLayout("layout3")} src={layout3} />
      </div>
      <div className={selectedLayout === "layout4" ? "eco-mb-24 selected-layout" : "eco-mb-24"}>
        <img onClick={() => hanldeSelectedLayout("layout4")} src={layout4} />
      </div>
      <div className={selectedLayout === "layout5" ? "eco-mb-24 selected-layout" : "eco-mb-24"}>
        <img onClick={() => hanldeSelectedLayout("layout5")} src={layout5} />
      </div>
      <div className={selectedLayout === "layout6" ? "eco-mb-24 selected-layout" : "eco-mb-24"}>
        <img onClick={() => hanldeSelectedLayout("layout6")} src={layout6} />
      </div>
      {/* <div className={selectedLayout === "layout7" ? "eco-mb-24 selected-layout" : "eco-mb-24"}>
        <img onClick={() => hanldeSelectedLayout("layout7")} src={layout7} />
      </div> */}
    </Stack>
  );



  const [popoverVisible, setPopoverVisible] = useState(false);





  return (
    <div style={{ margin: '34px 22px' }}>
      <div className="eco-mb-16">
        {selectedCard != 'embedded' && emmbeddedState === 'popup' &&
          <Accordion
            theme='default'
            type='content_only'
            title='Button'
            collapsed={errors.buttonName ? false : true}>
            <ButtonSettings missingvalidationFields={missingvalidationFields} errors={errors} setButtonField={setButtonField} buttonFormData={buttonFormData} />
          </Accordion>}
      </div>
      <div className="eco-mb-16">
        <Accordion
          theme='default'
          type='content_only'
          title='Layout'
          collapsed={true}>
          <div style={{ width: '100%' }}>
            <Stack horizontalAlign="h_center">
              {selectedLayout === null && 
              <div className="eco-mr-24">
                <img src={layout2} />
              </div>}

              {selectedLayout === "layout1" &&
                <div className="eco-mr-24">
                  <img src={layout1} />
                </div>}

              {selectedLayout === "layout2" &&
                <div className="eco-mr-24">
                  <img src={layout2} />
                </div>}

              {selectedLayout === "layout3" && 
                <div className="eco-mr-24">
                  <img src={layout3} />
                </div>}

              {selectedLayout === "layout4" &&
                <div className="eco-mr-24">
                  <img src={layout4} />
                </div>}

              {selectedLayout === "layout5" &&
                <div className="eco-mr-24">
                  <img src={layout5} />
                </div>}

              {selectedLayout === "layout6" &&
                <div className="eco-mr-24">
                  <img src={layout6} />
                </div>}

              {/* {selectedLayout === "layout7" &&
                <div className="eco-mr-24">
                  <img src={layout7} />
                </div>} */}

              <Popover
                placement="right"
                content={content}
                trigger="click"
                getPopupContainer={(triggerNode) => triggerNode}
                overlayInnerStyle={{
                  maxHeight: '70vh',
                  overflowY: 'scroll'
                }}>
                <Button category="icon" children="chevron-right" variant="subtle" className="show-layout-btn" />
              </Popover>
            </Stack>
          </div>
        </Accordion>
      </div>
      <div className="eco-mb-16">
        <Accordion
          theme='default'
          type='content_only'
          title='Categories'
          collapsed={true}>
          <CategoriesSettings categoriesFormData={categoriesFormData} setCategoriesField={setCategoriesField} />
        </Accordion>
      </div>
      <div className="eco-mb-16">
        <Accordion
          theme='default'
          type='content_only'
          title='Subcategories'
          collapsed={true}>
          <Subcategories subCatFormData={subCatFormData} setSubCategoriesField={setSubCategoriesField} />
        </Accordion>
      </div>
      <div className="eco-mb-16">
        <Accordion
          theme='default'
          type='content_only'
          title='Items'
          collapsed={true}>
          <ItemSettings itemsFormData={itemsFormData} setItemsField={setItemsField} />
        </Accordion>
      </div>
      <div className="eco-mb-16">
        <Accordion
          theme='default'
          type='content_only'
          title='Spacing'
          collapsed={true}>
          <SpaceingSettings paddingValidation={paddingValidation}
            setPaddingValidation={setPaddingValidation} paddingValidations={paddingValidations} setPaddingValidations={setPaddingValidations} errorMsg={errorMsg} handleChangePopupsettings={handleChangePopupsettings} spaceFormData={spaceFormData} setSpaceDataForm={setSpaceDataForm} setSpaceField={setSpaceField} />
        </Accordion>
      </div>

    </div>
  )
}

export default DesignForm;