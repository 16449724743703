import React, { useState, useEffect, useRef } from "react";
import {
  DraggableTree,
  Stack,
  Button,
  StackItem,
  Typography,
  Spin,
  Popup,
  Space,
  Message,
  Icon
} from "witmeg-ui-system";
import { CloseOutlined } from "@ant-design/icons";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";

import "./PagesOverlay.css";
import {
  deletePageByID,
  getAllPages,
  updatePageByID,
  getPageByMenuID,
  getSubPagesByParentPageID,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
// import { responsiveArray } from "antd/lib/_util/responsiveObserve";
import PagesOverlayPopOver from "./PagesOverlayPopOver";
import CreateNewPage from "./CreateNewPage";
import PagePopup from "./PagePopup";
import { useOutsideClick } from "../Hooks/UseOutsideClick";

const Pages = (props) => {
  const history = useHistory();
  const [treeData, setTreeData] = useState([]);
  const { confirm } = Modal;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPageID, setSelectedPageID] = useState("");
  const [showPagePopup, setShowPagePopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [formData, setFormData] = useState({});
  const wrapperRef = useRef(null);
  const [showEditMenue, setShowEditMenue] = useState(false);

  //outside click hook
  useOutsideClick(wrapperRef,[showPagePopup,showDeletePopup,showEditMenue],() =>props.closePagesOverlay());

  useEffect(() => {
    fetchAllPages();
  }, []);

  //Navigate to a new page
  async function handleNavigaePage(ID) {
    const res = await getPageByMenuID({ ID: ID });

    const pageName = res[0].DraftSlug;

    let path = `/home/${pageName}`;
    await history.push(path);
    props.closePagesOverlay();
    window.location.reload();
  }

  const generateTitleItem = (pageData, index) => {
    return (
      <PagesOverlayPopOver
        pagedata={pageData}
        index={index}
        handleEditPage={(data) => {
          setSelectedPageID(pageData.ID);
          setShowPagePopup(true);
          setFormData(data);
        }}
        handleDeletePage={handleDeletePage}
        handleNavigaePage={handleNavigaePage}
        setShowEditMenue={setShowEditMenue}
      />
    );
  };

  /**
   * generate subpage data by parentPageId
   * currently this API doen't provide the correct response
   * @param {array} pageData
   * @returns array
   */
  const generateChildren = async (pageData) => {
    const subpageData = await fetchSubPagesByParentPageID(pageData.ID);

    if (subpageData) {
      const subpages = await Promise.all(
        subpageData.map(async (data, index) => ({
          key: data.ID,
          title: generateTitleItem(data, index),
          children: await generateChildren(data),
        }))
      );
      return subpages;
    }

    return [];
  };

  const mapChildren = (pagesData, id) => {
    return pagesData
      .filter((page) => page.DraftMainPagesInfoID === id)
      .map((data, index) => ({
        key: data.ID,
        title: generateTitleItem(data, index),
        children: mapChildren(pagesData, data.ID),
      }));
  };

  /**
   * set pages menu data
   * fetch all pages by companyID
   */
  const fetchAllPages = async () => {
    try {
      setIsLoading(true);
      const pagesData = await getAllPages({
        ID: "",
        CompanyID: sessionStorage.getItem("company"),
      });

      const treeData = pagesData
        .filter((page) => !page.DraftMainPagesInfoID)
        .map((pagedata, index) => ({
          key: pagedata.ID,
          title: generateTitleItem(pagedata, index),
          children: mapChildren(pagesData, pagedata.ID),
        }));
      setTreeData(treeData);
    } catch (error) {
      console.log(`fetch data error: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * geenrate fetch subpages by parentId
   * @param {string} ID
   * @returns array
   */

  const fetchSubPagesByParentPageID = async (ID) => {
    try {
      const pageData = await getSubPagesByParentPageID({
        ID,
        CompanyID: sessionStorage.getItem("company"),
      });
      return pageData.filter((item) => item.DraftMainPagesInfoID === ID);
    } catch (error) {
      console.log(`fetch subpages data error: ${error}`);
    }
  };

  const updateTreeData = async (data) => {
    const parentKey = data?.dropToGap ? "" : data?.node?.key;
    const childKey = data?.dragNode?.key;
    const res = await getPageByMenuID({
      ID: childKey,
      CompanyID: "",
    });
    await updatePageByID({
      ID: childKey,
      CompanyID: sessionStorage.getItem("company"),
      PageType: res[0]?.DraftPageType,
      Slug: res[0]?.DraftSlug,
      PageName: res[0]?.DraftPageName,
      IsLockedForClientEditing: res[0]?.DraftIsLockedForClientEditing,
      ShowInMainMenu: res[0]?.DraftShowInMainMenu,
      NoLink: res[0]?.DraftNoLink,
      Status: true,
      Visibility: true,
      MenuDescription: res[0]?.DraftMenuDescription,
      MenuIconName: res[0]?.DraftMenuIconName,
      MenuIconURL: res[0]?.DraftMenuIconURL,
      HideOnAll: res[0]?.DraftHideOnAll,
      HideOnDesktop: res[0]?.DraftHideOnDesktop,
      HideOnTab: res[0]?.DraftHideOnTab,
      HideOnMobile: res[0]?.DraftHideOnMobile,
      SEOInfo: {
        Preview: res[0]?.DraftSEOInfo.Preview,
        PageTitle: res[0]?.DraftSEOInfo.PageTitle,
        MetaDescription: res[0]?.DraftSEOInfo.MetaDescription,
        PageURL: res[0]?.DraftSEOInfo.PageURL,
        HeaderHTML: res[0]?.DraftSEOInfo.HeaderHTML,
      },
      MainPagesInfoID: parentKey,
    });
    await fetchAllPages();
  };

  const[selectedPage,setSelectedPage] = useState(null)


  const handleDeletePage = async (record) => {
    setSelectedPage(record);
    setShowDeletePopup(true);
  };

async function handlePageDeletion () {
    const reqBody = { ID: selectedPage };

    try {
      await deletePageByID(reqBody);
      setShowDeletePopup(false)
      Message.success("Page has been deleted successfully !")
      await fetchAllPages()
       
    } catch (error) {
      console.log(error);
    }

}

  return (
    <div className="pages__overlay" ref={wrapperRef}>
      <Stack
        className="header_tag"
        direction="row"
        horizontalAlign="space_between"
        verticalAlign="v_center"
      >
        <StackItem alignment="center">
          <Typography
            alignment="center"
            textTransform="uppercase"
            pattern="bold"
          >
            Pages
          </Typography>
        </StackItem>
        <StackItem onClick={props.closePagesOverlay}>
          <CloseOutlined className="close-name" />
        </StackItem>
      </Stack>
      <Stack direction="column" className="eco-mt-24">
        <div className="eco-mb-24">
          <Button
            size="small"
            onClick={() => {
              setSelectedPageID(null);
              setFormData({});
              setShowPagePopup(true);
            }}
            className="add-page-button"
          >
            <PlusOutlined style={{ paddingRight: "14px" }} />
            Create Pages
          </Button>
        </div>
        {isLoading ? (
          <div
            style={{
              height: "calc(80vh - 22rem)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{ width: "300px", maxHeight: "60vh", overflowY: "auto" }}
            className="pages-scroll eco-pr-4"
          >
            <DraggableTree
              treeData={treeData}
              onDropFunction={updateTreeData}
              expandedKeys={null}
              dragFunction={() => {}}
              dragStartFunction={() => {}}
              onDragChange={() => {}}
            />
          </div>
        )}
      </Stack>
      {showPagePopup && (
        <PagePopup
          selectedPageID={selectedPageID}
          showPagePopup={showPagePopup}
          setShowPagePopup={setShowPagePopup}
          formData={formData}
          fetchAllPages={fetchAllPages}
        />
      )}
      <Popup 
          modalTitle="     "
          modalVisible={showDeletePopup}
          onCancel={() => setShowDeletePopup(false)}
          maskClosable={false}
          className="widget-delete-confirmation-popup">
            <div>
              <Stack style={{ marginBottom: '62px' }} verticalAlign="v_top">
                <Icon name="cancel" type="color" className="eco-mr-16" size={32} />
                <Stack direction="column" verticalAlign="v_top">
                  <Typography type="title" level={5} pattern="bold" className="eco-mb-16">You're about to delete this Page!</Typography>
                  <Typography type="text">Do you really want to delete these Page? This Process cannot be undone.</Typography>
                </Stack>
              </Stack>
              <Stack horizontalAlign="h_end">
                <Space size="middle">
                  <Button variant="primary" onClick={() => handlePageDeletion()}>Confirm</Button>
                  <Button variant="outline" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
                </Space>
              </Stack>
            </div>
        </Popup>
    </div>
  );
};

export default Pages;
