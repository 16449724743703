import { Modal } from "antd";
import React from "react";
import { Button, Space, Spin } from "witmeg-ui-system";
import ButtonSettings from "../ButtonSettings/index";

function ButtonPopUp({
  showPopUp,
  handleCancel,
  handleSubmit,
  buttonColor,
  setButtonColor,
  buttonTxtColor,
  setButtonTxtColor,
  onChangeButtonText,
  setButtonBorder,
  buttonBorder,
  onChangeFontSize,
  textSize,
  onChangeButtonBorder,
  textBold,
  textItalic,
  textUnderline,
  onChangeBoldText,
  onChangeItalicText,
  onChangeUnderlineText,
  buttonText, 
  btnValidation,
  onChangeButtonWebURL,
  onCheckedButtonWebURLOpenInNewWindow,
  buttonWebURL,
  isLoading,
}) {
  return (
    <div>
      <Modal
        width={644}
        title="Button"
        visible={showPopUp}
        onCancel={handleCancel}
        maskClosable={false}
        footer={
          <Space>
            { isLoading && <Spin /> }
            <Button
              onClick={handleSubmit}
              variant="primary"
              className="save-btn"
              disabled={isLoading}
            >
              {" "}
              Save{" "}
            </Button>
            <Button
              onClick={handleCancel}
              variant="outline"
              className="cancel-btn"
              disabled={isLoading}
            >
              Cancel
            </Button>
          </Space>
        }
      >
        {/* EDIT CONTNET */}
        <ButtonSettings
        btnValidation = {btnValidation}
          buttonColor={buttonColor}
          setButtonColor={setButtonColor}
          buttonTxtColor={buttonTxtColor}
          setButtonTxtColor={setButtonTxtColor}
          onChangeButtonText={onChangeButtonText}
          setButtonBorder={setButtonBorder}
          buttonBorder={buttonBorder}
          onChangeFontSize={onChangeFontSize}
          textSize={textSize}
          onChangeButtonBorder={onChangeButtonBorder}
          textBold={textBold}
          textItalic={textItalic}
          textUnderline={textUnderline}
          onChangeBoldText={onChangeBoldText}
          onChangeItalicText={onChangeItalicText}
          onChangeUnderlineText={onChangeUnderlineText}
          buttonText={buttonText}
          onChangeButtonWebURL={onChangeButtonWebURL}
          onCheckedButtonWebURLOpenInNewWindow={onCheckedButtonWebURLOpenInNewWindow}
          buttonWebURL={buttonWebURL}
        />
      </Modal>
    </div>
  );
}

export default React.memo(ButtonPopUp);
