import { Divider } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  GlobalHeader,
  GlobalLayout,
  RightPanel,
  Row,
  Space,
  Stack,
  StackItem,
  Typography,
  // Carousel,
  Accordion,
  async,
  Alert,
  Message,
} from "witmeg-ui-system";
 
// import { Carousel } from 'react-responsive-carousel';
import { Modal } from "antd";
import { CloseOutlined, DingdingOutlined } from "@ant-design/icons";
import GlobalImageUploader from "../GlobalImageUploader/GlobalImageUploader";
import CarouselImageUpload from "./CarouselImageUpload/CarouselImageUpload";
import CarouselImageUpload1 from "./CarouselImageUpload/CarouselImageUpload1";
import CarouselImageUpload2 from "./CarouselImageUpload/CarouselImageUpload2";
import CarouselImageUpload3 from "./CarouselImageUpload/CarouselImageUpload3";
import "./Widget.css";
import {
  getHomeLayoutByID,
  updateHomeLayout,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";
import Carousel from 'react-bootstrap/Carousel';

function Carousel_Widget(props) {

  const [viewOverlay, setAddOverlay] = useState(false);
  const [enableProductEditMode, setEnableProductEditMode] = useState(false);
  const [isSavingProduct, setIsSavingProduct] = useState(false);
  const [imageTempArray, setImageTempArray] = useState([]);
  const [data, setData] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [output, setOutput] = useState();
  const [src, setSrc] = useState(null);
  const [uploadToCloud, setUploadToCloud] = useState(false)
  //Carousel One
  const [output1, setOutput1] = useState();
  const [src1, setSrc1] = useState(null);

  const [imageSizeValidation, setImageSizeValidation] = useState(false);
  const [imageTypeValidation, setImageTypeValidation] = useState(false);
  const [crop0, setCrop0] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,

  });


  const [crop1, setCrop1] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,

  });

  const [crop2, setCrop2] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,

  });

  const [crop3, setCrop3] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,

  });


  //Carousel Two
  const [output2, setOutput2] = useState();
  const [src2, setSrc2] = useState(null);
  //Carousel Three
  const [output3, setOutput3] = useState();
  const [src3, setSrc3] = useState(null);

  //Image Data
  const [imageSet, setImageSet] = useState({ data: [] })


  const resizeWidth = useSelector((state) => state.commonData.resizeWidth)


  console.log("PRRRRR", props)
  const dispatch = useDispatch()

  let temp;
  // RIGHT PANAL
  const showWidgetEditOverlay = () => {

    if (props.params.PreviewButton === false) {
      setAddOverlay(true);
      dispatch(setCommonState({
        draggableState: true
      }))
    }
  };
  const closeWidgetEditOverlay = () => {
    setAddOverlay(false);
  };
  const style = {
    width: "100%",
    height: "100%",
    padding: "30px",
    backgroundColor: "#cecece",
  };


  // FROM THIS PROP U CAN ACCESS THE WIDGET HEIGHT AS IT CHANGES
  console.log("TINISNF", props.widgetHeight)

  useEffect(async () => {
    await fetchData();
  }, []);
  const fetchData = async () => {
    let deviceState = sessionStorage.getItem('deviceState')

    if (props.params.pageID) {

      let widID = await props.params.widgetID;
      let pageID = await props.params.pageID;
      const getData = await getHomeLayoutByID({
        ID: widID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 100,
      })
        .then((res) => {
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)
            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0])
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)
            }
          }


        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      const getData = await getHomeLayoutByID({
        ID: props.params.widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 100,
      })
        .then((res) => {
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)
            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0])
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)
            }
          }


        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const handleOk = async () => {
    //  
    let images
    if (imageSet.data.length > 0) {
      const result = Object.values(
        [].concat(imageSet.data, imageTempArray)
          .reduce((r, c) => (r[c.index] = Object.assign((r[c.index] || {}), c), r), {})
      )
      let imageData = JSON.stringify({ data: result });
      images = encodeURIComponent(imageData);
    } else {

      let imageData = JSON.stringify({ data: imageTempArray });
      images = encodeURIComponent(imageData);
    }


    if (props.params.pageID) {
      // IF PAGE ID HAVE
      let widID = await props.params.widgetID;
      let pageID = await props.params.pageID;
      if (imageTempArray.length == 0) {
        setImageError(true);
      } else {
        setConfirmLoading(true);
        const updateWidjet = await updateHomeLayout({
          ID: props.params.widgetID,
          ItemHeading: "carousel",
          Item: "carousel",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.X,
          Type: data?.Type,
          ItemData: images,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: pageID,
          CompanyID: sessionStorage.getItem("company"),
        }).then((response) => {
          // console.log(response);
          Message.success("Widget edited successfully");
          dispatch(setCommonState({
            draggableState: false
          }))
          fetchData();
          setSrc(null);
          setOutput(null)
          setOutput1(null);
          setOutput2(null);
          setOutput3(null);
          setSrc1(null);
          setSrc2();
          setSrc3();
          setAddOverlay(false);
          setImageError(false);
          setConfirmLoading(false);
          // window.location.reload();
        });
      }
    } else {
      //IF NO PAGE ID
      if (imageTempArray.length == 0) {
        setImageError(true);
      } else {
        setConfirmLoading(true);
        const updateWidjet = await updateHomeLayout({
          ID: props.params.widgetID,
          ItemHeading: "carousel",
          Item: "carousel",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.Y,
          Type: data?.Type,
          ItemData: images,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: "",
          CompanyID: sessionStorage.getItem("company"),
        }).then((response) => {
          // console.log(response);
          Message.success("Widget edited successfully");
          dispatch(setCommonState({
            draggableState: false
          }))
          fetchData();
          setSrc(null);
          setOutput(null)
          setOutput1(null);
          setOutput2(null);
          setOutput3(null);
          setSrc1(null);
          setSrc2();
          setSrc3();
          setAddOverlay(false);
          setImageError(false);
          setConfirmLoading(false);
          // window.location.reload();
        });
      }
    }
  };

  // const handleSetOutput = (index, data) => {
  //    ;
  //   let updatedOutput = Object.assign(output);
  //   updatedOutput[index] = data;
  //   setOutput(updatedOutput);
  // };

  const handleCancel = () => {
    setImageError(false);
    setAddOverlay(false);
    setSrc(null);
    setOutput1();
    setOutput2();
    setOutput3();
    setSrc1(null);
    setSrc2();
    setSrc3();
    setImageTypeValidation(false);
    setImageSizeValidation(false)
    // handleSetOutput([{}, {}, {}, {}]);
    setOutput();
    dispatch(setCommonState({
      draggableState: false
    }))

    setCrop0({
      unit: '%', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 80,
      height: 80
    });
    setCrop1({
      unit: '%', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 80,
      height: 80
    });
    setCrop2({
      unit: '%', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 80,
      height: 80
    });
    setCrop3({
      unit: '%', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 80,
      height: 80
    });
  };

  const handleTempArray = (value, field) => {
    setImageTempArray(value);
  };

  const handleClick = () => {
    // console.log("CLICKED");
  };
  // console.log(data, "HELLo");

  if (data != null && data?.ItemData != "") {
    var tempArray;

    tempArray = decodeURIComponent(data?.ItemData);

    var newArray = JSON.parse(tempArray);

    // console.log("myObject===>>>", newArray);
  }

  console.log("PSOW", resizeWidth)

  return (
    <>
      <div >
        <Row style={{ height: '100%' }}>
          <Col span={24}>

            
            <Carousel showArrows={props.widgetHeight < 10 ? false : true}  indicators = {false} >
              {newArray &&
                newArray.data.map((data, index) => (
                  <Carousel.Item onDoubleClick={showWidgetEditOverlay} className='carousel-img'>
                    <img
                      className="d-block w-100"
                      src={data?.imageUrl}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      draggable='false'
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          </Col>
        </Row>

        {!newArray && (
          <Carousel showArrows={props.widgetHeight || data?.Width < 10 ? false : true} indicators = {false}  >
            <Carousel.Item onDoubleClick={showWidgetEditOverlay} className='carousel-img'>
              <img
               className="d-block w-100"
                draggable='false'
                src='https://images.unsplash.com/photo-1661999939410-7e6ac7614588?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80'
              />
            </Carousel.Item>
            <Carousel.Item onDoubleClick={showWidgetEditOverlay} className='carousel-img'>
              <img
               className="d-block w-100"
                draggable='false'
                src='https://images.unsplash.com/photo-1661999939410-7e6ac7614588?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80'
              />
            </Carousel.Item>
            <Carousel.Item onDoubleClick={showWidgetEditOverlay} className='carousel-img'>
              <img
               className="d-block w-100"
                draggable='false'
                src='https://images.unsplash.com/photo-1661999939410-7e6ac7614588?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80'
              />
            </Carousel.Item>
          </Carousel>
        )}
      </div>

      <Modal
        title='Carousel Image Upload'
        maskClosable={false}

        onCancel={handleCancel}
        open={viewOverlay}
        confirmLoading={confirmLoading}
        footer={
          <Space>
            <Button
              onClick={handleOk}
              variant="primary"
              size="small"
              disabled={uploadToCloud}
            >
              Confirm
            </Button>
            <Button
              onClick={handleCancel}
              variant="secondary"
              className="eco-mr-8"
              size="small"
            >
              Cancel
            </Button>
          </Space>
        }
      >
        {imageError && (
          <Alert
            message='At least one image is required'
            type='error'
            showIcon
          />
        )}
        <div className='eco-mt-12'>
          <Accordion
            theme='default'
            type='content_only'
            title='Image Upload 1'
            collapsed={true}
          >
            <div style={{ width: "100%" }}>
              <CarouselImageUpload
                imageTempArray={imageTempArray}
                handleTempArray={handleTempArray}
                setImageError={setImageError}
                output={output}
                //                                setOutput={(data) => handleSetOutput(0, data)}
                setOutput={setOutput}
                src={src}
                setSrc={setSrc}
                crop={crop0}
                setCrop={setCrop0}
                setUploadToCloud={setUploadToCloud}

                imageSizeValidation={imageSizeValidation}
                setImageSizeValidation={setImageSizeValidation}
                imageTypeValidation={imageTypeValidation}
                setImageTypeValidation={setImageTypeValidation}
              />
            </div>
          </Accordion>
          <Accordion
            theme='default'
            type='content_only'
            title='Image Upload 2'
            collapsed={true}
          >
            <div style={{ width: "100%" }}>
              <CarouselImageUpload1
                imageTempArray={imageTempArray}
                handleTempArray={handleTempArray}
                setImageError={setImageError}
                output1={output1}
                // setOutput={(data) => handleSetOutput(1, data)}
                setOutput1={setOutput1}
                src1={src1}
                setSrc1={setSrc1}
                crop={crop1}
                setCrop={setCrop1}
                setUploadToCloud={setUploadToCloud}

                imageSizeValidation={imageSizeValidation}
                setImageSizeValidation={setImageSizeValidation}
                imageTypeValidation={imageTypeValidation}
                setImageTypeValidation={setImageTypeValidation}
              />
            </div>
          </Accordion>
          <Accordion
            theme='default'
            type='content_only'
            title='Image Upload 3'
            collapsed={true}
          >
            <div style={{ width: "100%" }}>
              <CarouselImageUpload2
                imageTempArray={imageTempArray}
                handleTempArray={handleTempArray}
                setImageError={setImageError}
                output2={output2}
                setOutput2={setOutput2}
                // setOutput={(data) => handleSetOutput(2, data)}
                src2={src2}
                setSrc2={setSrc2}
                crop={crop2}
                setCrop={setCrop2}
                setUploadToCloud={setUploadToCloud}

                imageSizeValidation={imageSizeValidation}
                setImageSizeValidation={setImageSizeValidation}
                imageTypeValidation={imageTypeValidation}
                setImageTypeValidation={setImageTypeValidation}
              />
            </div>
          </Accordion>
          <Accordion
            theme='default'
            type='content_only'
            title='Image Upload 4'
            collapsed={true}
          >
            <div style={{ width: "100%" }}>
              <CarouselImageUpload3
                imageTempArray={imageTempArray}
                handleTempArray={handleTempArray}
                setImageError={setImageError}
                output3={output3}
                // setOutput={(data) => handleSetOutput(3, data)}
                setOutput3={setOutput3}
                src3={src3}
                setSrc3={setSrc3}
                crop={crop3}
                setCrop={setCrop3}
                setUploadToCloud={setUploadToCloud}

                imageSizeValidation={imageSizeValidation}
                setImageSizeValidation={setImageSizeValidation}
                imageTypeValidation={imageTypeValidation}
                setImageTypeValidation={setImageTypeValidation}
              />
            </div>
          </Accordion>
        </div>
      </Modal>

      {/* {viewOverlay && (
          <div className='widget__overlay'>
            <div>
              <CloseOutlined
                onClick='closeWidgetEditOverlay'
                className='close-name'
              />
              <Button className='submit__btn' type='submit'>
                Submit
              </Button>
            </div>
          </div>
        )} */}
    </>
  );
}

export default Carousel_Widget;
