import { Modal } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Alert, Button, Message, Stack, Space } from "witmeg-ui-system";
import { Editor } from "@tinymce/tinymce-react";
// import style from "./textEditor.css"
import ReactHtmlParser from "react-html-parser";
import "../../../ReportWiidjet/Widget.css"

import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../../../redux/commonReducer";

export default function DndTextEditor(props) {
  const [viewEditor, setViewEditor] = useState(false);
  const [data, setData] = useState([]);
  const [editorError, setEditorError] = useState(false);
  const [typedData, setTypedData] = useState(props?.defaultText || '');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showhideEditor, setShowEditor] = useState(false);
  const [showToolbar, setShowToolBar] = useState(true);

  const editorRef = useRef(null);

  const dispatch = useDispatch()

  
  const handleSaveText = async () => {
    setShowEditor(false);
    props.handleSaveText(typedData)
  };
  const showEditor = () => {
    if (props.params.PreviewButton == false) {
      setTypedData(data?.ItemData);
      setShowEditor(true);
      dispatch(setCommonState({
        draggableState: true
      }))
    } else {
      setShowEditor(false);
    }
  };
  
  const handleEditorChange = (e) => {
    // console.log("Content was updated:", e.target.getContent());

    var temp = e.target.getContent();
    //This will remove all the unnesaccery html tags
    console.log("Content was updated:", temp)
    setTypedData(temp);
    // console.log(newContent);

    setEditorError(false);
  };

  const cancelText = () => {
    setShowEditor(false);
    dispatch(setCommonState({
      draggableState: false
    }))
    setShowToolBar(false)

    editorRef.current.setContent(data?.ItemData);
  };
  return (
    <div onDoubleClick={showEditor}>
      <div >
        {showhideEditor === false && (
          <div>
            {ReactHtmlParser(typedData || 'Enter Text')}
          </div>
        )}

        {/* {showhideEditor === false && (
          <div
            style={{ width: "100%" }}
            dangerouslySetInnerHTML={{
              __html: data?.ItemData ? data?.ItemData : "Neque porro quisquam",
            }}
          ></div>
        )} */}
      </div>

      <Modal
        width={1046}
        title='Text Editor'
        maskClosable={false}
        onOk={handleSaveText}
        onCancel={cancelText}
        visible={showhideEditor}

        footer={
          <Space>
            <Button
              onClick={handleSaveText}
              variant="primary"
              color="white"
              customStyles={{ width: "100px" }}
              size='small'
              category={confirmLoading ? "load-text" : "text"}
              text="Confirm"
            >

              Confirm
            </Button>
            <Button

              onClick={cancelText}
              variant="secondary"
              className="eco-mr-8"
              customStyles={{ width: "100px" }}
              size="small"

            >
              Cancel
            </Button>
          </Space>
        }

      >
        {editorError && (
          <Alert message='Editor cannot be blank' type='error' showIcon />
        )}
        {showhideEditor === true && (
          <div className='eco-mt-12' style={{ position: "inherit", zIndex: "10000000" }}>
            <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={props?.defaultText || ''}

              init={{
                branding: false,
                statusbar: false,
                indent: false,
                content_css: "writer",
                editor_css: showhideEditor ? "/textEditor.css" : "",
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image",
                  "charmap print preview anchor help",
                  "searchreplace visualblocks code",
                  "insertdatetime media table paste wordcount", "textcolor",
                ],
                toolbar:
                  "undo redo | bold italic underline | fontselect fontsizeselect formatselect | forecolor backcolor blockquot | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist |      template link anchor codesample  ",

              }}
              onChange={handleEditorChange}
              apiKey='ssv2iarxt5sjvufjszf9lrat3wu395eynq4hpttb0gdyzbnh'
            />


          </div>
        )}
      </Modal>

    </div>
  );
}
