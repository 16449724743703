import config from "react-global-configuration";
config.set(
  {
    backendURL: process.env.REACT_APP_API_URL,
    API_productregistry: process.env.REACT_APP_API_productregistry,
    API_URL: process.env.REACT_APP_API_URL,

    rest_API_productregistry: process.env.REACT_APP_rest_API_productregistry,
    rest_API_productregistry_V2:
      process.env.REACT_APP_rest_API_productregistry_V2,
    ProductjwtAud: process.env.REACT_APP_ProductjwtAud,
    ProductjwtIss: process.env.REACT_APP_ProductjwtIss,
    ProductprivateKey: process.env.REACT_APP_ProductprivateKey.replace(
      /\\n/g,
      "\n"
    ),
  },
  { freeze: false }
);
