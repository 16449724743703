import { Modal } from "antd";
import React, { useState, useEffect, useReducer } from "react";
import {
  Stack,
  Typography,
  StackItem,
  Icon,
  Accordion,
  Card,
  Message,
  Row,
  Col,
  Tabs,
  Tooltip
} from "witmeg-ui-system";
import ImageUpload from "../Dashboard/Forms/ImageUpload";
import IconWidgetPopup from "../GlobalIconUploader/IconWidgetPopup";
import axios from "axios";
import { generateImageUploadServerToken } from "../utilities/tokenGenerators";
import globalValues from "../../configs/globalValues";
import {
  getHomeLayoutByID,
  getHomeLayoutByPageID,
  updateHomeLayout,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { useSelector, useDispatch } from "react-redux";
import { setCommonState } from "../../redux/commonReducer";
import { hot } from "react-hot-loader/root";

function IconWidget({ widgetWidth, IconID, widgetID, checkData, PreviewButton, pageID }) {
  const [ImageUpload, setImageUpload] = useState(false);

  const [imageInformationValues, setImageInformationValues] = useState({});
  const [data, setData] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();
  const [imageError, setImageError] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [iconText, setIconText] = useState(null);
  const [toolTip, setToolTip] = useState(null);
  const [textFontSize, setTextFontSize] = useState('20');
  const [iconBackground, setIconBackground] = useState('');
  const [iconWidgetProps, setIconWidgetProps] = useState({})
  const [parsedData, setParsedData] = useState()
  const [editedFontSize, setEditedFontSize] = useState('20')

  const handleShowPopup = () => {

    if (PreviewButton == false) {
      dispatch(
        setCommonState({
          draggableState: true,
        })
      );
      setImageUpload(true);
    }
  };

  console.log('widgetWidth==>> ', widgetWidth)
  console.log('widgetWidth==>> ', iconText, textFontSize)

  const handelLogoImage = (data) => {
    setIconWidgetProps({ imageUrlSrc: data });
  };

  useEffect(() => {
    setImageInformationValues({ ...iconWidgetProps, iconText: iconText, toolTip: toolTip, textFontSize: textFontSize, iconBackground: iconBackground, IconID: widgetID });
  }, [iconText, toolTip, textFontSize, textFontSize, iconBackground, iconWidgetProps, data, widgetID])

  console.log('imageInformationValues: ', imageInformationValues)
  console.log('imageInformationValues - data : ', data)

  useEffect(async () => {
    // console.log(props);
    await fetchData();
  }, []);

  useEffect(() => {
    if (checkData) {
      setData(checkData);
    }
  }, [checkData]);

  // if (widgetID.length > 4) {
  //   // localStorage.setItem("widgetID", widgetID);

  //   console.log("WIDGETID", widgetID);
  // }

  const wid = localStorage.getItem("widgetID");

  // console.log("widgetID - out", widgetID);

  const fetchData = async () => {
    let deviceState = sessionStorage.getItem("deviceState");
    if (pageID) {
      const getData = await getHomeLayoutByID({
        ID: widgetID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      })
        .then((res) => {
          setData(res[0]);
          setParsedData(JSON.parse(res[0].ItemData))
          setEditedFontSize(JSON.parse(res[0].ItemData).textFontSize)
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      const getData = await getHomeLayoutByID({
        ID: widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      })
        .then((res) => {
          if (deviceState === "desktop") {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {
              setData(res[0]);
              // forceUpdate()
              // setParsedData(JSON.parse(res[0].ItemData)).replace(/[[\]]/g, '')
              setParsedData(JSON.parse(res[0].ItemData))
              setEditedFontSize(JSON.parse(res[0].ItemData).textFontSize)
              console.log('image upload data==>> ', JSON.parse(res[0].ItemData))
            }
          } else if (deviceState == "mobile") {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0]);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };


  const onOk = async () => {
     

    // const imageInformationValuesString = JSON.stringify(imageInformationValues).replace(/[[\]]/g, '')
    const imageInformationValuesString = JSON.stringify(imageInformationValues)

    console.log('image upload data==>> Object', imageInformationValues)
    console.log('image upload data==>> JSON_String', imageInformationValuesString)

     ;
    //fetchData();
    let Image;
    if (pageID) {
      if (imageInformationValues.length == 0) {
        setImageError(true);
      } else {
        setConfirmLoading(true);
        // Image = await handleImageUpload(imageInformationValues);
        let PropspageID = await pageID;
        const updateWidget = await updateHomeLayout({
          ID: widgetID,
          ItemHeading: "icon",
          Item: "icon",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.Y,
          Type: 'icon',
          ItemData: imageInformationValuesString,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: PropspageID,
          CompanyID: sessionStorage.getItem("company"),
        }).then(async (response) => {
          // console.log(response);

          // console.log("IMAGE WIDGET GET ITEM LINE 132 ===>", response);

          Message.success("Widget edited successfully");

          setImageUpload(false);
          setImageError(false);
          setConfirmLoading(false);
          // window.location.reload();
          // window.dispatchEvent(new Event("resize"));
          fetchData();
        });
      }
    } else {
      if (imageInformationValues.length == 0) {
        setImageError(true);
      } else {
        setConfirmLoading(true);
        await updateHomeLayout({
          ID: widgetID,
          ItemHeading: "icon",
          Item: "icon",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.Y,
          Type: 'icon',
          ItemData: imageInformationValuesString,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: "",
          CompanyID: sessionStorage.getItem("company"),
        });
        Message.success("Widget edited successfully");

        setImageUpload(false);
        setImageError(false);
        setConfirmLoading(false);
        fetchData();
        dispatch(setCommonState({
          draggableState: false
        }))
      }
    }
  };

  useEffect(() => {
    const resizedFontSize = editedFontSize * widgetWidth / data?.Width
    setParsedData({ ...parsedData, textFontSize: resizedFontSize })

    console.log('ItemData==>> ', editedFontSize, parsedData?.textFontSize, widgetWidth, data?.Width)

  }, [widgetWidth])

  console.log('ItemData==>> ', parsedData, IconID)

  return (
    <>
      <Row style={{ height: '100%' }}>
        <Col span={24}>
          {!data?.ItemData && (
            <div
              onDoubleClick={handleShowPopup}
              className="image-widget-component eco-mt-40"
            >
              <Stack
                direction="column"
                horizontalAlign="space_between"
                verticalAlign="v_center"
              >
                <Icon name="heart" size={80} type="filled" />
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </Stack>
            </div>
          )}

          {parsedData && (
            <>
              <Tooltip title={toolTip ? parsedData.toolTip : ''}>
                <Row bac onDoubleClick={handleShowPopup} style={{ height: '100%' }}>
                  <Col span={24} style={{ position: "relative" }} >
                    <img
                      alt=""
                      src={parsedData.imageUrlSrc}
                      draggable="false"
                      style={{ width: "100%", height: "100%" }}
                    />
                    <p style={{ fontSize: parsedData.textFontSize + 'px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{parsedData.iconText}</p>
                  </Col>
                </Row>
              </Tooltip>
            </>
          )}
        </Col>
      </Row>

      <IconWidgetPopup
        ImageUpload={ImageUpload}
        setImageUpload={setImageUpload}
        handelLogoImage={handelLogoImage}
        onOk={onOk}
        confirmLoading={confirmLoading}
        imageError={imageError}
        setImageError={setImageError}
        iconText={iconText}
        toolTip={toolTip}
        setIconText={setIconText}
        setToolTip={setToolTip}
        textFontSize={textFontSize}
        iconBackground={iconBackground}
        setIconBackground={setIconBackground}
        setTextFontSize={setTextFontSize}
      />
    </>
  );
}

export default hot(IconWidget);
