import React from "react";
import PropTypes from "prop-types";
import defaultCardIcon from "../../../../assets/img/default-card-icon.png";
import { Stack, StackItem, Typography } from "witmeg-ui-system";

function LayoutTwo(props) {
  const {
    title = "Title",
    description = "Description",
    image = defaultCardIcon,
    styleData = {},
    designData = {},
  } = props;
  return (
    <Stack direction="column" verticalAlign="v_start">
      <StackItem>
        <Stack className="eco-mb-16">
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "4px",
              background: "#F4F4F4",
              marginRight: "16px",
            }}
          >
            <img src={image} width="60" />
          </div>
          <div
            style={{
              padding: "10px",
              borderRadius: "4px",
              width:"100%"
            }}
          >
            <h3
              style={{
                fontFamily: designData.titleFontFamily,
                color:designData.titleFontColor,
                fontSize: `${designData.titleFontSize}px`,
                fontStyle: designData.titleStyleItalic?"italic":"",
                textDecoration: designData.titleStyleTextUnderline?"underline":"",
                fontWeight: designData.titleStyleBold?"bold":'',
                textAlign: designData.titleFontAlign,
              }}
            >
              {title}
            </h3>
          </div>
        </Stack>
      </StackItem>
      <StackItem>
        <div
          style={{
            borderRadius: "4px",
            width: "100%",
            height: "100px",
            padding: "20px",
            fontFamily: designData.descriptionFontFamily,
            fontSize: `${designData.descriptionFontSize}px`,
            fontStyle: designData.descriptionStyleItalic?"italic":"",
            textDecoration: designData.descriptionStyleTextUnderline?"underline":"",
            fontWeight: designData.descriptionStyleBold? "bold":'',
            textAlign: designData.descriptionFontAlign,
            overflowWrap: "break-word",
            lineHeight: "initial"
          }}
        >
          {description}
        </div>
      </StackItem>
    </Stack>
  );
}

LayoutTwo.propTypes = {};

export default LayoutTwo;
