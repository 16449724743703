import React, { useState, useRef, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Alert,
  Button,
  Card,
  Icon,
  Message,
  Popup,
  ProgressBar,
  Space,
  Stack,
  StackItem,
  Typography,
} from "witmeg-ui-system";
import { Modal } from "antd";
import "../../GlobalImageUploader/ImageUploader.css";
import { faSlash } from "@fortawesome/free-solid-svg-icons";

import "./BasicInformation.css";

function LogoImageUpload(props) {
  const [src, setSrc] = useState(null);
  /* Commented for if needed in the future */
  // const [crop, setCrop] = useState({ unit: "%", width: 50, aspect: 16 / 9 });
  const [crop, setCrop] = useState();
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [testCrop, setTestCrop] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blobFile, setBlobFile] = useState(null);
  const [getImage, setGetImage] = useState(false);
  const [imageSizeValidation, setImageSizeValidation] = useState(false);
  const [imageTypeValidation, setImageTypeValidation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [logo, setLogo] = useState('');

  // console.log(props.selectedProduct?.Logo);

  useEffect(() => {
    const { selectedProduct } = props;
    setLogo(selectedProduct?.Logo);
  }, [props.selectedProduct?.ID]);

  //File Validation On TYPE
  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };
  const selectImage = (e) => {
    // console.log("sdgsgs", image);

    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];

      console.log("IMAGE SIZE", image.size);

      const MAX_FILE_SIZE = 25000; //25MB

      const fileSieValidation = image.size / 1024;

      if (fileSieValidation > MAX_FILE_SIZE) {
        setImageSizeValidation("Image size is greater than 25 MB");
        setIsSuccess(true);
      } else {
        if (isValidFileUploaded(image)) {
          setImageSizeValidation("");
          setIsSuccess(false);
          const reader = new FileReader();
          reader.addEventListener("load", () => setSrc(reader.result));
          reader.readAsDataURL(image);
        } else {
          setIsSuccess(true);

          setImageTypeValidation("Image should be JPG, PNG OR JPEG");
        }
      }
    }
    // setSrc(URL.createObjectURL(file));
    setIsModalVisible(true);
  };

  const onCropComplete = (crop) => {
    console.log("IAM ", crop);
    makeClientCrop(crop);
    setTestCrop(crop);
  };

  async function makeClientCrop(crop) {
    console.log(testCrop);
    if (image && crop.width && crop.height) {
      const croppedImageUrl = await cropImageNow(image, crop, "newFile.jpeg");
      setOutput(croppedImageUrl);
    }

    // setIsModalVisible(false);
  }

  const cropImageNow = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          //console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        let fileUrl = window.URL.createObjectURL(blob);
        //console.log(blob.name)
        //console.log(this)
        resolve(fileUrl);
        setBlobFile(blob); // Set blob image inside the state here
      }, "image/png");
    });
  };

  function handleClear() {
    setSrc(null);
    setOutput(null);
    setCrop();
    console.log("SASD", props.selectedProduct?.Logo);
    props.handelLogoImage(null);
    props.setLogoUpdated(true);
    setLogo('')
    Message.success("Logo deleted successfuly.")
    
  }

  function onOk() {
    props.handelLogoImage(blobFile);
    console.log("SASD", blobFile);
    setGetImage(true);

    props.setLogoUpdated(true);
    setIsModalVisible(false);
  }

  function onCancel() {
    setIsModalVisible(false);
    setSrc(null);
    setOutput(null);
    setCrop();
  }

  function onImageLoaded(images) {
    setImage(images);
  }
  const inputFile = useRef(null);

  const onClick = (e) => {
    inputFile.current.click();
  };

  return (
    <div>
      <Stack
        className='image-upload-section'
        direction='column'
        onClick={onClick}
        horizontalAlign='h_center'
        verticalAlign='v_center'
      >
        <input
          type='file'
          accept='image/*'
          // onChange={selectImage}
          onChange={(event) => {
            selectImage(event);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
          ref={inputFile}
          style={{ display: "none" }}
        />
        <Icon name='upload-outline' type='filled' />
        <Typography type='text'>Click here to upload a file</Typography>


        {/* <Typography type="text" size="small" color="gray_base">(Max. file size 25MB)</Typography> */}
      </Stack>
      {isSuccess && (
        <>
          {" "}
          {imageSizeValidation && (
            <div className="eco-mt-20">
              <Alert showIcon message={`${imageSizeValidation}`} type='error'
                closable onClose ={ () =>setImageSizeValidation(false) }/>
            </div>

          )}
          {imageTypeValidation && (
            <div className="eco-mt-20">
              <Alert showIcon message={`${imageTypeValidation}`} type='error'
                closable onClose ={ () =>setImageTypeValidation(false) }/>
            </div>


          )}
        </>
      )}
      <div>
        {src && isSuccess === false && (
          <Popup
            modalTitle={<h3>Logo Crop</h3>}
            visible={isModalVisible}
            footer={
              <Space>
                <Button onClick={onOk} variant='primary' size='small'>
                  Done
                </Button>
                <Button
                  onClick={onCancel}
                  variant='secondary'
                  className='eco-mr-8'
                  size='small'
                >
                  Cancel
                </Button>
              </Space>
            }
            style={{ height: "800px", width: "800px" }}
            width={550}
          >
            <div>
              <ReactCrop
                src={src}
                onImageLoaded={setImage}
                crop={crop}
                onChange={setCrop}
                onComplete={onCropComplete}
              />
              <br />

              <br />
              <br />
            </div>
          </Popup>
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        {logo && getImage === false && (
          <>
            <Typography type='title' level={4}>
              Manage Uploads
            </Typography>
            <br />
            <Card
              shadow='default'
              disabled={false}
              type={"complex"}
              content={
                <Stack className='eco-pa-4' style={{ flexWrap: "wrap" }}>
                  <StackItem className='thumbnail-image'>
                    <img
                      alt='Crop'
                      style={{ width: "100%" }}
                      src={logo}
                    />
                  </StackItem>

                  <StackItem
                    style={{
                      flexGrow: "3",
                      height: "100px",
                      padding: "8px",
                    }}
                  >
                    {" "}
                    <div className='eco-mt-4'>
                      {/* {renderImageSizeInfo(fl.imageType)} */}
                    </div>
                  </StackItem>
                  <StackItem
                    style={{
                      height: "100px",
                      padding: "8px",
                      justifyContent: "flex-end",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={handleClear}
                      customStyles={{ border: "none" }}
                    >
                      <Icon name='trashcan' color='red' />
                    </Button>
                  </StackItem>
                </Stack>
              }
              width='100%'
              height='fit-content'
            />
          </>
        )}

        {output && getImage === true && (
          <>
            <Typography type='title' level={4}>
              Manage Uploads
            </Typography>
            <br />
            <Card
              shadow='default'
              disabled={false}
              type={"complex"}
              content={
                <Stack className='eco-pa-4' style={{ flexWrap: "wrap" }}>
                  <StackItem className='thumbnail-image'>
                    <img alt='Crop' style={{ width: "100%" }} src={output} />
                  </StackItem>

                  <StackItem
                    style={{
                      flexGrow: "3",
                      height: "100px",
                      padding: "8px",
                    }}
                  >
                    {" "}
                    <div className='eco-mt-4'>
                      {/* {renderImageSizeInfo(fl.imageType)} */}
                    </div>
                  </StackItem>
                  <StackItem
                    style={{
                      height: "100px",
                      padding: "8px",
                      justifyContent: "flex-end",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={handleClear}
                      customStyles={{ border: "none" }}
                    >
                      <Icon name='trashcan' color='red' />
                    </Button>
                  </StackItem>
                </Stack>
              }
              width='100%'
              height='fit-content'
            />
          </>
        )}
      </div>
    </div>
  );
}

export default LogoImageUpload;
