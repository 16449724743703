import React, { useRef, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Icon, Popup, Stack, StackItem, Typography, Tabs, Row, Col, Button, Switch, Message, Space, Spin } from "witmeg-ui-system";
import "../Settings/SettingsOverlay.css";
import LandingPages from "./Themes/LandingPages";
import GlobalSettingsForm from "./Themes/ThemeSettings/GlobalSettingsForm";
import ElementStylesForm from "./Themes/ThemeSettings/ElementStylesForm";
import { createSettings, editCompanyById, getCompanyByID, publishHomelayout, sitePublishUnPublish, updateSettings } from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { Popover } from "antd";
import { useForm, FormProvider } from "react-hook-form";
import { useOutsideClick } from "../Hooks/UseOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import { setCommonState } from "../../redux/commonReducer";

const SettingsOverlay = (props) => {
  const [showModal, setShowModal] = useState(false);
  //Get company publish state from redux store
  const CompanyIsPublish = useSelector((state) => state.companyData.companyData.IsPublished)
  const CompanyIsPublishRedux = useSelector((state) => state.commonData.publishState)
  const [confirmLoadingSettings, setConfirmLoadingSettings] = useState(false);

  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      top: "40px",
      bottom: "40px",
      right: "40px",
      left: "40px",
      backgroundColor: "#cecece",
      image: "",
    },
  });
  const onSubmit = (data) => {
    async function submitData(formData) {
      setConfirmLoadingSettings(true)
      if (!formData.ID)
        await createSettings(formData);
      else
        await updateSettings(formData);
      setConfirmLoadingSettings(false)
      setShowThemeSettingsModal(false);
      props.onChangeThemeSettings()
    }
    submitData(setThemeSettingsData(data));

  };

  console.log(CompanyIsPublish)
  const setThemeSettingsData = (data) => {
    return {
      ID: data?.id,
      CompanyID: sessionStorage.getItem("company"),
      GlobalSettings: {
        PageWidth: "100X", // No UI component to update
        BackgroundColour: data.backgroundColor,
        BackgroundImage: {
          Name: "bgImage",
          URL: data.image,
        },
        Padding: JSON.stringify({
          top: data?.top,
          right: data?.right,
          bottom: data?.bottom,
          left: data?.left,
        }),
      },
      ElementStyles: {
        ElementFormatting: "H1",
        FontName: "Arial",
        FontSize: 12,
        FontWeight: "Light",
        FontColor: "#25DE48",
        TextDirection: "Center",
        FontFormat: "Bold",
      },
    };
  };

  const [showSiteSettingsModal, setShowSiteSettingsModal] = useState(false);

  const [showThemeSettingsModal, setShowThemeSettingsModal] = useState(false);

  const [selectedTheme, setSelectedTheme] = useState("Stationary");

  const [loadSaving, setLoadSaving] = useState(false);

  const [activeThemeCard, setActiveThemeCard] = useState(null);

  const wrapperRef = useRef();

  const themeSettingsRef = useRef();

  const getTheme = (e) => {
    console.log(e.target.innerText);
    setSelectedTheme(e.target.innerText);
  };

  const content = (
    <Stack direction="column" verticalAlign="v_start">
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Stationary
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Saree Store
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          POS
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Paint store
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Meat Industry
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Grocery Wholesale
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Grocery Retail
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Electronics
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Cosmetics
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          Clothing
        </Typography>
      </div>
      <div className="theme-selection" onClick={(e) => getTheme(e)}>
        <Typography type="text" alignment="left">
          General
        </Typography>
      </div>
    </Stack>
  );

  const tabContent = [
    {
      tabID: "1",
      tabName: (
        <Stack horizontalAlign="space_between">
          <Typography type="text">{selectedTheme}</Typography>
          <Popover placement="bottomRight" content={content} trigger="click">
            <div>
              <Icon name="chevron-down" />
            </div>
          </Popover>
        </Stack>
      ),
      content: (
        <LandingPages handleCloseThemeSelector={() => setShowModal(false)} />
      ),
    },
  ];
  const [themesTab, setThemesTab] = useState([
    {
      tabID: "1",
      tabName: "Global Settings",
      content: <GlobalSettingsForm />,
    },
    {
      tabID: "2",
      tabName: "Element Styles",
      content: <ElementStylesForm />,
    },
  ]);

  const [showUnpublishPopup, setShowUnpublishPopup] = useState(false);

  async function handleUnPublished(data) {
     
    await sitePublishUnPublish({
      ID: sessionStorage.getItem("company"),
      IsPublished: false,
    }).then((response) => {
      Message.success("The site was un published");
      setShowUnpublishPopup(false);
      setShowSiteSettingsModal(false);
      dispatch(setCommonState({
        publishState: true
      }))
    });
  }

  const handleUpdateTheme = async () => {
    setLoadSaving(true);
    await getCompanyByID({
      ID: sessionStorage.getItem("company"),
      OrganisationID: "",
      SubDomain: "",
      CustomerDomain: "",
      Start: 0,
      Limit: 1,
    }).then(async (response) => {
      let reqbody = Object.assign(response[0], {
        Theme: activeThemeCard.ThemeName,
      });
      await editCompanyById(reqbody).then((res) => {
        Message.success("Changed Theme sucessfully");
        setShowModal(false);
        setLoadSaving(false);
      });
    });
  };


  useOutsideClick(
    wrapperRef,
    [showSiteSettingsModal, showModal, showThemeSettingsModal],
    props.hideSettingsOverlay
  );

  return (
    <div className="settings__overlay" ref={wrapperRef}>
      <Stack
        horizontalAlign="space_between"
        style={{ padding: "20px", borderBottom: "1px solid #D8D8D8" }}
      >
        <Typography alignment="center" textTransform="uppercase" pattern="bold">
          SETTINGS
        </Typography>
        <div onClick={props.hideSettingsOverlay}>
          <CloseOutlined className="close-name" />
        </div>
      </Stack>
      <Stack direction="column" className="settings-section">
        <StackItem>
          <Stack
            horizontalAlign="h_start"
            verticalAlign="v_center"
            className="settings-box"
            onClick={() => setShowSiteSettingsModal(true)}
          >
            <div className="eco-mr-32">
              <Icon name="settings" />
            </div>
            <div>
              <Typography
                type="title"
                level={5}
                pattern="bold"
                alignment="left"
              >
                Site Settings
              </Typography>
            </div>
          </Stack>
        </StackItem>
        <StackItem>
          <Stack
            horizontalAlign="h_start"
            verticalAlign="v_center"
            className="settings-box"
            onClick={() => setShowModal(true)}
          >
            <div className="eco-mr-32">
              <Icon name="file-image" />
            </div>
            <div>
              <Typography
                type="title"
                level={5}
                pattern="bold"
                alignment="left"
              >
                Change Themes
              </Typography>
            </div>
          </Stack>
        </StackItem>
        <StackItem>
          <Stack
            horizontalAlign="h_start"
            verticalAlign="v_center"
            className="settings-box"
            onClick={() => setShowThemeSettingsModal(true)}
          >
            <div className="eco-mr-32">
              <Icon name="settings" />
            </div>
            <div>
              <Typography
                type="title"
                level={5}
                pattern="bold"
                alignment="left"
              >
                Theme Settings
              </Typography>
            </div>
          </Stack>
        </StackItem>
      </Stack>
      <Popup
        width={765}
        modalVisible={showModal}
        maskClosable={false}
        closable
        modalTitle="Change Themes"
        footer={
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              {loadSaving ? <Spin /> : null}
              <Button
                variant="primary"
                className="save-btn"
                onClick={handleUpdateTheme}
                disabled={loadSaving}
              >
                Save
              </Button>
              <Button variant="outline" className="save-btn">
                Cancel
              </Button>
            </Space>
          </Stack>
        }
        onCancel={() => setShowModal(false)}
      >
        <div>
          <Typography type="text" style={{ marginBottom: "60px" }}>
            Choose a theme below to use on the website
          </Typography>
          <Row justify="center">
            <Col span={24}>
              <Tabs tabContent={tabContent} tabType="card" />
            </Col>
          </Row>
        </div>
      </Popup>
      <Popup
        width={650}
        modalVisible={showSiteSettingsModal}
        maskClosable={false}
        closable
        modalTitle="Site Settings"
        footer={
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              <Button variant="primary" className="save-btn">
                Save
              </Button>
              <Button variant="outline" className="cancel-btn">
                Cancel
              </Button>
            </Space>
          </Stack>
        }
        onCancel={() => setShowSiteSettingsModal(false)}
      >
        <Row justify="center">
          <Col span={24}>
            <div
              style={{ borderRadius: "4px 4px 0px 0px" }}
              className="settings-card"
            >
              <Typography
                type="title"
                level={5}
                className="eco-mb-24"
                pattern="bold"
              >
                No-index
              </Typography>
              <Row gutter={[16, 32]}>
                <Col span={24}>
                  <Stack horizontalAlign="space_between">
                    <Typography type="text" color="gray_base">
                      Block search engine from crawling this site
                    </Typography>
                    <Switch defaultChecked />
                  </Stack>
                </Col>
                <Col span={20}>
                  <Typography
                    type="text"
                    color="gray_base"
                    className="eco-mb-44"
                  >
                    Block search engines from crawling this page so that it does
                    not appear in search results.
                  </Typography>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{ borderRadius: "0px 0px 4px 4px" }}
              className="settings-card"
            >
              <Typography
                type="title"
                level={5}
                className="eco-mb-24"
                pattern="bold"
              >
                Unpublish Site
              </Typography>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography type="text" color="gray_base">
                    When you unpublish a site, it is no longer visible online
                    and cannot be accessed by users until it is
                    republished.Unpublishing does not affect your site billing
                    subscription.
                  </Typography>
                </Col>
                <Col span={24}>
                  <Stack horizontalAlign="h_end" className="eco-mb-24">
                    <Button
                      onClick={() => {
                        // setShowSiteSettingsModal(false);
                        setShowUnpublishPopup(true);
                      }}
                      variant="primary"
                    >
                      Unpublish Site
                    </Button>
                  </Stack>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Popup>

      {/* Company Unpublish Popup */}
      <Popup
        modalVisible={showUnpublishPopup}
        centered
        width={450}
        onCancel={() => setShowUnpublishPopup(false)}


      >
        <div>
          <Stack direction="column" verticalAlign="v_start">
            <Typography
              type="title"
              level={4}
              pattern="bold"
              className="eco-mb-8"
            >
              Are you sure you wish to proceed?
            </Typography>
            <Typography type="text" className="eco-mb-44">
              Unpublishing the site would not permanently delete all content and
              data from our servers.
            </Typography>
          </Stack>
          <Stack horizontalAlign="h_center">
            <Space size="middle">
              <Button
                variant="secondary"
                onClick={(e) => { e.stopPropagation(); setShowUnpublishPopup(false) }}
                className="unpublish-cancel-btn"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleUnPublished}
                className="unpublish-btn"
              //disabled={!CompanyIsPublish || !CompanyIsPublishRedux}
              >
                Unpublish Site
              </Button>
            </Space>
          </Stack>
        </div>
      </Popup>

      {/* Theme Settings */}
      {showThemeSettingsModal && (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            ref={themeSettingsRef}
          >
            <Popup
              width={650}
              modalTitle="Theme Setting"
              modalVisible={showThemeSettingsModal}
              maskClosable={false}
              confirmLoading={confirmLoadingSettings}
              closable
              footer={
                <Space size="middle">
                  <Button
                    variant="primary"
                    className="save-btn"
                    onClick={(e) =>
                      themeSettingsRef.current.dispatchEvent(
                        new Event("submit", { cancelable: true, bubbles: true })
                      )
                    }
                  >
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    className="cancel-btn"
                    onClick={() => setShowThemeSettingsModal(false)}
                  >
                    Cancel
                  </Button>
                </Space>
              }
              onCancel={() => setShowThemeSettingsModal(false)}
            >
              <div>
                <Row justify="center">
                  <Col span={24}>
                    <Tabs
                      tabContent={themesTab}
                      tabType="card"
                      heightLightTab={false}
                    />
                  </Col>
                </Row>
              </div>
            </Popup>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default SettingsOverlay;
