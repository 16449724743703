import React, { useEffect, useRef, useState } from 'react';
import { Icon, Popup, Stack, StackItem, Typography, Row, Col, Button, Space, Spin, Radio, Checkbox, FileUploader, Accordion, Select, Label, Switch, Message } from 'witmeg-ui-system';
import { Popover, Transfer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './RestaurantSettingsOverlay.css';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getCloudLocations, RESTAURANT_SETTINGS_TYPES } from '../utilities/helpers';
import { addRestaurantMenuSettings, fetchRestaurantMenuSettings, updateRestaurantMenuSettings } from '../utilities/restaurantSettingsActions';
import QRImage from "../../images/qr-code-image.png";
import { useOutsideClick } from '../Hooks/UseOutsideClick';
import html2canvas from 'html2canvas';
import { QRCode } from 'react-qrcode-logo';
import { jsPDF } from 'jspdf';
import { addRestaurantMenuSettingsData, createRestaurantQRCodeSettings, getRestaurantMenuSettingsData, getRestaurantQRCodeSettings, updateRestaurantMenuSettingsData, updateRestaurantQRCodeSettings } from '../utilities/apiRequests/neutriPosServerRequests';
import ImageUploader from '../Settings/ImageUploader';
import { setRestaurantDefaultOrderType } from '../../redux/resturantWidgetReducer';

const orderTypeModalInitialState = {
  selectedTypes: [],
  defaultType: null,
};

const locationSettingsModalInitialState = {
  availableLocations: [],
  selectedLocations: [],
  selectedLocationKeys: [],
  defaultLocation: null,
  isLoading: false,
};

const availableOptionsModalInitialState = {
  selectedOption: RESTAURANT_SETTINGS_TYPES.AVAILABLE_OPTION.OPT.CHECKOUT,
  data: [],
}

const qrCodeUploadImageModalInitialState = {
  selectedImageURL: null,
  isLoading: false,
}

const qrCodeGeneratorModalInitialState = {
  logoImageURL: '',
  locations: [],
  selectedLocation: null,
  table: {
    tables: [],
    selectedTable: {},
    generatedURL: '',
    isLoading: false,
  },
  collection: {
    generatedURL: '',
    isLoading: false,
  },
  delivery: {
    generatedURL: '',
    isLoading: false,
  },
}

const restaurantMenuSettingsInitialState = {
  orderTypeSettings: [],
  availableOptions: {
    selected: [],
    paymentMethods: [],
    orderTypePaymentMethods: [],
  },
  availableLocations: [],

  // CardTypes
  // PaymentMethods
  // PaymentMethods_OrderTypes
  // PaymentGateway
  // ExternalAPI
};

/* 
  Restaurant Settings sidebar modal

  Contains:
    - Order Types
    - Upload Images
    - QR Code Generator
    - Available Options
    - Location Settings
*/
const RestaurantSettingsOverlay = (props) => {
  console.log(props)
  const dispatch = useDispatch();

  /* Popups visibility state */
  const [showOrderTypesModal, setShowOrderTypeModal] = useState(false);
  const [showQRUploadImageModal, setShowQRUploadImageModal] = useState(false);
  const [showQrCodeGeneratorModal, setShowQrCodeGeneratorModal] = useState(false);
  const [showAvailableOptionsModal, setShowAvailableOptionsModal] = useState(false);
  const [showLocationSettingsModal, setShowLocationSettingsModal] = useState(false);

  const wrapperRef = useRef();

  const [cloudLocationsData, setCloudLocationsData] = useState([]);

  const [loadSaving, setLoadSaving] = useState(false);
  /* Popup state values */
  const [orderTypeModalState, setOrderTypeModalState] = useState(orderTypeModalInitialState);
  const [locationSettingsModalState, setLocationSettingsModalState] = useState(locationSettingsModalInitialState);
  const [availableOptionsModalState, setAvailableOptionsModalState] = useState(availableOptionsModalInitialState);
  const [qrCodeUploadImageModalState, setQRCodeUploadImageModalState] = useState(qrCodeUploadImageModalInitialState);
  const [qrCodeGeneratorModalState, setQRCodeGeneratorModalState] = useState(qrCodeGeneratorModalInitialState);
  const [tables, setTables] = useState([])
  const [selectedTable, setSelectedTable] = useState({})
  const [takeawayQRCode, setTakeawayQRCode] = useState("")
  const [deliveryQRCode, setDeliveryQRCode] = useState("")
  const [logoImage, setLogoImage] = useState(null)
  console.log('My logs', qrCodeGeneratorModalState)

  const [restaurantSettingsState, setRestaurantSettingsState] = useState(restaurantMenuSettingsInitialState);

  const [restaurantOrganizationID, setRestaurantOrganizationID] = useState(null);

  /* Redux data access */
  const { menuWidgetData } = useSelector(state => state.resturantWidget);
  const { companyData } = useSelector(state => state.companyData);


  useEffect(async () => {
    const locations = await getCloudLocations();
    setCloudLocationsData(locations ?? []);
    const rmOrganization = await localStorage.getItem('RMOrganization');
    setRestaurantOrganizationID(rmOrganization);
  }, []);

  const getBase64ImageFromDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))
  console.log(logoImage)
  useEffect(async () => {
    if (cloudLocationsData && cloudLocationsData.length > 0 && restaurantOrganizationID) {
      await fetchInitialData();
      getBase64ImageFromDataURL(props.companyLogo).then(data => setLogoImage(data))
      // const _dropdownLocationData = cloudLocationsData.map(_location => ({ value: _location.CloudLocationID, label: _location.CloudLocationName }));
      // setQRCodeGeneratorModalState(prevState => ({ ...prevState, locations: _dropdownLocationData, selectedLocation: _dropdownLocationData[0] }));
    }
  }, [cloudLocationsData, restaurantOrganizationID]);

  useEffect(async () => {
    if (orderTypeModalState.selectedTypes.length > 0) {
      await fetchAvailableOptionsData();
    }
  }, [orderTypeModalState]);

  const initializeOrderDataFromState = () => {
    if (menuWidgetData && menuWidgetData.orderType) {
      const defaultOrderType = _.find(RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT, _.matchesProperty('name', menuWidgetData.orderType));
      setOrderTypeModalState({ selectedTypes: [defaultOrderType], defaultType: defaultOrderType });
    }
  }

  const fetchOrderTypesData = async () => {
    const { OPT } = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES;

    const orderTypeSettings = await fetchRestaurantMenuSettings({
      type: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
      cloudLocationsData,
    });

    if (orderTypeSettings && orderTypeSettings.length > 0) {
      let defaultType = null;
      let selectedTypes = [];

      for (const item of orderTypeSettings[0]) {
        if (item.OrderTypeName === OPT.DINE_IN.name) {
          selectedTypes.push(OPT.DINE_IN);
          if (item.IsDefault) defaultType = OPT.DINE_IN;
        }
        if (item.OrderTypeName === OPT.COLLECTION.name) {
          selectedTypes.push(OPT.COLLECTION);
          if (item.IsDefault) defaultType = OPT.COLLECTION;
        }
        if (item.OrderTypeName === OPT.DELIVERY.name) {
          selectedTypes.push(OPT.DELIVERY);
          if (item.IsDefault) defaultType = OPT.DELIVERY;
        }
        if (item.OrderTypeName === OPT.CUSTOMER_CHOICE.name) {
          if (item.IsDefault) defaultType = OPT.CUSTOMER_CHOICE;
        }
      }

      setOrderTypeModalState({ defaultType, selectedTypes });
      setRestaurantSettingsState(prevState => ({ ...prevState, orderTypeSettings }));

      if (selectedTypes.length < 0) {
        initializeOrderDataFromState();
      }
    }
  }

  const fetchAvailableOptionsData = async () => {
    const paymentMethods = await getPaymentMethods();

    const { OPT } = RESTAURANT_SETTINGS_TYPES.AVAILABLE_OPTION;

    let availableOptionsSettings = await fetchRestaurantMenuSettings({
      type: RESTAURANT_SETTINGS_TYPES.AVAILABLE_OPTION.KEY,
      cloudLocationsData,
    });

    if (availableOptionsSettings && availableOptionsSettings.length > 0) {
      availableOptionsSettings = availableOptionsSettings.flat();
    }

    let paymentMethodsOrderTypeSettings = await fetchRestaurantMenuSettings({
      type: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS_ORDER_TYPES,
      cloudLocationsData,
    });

    setRestaurantSettingsState((prevState) => ({
      ...prevState,
      availableOptions: {
        ...prevState.availableOptions,
        selected: availableOptionsSettings ?? [],
        paymentMethods: paymentMethods ?? [],
        orderTypePaymentMethods: paymentMethodsOrderTypeSettings ?? [],
      },
    }));

    let selectedOption = OPT.CHECKOUT;
    if (availableOptionsSettings && availableOptionsSettings.length > 0) {
      if (availableOptionsSettings[0].MenuWidgetPurpose === OPT.MENU_ONLY.name) selectedOption = OPT.MENU_ONLY;
    }

    let paymentOptionData = [];

    const isPaymentMethodsOrderTypeSettingsAvailable =
      paymentMethodsOrderTypeSettings &&
        paymentMethodsOrderTypeSettings.length > 0 &&
        paymentMethodsOrderTypeSettings[0].length > 0
        ? true
        : false;

    for (const item of orderTypeModalState.selectedTypes) {
      let values = null;
      let isCard = false;
      let isCash = false;

      if (isPaymentMethodsOrderTypeSettingsAvailable) {
        values = _.find(paymentMethodsOrderTypeSettings[0], { OrderTypeName: item.name });
        if (values) {
          isCard = _.find(values.PaymentMethods, { DisplayName: 'Card' }) ? true : false;
          isCash = _.find(values.PaymentMethods, { DisplayName: 'Cash' }) ? true : false;
        }
      }

      let newData = {
        ...item,
        cardAccepted: isCard,
        cashAccepted: isCash
      };

      paymentOptionData.push(newData);
    }

    setAvailableOptionsModalState({
      data: paymentOptionData,
      selectedOption
    });
  }

  const fetchQRCodeSettingsData = async () => {
    setTakeawayQRCode("")
    setDeliveryQRCode("")
    setSelectedTable({})
    const companyBaseURL = sessionStorage.getItem("companyBaseURL")
    await getRestaurantQRCodeSettings({ BaseURL: companyBaseURL, OrganizationID: restaurantOrganizationID, CloudLocationID: qrCodeGeneratorModalState.selectedLocation?.value || cloudLocationsData[0].CloudLocationID }).then(data => {
      const tables = []

      if (data) {
        Object.keys(data.TableQRCodes).forEach((table, index) => {
          tables.push({ key: index, label: table, value: table, table: table, tableQRCode: data.TableQRCodes[table] })
        })
        setTables(tables)
        if (tables.length > 0) {
          setSelectedTable(tables[0])
        }
        setTakeawayQRCode(data.TakeawayQRCode)
        setDeliveryQRCode(data.DeliveryQRCode)
      }
    });

  }

  const fetchLocationSettingsData = async () => {
    const _allLocations = cloudLocationsData.map((_location, _index) => ({
      key: _index,
      label: _location.CloudLocationName,
      value: _location.CloudLocationID,
    }));

    const _locationSettings = await getRestaurantMenuSettingsData({
      SettingType: RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS,
      OrganizationID: restaurantOrganizationID,
    });

    let _selectedLocationKeys = [];
    let _selectedLocations = [];
    let _defaultLocation = null;

    if (_locationSettings && _locationSettings.length !== 0 && _locationSettings[0].AvailableLocations && _locationSettings[0].AvailableLocations.length > 0) {
      for (const __location of _locationSettings[0].AvailableLocations) {
        const __locationData = _.find(_allLocations, { value: __location.CloudLocationID });
        _selectedLocationKeys.push(__locationData.key);
        _selectedLocations.push(__locationData);

        if (__location.IsDefaultLocation) _defaultLocation = __locationData;
      }
    } else {
      _selectedLocationKeys = [_allLocations[0].key];
      _selectedLocations = [_allLocations[0]];
      _defaultLocation = _allLocations[0];
    }

    setLocationSettingsModalState({
      availableLocations: _allLocations,
      defaultLocation: _defaultLocation,
      selectedLocationKeys: _selectedLocationKeys,
      selectedLocations: _selectedLocations,
      isLoading: false,
    });

    setQRCodeGeneratorModalState(prevState => ({ ...prevState, locations: _selectedLocations, selectedLocation: _defaultLocation }));
  }

  const getPaymentMethods = async () => {
    const { OPT } = RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS;

    const paymentSettings = await fetchRestaurantMenuSettings({
      type: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS.KEY,
      cloudLocationsData
    });

    if (!paymentSettings || paymentSettings.length === 0 || paymentSettings[0].length === 0) {
      await addRestaurantMenuSettings({
        type: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS.KEY,
        cloudLocationsData,
        OrganizationID: restaurantOrganizationID,
        data: { PaymentMethod: OPT.CASH.name, DisplayName: OPT.CASH.title, IsCardRelated: false },
      });
      await addRestaurantMenuSettings({
        type: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS.KEY,
        cloudLocationsData,
        data: { PaymentMethod: OPT.CARD.name, DisplayName: OPT.CARD.title, IsCardRelated: true },
      });

      return await fetchRestaurantMenuSettings({
        type: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS.KEY,
        cloudLocationsData
      });
    } else {
      return paymentSettings;
    }
  }

  const fetchInitialData = async () => {
    await fetchOrderTypesData();
    await fetchQRCodeSettingsData();
    await fetchLocationSettingsData();
  };

  const onChangeOrderTypeSelection = (option, action) => {
    setOrderTypeModalState((prevState) => {
      const { OPT } = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES;
      let { selectedTypes, defaultType } = prevState;

      if (action) {
        selectedTypes = [...selectedTypes, option];
        if (!defaultType || defaultType === OPT.DINE_IN) {
          defaultType = option;
        }
      }

      if (!action) {
        selectedTypes = _.without(selectedTypes, option);

        if (defaultType && defaultType === option) {
          defaultType = selectedTypes[0] ?? null;
        }

        if (selectedTypes.length === 1 && defaultType === OPT.CUSTOMER_CHOICE) {
          defaultType = selectedTypes[0];
        }

        if (selectedTypes.length > 1 && defaultType === OPT.CUSTOMER_CHOICE) {
          const filteredDineIn = selectedTypes.filter(i => i.name === OPT.DINE_IN.name);
          defaultType = filteredDineIn[0];
        }
      }
      return { selectedTypes, defaultType };
    });
  };

  const onChangeDefaultOrderType = (option) => {
    setOrderTypeModalState((prevState) => ({ ...prevState, defaultType: option }));
  };

  const onOrderTypeModalSave = async () => {
    setLoadSaving(true);
    const { OPT } = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES;
    const orderTypeOptions = Object.values(OPT);

    const { orderTypeSettings } = restaurantSettingsState;
    const { selectedTypes, defaultType } = orderTypeModalState;

    dispatch(setRestaurantDefaultOrderType({ defaultOrderType: defaultType.name }));

    let addTypeList = [];
    let updateTypeList = [];

    for (const type of orderTypeOptions) {
      const isAvailableInServer = _.find(orderTypeSettings[0], { OrderTypeName: type.name });
      const isAvailableInState = _.find(selectedTypes, { name: type.name });

      if (isAvailableInServer && isAvailableInState) {
        const serverDataList = [];
        for (const item of orderTypeSettings) {
          serverDataList.push(_.find(item, { OrderTypeName: type.name }));
        }

        if (defaultType === isAvailableInState) {
          updateTypeList = [...updateTypeList, { list: serverDataList, update: { IsDefault: true } }];
        }

        if (isAvailableInServer.IsDefault && defaultType !== isAvailableInState) {
          updateTypeList = [...updateTypeList, { list: serverDataList, update: { IsDefault: false } }];
        }
      }

      if (isAvailableInServer && !isAvailableInState) {
        const serverDataList = [];
        for (const item of orderTypeSettings) {
          serverDataList.push(_.find(item, { OrderTypeName: type.name }));
        }

        updateTypeList = [...updateTypeList, { list: serverDataList, update: { IsActive: false } }];
      }

      if (!isAvailableInServer && isAvailableInState) {
        const optionData = {
          SettingType: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
          OrderTypeName: type.name,
          DisplayName: type.title,
        };

        addTypeList = [...addTypeList, { ...optionData, IsDefault: defaultType === isAvailableInState ? true : false }];
      }

      if (type.name === OPT.CUSTOMER_CHOICE.name && defaultType.name === OPT.CUSTOMER_CHOICE.name) {
        const optionData = {
          SettingType: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
          OrderTypeName: type.name,
          DisplayName: type.title,
        };

        addTypeList = [...addTypeList, { ...optionData, IsDefault: true }];
      }
    }

    for (const item of addTypeList) {
      await addRestaurantMenuSettings({
        type: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
        cloudLocationsData,
        data: item,
        OrganizationID: restaurantOrganizationID,
      });
    }

    for (const item of updateTypeList) {
      await updateRestaurantMenuSettings({
        type: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
        cloudLocationsData,
        data: item,
      });
    }

    await fetchOrderTypesData();

    setShowOrderTypeModal(false);
    setLoadSaving(false);
    Message.success("Order Types saved successfully!");
  };

  const onOrderTypeModalCancel = async () => {
    setShowOrderTypeModal(false);
    await fetchOrderTypesData();
  };

  const onQRLogoImageUpload = (_imageURL) => {
    setQRCodeUploadImageModalState((prevState) => ({
      ...prevState,
      selectedImageURL: _imageURL,
    }));
  }

  const onQRCodeUploadImageModalSave = async () => {
    const _newImageURL = qrCodeUploadImageModalState.selectedImageURL ?? "";
    // if(!_newImageURL) {
    //   Message.warning("You may need to upload a image to save!");
    //   return;
    // }

    setQRCodeUploadImageModalState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const _getRequestPromises = cloudLocationsData.map(
      ({ CloudLocationID }) =>
        new Promise(async function (resolve, refuse) {
          resolve(await getRestaurantQRCodeSettings({ CloudLocationID }));
        })
    );

    const _getPromisesResponse = await Promise.all(_getRequestPromises);
    const _isAvailable = _getPromisesResponse[0] && _getPromisesResponse[0].ID && _getPromisesResponse[0].ID !== "" ? true : false;

    let _saveRequestPromises = [];
    const _baseURLCharacters = companyData.CustomerDomain.split('');
    const _baseURL = _baseURLCharacters[_baseURLCharacters.length - 1] === '/' ? companyData.CustomerDomain.replace(/.$/, '') : companyData.CustomerDomain;

    if (_isAvailable) {
      _saveRequestPromises = _getPromisesResponse.map(({ ID, CloudLocationID, OrganizationID }) =>
        new Promise(async function (resolve, refuse) {
          resolve(
            await updateRestaurantQRCodeSettings({
              ID,
              CloudLocationID,
              OrganizationID,
              Logo: _newImageURL,
              BaseURL: _baseURL,
            })
          );
        }));
    } else {
      _saveRequestPromises = cloudLocationsData.map(
        ({ CloudLocationID }) =>
          new Promise(async function (resolve, refuse) {
            resolve(await createRestaurantQRCodeSettings({
              CloudLocationID,
              OrganizationID: restaurantOrganizationID,
              BaseURL: _baseURL,
              Logo: _newImageURL,
            }));
          })
      );
    }

    await Promise.all(_saveRequestPromises);

    setQRCodeGeneratorModalState(prevState => ({
      ...prevState,
      // logoImageURL: _newImageURL,
    }));

    onUploadImageModalCancel();
  };

  const onUploadImageModalCancel = () => {
    setQRCodeUploadImageModalState(qrCodeUploadImageModalInitialState);
    setShowQRUploadImageModal(false);
  };


  const onQRLocationSelectChange = (_value) => {
    setQRCodeGeneratorModalState((prevState) => ({
      ...qrCodeGeneratorModalInitialState,
      locations: prevState.locations,
      selectedLocation: _value,
    }));
    fetchQRCodeSettingsData()
  }

  const onTableSelectChange = (_value) => {
    setSelectedTable(_value)
  }

  const checkForQRCodeSettings = async (_isInitial = false) => {
    const _baseURLCharacters = companyData.CustomerDomain.split('');
    const _baseURL = _baseURLCharacters[_baseURLCharacters.length - 1] === '/' ? companyData.CustomerDomain.replace(/.$/, '') : companyData.CustomerDomain;

    const _requestBody = {
      CloudLocationID: qrCodeGeneratorModalState.selectedLocation.value,
      OrganizationID: restaurantOrganizationID,
      BaseURL: _baseURL,
      Logo: qrCodeGeneratorModalState.logoImageURL,
    }

    let _qrCodeSettings = await getRestaurantQRCodeSettings({ CloudLocationID: _requestBody.CloudLocationID });

    if (_isInitial) return (_qrCodeSettings && _qrCodeSettings.ID && _qrCodeSettings.ID !== "") ? _qrCodeSettings : null;

    if (_qrCodeSettings && _qrCodeSettings.ID && _qrCodeSettings.ID !== "") {
      setQRCodeGeneratorModalState(prevState => ({ ...prevState, logoImageURL: _qrCodeSettings.Logo }));

      if (_qrCodeSettings.Logo === _requestBody.Logo && _qrCodeSettings.BaseURL === _requestBody.BaseURL)
        return _qrCodeSettings;

      return await updateRestaurantQRCodeSettings({
        ..._requestBody,
        ID: _qrCodeSettings.ID,
        Logo: _requestBody.Logo,
        BaseURL: _baseURL,
      });
    }

    return await createRestaurantQRCodeSettings(_requestBody);
  }

  const onQRCodeGenerateClick = async (_orderType) => {
    if (_orderType.name === 'table') return;

    const { DINE_IN } = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT;

    setQRCodeGeneratorModalState((prevState) => ({ ...prevState, [_orderType.name]: { ...prevState[_orderType.name], isLoading: true } }));

    const _qrCodeData = await checkForQRCodeSettings();

    if (_orderType.name !== DINE_IN.name) {
      const _newStateData = {
        generatedURL: _qrCodeData[_orderType.qrCodeName],
        isLoading: false,
      };
      setQRCodeGeneratorModalState(prevState => ({
        ...prevState,
        [_orderType.name]: _newStateData,
      }));
      Message.success(`QR Code for ${_orderType.title} has been generated.`);
    }
  }

  const onQRCodeDownloadClick = async (_orderType, _downloadType) => {
    // if (_orderType.name === 'table') return;

    if (!_orderType || _orderType === "" || !_downloadType && _downloadType === "") return;

    // if (qrCodeGeneratorModalState[_orderType.name].generatedURL === '') {
    //   Message.warning("You need to generate the QR Code to download!");
    //   return;
    // }

    const _qrCanvasID = `#qr-canvas-order-type-${_orderType.name}-downloadable`;
    const _canvasSnap = await html2canvas(document.querySelector(_qrCanvasID), {
      useCORS: true, allowTaint: true

      // allowTaint: 
      // useCORS: false,
      // allowTaint: true,
      // backgroundColor: '#FFF'
      // onclone:
    });
    _canvasSnap.crossOrigin = "anonymous"
    console.log(_canvasSnap
    )
    const _fileName = `qr-code-${_orderType.name}-${_downloadType}.${_downloadType}`;

    if (_downloadType === 'jpeg' || _downloadType === 'png') {
      const link = document.createElement('a');
      link.download = _fileName;
      link.href = _canvasSnap.toDataURL('image/png');
      link.click();
    }

    if (_downloadType === 'pdf') {
      const doc = new jsPDF();
      doc.setFontSize(20);
      doc.text(`QR Code for ${_orderType.title}`, 20, 15);
      doc.text(`Location: ${qrCodeGeneratorModalState.selectedLocation.label}`, 20, 25);
      doc.addImage(_canvasSnap.toDataURL(), 'image/png', 15, 40, 180, 180);
      doc.save(_fileName);
    }

  }

  const onQrCodeGenerateModalCancel = () => {
    setShowQrCodeGeneratorModal(false);
  };

  const toggleAvailableOptions = (selectedOption) => {
    setAvailableOptionsModalState((prevState) => ({ ...prevState, selectedOption }));
  };

  const onChangeAvailablePaymentOptions = (name, isCard, value) => {
    setAvailableOptionsModalState(prevState => {
      let { data } = availableOptionsModalState;
      let item = _.find(data, { name });

      if (!item) return prevState;

      const index = _.findIndex(data, { name });

      if (isCard) {
        item.cardAccepted = value;
      } else {
        item.cashAccepted = value;
      }

      data.splice(index, 1, { ...item });

      return { ...prevState, data }
    });
  }

  const onAvailableOptionsModalSave = async () => {
    setLoadSaving(true);
    const { selectedOption, data } = availableOptionsModalState;
    const { OPT, KEY } = RESTAURANT_SETTINGS_TYPES.AVAILABLE_OPTION;
    const { availableOptions, orderTypeSettings } = restaurantSettingsState;

    const isOptionAvailable = availableOptions.selected && availableOptions.selected.length > 0 ? true : false;

    if (isOptionAvailable && availableOptions.selected[0].MenuWidgetPurpose !== selectedOption.name) {
      await updateRestaurantMenuSettings({
        type: KEY,
        data: {
          update: { MenuWidgetPurpose: selectedOption.name },
          list: availableOptions.selected,
        },
        cloudLocationsData,
      });
    }

    if (!isOptionAvailable) {
      await addRestaurantMenuSettings({
        type: KEY,
        cloudLocationsData,
        data: { MenuWidgetPurpose: selectedOption.name },
        OrganizationID: restaurantOrganizationID,
      });
    }



    if (selectedOption.name === OPT.CHECKOUT.name) {
      /* Delete currently available `Order Types Payment Methods` */
      const deleteList = [];
      for (const item of availableOptions.orderTypePaymentMethods.flat()) {
        const newData = {
          SettingType: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS_ORDER_TYPES,
          CloudLocationID: item.CloudLocationID,
          SettingData: {
            ID: item.ID,
            SettingType: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS_ORDER_TYPES,
            IsActive: false,
          },
        };

        deleteList.push(newData);
      }

      const deletePromises = deleteList.map(i => new Promise(async function (resolve, refuse) {
        resolve((await updateRestaurantMenuSettingsData(i)) ?? null);
      }));

      await Promise.all(deletePromises);


      /* Save new available `Order Types Payment Methods` */
      let dataList = [];
      for (const locationData of orderTypeSettings) {

        const insideList = [];

        for (const item of locationData) {
          const values = _.find(data, { name: item.OrderTypeName });

          const cardData = _.find(availableOptions.paymentMethods.flat(), {
            CloudLocationID: item.CloudLocationID,
            PaymentMethod: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS.OPT.CARD.name,
          });

          const cashData = _.find(availableOptions.paymentMethods.flat(), {
            CloudLocationID: item.CloudLocationID,
            PaymentMethod: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS.OPT.CASH.name,
          });

          let orderTypePaymentMethods = [];
          if (values.cardAccepted) orderTypePaymentMethods.push({ ...cardData, PaymentMethodID: cardData.ID });
          if (values.cashAccepted) orderTypePaymentMethods.push({ ...cashData, PaymentMethodID: cardData.ID });

          let newData = {
            SettingType: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS_ORDER_TYPES,
            CloudLocationID: item.CloudLocationID,
            OrganizationID: item.OrganizationID,
            SettingData: {
              SettingType: RESTAURANT_SETTINGS_TYPES.PAYMENT_METHODS_ORDER_TYPES,
              CloudLocationID: item.CloudLocationID,
              OrganizationID: item.OrganizationID,
              paymentMethods: orderTypePaymentMethods,
              OrderTypeID: item.ID,
              OrderTypeName: item.OrderTypeName,
              IsDefault: false,
            },
          };

          if (orderTypeModalState.defaultType.name === item.OrderTypeName) {
            newData.SettingData.IsDefault = true;
          }

          insideList.push(newData);
        }
        dataList = [...dataList, ...insideList];
      }

      let promises = dataList.map(i => new Promise(async function (resolve, refuse) {
        resolve((await addRestaurantMenuSettingsData(i)) ?? null);
      }));

      await Promise.all(promises);
    }

    await fetchAvailableOptionsData();

    setShowAvailableOptionsModal(false);
    setLoadSaving(false);
    Message.success("Available Options saved successfully!");
  };

  const onAvailableOptionsModalCancel = () => {
    setShowAvailableOptionsModal(false);
    fetchAvailableOptionsData();
  };

  const onLocationSettingsChange = (_keys) => {
    if (_keys.length === 0) return;

    setLocationSettingsModalState((prevState) => {
      const _selectedLocations = _keys.map((_key) => _.find(prevState.availableLocations, { key: _key })) ?? [];
      let _defaultLocation = prevState.defaultLocation;

      if (!_.find(_selectedLocations, { value: _defaultLocation.value })) {
        _defaultLocation = _selectedLocations[0];
      }

      return {
        ...prevState,
        selectedLocationKeys: _keys,
        selectedLocations: _selectedLocations,
        defaultLocation: _defaultLocation,
      };
    });
  }

  const onLocationSettingsDefaultChange = (_value) => {
    setLocationSettingsModalState((prevState) => ({
      ...prevState,
      defaultLocation: _value,
    }));
  }

  const onLocationSettingsModalSave = async () => {
    setLocationSettingsModalState(prevState => ({ ...prevState, isLoading: true }));

    const _currentLocationSettings = await getRestaurantMenuSettingsData({
      SettingType: RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS,
      OrganizationID: restaurantOrganizationID,
    });

    const _availableLocations = locationSettingsModalState.selectedLocations.map((_location) => {
      const __isDefault = _location.value === locationSettingsModalState.defaultLocation.value ? true : false;
      return {
        LocationName: _location.label,
        CloudLocationID: _location.value,
        IsDefaultLocation: __isDefault,
      };
    });

    if (_currentLocationSettings && _currentLocationSettings.length > 0 && _currentLocationSettings[0].AvailableLocations && _currentLocationSettings[0].AvailableLocations.length > 0) {
      await updateRestaurantMenuSettingsData({
        SettingType: RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS,
        OrganizationID: restaurantOrganizationID,
        CloudLocationID: locationSettingsModalState.defaultLocation.value,
        SettingData: {
          ID: _currentLocationSettings[0].ID,
          SettingType: RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS,
          [RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS]: _availableLocations,
          IsActive: true,
        }
      });
    } else {
      await addRestaurantMenuSettingsData({
        SettingType: RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS,
        OrganizationID: restaurantOrganizationID,
        CloudLocationID: locationSettingsModalState.defaultLocation.value,
        SettingData: {
          SettingType: RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS,
          [RESTAURANT_SETTINGS_TYPES.AVAILABLE_LOCATIONS]: _availableLocations,
          OrganizationID: restaurantOrganizationID,
          CloudLocationID: locationSettingsModalState.defaultLocation.value,
        }
      });
    }

    await fetchLocationSettingsData();
    setShowLocationSettingsModal(false);
    Message.success("Location Settings saved successfully!");
  };

  const onLocationSettingsModalCancel = () => {
    setShowLocationSettingsModal(false);
  };

  const restaurantSettingOptions = [
    {
      title: 'Order Types',
      icon: 'order-types',
      onClick: () => setShowOrderTypeModal(true),
    },
    {
      title: 'Location Settings',
      icon: 'location-settings',
      onClick: () => setShowLocationSettingsModal(true),
    },
    {
      title: 'Upload Image',
      icon: 'upload-image',
      onClick: () => setShowQRUploadImageModal(true),
    },
    {
      title: 'QR Code Generator',
      icon: 'qr',
      onClick: () => setShowQrCodeGeneratorModal(true),
    },
    {
      title: 'Available Options',
      icon: 'payment-options',
      onClick: () => setShowAvailableOptionsModal(true),
    },
  ];

  {/* Download popover content */ }
  const downloadContent = (_orderType) => {
    console.log('orderType', _orderType)
    return <Stack direction="column" verticalAlign="v_start">
      <Space size="middle" className="eco-mb-16" onClick={() => onQRCodeDownloadClick(_orderType, 'png')}>
        <Icon name="file-image" />
        <Typography type="text">PNG File</Typography>
      </Space>
      <Space size="middle" className="eco-mb-16" onClick={() => onQRCodeDownloadClick(_orderType, 'pdf')}>
        <Icon name="file-pdf" />
        <Typography type="text">PDF File</Typography>
      </Space>
      <Space size="middle" className="eco-mb-16" onClick={() => onQRCodeDownloadClick(_orderType, 'jpeg')}>
        <Icon name="file-image" />
        <Typography type="text">JPEG File</Typography>
      </Space>
    </Stack>
  };

  // const handleSelectAllLeft = () => {
  //   const allKeys = transferData.map((item) => item.key);
  //   setTargetKeys(allKeys);
  // };

  useOutsideClick(
    wrapperRef,
    [showOrderTypesModal, showQRUploadImageModal, showQrCodeGeneratorModal, showAvailableOptionsModal, showLocationSettingsModal],
    props.hideRestaurantSettingsOverlay
  );

  const _qrCodePreviewEyeRadiusValues = [
    { outer: ['3', '3', '3', '3'], inner: ['1', '1', '1', '1'] },
    { outer: ['3', '3', '3', '3'], inner: ['1', '1', '1', '1'] },
    { outer: ['3', '3', '3', '3'], inner: ['1', '1', '1', '1'] },
  ];

  const _qrCodeDownloadableEyeRadiusValues = [
    { outer: ['7', '7', '7', '7'], inner: ['1', '1', '1', '1'] },
    { outer: ['7', '7', '7', '7'], inner: ['1', '1', '1', '1'] },
    { outer: ['7', '7', '7', '7'], inner: ['1', '1', '1', '1'] },
  ];
  console.log('qrCodeGeneratorModalState', qrCodeGeneratorModalState)
  return (
    <div className="restaurant-settings__overlay" ref={wrapperRef}>

      {/* Sidebar Menu Title bar */}
      <Stack horizontalAlign="space_between" className="title-bar">
        <Typography alignment="center" textTransform="uppercase" pattern="bold">
          RESTAURANT SETTINGS
        </Typography>
        <div onClick={props.hideRestaurantSettingsOverlay}>
          <CloseOutlined className="close-name" />
        </div>
      </Stack>

      {/* Restaurant Settings options */}
      <Stack direction="column" className="restaurant-settings-section">
        <StackItem>
          {restaurantSettingOptions.map((option) => (
            <Stack horizontalAlign="h_start" verticalAlign="v_center" className="restaurant-settings-box" onClick={option.onClick}>
              <div className="eco-mr-32"><Icon name={option.icon} /></div>
              <div>
                <Typography type="title" level={5} pattern="bold" alignment="left">
                  {option.title}
                </Typography>
              </div>
            </Stack>
          ))}
        </StackItem>
      </Stack>

      {/* Order Type modal */}
      <Popup
        width={765}
        modalVisible={showOrderTypesModal}
        maskClosable={false}
        modalTitle="Order Types"
        footer={
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              {loadSaving ? <Spin /> : null}
              <Button variant="primary" className="save-btn" onClick={onOrderTypeModalSave} disabled={loadSaving}>
                Save
              </Button>
              <Button variant="outline" className="save-btn" onClick={onOrderTypeModalCancel}>
                Cancel
              </Button>
            </Space>
          </Stack>
        }
        onCancel={onOrderTypeModalCancel}>

        <div style={{ border: '1px solid #C6C7D2', borderRadius: '4px 4px 0px 0px' }}>
          <Row justify="center">

            {/* Order Type options */}
            <Col span={24} style={{ borderBottom: '1px solid #E9E9E9' }}>
              <Col style={{ borderRadius: '4px 4px 0px 0px' }} className="restaurant-settings-card">
                <Typography type="title" level={5} className="eco-mb-24" pattern="bold">
                  Select the type(s) of orders that you want to activate on you website
                </Typography>
                <Stack horizontalAlign="space_evenly">
                  {[RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DINE_IN, RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.COLLECTION, RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DELIVERY].map((option) => {
                    const isSelected = _.find(orderTypeModalState.selectedTypes, option) ? true : false;

                    return (
                      <div
                        onClick={() => onChangeOrderTypeSelection(option, !isSelected)}
                        className={isSelected ? 'order-type-box selected-order-type-box' : 'order-type-box'}>
                        <Icon name={option.icon} size={44} color={isSelected ? 'white' : ''} />
                        <Typography type="text" color={isSelected ? 'white_base' : ''}>
                          {option.title}
                        </Typography>
                      </div>
                    );
                  })}
                </Stack>
              </Col>
            </Col>

            {/* Order Type default selection */}
            {orderTypeModalState.selectedTypes.filter((i) => i.name !== RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DINE_IN.name).length > 1 && (
              <Col span={24}>
                <Col style={{ borderRadius: '0px 0px 4px 4px' }} className="restaurant-settings-card">
                  <Typography type="text">Choose a default order type</Typography>
                  <br />
                  <Stack horizontalAlign="space_evenly">
                    {[RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.COLLECTION, RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DELIVERY, RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.CUSTOMER_CHOICE].map((option) => (
                      <Radio
                        name="default-order-type"
                        value={option.name}
                        checked={orderTypeModalState.defaultType === option}
                        onChange={(e) => onChangeDefaultOrderType(option)}>
                        {option.title}
                      </Radio>
                    ))}
                  </Stack>
                </Col>
              </Col>
            )}

          </Row>
        </div>

      </Popup>

      {/* Upload QR Image modal */}
      <Popup
        width={765}
        modalVisible={showQRUploadImageModal}
        maskClosable={false}
        modalTitle="Upload Image"
        footer={
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              {qrCodeUploadImageModalState.isLoading ? <Spin /> : null}
              <Button variant="primary" className="save-btn" onClick={onQRCodeUploadImageModalSave} disabled={qrCodeUploadImageModalState.isLoading}>
                Save
              </Button>
              <Button variant="outline" className="save-btn" onClick={onUploadImageModalCancel}>
                Cancel
              </Button>
            </Space>
          </Stack>
        }
        onCancel={onUploadImageModalCancel}>
        <div style={{ border: '1px solid #C6C7D2', borderRadius: '4px 4px 0px 0px', padding: '20px' }}>
          <Typography type="title" level={5} className="eco-mb-24" pattern="bold">Upload the Company Logo</Typography>
          <ImageUploader
            file={qrCodeUploadImageModalState.selectedImageURL ?? qrCodeGeneratorModalState.logoImageURL ?? ''}
            onSetImage={onQRLogoImageUpload}
            onSetIsValidImageType={(err) => console.log(err)}
            width="500"
            height="500"
          />
        </div>
      </Popup>

      {/* QR Code Generator modal */}
      <Popup
        width={765}
        modalVisible={showQrCodeGeneratorModal}
        maskClosable={false}
        modalTitle="QR Code Generator"
        footer={
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              <Button variant="outline" className="save-btn" onClick={onQrCodeGenerateModalCancel}>
                Cancel
              </Button>
            </Space>
          </Stack>
        }
        onCancel={onQrCodeGenerateModalCancel}>
        <div style={{ border: '1px solid #C6C7D2', borderRadius: '4px 4px 0px 0px', padding: '30px 20px' }}>
          {/* <Row className="eco-mb-24" align="middle">
              <Col span={8}>
                <div className="form-lbl-wrapper">
                  <Label>Location</Label>
                </div>
              </Col>
              <Col span={16}>
                <Select
                  value={qrCodeGeneratorModalState.selectedLocation}
                  options={qrCodeGeneratorModalState.locations}
                  onChange={onQRLocationSelectChange}
                  noBorder
                />
              </Col>
            </Row> */}
          <Stack className="eco-mb-24">
            <Space size="large">
              <label>Location</label>
              <div style={{ width: '210px' }}>
                <Select
                  value={qrCodeGeneratorModalState.selectedLocation}
                  options={qrCodeGeneratorModalState.locations}
                  onChange={onQRLocationSelectChange}
                  noBorder
                />
              </div>
            </Space>
          </Stack>

          {/* Table QR  */}
          {
            orderTypeModalState.selectedTypes && orderTypeModalState.selectedTypes.length > 0 &&
            orderTypeModalState.selectedTypes.find(_ot => _ot.name === RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DINE_IN.name) &&
            <Accordion
              title="Table"
              collapsed={true}>
              <div style={{ width: '100%' }}>
                <Typography type="text" className="eco-mb-24">Select a table to generate QR</Typography>
                <Stack horizontalAlign="space_between" className="eco-mb-28">
                  <div style={{ width: '350px' }}>
                    <Select value={selectedTable}
                      options={tables}
                      onChange={(e) => { onTableSelectChange(e) }} noBorder />
                  </div>
                </Stack>
                <Stack direction="column" verticalAlign="v_center">
                  <div className='eco-mb-16'>
                    <>
                      <QRCode
                        id='qr-canvas-order-type-table'
                        size={160}
                        value={selectedTable.tableQRCode}
                        ecLevel="Q"
                        logoImage={logoImage}
                        logoWidth={50}
                        logoHeight={50}
                        logoPadding={2}
                        quietZone={5}
                        logoPaddingStyle="square"
                        bgColor='#FFF'
                        eyeRadius={_qrCodePreviewEyeRadiusValues}

                      />
                      <div style={{ position: 'absolute', zIndex: -1, opacity: 0 }}>
                        <QRCode
                          id='qr-canvas-order-type-table-downloadable'
                          size={400}
                          enableCORS={false}
                          value={selectedTable.tableQRCode}
                          ecLevel="Q"
                          logoImage={logoImage}
                          logoWidth={100}
                          logoHeight={100}
                          logoPadding={5}
                          quietZone={10}
                          logoPaddingStyle="square"
                          bgColor='#FFF'
                          eyeRadius={_qrCodeDownloadableEyeRadiusValues}
                        />
                      </div>
                    </>
                  </div>
                  <Space size="middle">
                    <Label>Download</Label>
                    <Popover
                      placement="bottomRight"
                      content={downloadContent(RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DINE_IN)}
                      trigger="click"
                      getPopupContainer={triggerNode => { return triggerNode.parentNode; }}
                      overlayStyle={{ width: '200px' }}

                      overlayClassName="download-overlay">
                      <div className='download-icon'>
                        <Icon name="download" color="gray" size={20} />
                      </div>
                    </Popover>
                  </Space>
                </Stack>
              </div>
            </Accordion>
          }

          {/* Collection QR  */}
          {
            orderTypeModalState.selectedTypes && orderTypeModalState.selectedTypes.length > 0 &&
            orderTypeModalState.selectedTypes.find(_ot => _ot.name === RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.COLLECTION.name) &&
            <Accordion
              title="Collection"
              collapsed={true}>
              <div style={{ width: '100%' }}>
                <Stack direction="column" verticalAlign="v_center">
                  <div className='eco-mb-16'>

                    <QRCode
                      id='qr-canvas-order-type-collection'
                      size={160}
                      value={takeawayQRCode}
                      ecLevel="Q"
                      logoImage={logoImage}
                      logoWidth={50}
                      logoHeight={50}
                      logoPadding={2}
                      quietZone={5}
                      logoPaddingStyle="square"
                      bgColor='#FFF'
                      eyeRadius={_qrCodePreviewEyeRadiusValues}
                      removeQrCodeBehindLogo />
                    <div style={{ position: 'absolute', zIndex: -1, opacity: 0 }}>
                      <QRCode
                        id='qr-canvas-order-type-collection-downloadable'
                        size={400}
                        value={takeawayQRCode}
                        ecLevel="Q"
                        logoImage={logoImage}
                        logoWidth={100}
                        logoHeight={100}
                        logoPadding={5}
                        quietZone={10}
                        logoPaddingStyle="square"
                        bgColor='#FFF'
                        eyeRadius={_qrCodeDownloadableEyeRadiusValues}
                        removeQrCodeBehindLogo
                      />
                    </div>

                  </div>
                  <Space size="middle">
                    <Label>Download</Label>
                    <Popover
                      placement="bottomRight"
                      content={downloadContent(RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.COLLECTION)}
                      trigger="click"
                      getPopupContainer={triggerNode => { return triggerNode.parentNode; }}
                      overlayStyle={{ width: '200px' }}
                      overlayClassName="download-overlay">
                      <div className='download-icon'>
                        <Icon name="download" color="gray" size={20} />
                      </div>
                    </Popover>
                  </Space>
                </Stack>
              </div>
            </Accordion>
          }

          {/* Delivery QR  */}
          {
            orderTypeModalState.selectedTypes && orderTypeModalState.selectedTypes.length > 0 &&
            orderTypeModalState.selectedTypes.find(_ot => _ot.name === RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DELIVERY.name) &&
            (<Accordion
              title="Delivery"
              collapsed={true}>
              <div style={{ width: '100%' }}>
                <Stack direction="column" verticalAlign="v_center">
                  <div className='eco-mb-16'>

                    <QRCode
                      id='qr-canvas-order-type-delivery'
                      size={160}
                      value={deliveryQRCode}
                      ecLevel="Q"
                      logoImage={logoImage}
                      logoWidth={50}
                      logoHeight={50}
                      logoPadding={2}
                      quietZone={5}
                      logoPaddingStyle="square"
                      eyeRadius={_qrCodePreviewEyeRadiusValues}
                      removeQrCodeBehindLogo />
                    <div style={{ position: 'absolute', zIndex: -1, opacity: 0 }}>
                      <QRCode
                        id='qr-canvas-order-type-delivery-downloadable'
                        size={400}
                        value={deliveryQRCode}
                        ecLevel="Q"
                        logoImage={logoImage}
                        logoWidth={100}
                        crossorigin="anonymous"
                        logoHeight={100}
                        logoPadding={5}
                        quietZone={10}
                        logoPaddingStyle="square"
                        eyeRadius={_qrCodeDownloadableEyeRadiusValues}
                        removeQrCodeBehindLogo />
                    </div>

                  </div>
                  <Space size="middle">
                    <Label>Download</Label>
                    <Popover
                      placement="bottomRight"
                      content={downloadContent(RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT.DELIVERY)}
                      trigger="click"
                      getPopupContainer={triggerNode => { return triggerNode.parentNode; }}
                      overlayStyle={{ width: '200px' }}
                      overlayClassName="download-overlay"
                      disabled>
                      <div className='download-icon'>
                        <Icon name="download" color="gray" size={20} />
                      </div>
                    </Popover>
                  </Space>
                </Stack>
              </div>
            </Accordion>)
          }
        </div>
      </Popup>

      {/* Available Options modal */}
      <Popup
        width={765}
        modalVisible={showAvailableOptionsModal}
        maskClosable={false}
        modalTitle="Available Options"
        footer={
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              {loadSaving ? <Spin /> : null}
              <Button variant="primary" className="save-btn" onClick={onAvailableOptionsModalSave} disabled={loadSaving}>
                Save
              </Button>
              <Button variant="outline" className="save-btn" onClick={onAvailableOptionsModalCancel}>
                Cancel
              </Button>
            </Space>
          </Stack>
        }
        onCancel={onAvailableOptionsModalCancel}>
        <div style={{ border: '1px solid #C6C7D2', borderRadius: '4px 4px 0px 0px' }}>
          <Row justify="center">

            {/* Is checkout requires or not */}
            <Col span={24} style={{ borderBottom: '1px solid #E9E9E9', padding: '20px' }}>
              <Col style={{ borderRadius: '4px 4px 0px 0px' }} className="restaurant-settings-card">
                <Typography type="title" level={5} className="eco-mb-24" pattern="bold">
                  Choose what you want to allow for the restaurant website
                </Typography>
                <Stack horizontalAlign="space_evenly">
                  {
                    [RESTAURANT_SETTINGS_TYPES.AVAILABLE_OPTION.OPT.MENU_ONLY, RESTAURANT_SETTINGS_TYPES.AVAILABLE_OPTION.OPT.CHECKOUT].map((option) => {
                      const { selectedOption } = availableOptionsModalState;
                      const isSelected = selectedOption.name === option.name ? true : false;

                      return (
                        <div
                          onClick={() => toggleAvailableOptions(option)}
                          className={isSelected ? 'order-type-box selected-order-type-box' : 'order-type-box'}>
                          <Icon name={option.icon} size={44} color={isSelected ? 'white' : ''} className="eco-mb-12" />
                          <Typography type="text" color={isSelected ? 'white_base' : ''}>
                            {option.title}
                          </Typography>
                        </div>
                      )
                    })
                  }
                </Stack>
              </Col>
            </Col>

            {/* Shows only when `Checkout` selected */}
            {
              availableOptionsModalState.selectedOption === RESTAURANT_SETTINGS_TYPES.AVAILABLE_OPTION.OPT.CHECKOUT ? (
                <Col span={24} style={{ padding: '20px' }}>
                  <Col style={{ borderRadius: '0px 0px 4px 4px' }} className="restaurant-settings-card">
                    <Typography type="text" className="eco-mb-24">Choose the payment type(s)</Typography>
                    <br />

                    {/* User selected order types with payment options */}
                    {
                      availableOptionsModalState.data.map(item => (
                        <Stack horizontalAlign="h_start" className="eco-mb-24">
                          <Col span={6}>{item.title}</Col>
                          <Checkbox
                            name={`${item.name}-payment-method`}
                            value="card"
                            onChange={(e) => onChangeAvailablePaymentOptions(item.name, true, !item.cardAccepted)}
                            checked={item.cardAccepted}
                            className="eco-mr-70">
                            Card
                          </Checkbox>
                          <Checkbox
                            name={`${item.name}-payment-method`}
                            value="cash"
                            onChange={(e) => onChangeAvailablePaymentOptions(item.name, false, !item.cashAccepted)}
                            checked={item.cashAccepted}>
                            Cash
                          </Checkbox>
                        </Stack>
                      ))
                    }
                  </Col>
                </Col>
              ) :
                (
                  <Col span={24} style={{ padding: '35px' }}>
                    <Space size={30}>
                      <Label>Display Product Prices</Label>
                      <Switch />
                    </Space>
                  </Col>
                )
            }
          </Row>
        </div>
      </Popup>

      {/* Location Settings modal */}
      <Popup
        width={765}
        modalVisible={showLocationSettingsModal}
        maskClosable={false}
        modalTitle="Location Settings"
        footer={
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              {locationSettingsModalState.isLoading ? <Spin /> : null}
              <Button variant="primary" className="save-btn" onClick={onLocationSettingsModalSave} disabled={locationSettingsModalState.isLoading}>
                Save
              </Button>
              <Button variant="outline" className="save-btn" onClick={onLocationSettingsModalCancel}>
                Cancel
              </Button>
            </Space>
          </Stack>
        }
        onCancel={onLocationSettingsModalCancel}>
        <div>
          <Typography type="text" pattern="bold" className="eco-mb-44">
            Please select the location for the Online Ordering
          </Typography>
          <Stack horizontalAlign="h_end" className="eco-mb-24">
            <Space size="large">
              <label>Default Location</label>
              <div style={{ width: '210px' }}>
                <Select
                  value={locationSettingsModalState.defaultLocation}
                  options={locationSettingsModalState.selectedLocations}
                  onChange={onLocationSettingsDefaultChange} />
              </div>
            </Space>
          </Stack>
          <Transfer
            className="eco-mb-24 location-list cms-trans-icon"
            dataSource={locationSettingsModalState.availableLocations}
            showSearch={true}
            selectAllLabels={[
              (<span>Available Locations</span>),
              (<span>Selected Locations</span>)
            ]}
            render={(item) => item.label}
            targetKeys={locationSettingsModalState.selectedLocationKeys}
            onChange={onLocationSettingsChange} />
        </div>
      </Popup>
    </div>
  );
};

export default RestaurantSettingsOverlay;