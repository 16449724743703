import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Theme from "./default";
import "./App.css";
import "../node_modules/witmeg-ui-system/dist/styles.css";
import 'antd/dist/antd.css';
import { Provider } from 'react-redux'
import store from './redux/rootReducer'
ReactDOM.render(

  //IF YOU COMMENT REACT STRICT MODE IT WILL STOP THE API CALLING TWICE
  <React.StrictMode>
    <Provider store={store}>
      <Theme />
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);
// ReactDOM.render(<Theme />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}