import React, { useEffect, useState } from "react";

import TestTable from "./Carousel";

import Editor from "./TextEditor";
import Carousel from "./Carousel";
import ContactUs from "./ContactUs";
import IconWidget from "./IconWidget";
import TabWidget from "./TabWidget";

import ImageWidget from "./ImageWidget";
import GalleryWidjet from "./GalleryWidjet";
import BlogWidget from "./BlogWidget";
import VideoWidget from "./VideoWidget";
import EcomItemPicks from "../../preview/itempicks";
import { async } from "witmeg-ui-system";
import Offers from "./EcommerceWidgets/Offers";
import RestaurantMenu from './ResturantMenu/index'
import PricingPlan from "./PricePlan";
import { useSelector } from "react-redux";
import Map from "./Map/Map";
import AccordianWidget from "./AccordianWidget";
import ButtonWidget from "./ButtonWidget";
import NavigationLinksWidgets from "./NavigationLinksWidgets";
import CardWidget from "./CardWidget/CardWidget";
import CardWidgetWrapper from "./CardWidget/CardWidgetWrapper";
import ImageWithContentWidget from "./ImageWithContentWidget";

export default function WidjetIndex(props) {
  const WidgetData = useSelector((state) => state.widgetData);
  const [data, setData] = useState(null);

  const [companyData, setCompanyData] = useState([]);
  const [reduxWidgetID, setReduxWidgetID] = useState(null);

  useEffect(() => {
    if (WidgetData.WIDGETID !== null) {
      setReduxWidgetID(WidgetData.WIDGETID);
    }
  }, [WidgetData]);
  //THIS IS WHERE ALL THE WIDGETS WILL BE WHEN YOU ADD A NEW WIDGET PUT IT INSIDE  THE SWITCH CASE WITH THE COMPONENT NAME
  return (
    <>
      {(() => {
        switch (props.params.widjetName) {
          case "carousel":
            return (
              <Carousel
                params={{
                  widgetID: props.params.layoutid[0],
                  pageID: props.params.pageID,
                  PreviewButton: props.params.PreviewButton,
                }}
                widgetHeight={props.params.widgetHeight}
              />
            );
          case "image":
            return (
              <ImageWidget
                params={{
                  propID: props?.params?.layoutid,
                  pageID: props?.params?.pageID,
                }}
                widgetID={props.params.layoutid[0]}
                PreviewButton={props.params.PreviewButton}
                pageID={props?.params?.pageID}
              />
            );

          case "gallery":
            return (
              <GalleryWidjet
                params={{
                  widgetID: props.params.layoutid[0],
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                }}
              />
            );

          case "text editor":
            return (
              <Editor
                params={{
                  widgetID: props.params.layoutid[0],
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                  // w: props.params.w,
                  // h: props.params.h,
                }}
              />
            );

          case "contact form":
            return (
              <ContactUs
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                }}
              />
            );

          case "Price Plan":
            return (
              <PricingPlan
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                }}
              />
            );

          case "blog":
            return (
              <BlogWidget
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props?.params?.pageID,
                }}
              />
            );
          case "video":
            return (
              <VideoWidget
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props?.params?.pageID,
                }}
              />
            );

          //Resturant menu widget
          case "resturant menu":
            return (
              <RestaurantMenu
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props?.params?.PreviewButton,
                  pageID: props?.params?.pageID,
                  widgetData: props.params.layoutid[3],
                }}
              />
            );


          //Navigation links
          case "navigation links":
            return (
              <NavigationLinksWidgets
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props?.params?.pageID,
                }}
              />
            );

          // E COMMERCE WIDGETS
          case "bestselling":
            return (
              <Offers
                // ItemHeading={data?.ItemHeading}
                ItemHeading='Best selling'
                PreviewButton={props.params.PreviewButton}
                WidgetName={"bestselling"}
                pageID={props.params.pageID}
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                }}
              />
            );

          case "featured":
            return (
              <Offers
                // ItemHeading={data?.ItemHeading}
                ItemHeading='Featured Products'
                PreviewButton={props.params.PreviewButton}
                WidgetName={props.params.widjetName}
                pageID={props.params.pageID}
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                }}
              />
            );

          case "featured offers":
            return (
              <Offers
                // ItemHeading={data?.ItemHeading}
                ItemHeading='Featured Offers'
                PreviewButton={props.params.PreviewButton}
                WidgetName={"featuredffers"}
                pageID={props.params.pageID}
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                }}
              />
            );

          case "offers":
            return (
              <Offers
                // ItemHeading={data?.ItemHeading}
                ItemHeading='Offers'
                PreviewButton={props.params.PreviewButton}
                WidgetName={props.params.widjetName}
                pageID={props.params.pageID}
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props.params.pageID,
                }}
              />
            );
          case "accordion":
            return (
              <AccordianWidget
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  // checkData: data,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props?.params?.pageID,
                }}
              />
            );

          case "button":
            return (
              <ButtonWidget
                params={{
                  widgetID: props.params.layoutid[0],
                  // w: props.params.w,
                  // h: props.params.h,
                  PreviewButton: props.params.PreviewButton,
                  pageID: props?.params?.pageID
                }}
              />
            );

          case "icon":
            return (
              <IconWidget
                params={{
                  propID: props?.params?.layoutid,
                  pageID: props?.params?.pageID,
                }}
                widgetID={props.params.layoutid[0]}
                PreviewButton={props.params.PreviewButton}
                pageID={props?.params?.pageID}
                widgetWidth={props?.params?.widgetWidth}
                IconID={props?.params?.IconID}
              />
            );
          case "maps":
            return (
              <Map
                widgetID={props.params.layoutid[0]}
                PreviewButton={props.params.PreviewButton}
                pageID={props?.params?.pageID}
              />
            );

          case "tab":
            return (
              <TabWidget
                params={{
                  propID: props?.params?.layoutid,
                  pageID: props?.params?.pageID,
                }}
                widgetID={props.params.layoutid[0]}
                PreviewButton={props.params.PreviewButton}
                pageID={props?.params?.pageID}
              />
            );
          case "card":
            return (
              <CardWidgetWrapper
                params={{
                  propID: props?.params?.layoutid,
                  pageID: props?.params?.pageID,
                }}
                widgetID={props.params.layoutid[0]}
                PreviewButton={props.params.PreviewButton}
                pageID={props?.params?.pageID}
              />
            );

          case "Image with Text":
            return (
              <ImageWithContentWidget
                params={{
                  propID: props?.params?.layoutid,
                  pageID: props?.params?.pageID,
                }}
                widgetID={props.params.layoutid[0]}
                PreviewButton={props.params.PreviewButton}
                pageID={props?.params?.pageID}
              />
            );

          default:
            return <div></div>;
        }
      })()}
    </>
  );
}
