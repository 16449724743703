import React from "react";
import Example from "./example";
import { DndProvider } from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

import "./styles.css";

function DndContainer(props) {
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <Example updateLayout={props.updateLayout} handleChangeImage={props.handleChangeImage} gridData={props.gridData} currentImage={props.currentImage}/>
      </DndProvider>
    </div>
  );
}
export default DndContainer
