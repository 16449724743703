import React, { useState, useEffect, Component } from "react";
import { SideMenuWithToggle, Icon, async } from "witmeg-ui-system";
import axios from "axios";
import config from "react-global-configuration";
import { Link } from "react-router-dom";
import { routePaths } from "../../routes/routeConstants";
import HomePageRoutes from "../Home/HomePageRoutes";
import Logo from "../../assets/img/logo.svg";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import _ from 'lodash';

class GlobalLeftMenu extends Component {
  viewProfile = function () {
    const { history } = this.props;
    history.push(routePaths.home__design);
    // window.location.reload();
  };

  //SETTINGS NAVIGATION
  settingsBtn = () => {
    const { history } = this.props;

    history.push("/settings");
  };

  getMenuItems = () =>{
    console.log('this.props.menuDataAvailable:',this.props.menuDataAvailable);
    let items = [
      {
        key: "0",
        icon: <Icon name='retail-design' size={20} />,
        label: "DASHBOARD",
        onClick: () => {
          this.viewProfile();
        },
      },
      {
        key: "1",
        icon: <Icon name='widgets' size={20} />,
        label: "WIDGETS",
        onClick: () => {
          this.props.showWidgetOverlay();
        },
      },
      {
        key: "2",
        icon: <Icon name='articles' size={20} />,
        label: "ARTICLES",
        onClick: () => {
          alert("You clicked Home");
        },
      },
      {
        key: "3",
        icon: <Icon name='page' size={20} />,
        label: "PAGES",
        onClick: () => {
          this.props.showPagesOverlay();
        },
      },
      {
        key: "4",
        icon: <Icon name='e-commerce' size={20} />,
        label: "E-COMMERCE",
        onClick: () => {
          this.props.showEcommerceOverlay();
        },
      },
      {
        key: "6",
        icon: <Icon name='retail-settings' size={20} />,
        label: "SETTINGS",
  
        onClick: () => {
          this.props.showSettingsOverlay();
        },
      },
    ];

    if (this.props.menuDataAvailable) {
      items.splice(5, 0, {
        key: '5',
        icon: <Icon name="left-menu-restaurant" size={20} />,
        label: 'Restaurant Settings',

        onClick: () => {
          this.props.showRestaurantSettingsOverlay();
        },
      });
      items.join();
    }

    return items;
  }

  render() {
    return (
      <div className='retailpax-sidemenu'>
        <SideMenuWithToggle
          logoUrlPath={routePaths.dashboardRoot}
          defaultSelectedKeys={['0']}
          collapsed={this.props.menuState}
          items={this.getMenuItems()}
          logoUrl={Logo}
        />
        <div className='eco-app-content'>
          {/* <HomePageRoutes /> */}
          {/* <Outlet /> */}
        </div>
      </div>
    );
  }
}

// import React, { useState, useEffect } from "react";
// import { SideMenuWithToggle, Icon, async } from "witmeg-ui-system";
// import axios from "axios";
// import config from "react-global-configuration";
// import { Link } from "react-router-dom";
// import { routePaths } from "../../routes/routeConstants";
// import HomePageRoutes from "../Home/HomePageRoutes";
// import Logo from "../../assets/img/logo.svg";

// useEffect(async () => {
//   await fetchData();
// }, []);

// const fetchData = async () => {
//   setLoaded(true);
//   axios
//     .get(
//       config.get("backendURL") + "menus/" + sessionStorage.getItem("company")
//     )
//     .then(async (response) => {
//       const tableDataFromResult = await getTableDataFromResult(response.data);
//       console.log("tableDataFromResult", tableDataFromResult);
//       setMenus(response.data);
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   axios
//     .get(config.get("backendURL") + "homelayout/image/count/0")
//     .then((response) => {
//       console.log(response);

//       setImageCount(response.data);
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   axios
//     .get(
//       config.get("backendURL") +
//         "layouts/layout/" +
//         sessionStorage.getItem("company")
//     )
//     .then((response) => {
//       this.setState({
//         datax: JSON.parse(response.data[0].itemdetails).sort(
//           (a, b) => a.order - b.order
//         ),
//         slayoutid: response.data[0]._id,
//       });
//     })
//     .catch((error) => {
//       console.log(error);
//     });

//   // axios
//   //   .get(config.get("backendURL") + "themes/")
//   //   .then((response) => {
//   //     console.log(response);
//   //     setThemes(response.data);
//   //   })
//   //   .catch(function (error) {
//   //     console.log(error);
//   //   });

//   setLoaded(false);
// };

// const getTableDataFromResult = async (data) => {
//   console.log("data");
//   console.log(data);
//   return data;
// };

const mapStateToProps = (state) => {
  const { resturantWidget } = state;
  return {
    menuDataAvailable: resturantWidget.menuWidgetData.menuDataAvailable,
  };
};

export default connect(mapStateToProps)(withRouter(GlobalLeftMenu));
