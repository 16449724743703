import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Label, InputBox, Slider, Switch, Button, Typography, Stack, Space, ColorPicker } from 'witmeg-ui-system';
import { Popover } from 'antd';
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import divider1 from "../../../../images/divider-1.png"
import divider2 from "../../../../images/divider-2.png"
import divider3 from "../../../../images/divider-3.png"
import divider4 from "../../../../images/divider-4.png"
import FontPicker from "font-picker-react";
import "../Forms/forms.css"
import { DividerStyleCategory } from '../../../utilities/helpers';

const dividerInitialState = {
    enabled: false,
    category: DividerStyleCategory.DIVIDER_1,
    style: 1,
    color: '#000000',
};

export default function ItemSettings({ setItemsField, itemsFormData }) {

    const [itemdescBold, setItemdescBold] = useState(false)
    const [itemdescItalic, setItemdescItalic] = useState(false)
    const [itemdescUnderline, setItemdescUnderline] = useState(false)
    const [itemNameBold, setItemNameBold] = useState(false)
    const [itemNameItalic, setItemNameItalic] = useState(false)
    const [itemNameUnderline, setItemNameUnderline] = useState(false)
    const [menuItemsSpace, setMenuItemsSpace] = useState("0");
    const [dividerState, setDividerState] = useState(itemsFormData?.divider ?? dividerInitialState);
    const [selectedCategoryDivider, setSelectedCategoryDivider] = useState("");
    const [descFont, setDescFont] = useState('16')
    const [nameFontSize, setNameFontSize] = useState('16');
    const [fontList, setFontList] = useState(itemsFormData?.nameItemFontFamily ? itemsFormData?.nameItemFontFamily : "Open Sans")
    const [fondestList, setDesFontList] = useState(itemsFormData?.descFontFamily ? itemsFormData?.descFontFamily : "Open Sans")
    const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";

    const changeDividerState = (value) => {
        setDividerState((prevState) => {
            const newState = { ...prevState, ...value };
            setItemsField('divider', newState);
            return newState;
        });
    };

    useEffect(() => {
        setItemdescBold(itemsFormData?.descbold)
        setItemdescItalic(itemsFormData?.descitalic)
        setItemdescUnderline(itemsFormData?.descunderline)
        setItemNameBold(itemsFormData?.namebold)
        setItemNameItalic(itemsFormData?.nameitalic)
        setItemNameUnderline(itemsFormData?.nameunderline)
        setDescFont(itemsFormData?.descFontSize)
        setNameFontSize(itemsFormData?.nameItemFontSize)
        setMenuItemsSpace(itemsFormData?.descSpaceItems)
    }, [itemsFormData])


    //DIVIDER CONTENT
    const handleCategoryDivider = (divider) => {
        setSelectedCategoryDivider(divider);
    }

    const handleFontColorChange = (e) => {
        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setItemsField("itemNameFontColor", `#${e}`)
        } else {
            setItemsField("itemNameFontColor", "#000")
            setItemFontColor("#000")
        }
    }

    const handleColorChange = (e) => {
        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setItemsField("descFontColor", `#${e}`)
        } else {
            setItemsField("descFontColor ", "#000")
            setItemDescColor("#000")
        }
    }

    const onDividerSwitchChange = (enabled) => changeDividerState({ enabled });

    const onDividerCategoryChange = (category) => changeDividerState({ category });

    const onDividerStyleChange = (style) => changeDividerState({ style });

    const onDividerColorChange = (color, colorPicker = true) => {
        if (colorPicker) {
            changeDividerState({ color });
            return;
        }

        const regex = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$';
        const validatedColor = regex.match(color) ? `#${color}` : '#000000';

        changeDividerState({ color: validatedColor });
    };

    console.log("DividerStyleCategory.DIVIDER_1",DividerStyleCategory.DIVIDER_1);

    const dividerContent = (
        <Stack direction="column" horizontalAlign="h_center">
            <div className={dividerState.category === "divider1" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_1)}>
                <img alt={DividerStyleCategory.DIVIDER_1} src={divider1} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_1)} />
            </div>
            <div className={dividerState.category === "divider2" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_2)}>
                <img alt={DividerStyleCategory.DIVIDER_2} src={divider2} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_2)} />
            </div>
            <div className={dividerState.category === "divider3" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_3)}>
                <img alt={DividerStyleCategory.DIVIDER_3} src={divider3} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_3)} />
            </div>
            {/* <div className={dividerState.category === "divider4" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_4)}>
                <img alt={DividerStyleCategory.DIVIDER_4} src={divider4} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_4)} />
            </div> */}
        </Stack>
    )

    const [itemFontColor, setItemFontColor] = useState("")
    const [itemDescColor, setItemDescColor] = useState("")



    return (
        //Name Section Starts Here
        <div style={{ width: '100%' }}>
            <Typography type="title" level={5} color="gray_darker" style={{ marginBottom: "20px" }} pattern="bold">Name</Typography>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Family</Label></div>
                </Col>
                <Col style={{ zIndex: "999999" }} span={16}>

                    <FontPicker
                        apiKey={API_KEY}
                        activeFontFamily={fontList}
                        onChange={(nextFont) => {
                            setItemsField("nameItemFontFamily", nextFont.family);
                            setFontList(nextFont.family)
                        }
                        }
                        limit={100}
                        // sort="popularity"
                        pickerId="items"

                    />




                </Col>

            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Size</Label></div>
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue={nameFontSize}
                        value={nameFontSize}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => { setItemsField("nameItemFontSize", e); setNameFontSize(e) }}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} onChange={(e) => { handleFontColorChange(e.target.value) }} type="text" noBorder backgroundOnly value={itemsFormData?.itemNameFontColor ? itemsFormData?.itemNameFontColor : itemFontColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker defaultColor={itemFontColor} setDefaultColor={setItemFontColor} onChangeComplete={(color) => setItemsField("itemNameFontColor", color.hex)} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '32px' }}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Style</Label></div>
                </Col>
                <Col span={16}>
                    <Space size="middle">
                        <Button onClick={() => { itemNameBold ? setItemsField('namebold', false) : setItemsField("namebold", true); itemNameBold ? setItemNameBold(false) : setItemNameBold(true) }} category="icon" variant={itemNameBold ? "primary" : 'secondary'} children="bold" color={itemNameBold ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button onClick={() => { itemNameItalic ? setItemsField('nameitalic', false) : setItemsField("nameitalic", true); itemNameItalic ? setItemNameItalic(false) : setItemNameItalic(true) }} category="icon" variant={itemNameItalic ? 'primary' : 'secondary'} children="italic" color={itemNameItalic ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button onClick={() => { itemNameUnderline ? setItemsField('nameunderline', false) : setItemsField("nameunderline", true); itemNameUnderline ? setItemNameUnderline(false) : setItemNameUnderline(true) }} category="icon" variant={itemNameUnderline ? 'primary' : 'secondary'} children="underline" color={itemNameUnderline ? "white" : "gray"} className="font-style-btn"></Button>
                    </Space>
                </Col>
            </Row>
            {/* Name Section Ends Here */}

            {/* Description Starts Here */}
            <Typography type="title" level={5} color="gray_darker" style={{ marginBottom: "20px" }} pattern="bold">Description</Typography>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Family</Label></div>
                </Col>
                <Col style={{ zIndex: "999999" }} span={16}>
                    <FontPicker
                        apiKey={API_KEY}
                        activeFontFamily={fondestList}
                        onChange={(nextFont) => {
                            setItemsField("descFontFamily", nextFont.family);
                            setDesFontList(nextFont.family)
                        }
                        }
                        limit={100}
                    />


                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Size</Label></div>
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue="16"
                        value={descFont}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => { setItemsField("descFontSize", e); setDescFont(e) }}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} onChange={(e) => { handleColorChange(e.target.value) }} type="text" noBorder backgroundOnly value={itemsFormData?.descFontColor ? itemsFormData?.descFontColor : itemDescColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => setItemsField("descFontColor", color.hex)} defaultColor={itemDescColor} setDefaultColor={setItemDescColor} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '32px' }}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Style</Label></div>
                </Col>
                <Col span={16}>
                    <Space size="middle">
                        <Button onClick={() => { itemdescBold ? setItemsField('descbold', false) : setItemsField("descbold", true); itemdescBold ? setItemdescBold(false) : setItemdescBold(true) }} category="icon" variant={itemdescBold ? "primary" : 'secondary'} children="bold" color={itemdescBold ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button onClick={() => { itemdescItalic ? setItemsField('descitalic', false) : setItemsField("descitalic", true); itemdescItalic ? setItemdescItalic(false) : setItemdescItalic(true) }} category="icon" variant={itemdescItalic ? 'primary' : 'secondary'} children="italic" color={itemdescItalic ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button onClick={() => { itemdescUnderline ? setItemsField('descunderline', false) : setItemsField("descunderline", true); itemdescUnderline ? setItemdescUnderline(false) : setItemdescUnderline(true) }} category="icon" variant={itemdescUnderline ? 'primary' : 'secondary'} children="underline" color={itemdescUnderline ? "white" : "gray"} className="font-style-btn"></Button>
                    </Space>
                </Col>
            </Row>

            {/* Background Hover Color Start Here */}
            <Row className="form-row eco-mb-16" align="middle" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '32px' }}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Background Hover Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} onChange={(e) => { handleFontColorChange(e.target.value) }} type="text" noBorder backgroundOnly value={itemsFormData?.itemNameFontColor ? itemsFormData?.itemNameFontColor : itemFontColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker defaultColor={itemFontColor} setDefaultColor={setItemFontColor} onChangeComplete={(color) => setItemsField("itemNameFontColor", color.hex)} />
                </Col>
            </Row>
            {/* Background Hover Color End Here */}

            <Typography type="title" level={5} color="gray_darker" style={{ marginBottom: "20px" }} pattern="bold">Space between menu items</Typography>
            <Row className="form-row eco-mb-16" align="middle" justify="space-between" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '32px' }}>
                <Col span={8}></Col>
                <Col span={12}>
                    <Slider value={menuItemsSpace} onChange={(newValue) => { setItemsField("descSpaceItems", newValue); setMenuItemsSpace(newValue) }} defaultValue={menuItemsSpace} />
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue={menuItemsSpace}
                        value={menuItemsSpace}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => { setItemsField("descSpaceItems", e); setMenuItemsSpace(e) }}
                    />
                </Col>
            </Row>

            {/* Divider */}
            <Row className="form-row eco-mb-16" align="middle" gutter={[16, 32]}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Divider</Label></div>
                </Col>
                <Col span={16}>
                    <Switch checked={dividerState.enabled} onChange={(value) => onDividerSwitchChange(value)} />
                </Col>
                <Col span={24}>
                    <Stack horizontalAlign="h_center">
                        <div className="eco-mr-24">
                            <div style={{ width: '100%', border: '1px solid #DBDCE3', padding: '10px' }}>
                                {(!dividerState || dividerState.category === "") && <img alt={DividerStyleCategory.DIVIDER_1} src={divider1} />}
                                {dividerState.category === DividerStyleCategory.DIVIDER_1 && <img alt={DividerStyleCategory.DIVIDER_1} src={divider1} />}
                                {dividerState.category === DividerStyleCategory.DIVIDER_2 && <img alt={DividerStyleCategory.DIVIDER_2} src={divider2} />}
                                {dividerState.category === DividerStyleCategory.DIVIDER_3 && <img alt={DividerStyleCategory.DIVIDER_3} src={divider3} />}
                                {/* {dividerState.category === DividerStyleCategory.DIVIDER_4 && <img alt={DividerStyleCategory.DIVIDER_4} src={divider4} />} */}
                            </div>
                        </div>
                        <Popover placement="right" content={dividerContent} trigger="click" getPopupContainer={(triggerNode) => triggerNode} overlayStyle={{ width: '300px' }}>
                            <Button category="icon" children="chevron-right" variant="subtle" className="show-layout-btn" />
                        </Popover>
                    </Stack>
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle" justify="space-between">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Divider Style</Label></div>
                </Col>
                <Col span={12}>
                    <Slider min={1} max={6} value={dividerState.style} onChange={(value) => onDividerStyleChange(value)} />
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue={dividerInitialState.style}
                        value={dividerState.style}
                        data={[1, 2, 3, 4, 5 , 6]}
                        hideEmptyPopup={true}
                        onChange={(value) => onDividerStyleChange(value)}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Colour</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox allowPaste={false} type="text" noBorder backgroundOnly value={dividerState.color} disabled setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => onDividerColorChange(color.hex)} defaultColor={dividerState.color} setDefaultColor={dividerState.color} />
                </Col>
            </Row>
        </div>
    )
}
