import React from "react";
import { Stack, StackItem, Typography } from "witmeg-ui-system";
import "./LoginPage.css";
import LoginForm from "./LoginForm";
import LoginLogo from "../../assets/img/Group.svg";
import Logo from "../../assets/img/login_logo.svg";

function Login() {
  return (
    <Stack className='login-page' horizontalAlign='h_center'>
      <Stack className='login-page-container' direction='row'>
        <StackItem className='login-page-image-container'>          
        </StackItem>
        <StackItem className='login-page-form-container'>
          <Stack
            className='login-page-form'
            direction='column'
            horizontalAlign='h_start'
          >
            <StackItem className='eco-mb-32'>
              <img src={Logo} />
            </StackItem>
            <StackItem className='login-form-elements-mt'>
              <Typography
                type='title'
                level={3}
                pattern='bold'
                textTransform='capitalize'
                alignment='left'
              >
                Sign In
              </Typography>
            </StackItem>
            <StackItem className='login-form-elements-mt'>
              <Typography size='small' pattern='bold' alignment='left'>
                Sign in to your account to get started
              </Typography>
            </StackItem>

            <div className='login-form-elements-mt'>
              <LoginForm />
            </div>

            {/* <StackItem className='login-form-elements-mt'>
              <Typography size='small' alignment='center'>
                Don’t have an account?{" "}
                <Typography size='small' pattern='bold' alignment='center'>
                  Sign up
                </Typography>
              </Typography>
            </StackItem> */}
          </Stack>
        </StackItem>

        
      </Stack>
    </Stack>
  );
}

export default Login;
