import globalValues from "../../configs/globalValues";

const {
  WITMEG_ORGSERVER___BASEURL,
  WIMEGG_RETAILPAX__BASEURL,
  REACT_APP_API_productregistry,
  REACT_APP_rest_API_productregistry,
  REACT_APP_rest_API_productregistry_V2,
  REACT_APP_API_salesregistry,
  REACT_APP_ENTITLEMENTREG__URL,
  REACT_APP__NEUTRIPOS_BASEURL,
  REACT_APP_PAYMENT_BASE_URL
} = globalValues.baseURLS;

const apiEndpointPaths = {
  //*******************ORG SERVER (ORG REGISTEY) *********************/
  witmegOrgServer: {
    loginUser: `${WITMEG_ORGSERVER___BASEURL}/user/login`,
    loginUserMenuWidget: `${WITMEG_ORGSERVER___BASEURL}/v1/user/retailpacx/auth`,
    getRegisteredUserData: `${WITMEG_ORGSERVER___BASEURL}/registereduser/user/`, //--> POST
    getOrganisationAdmin: `${WITMEG_ORGSERVER___BASEURL}/v1/user/adminpermission`, //--> POST

  },
  dropInPaymentServer: {
    getPaymentMethods: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/paymentmethod/fetch`,
    instantPayment: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/payment/instant`,
    paymentDetail: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/pay/paymentdetail`,
    tokenPay: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/pay/token`,
    getPaymentTokens: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/payment/token/fetch`,
    getPaymentStores: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/management/fetch/stores`,
  },
  neutriPostServer: {
    //Get All Menu From NeutriPos
    getAllMenuData: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/menu`,

    /* Get Restaurant Menu created Date */
    getRestaurantMenuCreatedDate: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/menudate`,

    // Get All Main Menu IDs
    getRestaurantMainMenuIDs: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/mainmenu`,

    //Get All Main Menu Data by Main menu IDs
    getRestaurantMainMenuDataByIDs: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/submenu/mainmenuid`,

    //Get All Sub Menu Data
    getAllSubMenuProducts: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/menuproducts/submenuid`,

    // Get `Restaurant Menu settings` data
    getRestaurantMenuSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/webordersettings/fetch`,

    // Add `Restaurant Menu settings` data
    addRestaurantMenuSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/webordersettings/add`,

    // Update `Restaurant Menu settings` data
    updateRestaurantMenuSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/webordersettings/update`,

    // Create QR Code Settings for Restaurant
    setRestaurantQRCodeSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/qrcode/create`,

    // Fetch QR Code Settings for Restaurant
    getRestaurantQRCodeSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/qrcode/fetch`,

    // Update QR Code Settings for Restaurant
    updateRestaurantQRCodeSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/qrcode/update`,

    // Deactivate QR Code for Restaurant Menu
    deactivateRestaurantQRCodeSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/qrcode/deactivate`,
  },

  // ************************** Retail Paths Server *********************
  witmegRetailPaxServer: {
    getAllCompanies: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/all`, // --> POST
    createCompany: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/add`, // --> POST
    getCompanyById: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/id`, // --> POST
    editCompanyById: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/edit`, // --> POST
    deleteCompanyById: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/delete`, //--> POST
    getCompanyByOrgID: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/orgid`, //--> POST



    //Home Layouts
    getAllHomeLayouts: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/homelayout/all`, // --> POST
    getHomeLayoutByCOMID: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/homelayout/company`, // --> POST
    addHomeLayout: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/homelayout/add`, // ADD HOME lAYOUT
    removeHomeLayout: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/homelayout/delete`, //---> POST
    updateHomeLayout: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/homelayout/edit`, //---> POST
    getHomeLayoutbyID: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/homelayout/id`, //---> POST
    getHomeLayoutbyPageID: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/homelayout/page`, //---> POST
    publishHomelayout: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/homelayout/publish`, //---->> POST

    //Widges
    getAllWidgets: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/widget/all`, //--> POST

    // E - Commerce

    fetchProductList: `${REACT_APP_rest_API_productregistry}/fetchproductlist`,
    fetchEcommerWidgets: `${REACT_APP_rest_API_productregistry}/type/id/52/0/20`,

    fetchRequest: `${REACT_APP_API_productregistry}`,

    fetchV2ProductsList: `${REACT_APP_rest_API_productregistry_V2}/category/pagination/id/52/5/0/12`,
    filterV2Products: `${REACT_APP_rest_API_productregistry_V2}/smartfilter/pagination/`,
    //Update Company Products
    updateCompanyProducts: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/product/edit`,

    //GET THE CURRENCIES

    getCurrencies: `${REACT_APP_API_salesregistry}currencies/id/26`,

    // Get Settings
    getSettings: `${REACT_APP_API_salesregistry}setting`,

    //Pages
    //Create New Page
    createNewPage: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/pagesinfo/add`,
    deletePage: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/pagesinfo/delete`,
    updatePage: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/pagesinfo/edit
    `,
    getAllPages: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/cms/pagesinfo/all/companyid`,
    getPageByMenuID: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/pagesinfo/id`,
    //Get Sub Page Info By Parent Page Info ID
    getSubPagesByParentPageID: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/pagesinfo/mainpagesinfoid`,

    //Themes
    getAllThemes: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/theme/all`,

    //Publish Un Publish
    sitePublish: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/company/publish`,

    /* `Pricing Plan` paths */
    getTierDetails: `${REACT_APP_ENTITLEMENTREG__URL}/v2/tier/getall`,
    getVerticalPlan: `${REACT_APP_ENTITLEMENTREG__URL}/v1/witmeg/vertical/getall`,
    getFrequencyDetails: `${REACT_APP_ENTITLEMENTREG__URL}/v1/frequencydetails/all`,
    getProductFeatures: `${REACT_APP_ENTITLEMENTREG__URL}/v1/vertical/features/list`,


    //settings
    getSettingsByCompanyID: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/settings/companyid`,
    createSettings: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/settings/add`,
    updateSettings: `${WIMEGG_RETAILPAX__BASEURL}/v1/retail/settings/edit`

  },
};

export default apiEndpointPaths;
