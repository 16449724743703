import React, { Component } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Modal, Drawer } from 'antd';
import axios from 'axios';
import UploadItems from "./imageupload-global";
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';

export default class Company extends Component {
  constructor(props) {
    super(props);
    this.onChangeProvider = this.onChangeProvider.bind(this);
    this.onChangeServiceKey = this.onChangeServiceKey.bind(this);
    this.onChangeClientKey = this.onChangeClientKey.bind(this);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      Name: '',
      Logo: '',
      favicon: '',
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      StateProvinceRegion: '',
      PostZipCode: '',
      Country: '',
      Phone: '',
      Fax: '',
      Email: '',
      OpenningHours: '',
      SubDomain: '',
      CustomerDomain: '',
      OrganisationID: '',
      ServiceKey: '',
      ClientKey: '',
      CDNPath: '',
    }
  }

  componentDidMount() {
    axios.get(config.get('backendURL') + 'company/' + sessionStorage.getItem('company'))
      .then(response => {
        this.setState({
          Name: response.data.Name,
          Logo: response.data.Logo,
          favicon: response.data.favicon,
          AddressLine1: response.data.AddressLine1,
          AddressLine2: response.data.AddressLine2,
          City: response.data.City,
          StateProvinceRegion: response.data.StateProvinceRegion,
          PostZipCode: response.data.PostZipCode,
          Country: response.data.Country,
          Phone: response.data.Phone,
          Fax: response.data.Fax,
          Email: response.data.Email,
          OpenningHours: response.data.OpenningHours,
          SubDomain: response.data.SubDomain,
          CustomerDomain: response.data.CustomerDomain,
          OrganisationID: response.data.OrganisationID,
          ServiceKey: response.data.WorldPayServiceKey,
          ClientKey: response.data.WorldPayClientKey,
          FBappid: response.data.FBappid,
          FBpageid: response.data.FBpageid,
          LogoCDN: response.data.LogoCDN,
          LocationID: response.data.LocationID,
          WishListEnabled: response.data.WishListEnabled,
          HelpPage: response.data.HelpPage,
          SocialMedia: response.data.SocialMedia,
          DeliveryMessage: response.data.DeliveryMessage,
          ShopNowItems: response.data.ShopNowItems,
          theme: response.data.theme,
          CDNPath:response.data.CDNPath
        })

      })
      .catch(function (error) {
        console.log(error);
      })
  }
  onChangeServiceKey(e) {
    this.setState({
      ServiceKey: e.target.value
    })
  }
  onChangeClientKey(e) {
    this.setState({
      ClientKey: e.target.value
    })
  }
  onChangeProvider(e) {
    this.setState({
      Provider: e.target.value
    })
  }

  onSubmit(e) {
    e.preventDefault();

    const company = {
      Name: this.state.Name,
      OrganisationID: this.state.OrganisationID,
      Logo: this.state.Logo,
      favicon: this.state.favicon,
      AddressLine1: this.state.AddressLine1,
      AddressLine2: this.state.AddressLine2,
      City: this.state.City,
      StateProvinceRegion: this.state.StateProvinceRegion,
      PostZipCode: this.state.PostZipCode,
      Country: this.state.Country,
      Phone: this.state.Phone,
      Fax: this.state.Fax,
      Email: this.state.Email,
      OpenningHours: this.state.OpenningHours,
      SubDomain: this.state.SubDomain,
      CustomerDomain: this.state.CustomerDomain,
      WorldPayServiceKey: this.state.ServiceKey,
      WorldPayClientKey: this.state.ClientKey,
      FBappid:this.state.FBappid,
      FBpageid:this.state.FBpageid,
      LogoCDN:this.state.LogoCDN,
      LocationID:this.state.LocationID,
      WishListEnabled:this.state.WishListEnabled,
      HelpPage:this.state.HelpPage,
      SocialMedia:this.state.SocialMedia,
      DeliveryMessage:this.state.DeliveryMessage,
      ShopNowItems:this.state.ShopNowItems,
      theme:this.state.theme,
      CDNPath:this.state.CDNPath
    }

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      "aud": process.env.REACT_APP_jwtAud,
      "exp": timestamp,
      "iss": process.env.REACT_APP_jwtIss
    };

    var token = jwt.sign(payload, process.env.REACT_APP_secret);

    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + token,
      }
    };

    axios.post(config.get('backendURL') + 'company/update/' + sessionStorage.getItem('company'), company, requestOptions)
      .then(function (data) {

        Modal.success({
          title: 'Success',
          content: 'Payment details has been updated',
          onOk() { },
        });


      })

    //window.location = '/';
  }

  render() {
    const { visible, faviconvisible } = this.state;
    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Payment Provider</Form.Label>

            <Form.Control as="select" onChange={this.onChangeProvider}>
              <option>WorldPay</option>
              <option>FirstPay</option>
              <option>Paymentsense UK </option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridServiceKey">
            <Form.Label>Service Key</Form.Label>
            <Form.Control value={this.state.ServiceKey} onChange={this.onChangeServiceKey} />
          </Form.Group>

        </Form.Row>

        <Form.Row>

          <Form.Group as={Col} controlId="formGridClientKey">
            <Form.Label>Client Key</Form.Label>
            <Form.Control value={this.state.ClientKey} onChange={this.onChangeClientKey} />
          </Form.Group>
        </Form.Row>

        <Button variant="primary" type="submit">
          Submit
  </Button>


      </Form>


    );
  }
}
