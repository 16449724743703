import React, { useState } from "react";
import PropTypes from "prop-types";
import { default as ReactFontPicker } from "font-picker-react";

function FontPicker(props) {
  const { font ="Open Sans", onChange, sort = "alphabet", id = "fontpicker" } = props;
  const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";
  const [activeFontFamily, setActiveFontFamily] = useState(font?font:"Open Sans")
  return (
    <ReactFontPicker
      apiKey={API_KEY}
      activeFontFamily={activeFontFamily}
      onChange={(val) =>{setActiveFontFamily(val.family);onChange(val.family)}}
      limit={100}
      sort={sort}
      pickerId={id}

    />
  );
}

FontPicker.propTypes = {};

export default FontPicker;
