import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Alert,
  async,
  Button,
  Card,
  Icon,
  ProgressBar,
  Space,
  Spin,
  Stack,
  StackItem,
  Typography,
} from "witmeg-ui-system";
import { Modal } from "antd";
import "../GlobalImageUploader/ImageUploader.css";
import { generateImageUploadServerToken } from "../utilities/tokenGenerators";
import globalValues from "../../configs/globalValues";
import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";
import { useEffect } from "react";

function GlobalIconUploader(props) {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [testCrop, setTestCrop] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blobFile, setBlobFile] = useState(null);
  const [getImage, setGetImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [imageSizeValidation, setImageSizeValidation] = useState(false);
  const [imageTypeValidation, setImageTypeValidation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [uploadToCloud, setUploadToCloud] = useState(false)
  const dispatch = useDispatch()

  // console.log(props);//

  //File Validation On TYPE
  const isValidFileUploaded = (file) => {
    const validExtensions = ["svg+xml"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const selectImage = (e) => {
    // console.log("sdgsgs", e);

    setImageSizeValidation(false)
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];

      const MAX_FILE_SIZE = 25000; //25MB
      const fileSizeValidation = image.size / 1024;
      if (fileSizeValidation > MAX_FILE_SIZE) {
        setImageSizeValidation("Image size is greater than 25 MB");
        setIsSuccess(true);
      } else {
        if (isValidFileUploaded(image)) {
          setImageSizeValidation("");
          setIsSuccess("");
          setOutput(null);

          const reader = new FileReader();
          reader.addEventListener("load", () => setSrc(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        } else {
          setIsSuccess(true);
          setImageTypeValidation("Image should be an SVG");
        }
      }
    }
    // setSrc(URL.createObjectURL(file));
    setIsModalVisible(true);
    props.setImageError(false);
  };

  const onCropComplete = (crop) => {
    // console.log("IAM ", crop);
    makeClientCrop(crop);
    setTestCrop(crop);
  };

  async function makeClientCrop(crop) {
    // console.log(testCrop);
    if (image && crop.width && crop.height) {
      const croppedImageUrl = await cropImageNow(image, crop, "newFile.jpeg");
      setOutput(croppedImageUrl);
      setUploadDone(false)
    }

    // setIsModalVisible(false);
  }

  const cropImageNow = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          //console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        let fileUrl = window.URL.createObjectURL(blob);
        //console.log(blob.name)
        //console.log(this)
        resolve(fileUrl);
        setBlobFile(blob); // Set blob image inside the state here
      }, "image/svg");
    });
  };

  function handleClear() {
    setSrc(null);
    setOutput(null);
    // console.log("SASD", props.selectedProduct?.Logo);
  }

  async function handleUpload() {
    Image = await handleImageUpload(blobFile);

    props.handelLogoImage(Image);
    // console.log("SASD", blobFile);
    setGetImage(true);
    // props.setLogoUpdated(true);
  }

  //Upload Image to Cloud
  const handleImageUpload = async (uploadingImage) => {
    setIsLoading(true);
    setUploadToCloud(true)
    const token = await generateImageUploadServerToken();

    const fmData = new FormData();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    const timeStamp =
      Math.floor(Math.random() * 10000 + 1) +
      Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";

    const fileName = `P_${timeStamp}`;
    const filePath = `/${environmentFolderName}/retailpacx/widgets/image-widget/`;

    fmData.append("file", uploadingImage);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const res = await Axios.post(
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
        fmData,
        config
      );

      // console.log("server res: ", res.data.Result);
      setIsLoading(false);
      setUploadDone(true)
      setUploadToCloud(false)
      props.setUploadToCloud(false)
      return res.data.Result;
    } catch (err) {
      // console.log("Eroor: ", err);
      const error = new Error("Some error");
    }
  };

  function onCancel() {
    props.setImageUpload(false);
    props.setImageError(false);
    setSrc(null);
    setOutput();
    setCrop();
    dispatch(setCommonState({
      draggableState: false
    }))
    setImageTypeValidation(false);
    setImageSizeValidation(false)
    // setCrop({ unit: "%", width: 50, aspect: 16 / 9 });
  }

  const inputFile = useRef(null);

  const onClick = () => {
    inputFile.current.click();
  };

  useEffect(() => {
    props.setUploadedIcon(output)
  }, [output])


  console.log('isSuccess==>>', isSuccess)

  return (
    <div style={{ padding: "32px 20px" }}>
      {/* IMAGE VALDATIONS */}
      {props.imageError && (
        <Alert message='Image is not uploaded' type='error' showIcon />
      )}
      {isSuccess && (
        <>
          {imageSizeValidation && (
            <Alert message={imageSizeValidation} type='error' showIcon />
          )}
          {imageTypeValidation && (
            <Alert message={imageTypeValidation} type='error' showIcon />
          )}
        </>
      )}

      <div className='eco-mt-12'>
        <Stack
          className='image-upload-section'
          direction='column'
          onClick={onClick}
          horizontalAlign='h_center'
          verticalAlign='v_center'
        >
          <input
            type='file'
            accept='image/*'
            onChange={(event) => {
              selectImage(event);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
            ref={inputFile}
            style={{ display: "none" }}
          />
          <Icon name='upload-outline' type='filled' />
          <Typography type='text'>Click here to upload a file</Typography>
          <Typography type="text" size="small" color="gray_base">(Max. file size 25MB)</Typography>
          {/* <Typography type='text' size='small' color='gray_base'>
            (Max. file size 25MB)
          </Typography> */}
        </Stack>
        <input
          type='file'
          accept='image/*'
          onChange={selectImage}
          style={{ display: "none" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        {src && (
          <div>
            <Typography
              pattern='italic'
              color='gray_base'
              type='text'
              size='small'
            >
              Note : Please crop the Icon before uploading
            </Typography>

            <br />
            <ReactCrop
              src={src}
              onImageLoaded={setImage}
              crop={crop}
              onChange={setCrop}
              onComplete={onCropComplete}
            />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
      <div style={{ marginTop: "20px" }}>
        {props.selectedProduct?.Logo && getImage === false && (
          <>
            <Typography type='title' level={4}>
              Manage Upload
            </Typography>
            <br />
            <Card
              shadow='default'
              disabled={false}
              type={"complex"}
              content={
                <Stack className='eco-pa-4' style={{ flexWrap: "wrap" }}>
                  <StackItem className='thumbnail-image'>
                    <img
                      alt='Crop'
                      style={{ width: "100%" }}
                      draggable='false'
                      src={props.selectedProduct?.Logo}
                    />
                  </StackItem>

                  <StackItem
                    style={{
                      flexGrow: "3",
                      height: "100px",
                      padding: "8px",
                    }}
                  >
                    {" "}
                    <div className='eco-mt-4'>
                      {/* {renderImageSizeInfo(fl.imageType)} */}
                    </div>
                  </StackItem>
                  <StackItem
                    style={{
                      height: "100px",
                      padding: "8px",
                      justifyContent: "flex-end",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isLoading === false && uploadDone != true && (
                      <Button
                        onClick={handleUpload}

                      >

                        Click To Upload
                      </Button>
                    )}

                    {isLoading && <Spin />}

                    {uploadDone === true && <ProgressBar type="circle" size="small" variant="success" width={50} value="100" />}
                    {/* <Button
                        onClick={handleClear}
                        customStyles={{ border: "none" }}
                      >
                        <Icon name='trashcan' color='red' />
                      </Button> */}
                  </StackItem>
                </Stack>
              }
              width='100%'
              height='fit-content'
            />
          </>
        )}

        {output && (
          <>
            <Typography type='title' level={4}>
              Manage Upload
            </Typography>
            <br />
            <Card
              shadow='default'
              disabled={false}
              type={"complex"}
              content={
                <Stack className='eco-pa-4' style={{ flexWrap: "wrap" }}>
                  <StackItem className='thumbnail-image'>
                    <img
                      alt='Crop'
                      style={{ width: "100%" }}
                      src={output}
                      draggable='false'
                    />
                  </StackItem>

                  <StackItem
                    style={{
                      flexGrow: "3",
                      height: "100px",
                      padding: "8px",
                    }}
                  >
                    {" "}
                    <div className='eco-mt-4'>
                      {/* {renderImageSizeInfo(fl.imageType)} */}
                    </div>
                  </StackItem>
                  <StackItem
                    style={{
                      height: "100px",
                      padding: "8px",
                      justifyContent: "flex-end",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >


                    {isLoading === false && uploadDone === false && (
                      <Button
                        onClick={handleUpload}

                      >

                        Click To Upload
                      </Button>
                    )}

                    {isLoading && <Spin />}

                    {uploadDone === true && <ProgressBar type="circle" size="small" variant="success" width={50} value="100" />}
                    {/* <Button
                        onClick={handleClear}
                        customStyles={{ border: "none" }}
                      >
                        <Icon name='trashcan' color='red' />
                      </Button> */}
                  </StackItem>
                </Stack>
              }
              width='100%'
              height='fit-content'
            />
          </>
        )}
      </div>
    </div>
  );
}

export default GlobalIconUploader;
