import React, { useState } from 'react';
import './customTabs.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeSelectedRestaurantMainMenu, fetchAllRestaurantMenuData } from '../../../../redux/resturantWidgetReducer';
import '../../../utilities/stringOverride';
import { Stack } from 'witmeg-ui-system';

const CustomTabs = ({ children, isPopup = false }) => {
  const dispatch = useDispatch();

  const { menuData:{ storage: { mainMenus }, selectedMainMenuID, uiCustomizations }, defaultCloudLocationID, organizationID } = useSelector((state) => state.resturantWidget);

  const handleActiveTab = (_menuID)=>{
    dispatch(changeSelectedRestaurantMainMenu({ mainMenuID: _menuID }));
    dispatch(fetchAllRestaurantMenuData({
      mainMenuID: _menuID,
      CloudLocationID: defaultCloudLocationID,
      OrganizationID: organizationID,
    }));
  }

  return (
    <div className="custom-tabs">
      <Stack horizontalAlign="h_center" wrap={true} direction="row" style={{ width: '70%' }}>
        {mainMenus && mainMenus.length > 0 && mainMenus.map((_item) => {
          return (
            <div
              key={_item.menuID}
              onClick={() => handleActiveTab(_item.menuID)}
              className={ _item.menuID === selectedMainMenuID ? 'tab-item selected-tab-item' : 'tab-item' }>
              <span style={uiCustomizations?.tabs?.title ?? {}}>
                {_item.menuName.toCapitalFirst()}
              </span>
            </div>
          );
        })}
      </Stack>
      <div className="tab-content-container">{children}</div>
    </div>
  );
};

export default CustomTabs;
