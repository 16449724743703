import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const widgetSlice = createSlice({
    name: "Widgets",
    initialState: {
        WIDGETID: "",
        WIDGETITEM: "",
        WIDGETDATA: "",
        WIDGETTYPE: "",
    },
    reducers: {
        setWidgetData: (state, action) => {
            const { WidgetID, WidgetItem, WidgetData, WidgetType } = action.payload || {};

            state.WIDGETID = WidgetID;
            state.WIDGETITEM = WidgetItem;
            state.WIDGETTYPE = WidgetType;
            state.WIDGETDATA = WidgetData;
        }
    }
})

export const { setWidgetData } = widgetSlice.actions;
export default widgetSlice.reducer;