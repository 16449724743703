import React, { useEffect, useState } from "react";
import {
  InputBox,
  Label,
  Row,
  Col,
  ColorPicker,
  FileUploader,
  Stack,
  Select,
  Button,
  async,
  Spin,
} from "witmeg-ui-system";
import { useFormContext } from "react-hook-form";
import ImageUploader from "../../ImageUploader";
import { getSettingsByCompanyID } from "../../../utilities/apiRequests/witmegRetailPaxServerRequests";
import { set } from "lodash";
import { Slider } from "antd";

const GlobalSettingsForm = () => {
  const formatOptions = [
    {
      label: "Background color",
      value: "backgroundColor",
    },
    {
      label: "Image",
      value: "image",
    },
  ];
  const { setValue } = useFormContext();
  const [backgroundColor, setBackgroundColor] = useState("#cecece");
  const [image, setImage] = useState();
  const [slider, setSlider] = useState(0);
  const [top, setTop] = useState("40px");
  const [right, setRight] = useState("40px");
  const [bottom, setBottom] = useState("40px");
  const [left, setLeft] = useState("40px");
  const [isFetching, setIsFetching] = useState(false);

  const onChangeBackgroundColor = (data) => {
    setBackgroundColor(data);
    setValue("backgroundColor", data);
    setValue("image", "");
  };

  const onChangeImage = (data) => {
    setImage(data);
    setValue("image", data);
    setValue("backgroundColor", "");
  };

  const [selectedFormatOption, setSelectedFormatOption] = useState({
    label: "Background color",
    value: "backgroundColor",
  });

  const onChangeSlider = (data) => {
    setSlider(data);
    setPaddingAll(`${data}px`);
  };

  const setPaddingAll = (data) => {
    setTop(data);
    setValue("top", data);
    setRight(data);
    setValue("right", data);
    setBottom(data);
    setValue("bottom", data);
    setLeft(data);
    setValue("left", data);
  };

  const fetchSettings = async () => {
    setIsFetching(true);
    const reqBody = {
      CompanyID: sessionStorage.getItem("company"),
    };
    const response = await getSettingsByCompanyID(reqBody);
    onChangeBackgroundColor(response?.GlobalSettings?.BackgroundColour);
    if (response?.GlobalSettings?.BackgroundImage?.URL) {
      onChangeImage(response?.GlobalSettings?.BackgroundImage?.URL);
      process.nextTick(() => {
        setSelectedFormatOption({
          label: "Image",
          value: "image",
        });
      });
    }

    const {
      top = "40px",
      right = "40px",
      bottom = "40px",
      left = "40px",
    } = response?.GlobalSettings?.Padding
      ? JSON.parse(response?.GlobalSettings?.Padding)
      : "";
    setTop(top);
    setValue("top", top);
    setRight(right);
    setValue("right", right);
    setBottom(bottom);
    setValue("bottom", bottom);
    setLeft(left);
    setValue("left", left);
    setValue("id", response?.ID);
    if (new Set([top, right, bottom, left]).size === 1) {
      setSlider(top.replace(/[^\d]/g, ""));
    }
    setIsFetching(false);
  };

  useEffect(() => {
    async function fetchData(params) {
      await fetchSettings();
    }
    fetchData();
  }, []);

  if (isFetching) {
    return (
      <div
        style={{
          height: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div style={{ margin: "32px 22px" }}>
      <Row className="form-row eco-mb-24" align="middle">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Choose a format</Label>
          </div>
        </Col>
        <Col span={16}>
          <Select
            options={formatOptions}
            value={selectedFormatOption}
            onChange={(e) => {
              // onChangeBackgroundColor("");
              // onChangeImage("");
              setSelectedFormatOption(e);
            }}
            noBorder
          />
        </Col>
      </Row>
      {selectedFormatOption.value === "backgroundColor" && (
        <Row className="form-row eco-mb-24" align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Background color</Label>
            </div>
          </Col>
          <Col span={8}>
            <InputBox
              value={backgroundColor}
              type="text"
              noBorder
              backgroundOnly
              onChange={(e) => onChangeBackgroundColor(e.target.value)}
            />
          </Col>
          <Col span={8}>
            <ColorPicker
              defaultColor={backgroundColor}
              setDefaultColor={onChangeBackgroundColor}
            />
          </Col>
        </Row>
      )}
      {selectedFormatOption.value == "image" && (
        <Row className="eco-mb-24">
          <Col span={24}>
            <ImageUploader
              file={image}
              onSetImage={(img) => {
                onChangeImage(img);
              }}
              onSetIsValidImageType={(err) => {
                console.log(err);
              }}
              width="500"
              height="500"
            />
          </Col>
        </Row>
      )}
      <Row justify="center" className="eco-mb-24">
        <Col span={24}>
          <div className="form-lbl-wrapper">
            <Label>Padding</Label>
          </div>
        </Col>
        <Col span={18}>
          <Stack
            direction="column"
            style={{ border: "1px solid #cecece", padding: "10px" }}
          >
            {/* <div style={{ width: "50px" }}>
              <InputBox type="text" value="0px" className="element-input" />
            </div> */}
            <Stack>
              {/* <div style={{ flexGrow: "1", width: "50px" }}>
                <InputBox type="text" value="40px" className="element-input" />
              </div> */}
              <div
                style={{
                  backgroundColor: "#e7eff2",
                  flexGrow: "4",
                  padding: "10px",
                  margin: "10px",
                  width: "270px",
                }}
              >
                <Stack direction="column">
                  <div style={{ width: "50px" }}>
                    <InputBox
                      type="text"
                      defaultValue="40px"
                      value={top}
                      className="element-input"
                      onChange={(e) => setValue("top", e.target.value)}
                    />
                  </div>
                  <Stack horizontalAlign="space_between" width={250}>
                    <div style={{ width: "50px" }}>
                      <InputBox
                        type="text"
                        defaultValue="40px"
                        value={left}
                        className="element-input"
                        onChange={(e) => setValue("left", e.target.value)}
                      />
                    </div>
                    <div style={{ width: "50px" }}>
                      <InputBox
                        type="text"
                        defaultValue="40px"
                        value={right}
                        className="element-input"
                        onChange={(e) => setValue("right", e.target.value)}
                      />
                    </div>
                  </Stack>
                  <div style={{ width: "50px" }}>
                    <InputBox
                      type="text"
                      defaultValue="40px"
                      value={bottom}
                      className="element-input"
                      onChange={(e) => setValue("bottom", e.target.value)}
                    />
                  </div>
                </Stack>
              </div>
              {/* <div style={{ flexGrow: "1", width: "50px" }}>
                <InputBox type="text" value="0px" className="element-input" />
              </div> */}
            </Stack>
            {/* <div style={{ width: "50px" }}>
              <InputBox type="text" value="0px" className="element-input" />
            </div> */}
          </Stack>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={10}>
          <Slider value={slider} onChange={onChangeSlider} />
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <Button variant="link" onClick={() => fetchSettings()}>
            Reset padding
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default GlobalSettingsForm;
