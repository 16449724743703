import lodash from "lodash";
import { APIError, errorLogger } from "../errorHandlers";

import apiEndpointPaths from "../apiEndpointPaths";
import axios from "axios";
import {
  generateEntitlementRegistry,
  generateProdToken,
  generateRetailPaxToken,
  generateSalesRegistryToken,
  generateTokenForNeutriPOS,
} from "../tokenGenerators";

//Get all Companies
export async function getAllCompaniesData(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllCompanies;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// Create Companies
export async function createCompany(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.createCompany;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// Get Company By ID
export async function getCompanyByID(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCompanyById;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Edit Company by ID
export async function editCompanyById(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.editCompanyById;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// Delete Companies
export async function deleteCompany(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.deleteCompanyById;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Get Company By OrgID
export async function getCompanyByOrgID(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCompanyByOrgID;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Site Publish Un Publish
export async function sitePublishUnPublish(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.sitePublish;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Update Company Products
export async function updateCompanyByProduct(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl =
      apiEndpointPaths.witmegRetailPaxServer.updateCompanyProducts;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// GET ALL HOME LAYOUTS
export async function getAllHomeLayouts(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllHomeLayouts;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//GET HOME LAYOUT BY PAGE ID
export async function getHomeLayoutByPageID(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl =
      apiEndpointPaths.witmegRetailPaxServer.getHomeLayoutbyPageID;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// GET ALL HOME LAYOUTS BY COMPANY ID
export async function getHomeLayoutByCOMID(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl =
      apiEndpointPaths.witmegRetailPaxServer.getHomeLayoutByCOMID;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// CREATE  HOME LAYOUT
export async function addHomeLayout(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.addHomeLayout;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// CREATE  HOME LAYOUT
export async function publishHomelayout(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.publishHomelayout;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = " Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// UPDATE  HOME LAYOUT
export async function updateHomeLayout(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.updateHomeLayout;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//GET HOME LAYOUT BY ID
export async function getHomeLayoutByID(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getHomeLayoutbyID;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// DELETE  HOME LAYOUT
export async function deleteHomeLayout(reqBody = {}, options = {}) {

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.removeHomeLayout;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result, Status } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result, Status;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// GET ALL WIDGETS
export async function getAllWidgets(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllWidgets;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// E COMMERCE WIDGETS
// GET ALL WIDGETS
export async function getAllProductDetails(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateProdToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = `${apiEndpointPaths.witmegRetailPaxServer.filterV2Products}${apiReqBody.OrgID}/${apiReqBody.LocationID}/0/20`;
    // const apiReqUrl = `${apiEndpointPaths.witmegRetailPaxServer.filterV2Products}5f32109b761a5a4312aa9706/26/0/20`;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    debugger;
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// GET  REQUEST BODY
export async function getRequestBody(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateProdToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.fetchRequest;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const Result = resObj.data.productcategories;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//E COMMERCE OVERLAY
export async function ecommerceWidgets(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateProdToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl =
      apiEndpointPaths.witmegRetailPaxServer.fetchEcommerWidgets;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const Result = resObj.data.Result;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}
//Setings

export async function getSettings(orgID) {
  try {
    let token = await generateSalesRegistryToken();
    const apiReqUrl = `${apiEndpointPaths.witmegRetailPaxServer.getSettings}/${orgID}/0`

    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "GET",
      url: apiReqUrl,
      headers,
    });

    console.log("resObj", resObj)

    const Result = resObj.data;

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}
// Currency
export async function getCurrency(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateSalesRegistryToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getCurrencies;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const Result = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// GET ALL PAGES
export async function getAllPages(reqBody = {}, options = {}) {
  // console.log("I AM API");

  // console.log(getCompanyByID);

  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllPages;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Create Pages
export async function createPages(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.createNewPage;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Update Page By ID
export async function updatePageByID(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.updatePage;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

// getPageByMenuID
export async function getPageByMenuID(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getPageByMenuID;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Delete Page By ID
export async function deletePageByID(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.deletePage;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function getSubPagesByParentPageID(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    // console.log("reqBody");
    // console.log(reqBody.ID);
    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getSubPagesByParentPageID;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

//Themes
//Get All Themes

export async function getAllThemes(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getAllThemes;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    // const resObj = await axiosWitmegRetailPaxServerInstance({
    //   url: apiReqUrl,
    //   method: "POST",
    //   data: apiReqBody,
    // });

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;
    // if (!Status) {
    //   throw new APIError(Result);
    // }
    // console.log("Result");
    // console.log(Result);
    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get Frequency details
    ex: `Monthly`, `Annual`
*/
export async function getFrequencyDetails(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getFrequencyDetails;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "GET",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get all `Vertical Plans`
*/
export async function getVerticalListPlan(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getVerticalPlan;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "GET",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get Vertical Plan Features
    ex: `Witmeg-Retail`, `Witmeg-Hospitality`
*/
export async function getVerticalPlanFeatures(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getProductFeatures;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Get `Tiers details`
*/
export async function getTierDetails(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateEntitlementRegistry();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getTierDetails;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;

  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function getSettingsByCompanyID(reqBody = {}, options = {}) {

  try {

    const apiReqBody = { ...reqBody };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.getSettingsByCompanyID;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function createSettings(reqBody = {}, options = {}) {

  try {

    const apiReqBody = { ...reqBody };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.createSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function updateSettings(reqBody = {}, options = {}) {

  try {

    const apiReqBody = { ...reqBody };

    let token = await generateRetailPaxToken();

    const apiReqUrl = apiEndpointPaths.witmegRetailPaxServer.updateSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };

    const resObj = await axios({
      method: "POST",
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Result } = resObj.data;

    return Result;
  } catch (error) {
    let customErrMsg = error.customErrMsg || "";
    if (error.custmErrType === "APIERROR") {
      switch (true) {
        default: {
          customErrMsg = "Error Occurred. Please try again.";
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}
