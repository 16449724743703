import React, { useRef, useState } from "react";
import { Button, Popup, Space, Spin, Tabs } from "witmeg-ui-system";
import "./card.css";

import DesignForm from "./Forms/DesignForm";
import StyleForm from "./Forms/StyleForm";
import UploadForm from "./Forms/UploadForm";
import LayoutTwo from "./Layouts/LayoutTwo";
import { useForm, FormProvider } from "react-hook-form";
import LayoutFive from "./Layouts/LayoutFive.jsx";

const LAYOUTS = {
  CARD_LAYOUT_TWO: "CARD_LAYOUT_TWO",
  CARD_LAYOUT_FIVE: "CARD_LAYOUT_FIVE",
};

const CardWidget = (props) => {
  const {
    designData = {},
    styleData = {},
    commonData = {},
    onUpdate,
    isFetching,
  } = props;

  const { layout = "CARD_LAYOUT_FIVE" } = designData;

  const [showCardWidgetPopup, setShowCardWidgetPopup] = useState(false);

  const renderLayout = (layout) => {
    switch (layout) {
      case LAYOUTS.CARD_LAYOUT_TWO:
        return (
          <LayoutFive
            image={commonData.image}
            title={commonData.cardTitle}
            description={commonData.cardDescription}
            designData={designData}
            styleData={styleData}
          />
        );
      case LAYOUTS.CARD_LAYOUT_FIVE:
        return (
          <LayoutTwo
            image={commonData.image}
            title={commonData.cardTitle}
            description={commonData.cardDescription}
            designData={designData}
            styleData={styleData}
          />
        );
      default:
        return (
          <LayoutFive
            image={commonData.image}
            title={commonData.cardTitle}
            description={commonData.cardDescription}
            designData={designData}
            styleData={styleData}
          />
        );
    }
  };

  const tabContent = [
    {
      tabID: "1",
      tabName: "Upload",
      content: <UploadForm image={commonData.image} data={commonData} />,
    },
    {
      tabID: "2",
      tabName: "Design",
      content: <DesignForm data={designData} />,
    },
    {
      tabID: "3",
      tabName: "Style",
      content: <StyleForm data={styleData} />,
    },
  ];
  const methods = useForm({
    defaultValues: {
      commonData: {},
      designData: {},
      styleData: {},
    },
  });

  const cardRef = useRef();

  if (isFetching) {
    return (
      <div
        style={{
          height: "60vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      <div
        style={{ height: "100%", width: "100%" }}
        onDoubleClick={() => setShowCardWidgetPopup(true)}
      >
        {renderLayout(layout)}
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onUpdate)} ref={cardRef}>
          {showCardWidgetPopup && (
            <Popup
              width={680}
              modalTitle="Card"
              modalVisible={showCardWidgetPopup}
              onCancel={() => setShowCardWidgetPopup(false)}
              footer={
                <Space size="middle">
                  <Button
                    variant="primary"
                    className="save-btn"
                    onClick={(e) => {
                      cardRef.current.dispatchEvent(
                        new Event("submit", { cancelable: true, bubbles: true })
                      );
                      setShowCardWidgetPopup(false);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    className="cancel-btn"
                    onClick={() => setShowCardWidgetPopup(false)}
                  >
                    Cancel
                  </Button>
                </Space>
              }
            >
              <div>
                <Tabs tabContent={tabContent} />
              </div>
            </Popup>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default CardWidget;
