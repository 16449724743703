import React, { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  Alert,
  async,
  Button,
  Message,
  Popup,
  Space,
  Stack,
  Typography,
} from "witmeg-ui-system";
import {
  getHomeLayoutByID,
  updateHomeLayout,
  getHomeLayoutByPageID,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import ReactHtmlParser from "react-html-parser";
import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";
import { Modal } from "antd";

export default function App(props) {
  const [openBlog, setOpenBlog] = useState(false);
  const [editorError, setEditorError] = useState(false);
  const [typedData, setTypedData] = useState(null);
  const [data, setData] = useState([]);
  const [showhideEditor, setShowEditor] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const editorRef = useRef(null);

  const dispatch = useDispatch()

  useEffect(async () => {
    await fetchData();
  }, []);

  // useEffect(() => {
  //   if (props.checkData) {
  //     setData(props.checkData)
  //   }
  // }, [props.checkData])


  const fetchData = async () => {

    let deviceState = sessionStorage.getItem('deviceState')

    if (props.params.pageID) {
      //  ;

      let widID = await props.params.widgetID;
      let pageID = await props.params.pageID;

      const getData = await getHomeLayoutByID({
        ID: widID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      })
        .then((res) => {
          console.log("WE HAVE TOUCH DOWN", res[0]);
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              setTypedData(res[0].ItemData);

            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0]);
              setTypedData(res[0].ItemData);
            }
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      //  ;
      let widID = await props.params.widgetID;

      const getData = await getHomeLayoutByID({
        ID: widID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      })
        .then((res) => {
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              setTypedData(res[0].ItemData);

            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0]);
              setTypedData(res[0].ItemData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const log = async () => {
    //  ;
    fetchData()
    if (typedData == "") {
      setEditorError(true);
    } else {
      setEditorError(false);
      if (props.params.pageID) {
        setConfirmLoading(true)
        let widID = await props.params.widgetID;
        let pageID = await props.params.pageID;
        //  ;
        const updateWidjet = await updateHomeLayout({
          ID: widID,
          ItemHeading: "blog",
          Item: "blog",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.X,
          Type: "blog",
          ItemData: typedData,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: pageID,
          status: 20,
          CompanyID: sessionStorage.getItem("company"),
        }).then(async (response) => {
          console.log(response);
          dispatch(setCommonState({
            draggableState: false
          }))
          setConfirmLoading(false);
          console.log("I WAS HERE ON BLOG WIDGET");
          setOpenBlog(false);
          fetchData();
          setShowEditor(false);
          await Message.success("Widget edited successfully");
          // window.location.reload();

        });
      } else {
        //  ;
        let widID = await props.params.widgetID;
        setConfirmLoading(true);
        const updateWidjet = await updateHomeLayout({
          ID: widID,
          ItemHeading: "blog",
          Item: "blog",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.X,
          Type: "blog",
          ItemData: typedData,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: "",
          status: 30,
          CompanyID: sessionStorage.getItem("company"),
        }).then((response) => {
          console.log(response);
          dispatch(setCommonState({
            draggableState: false
          }))
          setConfirmLoading(false);
          console.log("I WAS HERE ON BLOG WIDGET ON FALSE CONDTION");

          Message.success("Widget edited successfully");
          setOpenBlog(false);
          fetchData();
          // window.location.reload();
          setShowEditor(false);
        });
      }
    }
  };

  function handleOpenEditor() {
    if (props.params.PreviewButton == false) {
      setShowEditor(true);
      dispatch(setCommonState({
        draggableState: true
      }))
    } else {
      setShowEditor(false);
    }
  }

  const cancelText = () => {
    setShowEditor(false);
    dispatch(setCommonState({
      draggableState: false
    }))
    // setTypedData(null);

    editorRef.current.setContent(data?.ItemData);
  };
  const handleEditorChange = (e) => {
    // console.log("Content was updated:", e.target.getContent());
    //This will remove all the unnesaccery html tags
    // let newContent = content.replace(/(<([^>]+)>)/gi, "");
    setTypedData(e.target.getContent());
    // setTypedData('<p><span style=\"color: blue;\"><em><strong>Blog</strong></em></span></p>');
    // console.log(newContent);

    setEditorError(false);
  };



  return (
    <>
      <div onDoubleClick={handleOpenEditor}>
        {showhideEditor === false && (
          <div>
            {/* PARSING THE HTML CONTENT */}
            {ReactHtmlParser(
              data?.ItemData
                ? data?.ItemData
                : "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            )}
          </div>
        )}
      </div>

      <Modal
        title='Text Editor'
        maskClosable={false}
        onOk={log}
        onCancel={cancelText}
        visible={showhideEditor}
        width={1046}

        footer={
          <Space>
            <Button
              onClick={log}

              variant="primary"
              color="white"
              customStyles={{ width: "100px" }}
              size='small'
              category={confirmLoading ? "load-text" : "text"}
              text="Confirm"
            >

              Confirm
            </Button>
            <Button

              onClick={cancelText}
              variant="secondary"
              className="eco-mr-8"
              customStyles={{ width: "100px" }}
              size="small"

            >
              Cancel
            </Button>
          </Space>
        }

      >
        {editorError && (
          <Alert message='Editor cannot be blank' type='error' showIcon />
        )}
        {showhideEditor === true && (
          <div className='eco-mt-12' style={{ position: "inherit", zIndex: "10000000" }}>
            <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={
                data?.ItemData ? data?.ItemData : "<p>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>"
              }
              init={{
                branding: false,
                statusbar: false,
                indent: false,

                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image",
                  "charmap print preview anchor help",
                  "searchreplace visualblocks code",
                  "insertdatetime media table paste wordcount", "textcolor",
                ],
                toolbar:
                  "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist |      template link anchor codesample  ",
              }}
              onChange={handleEditorChange}
              apiKey='ssv2iarxt5sjvufjszf9lrat3wu395eynq4hpttb0gdyzbnh'
            />


          </div>
        )}
      </Modal>

    </>
  );
}
