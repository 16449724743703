import React, { useState, useEffect } from "react";
import {
  Stack,
  Icon,
  Typography,
} from "witmeg-ui-system";
import { Popover } from "antd";


export default function PagesOverlayPopOver(props) {
  const { pagedata, handleEditPage, handleDeletePage, handleNavigaePage, setShowEditMenue } = props

  const [isOpen, setIsOpen] = useState(false)

  console.log("AS", pagedata)

  return (
    <div onClick={(e) => { e.stopPropagation(); pagedata.DraftNoLink === false && handleNavigaePage(pagedata.ID) }}>
      <Stack direction="row" horizontalAlign="space_between">
        <div>
          <Typography type="text">{pagedata.DraftPageName}</Typography>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <Popover
            placement="right"
            trigger="click"
            content={<Stack direction="column" verticalAlign="v_start">
              <div onClick={() => { setIsOpen(false); handleEditPage(pagedata); setShowEditMenue(false) }}><Typography type="text" alignment="left" className="settings-options">Edit</Typography></div>
              <div onClick={() => { setIsOpen(false); handleDeletePage(pagedata.ID); setShowEditMenue(false) }}><Typography type="text" alignment="left" className="settings-options">Delete</Typography></div>
            </Stack>}
            visible={isOpen}
            onVisibleChange={() => {
              if (isOpen === true) {
                setIsOpen(false);
                setShowEditMenue(false)
              }
            }}
          >
            <div onClick={() => { setIsOpen(true); setShowEditMenue(true) }}>
              <Icon name="settings" size={16} />
            </div>
          </Popover>
        </div>
      </Stack>
    </div>
  )
}
