import React, { useEffect, useState } from "react";
import { Button, Message } from "witmeg-ui-system";
import { useDispatch } from "react-redux";
import { setCommonState } from "../../../../redux/commonReducer";
import { getHomeLayoutByID, updateHomeLayout } from "../../../utilities/apiRequests/witmegRetailPaxServerRequests";
import ButtonPopUp from "../../../ReportWiidjet/ButtonPop";

function DndButtonWidget(props) {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { widgetID, PreviewButton, pageID } = props.params;
  const [showPopUp, setShowPopup] = useState(false);
  const [buttonTxt, setButtonTxt] = useState("Click Here");
  const [buttonColor, setButtonColor] = useState("");
  const [buttonTxtColor, setButtonTxtColor] = useState("");
  const [buttonBorder, setButtonBorder] = useState(0);
  const [textSize, setTextSize] = useState(14);
  const [textBold, setTextBold] = useState(false);
  const [textItalic, setTextItalic] = useState(false);
  const [textUnderline, setTextUnderline] = useState(false);
  const [widgetData, setWidgetData] = useState(null);
  const [tempBtnData, setTempBtnData] = useState(false);
  const [btnValidation, setBtnValidation] = useState(false);
  const [buttonWebURL, setButtonWebURL] = useState({
    siteURL: '',
    openInNewWindow: false,
  });

  useEffect(() => {
    if(props.defaultValues && props.defaultValues.styles) {
      let defStyles = JSON.parse(props.defaultValues.styles);
      let defText = props.defaultValues.text;
      setButtonTxt(defText)
      setButtonColor(defStyles.backgroundColor);
      setButtonTxtColor(defStyles.color);
      setButtonBorder(defStyles.border);
      setTextSize(defStyles.fontSize);
      // setTextBold((value) => data.txtBold ?? value);
    }
  }, [props.defaultValues])
  

  const fetchData = async () => {
    try {
      //   let deviceState = sessionStorage.getItem("deviceState");
      const response = await getHomeLayoutByID({
        ID: widgetID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      });
      console.log("response", response);
      setWidgetData(response[0]);
      setData(prePareDataDecode(response[0]));
    } catch (error) { }
  };

  const setData = (data = {}) => {
    setButtonTxt((value) => data.buttonTxt ?? value);
    setButtonColor((value) => data.btnColor ?? value);
    setButtonTxtColor((value) => data.btnTxtColor ?? value);
    setButtonBorder((value) => data.btnBorder ?? value);
    setTextSize((value) => data.txtSize ?? value);
    setTextBold((value) => data.txtBold ?? value);
    setTextItalic((value) => data.txtItalic ?? value);
    setTextUnderline((value) => data.txtUnderline ?? value);
    setTempBtnData((value) => data.buttonTxt ? true : false);
    setButtonWebURL({
      siteURL: data.buttonWebURL?.siteURL ?? '',
      openInNewWindow: data.buttonWebURL?.openInNewWindow ?? false,
    });
  };

  const prePareDataEncode = () => {
    // const styles = {
    //   border: buttonBorder,
    //   buttonTxt: buttonTxt,
    //   btnColor: buttonColor,
    //   btnTxtColor: buttonTxtColor,
    //   btnBorder: buttonBorder,
    //   txtSize: textSize,
    //   txtBold: textBold,
    //   txtItalic: textItalic,
    //   txtUnderline: textUnderline,
    //   buttonWebURL: buttonWebURL,
    // };
    const styles = {
      border: buttonBorder,
      backgroundColor: buttonColor,
      color: buttonTxtColor,
      fontSize: textSize,
      fontWeight: textBold,
      fontStyle: textItalic,
      textDecoration: textUnderline,
      buttonWebURL: buttonWebURL,
    };
    return JSON.stringify(styles);
  };

  const prePareDataDecode = (data) => {
    return data?.ItemData ? JSON.parse(data?.ItemData) : "";
  };

  const handleSubmit = async () => {
    dispatch(setCommonState({ draggableState: false }));
    setShowPopup(false);
    setBtnValidation(false)
    props.handleBtnAdd({
      text:buttonTxt,
      styles: prePareDataEncode()
    })
    // await updateHomeLayout({
    //   ID: widgetID,
    //   ItemHeading: "button",
    //   Item: "button",
    //   Width: widgetData?.Width,
    //   Height: widgetData?.Height,
    //   X: widgetData?.X,
    //   Y: widgetData?.Y,
    //   Type: "button",
    //   ItemData: prePareDataEncode(),
    //   RatioW: "",
    //   RatioH: "",
    //   Link: "",
    //   PageID: pageID,
    //   CompanyID: sessionStorage.getItem("company"),
    // });

    // Message.success("Widget edited successfully");
    // setShowPopup(false);
    // setBtnValidation(false)

    // await fetchData();
    // dispatch(setCommonState({ draggableState: false }));
    // setIsLoading(false);
  };

  useEffect(() => fetchData(), []);

  const handleShowPopup = () => {
    if (!PreviewButton) {
      dispatch(setCommonState({ draggableState: true }));
      setShowPopup(true);
    }
  };

  const handleCancel = () => {
    dispatch(setCommonState({ draggableState: false }));
    setShowPopup(false);
    setBtnValidation(false)
    fetchData()
  };

  const onChangeButtonText = (e) => {
    setButtonTxt(e.target.value)
  }

  const onChangeButtonWebURL = (_text) => {
    setButtonWebURL((prevState) => ({
      siteURL: _text,
      openInNewWindow: prevState.openInNewWindow,
    }));
  }

  const onCheckedButtonWebURLOpenInNewWindow = (_value) => {
    setButtonWebURL((prevState) => ({
      siteURL: prevState.siteURL,
      openInNewWindow: _value,
    }));
  }

  //Button Border Function
  const onChangeButtonBorder = (newValue) => {
    setButtonBorder(Number(newValue));
  };

  //Button Font Size Function
  const onChangeFontSize = (textSize) => {
    setTextSize(Number(textSize));
  };

  //Button Text Bold Function
  const onChangeBoldText = () => {
    setTextBold((value) => !value);
  };

  //Button Italic Function
  const onChangeItalicText = () => {
    setTextItalic((value) => !value);
  };

  //Button Underline Function
  const onChangeUnderlineText = () => {
    setTextUnderline((value) => !value);
  };

  //Button Color
  const btnStyles = {
    backgroundColor: buttonColor,
    border: "none",
    color: buttonTxtColor,
    borderRadius: `${buttonBorder}px`,
    width: "100%",
    height: "100%",
  }

  return (
    <>
      {" "}
      {!tempBtnData && (
        <div onDoubleClick={handleShowPopup} style={btnStyles}>
          <Button customStyles={btnStyles} variant="primary" >
          {buttonTxt !==""? buttonTxt: ""}
          </Button>
        </div>
      )}
      {tempBtnData && (
        <div onDoubleClick={handleShowPopup} style={btnStyles}>
          <Button customStyles={btnStyles} >

            <div style={{
              fontSize: `${textSize}px`,
              fontWeight: `${textBold ? "700" : '500'}`,
              fontStyle: `${textItalic ? "italic" : "normal"}`,
              textDecoration: `${textUnderline ? "underline" : "none"}`,
              backgroundColor: buttonColor,
              width: '100%',
              height:'100%'
            }}>
              {buttonTxt}
            </div>

          </Button>
        </div>
      )}
      {/* MODAL POP UP */}
      <ButtonPopUp
        showPopUp={showPopUp}
        btnValidation={btnValidation}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        buttonColor={buttonColor}
        setButtonColor={setButtonColor}
        buttonTxtColor={buttonTxtColor}
        setButtonTxtColor={setButtonTxtColor}
        onChangeButtonText={onChangeButtonText}
        setButtonBorder={setButtonBorder}
        buttonBorder={buttonBorder}
        onChangeFontSize={onChangeFontSize}
        textSize={textSize}
        onChangeButtonBorder={onChangeButtonBorder}
        textBold={textBold}
        textItalic={textItalic}
        textUnderline={textUnderline}
        onChangeBoldText={onChangeBoldText}
        onChangeItalicText={onChangeItalicText}
        onChangeUnderlineText={onChangeUnderlineText}
        buttonText={buttonTxt}
        onChangeButtonWebURL={onChangeButtonWebURL}
        onCheckedButtonWebURLOpenInNewWindow={onCheckedButtonWebURLOpenInNewWindow}
        buttonWebURL={buttonWebURL}
        isLoading={isLoading}
      />
    </>
  );
}

export default React.memo(DndButtonWidget);
