import base64 from "base-64";
import * as jose from "jose";
import { DateTime } from "luxon";
import qs from "query-string";
import React, { useState } from "react";
import browserStorage from "store2";
import { routePaths } from "../../routes/routeConstants";
import { Redirect } from "react-router-dom";

import routerHistory from "./routerHistory";

// export function getLoggedUserDetails() {
//   const user = browserStorage.get("user");
//   if (user) {

//     return user;
//   }
//   logoutUser();
//   return {};
// }

export function getLoggedUserDetails() {
  var temp = localStorage.getItem("user");

  if (temp) {
    return JSON.parse(temp);
  }
  logoutUser();
  return {};
}

export function getLoggedUserId() {
  let tempDetails = getLoggedUserDetails();
  console.log(tempDetails);
  const loggedUserDetails = tempDetails;
  return tempDetails.userid;
}
export function checkIsUserLoggedIn() {
  const existingUserValueInStorage = getLoggedUserDetails();

  if (existingUserValueInStorage.AccessToken) {
    return true;
  }
  return false;
}

export function logoutUser(options = {}) {
  const {
    redirectTo = routePaths.loginRoot, // By default re-irect to here.
    history = routerHistory,
    preventRedirect = false,
  } = options;

  browserStorage.set("user", "");
  browserStorage.set("OrganisationID", "");
  sessionStorage.setItem("loggedin", false);
  sessionStorage.setItem("username", "");
  localStorage.setItem("fullUserDetails", "");
  sessionStorage.setItem("deliverycost", "");
  sessionStorage.setItem("userAuthenticated", false)
  sessionStorage.setItem("CloudLocationIDs", null)
  localStorage.setItem("CloudLocationIDs", [])
  localStorage.setItem("userAuthenticated", false)
  document.cookie = "loginData=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  
  window.location.reload();

  if (!preventRedirect && history && history.push) {
    history.push(redirectTo);
  }

  if (!preventRedirect) {
    routerHistory.go(redirectTo);
  }

  return !preventRedirect ? (window.location.href = redirectTo) : null;
}

export function LogoutUserButtonWrapper(props) {
  const { children, redirectTo = routePaths.loginRoot } = props;

  const [loggedOut, setLoggedOut] = useState(false);

  const logout = () => {
    setLoggedOut(true);
    logoutUser();
  };

  if (loggedOut) {
    return <Redirect to={redirectTo} push />;
  }

  return (
    <div
      onClick={logout}
      style={{
        width: "100%",
      }}
    >
      {children}
    </div>
  );
}

// This is what happen when User Successfully Logged. (Either through UserName/Password OR Federated Login)
export function saveLoggedUser(passedUserDetails = {}) {
  try {
    const { AccessToken } = passedUserDetails;

    if (!AccessToken) {
      return;
    }
    const existingUserValuesInStorage = getLoggedUserDetails();

    // Getting encoded values inside AccessToken.
    // const decodedTokenInfo = jwt.decode(AccessToken);
    const decodedTokenInfo = jose.decodeJwt(AccessToken);

    browserStorage.set("user", {
      ...existingUserValuesInStorage,
      ...passedUserDetails, // In most cases, this receive key values such as AccessToken, RefreshToken, isFederatedLogin.
      ...decodedTokenInfo, // Normally available data keys are userid, exp, authorized, access_uuid.)
    });
  } catch {
    logoutUser();
  }
}

// NOTE : This only handle expiration by checking token exp value in local storage.
export function checkIsUserExpired(options = {}) {
  if (!checkIsUserLoggedIn()) {
    return null;
  }

  const existingUserValueInStorage = getLoggedUserDetails();
  const { exp: tokenExpireTimeAsSeconds } = existingUserValueInStorage;

  const expTime = DateTime.fromSeconds(tokenExpireTimeAsSeconds);
  const currentTime = DateTime.local();

  return expTime < currentTime;
}

export function removeUserIfExpired() {
  if (checkIsUserLoggedIn() && checkIsUserExpired()) {
    logoutUser({
      preventRedirect: true, // No redirection happen here, Because public pages should be accessible even when user is not logged/valid/expired.
    });
  }
}
