import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { async, Button, Col, Row } from "witmeg-ui-system";
import {
  ecommerceWidgets,
  getCurrency,
  getSettings
} from "../../utilities/apiRequests/witmegRetailPaxServerRequests";
import Axios from "axios";
import {
  generateProdToken,
  generateSalesRegistryToken,
} from "../../utilities/tokenGenerators";
import OfferProduct from "./OfferProduct";
const { Meta } = Card;
function Offers({ ItemHeading, WidgetName }) {
  const [filteredData, setFilteredData] = useState([]);
  const [currencyData, setCurrencyData] = useState([]);
  const [widgetName, setWidgetName] = useState(WidgetName);

  console.log("I AM WID", WidgetName);
  useEffect(async () => {
    let ORGID = sessionStorage.getItem("ORGID");

    let token = await generateSalesRegistryToken();

    if (widgetName == "featuredoffers" || widgetName == "bestselling") {
      var res = widgetName.replace(/\s+/g, "");

      setWidgetName(res);
    } else {
      setWidgetName(WidgetName);
    }

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const helloData = await Axios.get(
      // `https://salesregistry-dev.endpoints.neuralways.cloud.goog:5003/v1/currencies/${ORGID}/26`,
      ` https://salesregistry-dev.endpoints.neuralways.cloud.goog:5003/v1/currencies/${ORGID}/26`,
      requestOptions
    ).then((response) => {
      console.log("CURRENCY", response);
      setCurrencyData(response.data.Result);
    });
  }, []);
  useEffect(async () => {


    let ORGID = sessionStorage.getItem("ORGID");
    let orgSettings = await getSettings(ORGID)


    if (orgSettings.Status) {
      const salesLocationID = orgSettings.Result[0].SalesLocationID

      let token = await generateProdToken();
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      await Axios.get(
        `https://productregistry-dev.endpoints.neuralways.cloud.goog:5001/v1/rest/products/${widgetName}/${ORGID}/${salesLocationID}/0/4`,
        requestOptions
      )
        .then((res) => {
          const temp = async () => {
            console.log("IAM RESSSS", res.data.Result);

            if (res.data.Status) {
              setFilteredData(res.data.Result);
            }

          };

          temp();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const fetchCurrency = async () => {
    let ORGID = sessionStorage.getItem("ORGID");
    const getCurrencydata = await getCurrency({
      OrganisationID: ORGID,
    }).then((res) => {
      console.log("I AM RESSSSSSSSSS", res);
    });
  };

  return (
    <div style={{ backgroundColor: '#eeeeff', height: '100%', maxWidth: '1650px', margin: 'auto', padding: '16px' }}>
      <Row gutter={[16, 16]}>
        <Col><h2>{ItemHeading}</h2></Col>
      </Row>
      <Row gutter={[16, 16]}>
        {filteredData && filteredData.length > 0 ?
          <>
            {filteredData?.map((product, index) => (
              <Col xs={24} md={12} lg={6} id={"key" + product.ProductID}>
                <OfferProduct product={product} />
              </Col>
            ))}
          </>
          : <div style={{ background: '#fafafa', textAlign: 'center', padding: '64px', width: '100%', minHeight: '320px' }}>
            <h1 style={{ color: '#aaa' }}>No {ItemHeading} available </h1>
          </div>
        }


      </Row>
    </div>
  );
}

export default Offers;
