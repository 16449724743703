import React from "react";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import { Row, Col, Breadcrumb, Table } from "react-bootstrap";
import config from "react-global-configuration";
import jwt from "jsonwebtoken";
import "bootstrap/dist/css/bootstrap.min.css";

class App extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      vimage: [],
      loaded: false,
      pcontent: "",
      itemdata: this.props.params.ItemData,
    };
  }
  componentDidMount() {
    console.log(this.props.params.id);
    axios
      .get(config.get("backendURL") + "homelayout/byid/" + this.props.params.id)
      .then(async (response) => {
        console.log(response);
        this.setState({
          itemdata: response.data.itemdata,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    console.log(this.state.itemdata);
  }

  handleEditorChange = (content, editor) => {
    const pageData = {
      itemheading: "",
      item: "editor",
      width: this.props.params.w,
      height: this.props.params.h,
      x: this.props.params.x,
      y: this.props.params.y,
      order: this.props.params.order,
      type: "editor",
      itemdata: content,
      link: this.state.Title,
      pageid: this.props.params.pageid,
      companyid: sessionStorage.getItem("company"),
    };

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: process.env.REACT_APP_jwtAud,
      exp: timestamp,
      iss: process.env.REACT_APP_jwtIss,
    };

    var token = jwt.sign(payload, process.env.REACT_APP_secret);

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    console.log(pageData);

    axios
      .post(
        config.get("backendURL") +
          "homelayout/update/" +
          this.props.params.passid,
        pageData,
        requestOptions
      )
      .then(function (data) {
        console.log(data);
        if (data.statusText == "OK") {
        }
      });
  };

  render() {
    //console.log(this.state.vimage)

    return (
      <>
        <Editor
          initialValue={this.state.itemdata}
          init={{
            height: 500,
            menubar: true,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],

            content_css:
              sessionStorage.getItem("theme") == "Apparel-casi"
                ? "../templates/apparel-casi/css/core_styles.css,https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
                : "",

            toolbar:
              "undo redo | formatselect | fontsizeselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
          }}
          inline={true}
          apiKey='ssv2iarxt5sjvufjszf9lrat3wu395eynq4hpttb0gdyzbnh'
          onEditorChange={this.handleEditorChange}
        />
      </>
    );
  }
}

export default App;

// import React, { useRef, useState } from "react";
// import { Editor } from "@tinymce/tinymce-react";

// export default function App() {
//   const editorRef = useRef(null);
//   const log = () => {
//     if (editorRef.current) {
//       console.log(editorRef.current.getContent());
//     }
//   };

//   const [toggle, setToggle] = useState(true);
//   const [text, setText] = useState(
//     "Some quick example text to build on the card title and make up the bulk of the card's content."
//   );

//   function toggleInput() {
//     setToggle(false);
//   }

//   function handleChange(event) {
//     setText(event.target.value);
//   }

//   function mouseLeaveAdd() {
//     setToggle(false);
//   }
//   return (
//     <>
//       <Editor
//         onInit={(evt, editor) => (editorRef.current = editor)}
//         initialValue='<p>This is the initial content of the editor.</p>'
//         init={{
//           height: 300,

//           menubar: false,
//           plugins: [
//             "advlist autolink lists link image charmap print preview anchor",
//             "searchreplace visualblocks code fullscreen",
//             "insertdatetime media table paste code help wordcount",
//           ],
//           toolbar:
//             "undo redo | formatselect | " +
//             "bold italic backcolor | alignleft aligncenter " +
//             "alignright alignjustify | bullist numlist outdent indent | " +
//             "removeformat | help",
//           content_style:
//             "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
//         }}
//       />
//       <button onClick={log}>Log editor content</button>
//     </>
//   );
// }
