import React from 'react';
import { Col, ColorPicker, InputBox, Label, Row, Select, Stack,Icon, Button,Typography } from 'witmeg-ui-system'

const ElementStylesForm = () => {
  return (
    <div style={{margin:"32px 22px"}}>
        <Typography type="title" level={5} className="eco-mb-32" pattern="bold">Customize Tags</Typography>
        <Row className="form-row eco-mb-16" align="middle">
            <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Choose element to edit formatting</Label></div>
            </Col>
            <Col span={16}>
                <Select noBorder/>
            </Col>
        </Row>
        <Row className="form-row eco-mb-16" align="middle">
            <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Font</Label></div>
            </Col>
            <Col span={16}>
                <Select noBorder/>
            </Col>
        </Row>
        <Row className="form-row eco-mb-16" align="middle">
            <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Font Size</Label></div>
            </Col>
            <Col span={16}>
                <Select noBorder/>
            </Col>
        </Row>
        <Row className="form-row eco-mb-16" align="middle">
            <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Font Weight</Label></div>
            </Col>
            <Col span={16}>
                <Select noBorder/>
            </Col>
        </Row>
        <Row className="form-row" align="middle">
            <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Font color</Label></div>
            </Col>
            <Col span={8}>
                <InputBox type="text" noBorder backgroundOnly/>
            </Col>
            <Col span={8}>
                <ColorPicker/>
            </Col>
        </Row>
        <Row className="form-row eco-mb-16" align="middle">
            <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Font Format</Label></div>
            </Col>
            <Col span={16}>
                <Select noBorder/>
            </Col>
        </Row>
        <Row className="form-row" align="middle">
            <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Font Alignment</Label></div>
            </Col>
            <Col span={16}>
                <Stack>
                    <Button category="icon" children="align-left" variant="primary" className="eco-mr-20 text-direction-btn" size="small" iconSize={20}/>
                    <Button category="icon" children="align-center" variant="primary" className="eco-mr-20 text-direction-btn" size="small" iconSize={20}/>
                    <Button category="icon" children="align-right" variant="primary" className="eco-mr-20 text-direction-btn" size="small" iconSize={20}/>
                    <Button category="icon" children="align-justify" variant="primary" className="eco-mr-20 text-direction-btn" size="small" iconSize={20}/>
                </Stack>
            </Col>
        </Row> 
    </div>
  )
}

export default ElementStylesForm