import _ from 'lodash';

export const setComponentSize = (layOutType) => {
  switch (layOutType) {
    case "carousel":
      return {
        width: 6,
        height: 10,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "image":
      return {
        width: 2,
        height: 4,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "video":
      return {
        width: 5,
        height: 10,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "icon":
      return {
        width: 2,
        height: 6,
        minW: 2,
        maxW: 2,
        minH: 2,
        maxH: 6,
      };
    case "editor":
      return {
        width: 6,
        height: 6,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "gallery":
      return {
        width: 8,
        height: 8,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "contact form":
      return {
        width: 6,
        height: 18,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "blog":
      return {
        width: 6,
        height: 6,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };

    case "navigation links":
      return {
        width: 12,
        height: 3,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "best selling":
      return {
        width: 6,
        height: 6,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };



    case "featured":
      return {
        width: 6,
        height: 6,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "featured offers":
      return {
        width: 6,
        height: 6,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "offers":
      return {
        width: 6,
        height: 6,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "Price Plan":
      return {
        width: 6,
        height: 32,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
    case "resturant menu":
      return { width: 24, height: 32 };

    case "accordion":
      return { width: 6, height: 3 };
    case "button":
      return { width: 5, height: 4 };
    case "maps":
      return { width: 6, height: 12 };
    case "card":
      return { width: 6, height: 12 };
    default:
      return {
        width: 6,
        height: 6,
        minW: 8,
        maxW: 6,
        minH: 6,
        maxH: 6,
      };
  }
};

export const imageUpload = () => { };

export const setGridBackground = (themeSettings) => {
  const { GlobalSettings = {} } = themeSettings;
  const {
    BackgroundColour = "",
    BackgroundImage = "",
    Padding = JSON.stringify({
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    }),
  } = GlobalSettings;
  const allPaddings = JSON.parse(Padding);
  if (BackgroundColour)
    return {
      backgroundColor: BackgroundColour,
      paddingTop: allPaddings.top,
      paddingRight: allPaddings.right,
      paddingBottom: allPaddings.bottom,
      paddingLeft: allPaddings.left,
    };
  return {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    "-mozBackgroundSize": "cover",
    "-webkitBackgroundSize": "cover",
    backgroundImage: `url(${BackgroundImage?.URL})`,
    paddingTop: allPaddings.top,
    paddingRight: allPaddings.right,
    paddingBottom: allPaddings.bottom,
    paddingLeft: allPaddings.left,
  };
};

//Resturant Menu Widget Space Validations
var regexSpaceValidation = new RegExp(/^-?\d+(?:px|em|rem|vw|vh|pt|(%))?$/);

export const spaceValidation = (text) => {
  return regexSpaceValidation.test(text);
}

export const DividerStyleCategory = {
  DIVIDER_1: 'divider1',
  DIVIDER_2: 'divider2',
  DIVIDER_3: 'divider3',
  DIVIDER_4: 'divider4',
};

export const getDividerStyles = (divider) => {
  return divider && divider.enabled
    ? {
      borderBottomStyle:
        divider.category &&
          (divider.category === DividerStyleCategory.DIVIDER_3 || divider.category === DividerStyleCategory.DIVIDER_4)
          ? 'dashed'
          : 'solid',
      borderBottomColor: divider.color ?? '#000000',
      borderBottomWidth: divider.style
        ? divider.category === DividerStyleCategory.DIVIDER_1
          ? `${divider.style / 2}px` : `${divider.style}px`
        : '1px',
    }
    : { borderBottom: 'none' };
};

export const getCloudLocations = async () => {
  try {
    return (await JSON.parse(localStorage.getItem('CloudLocations'))) ?? [];
  } catch (error) {
    console.log('Cannot retrieve Cloud Locations; Error:', error);
  }
};

export const getOrganizationID = async () =>{
  try {
    return (await sessionStorage.getItem('ORGID')) ?? null;
  } catch (error) {
    console.log('Cannot retrieve Organization ID; Error:', error);
  }
}

export const RESTAURANT_SETTINGS_TYPES = {
  QR_CODE: 'QRCode',
  MAIN_ORDER_TYPES: {
    KEY: 'MainOrderTypes',
    OPT: {
      DINE_IN: {
        name: 'table',
        icon: 'dine-in',
        title: 'Dine-In',
        qrCodeName: 'TableQRCodes',
      },
      COLLECTION: {
        name: 'collection',
        icon: 'collection',
        title: 'Collection',
        qrCodeName: 'TakeawayQRCode',
      },
      DELIVERY: {
        name: 'delivery',
        icon: 'delivery-bike',
        title: 'Delivery',
        qrCodeName: 'DeliveryQRCode',
      },
      CUSTOMER_CHOICE: {
        title: 'Let customer choose',
        name: 'customer-choice',
      },
    },
  },
  CARD_TYPES: 'CardTypes',
  PAYMENT_METHODS: {
    KEY: 'PaymentMethods',
    OPT: {
      CASH: {
        name: 'cash',
        title: 'Cash',
      },
      CARD: {
        name: 'card',
        title: 'Card',
      },
    },
  },
  PAYMENT_METHODS_ORDER_TYPES: 'PaymentMethods_OrderTypes',
  PAYMENT_GATEWAY: 'PaymentGateway',
  EXTERNAL_API: 'ExternalAPI',
  AVAILABLE_OPTION: {
    KEY: 'AvailableOption',
    OPT: {
      CHECKOUT: {
        name: 'Checkout',
        title: 'Checkout',
        icon: 'cash',
      },
      MENU_ONLY: {
        name: 'MenuOnly',
        title: 'Menu Only',
        icon: 'popup',
      },
    },
  },
  AVAILABLE_LOCATIONS: 'AvailableLocations',
};

export const RESTAURANT_DATA_CONFIG = {
  ORDER_TYPES: {
    DINE_IN: {
      name: 'table',
      priceName: 'TablePrice',
    },
    DELIVERY: {
      name: 'delivery',
      priceName: 'DeliveryPrice',
    },
    COLLECTION: {
      name: 'collection',
      priceName: 'TakeAwayPrice',
    },
  },
};

export const getRestaurantMenuProductPrice = (_productData, _orderType, _provider = 'Restaurant') => {
  if(!_productData || !_orderType ) return 0;

  const { DINE_IN, DELIVERY, COLLECTION } = RESTAURANT_DATA_CONFIG.ORDER_TYPES;

  let _isUnitChartItem = false;
  if(_productData.UnitChartInfo && _productData.UnitChartInfo.length > 0) _isUnitChartItem = true;

  if(_isUnitChartItem) {
    let __unitChartItemPrices = [];

    for(let __unitChartItem of _productData.UnitChartInfo) {
      const ___itemPrices = _.find(__unitChartItem.ServicePriceInfo, { ServiceProvider: _provider });

      if (_orderType === DINE_IN.name) __unitChartItemPrices.push(___itemPrices[DINE_IN.priceName] ?? 0);
      if (_orderType === DELIVERY.name) __unitChartItemPrices.push(___itemPrices[DELIVERY.priceName] ?? 0);
      if (_orderType === COLLECTION.name) __unitChartItemPrices.push(___itemPrices[COLLECTION.priceName] ?? 0);
    }

    return Math.min(...__unitChartItemPrices);

  } else {
    if(!_productData.ServicePriceInfo.Price || _productData.ServicePriceInfo.Price.length === 0) return 0;

    const __itemPrice = _.find(_productData.ServicePriceInfo.Price, { ServiceProvider: _provider });

    if (_orderType === DINE_IN.name) return __itemPrice[DINE_IN.priceName] ?? 0;
    if (_orderType === DELIVERY.name) return __itemPrice[DELIVERY.priceName] ?? 0;
    if (_orderType === COLLECTION.name) return __itemPrice[COLLECTION.priceName] ?? 0;
  }

  return 0;
}

export const getCurrencyFormat = (_type = 'GBP') => (_value, _withSpace = false) => {
  const _formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: _type.toUpperCase() });
  const _newValue = _formatter.format(_value);

  if (_withSpace) return _formatter.format(_value).replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');

  return _newValue;
};
