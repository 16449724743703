import React, { useState, useEffect, useReducer } from "react";
import { Stack, Typography, Icon, Card, Message, Row, Col } from "witmeg-ui-system";
import GlobalImageUploader from "../GlobalImageUploader/GlobalImageUploader";
import { getHomeLayoutByID, updateHomeLayout } from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { useDispatch } from "react-redux";
import { setCommonState } from "../../redux/commonReducer";
import { hot } from "react-hot-loader/root";
// import GlobalImageWithContent from "../GlobalImageWithContent/GlobalImageWithContent";

const ImageWidget = ({ widgetID, checkData, PreviewButton, pageID }) => {
  const [ImageUpload, setImageUpload] = useState(false);

  const [imageInformationValues, setImageInformationValues] = useState();
  const [data, setData] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();
  const [imageError, setImageError] = useState(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleShowPopup = () => {
    if (PreviewButton == false) {
      dispatch(setCommonState({ draggableState: true }));
      setImageUpload(true);
    }
  };

  const handelLogoImage = (data) => {
    setImageInformationValues(data);
  };

  useEffect(async () => await fetchData(), []);

  useEffect(() => {
    if (checkData) setData(checkData);
  }, [checkData]);

  const fetchData = async () => {
    let deviceState = sessionStorage.getItem("deviceState");
    if (pageID) {
      const _response = await getHomeLayoutByID({
        ID: widgetID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
        IMAGE: "LINE 86"
      });

      if(!_response[0]?.RenderSource) return;

      if((deviceState === 'desktop' && _response[0]?.RenderSource === "Desktop") || (deviceState === 'mobile' && _response[0]?.RenderSource === "Mobile")){
        setData(_response[0]);
      }

    } else {
      const _response = await getHomeLayoutByID({
        ID: widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
        IMAGE: "LINE 111"
      });

      if(!_response[0]?.RenderSource) return;

      if ((deviceState === "desktop" && _response[0]?.RenderSource === "Desktop") || (deviceState === "mobile" && _response[0]?.RenderSource === "Mobile")) {
        setData(_response[0]);
      }
    }
  };

  const onClickConfirm = async (_imageData, _crop = false) => {
    await fetchData();

    if((!_crop && (!_imageData || _imageData === "")) || (_crop && (!imageInformationValues || imageInformationValues === ""))) {
      setImageError(true);
      return;
    }

    const _newImageData = _imageData ?? imageInformationValues;
    if(_imageData && _imageData !== imageInformationValues) setImageInformationValues(_imageData);

    if (pageID) {
      const propsPageID = await pageID;

      setConfirmLoading(true);

      await updateHomeLayout({
        ID: widgetID,
        ItemHeading: "image",
        Item: "image",
        Width: data?.Width,
        Height: data?.Height,
        X: data?.X,
        Y: data?.Y,
        Type: "image",
        ItemData: _newImageData,
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: propsPageID,
        RenderSource: "Desktop",
        CompanyID: sessionStorage.getItem("company"),
      });
      Message.success("Widget edited successfully");
      dispatch(setCommonState({ draggableState: false }));

      await fetchData();

      setImageUpload(false);
      setImageError(false);
      setConfirmLoading(false);
    } else {
      const _reqData = {
        ID: widgetID,
        ItemHeading: 'image',
        Item: 'image',
        Width: data?.Width,
        Height: data?.Height,
        X: data?.X,
        Y: data?.Y,
        Type: 'image',
        ItemData: _newImageData,
        RatioW: '',
        RatioH: '',
        Link: '',
        RenderSource: 'Desktop',
        PageID: '',
        CompanyID: sessionStorage.getItem('company'),
      };
      setConfirmLoading(true);
      await updateHomeLayout({ ..._reqData, RenderSource: 'Desktop' });
      await updateHomeLayout({ ..._reqData, RenderSource: 'Mobile' });

      Message.success("Widget edited successfully");
      dispatch(setCommonState({ draggableState: false }));

      await fetchData();

      setImageUpload(false);
      setImageError(false);
      setConfirmLoading(false);

    }
  }

  return (
    <>
      <Row style={{ height: '100%' }}>
        <Col span={24}>
          {!data?.ItemData && (
            <Card
              rounded="small"
              shadow="no_shadow"
              content={
                <div
                  onDoubleClick={handleShowPopup}
                  className="image-widget-component eco-mt-40"
                >
                  <Stack
                    direction="column"
                    horizontalAlign="space_between"
                    verticalAlign="v_center"
                  >
                    <Icon name="upload-outline" size={32} type="filled" />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <Typography
                      level={4}
                      type="title"
                      alignment="center"
                      color="gray_base"
                    >
                      Double Click To Add Image
                    </Typography>
                  </Stack>
                </div>
              }
            />
          )}

          {data?.ItemData && (
            <>
              <Row onDoubleClick={handleShowPopup} style={{ height: '100%' }}>
                <Col span={24} style={{ position: "relative" }}>


                  <img
                    alt=""
                    src={data?.ItemData}
                    draggable="false"
                    style={{ width: "100%", height: "100%" }}
                  />


                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>

      <GlobalImageUploader
        ImageUpload={ImageUpload}
        setImageUpload={setImageUpload}
        handelLogoImage={handelLogoImage}
        onClickConfirm={onClickConfirm}
        confirmLoading={confirmLoading}
        imageError={imageError}
        setImageError={setImageError} />
        {/* <GlobalImageWithContent 
          ImageUpload={ImageUpload}
          setImageUpload={setImageUpload}
          handelLogoImage={handelLogoImage}
          onClickConfirm={onClickConfirm}
          confirmLoading={confirmLoading}
          imageError={imageError}
          setImageError={setImageError}
        /> */}
    </>
  );
}

export default hot(ImageWidget);
