import React, { useState } from "react";
import {
  Col,
  Label,
  Row,
  Stack,
  Typography,
  Card,
  TextArea,
  InputBox,
  ProgressBar,
} from "witmeg-ui-system";

const SEOInfoForm = ({ setField, errors, setErrors, form }) => {
  const [pageURL, setPageURL] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");

  const onChangeURL = (e) => {
    setPageURL(e.target.value);
    setField("PageURL", e.target.value);
  };

  const onChangePageTitle = (e) => {
    setPageTitle(e.target.value);
    setField("SEOPageTitle", e.target.value);
  };

  const onChangePageDescription = (e) => {
    setPageDescription(e.target.value);
    setField("MetaDescription", e.target.value);
  };

  return (
    <div>
          <div style={{ padding: "20px",borderBottom: '1px solid #C6C7D2' }}>
            <Row className='form-row'>
              <Typography type='title' level={5} pattern="bold" >
                SEO Settings
              </Typography>
            </Row>
            <Row>
              <Col span={24}>
                <div className='form-lbl-wrapper'>
                  <Label>Preview</Label>
                </div>
              </Col>
              <Col span={24}>
                <Stack
                  direction='column'
                  horizontalAlign='h_start'
                  verticalAlign='v_top'
                  className='preview-card'
                >
                  <Typography type='text' alignment='left'>
                    {pageURL}
                  </Typography>
                  <Typography
                    type='title'
                    level={3}
                    alignment='left'
                    color='blue_base'
                  >
                    {pageTitle}
                  </Typography>
                  <Typography type='text' alignment='left' color='gray_base'>
                    {pageDescription}
                  </Typography>
                </Stack>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='form-lbl-wrapper'>
                  <Label>Page Title</Label>
                </div>
                <Typography type='text'>
                  This is the short snippet of text which will appears on the
                  browser tab also the main text that shows in search engine
                  results
                </Typography>
                <Typography type='text' alignment='right'>
                  {100 - pageTitle?.length} characters
                </Typography>
              </Col>
              <Col span={24}>
                <TextArea maxLength='100' onChange={onChangePageTitle} className="description-box"/>
              </Col>
              <Col span={24}>
                <ProgressBar
                  value={pageTitle?.length}
                  size='small'
                  variant={pageTitle?.length <= 40 ? "danger" : "success"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className='form-lbl-wrapper'>
                  <Label>Meta Description</Label>
                </div>
                <Typography type='text'>
                  This is a short snippet which aims to convince the user that
                  the page contains the information they're looking for
                </Typography>
                <Typography type='text' alignment='right'>
                  {240 - pageDescription?.length} characters
                </Typography>
              </Col>
              <Col span={24}>
                <TextArea maxLength='240' onChange={onChangePageDescription} className="description-box"/>
              </Col>
              <Col span={24}>
                <ProgressBar
                  size='small'
                  value={pageDescription?.length}
                  variant={pageDescription?.length <= 50 ? "danger" : "success"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <div className='form-lbl-wrapper'>
                  <Label>Page URL</Label>
                </div>
              </Col>
              <Col span={16}>
                <InputBox type='text' onChange={onChangeURL} noBorder backgroundOnly />
              </Col>
            </Row>
          </div>
          <div style={{ padding: "20px"}}>
          <Row className='form-row'>
              <Typography type='title' level={5} pattern="bold">
                Header HTML
              </Typography>
            </Row>
            <Row>
              <Col span={24}>
                <div className='form-lbl-wrapper'>
                  <Label>
                    Small description about what is this header HTML does
                  </Label>
                </div>
              </Col>
              <Col span={24}>
                <TextArea
                  className="description-box"
                  onChange={(e) => setField("HeaderHTML", e.target.value)}
                />
              </Col>
            </Row>
          </div>  
          </div>
  );
};

export default SEOInfoForm;
