
import { APIError, errorLogger } from "../errorHandlers";
import apiEndpointPaths from "../apiEndpointPaths";
import axios from "axios";
import { generateTokenForMenuFromNeutriPost, generateTokenForNeutriPOS } from "../tokenGenerators";

export const getAllRestaurantMenuData = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;

    const apiReqBody = { ...fullReqBodyData };
    const token = await generateTokenForMenuFromNeutriPost(true);

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const response = await axios.post(
      `${apiEndpointPaths.neutriPostServer.getAllMenuData}`,
      apiReqBody,
      options
    );

    const { Status, Result, Currency, MenuCreationDate } = response.data;

    return Status ? { data: Result, currency: Currency, menuCreatedDate: MenuCreationDate } : null;

  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}

/*
  Get Restaurant Menu created Date
*/
export const getRestaurantMenuCreatedDate = async (reqBody = {}, options = {}) => {
  try {
    const { ...fullReqBodyData } = reqBody;

    const apiReqBody = { ...fullReqBodyData };
    const token = await generateTokenForMenuFromNeutriPost(true);

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const response = await axios.post(
      `${apiEndpointPaths.neutriPostServer.getRestaurantMenuCreatedDate}`,
      apiReqBody,
      options
    );

    const { Status, Currency, MenuCreationDate } = response.data;

    return Status ? { currency: Currency, menuCreatedDate: MenuCreationDate } : null;

  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}

/*
  Get Restaurant `Main Menu` IDs
*/
export async function getRestaurantMainMenuIDs(reqBody = {}, options = {}) {

  try {
    const token = await generateTokenForMenuFromNeutriPost();

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const response = await axios.post(
      `${apiEndpointPaths.neutriPostServer.getRestaurantMainMenuIDs}`,
      reqBody,
      options,
    );

    const { Status, Result, Currency } = response.data;

    return Status ? { data: Result, currency: Currency } : null;

  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}

/*
  Get Restaurant `Main Menu` data by IDs
*/
export async function getRestaurantMainMenuDataByIDs(reqBody = {}, options = {}) {

  try {
    const { ...fullReqBodyData } = reqBody;

    const apiReqBody = { ...fullReqBodyData };
    const token = await generateTokenForMenuFromNeutriPost();

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const response = await axios.post(
      `${apiEndpointPaths.neutriPostServer.getRestaurantMainMenuDataByIDs}`,
      apiReqBody,
      options
    );

    const { Status, Result, Currency } = response.data;

    return Status ? { data: Result, currency: Currency } : null;
  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}

//Get All Sub  Menu Data 

export async function GetAllSubMenuData(reqBody = {}, options = {}) {

  try {
    const { ...fullReqBodyData } = reqBody;

    const apiReqBody = { ...fullReqBodyData };
    const token = await generateTokenForMenuFromNeutriPost(true);

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(
      `${apiEndpointPaths.neutriPostServer.getAllMenuData}`,
      apiReqBody,
      options
    );

    const { Status, Result } = resObj.data;

    return Result;
  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}

/* 
  Get `Restaurant Menu Settings` data
*/
export async function getRestaurantMenuSettingsData(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPOS();

    const apiReqUrl = apiEndpointPaths.neutriPostServer.getRestaurantMenuSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Add `Restaurant Menu Settings` data
*/
export async function addRestaurantMenuSettingsData(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPOS();

    const apiReqUrl = apiEndpointPaths.neutriPostServer.addRestaurantMenuSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

/* 
  Update `Restaurant Menu Settings` data
*/
export async function updateRestaurantMenuSettingsData(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPOS();

    const apiReqUrl = apiEndpointPaths.neutriPostServer.updateRestaurantMenuSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function createRestaurantQRCodeSettings(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPOS();

    const apiReqUrl = apiEndpointPaths.neutriPostServer.setRestaurantQRCodeSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function getRestaurantQRCodeSettings(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPOS();

    const apiReqUrl = apiEndpointPaths.neutriPostServer.getRestaurantQRCodeSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };
    console.log('QR cod fetch', apiReqUrl, apiReqBody)
    const response = await axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}

export async function updateRestaurantQRCodeSettings(reqBody = {}, options = {}) {
  try {
    const { ...fullReqBodyData } = reqBody;
    const apiReqBody = { ...fullReqBodyData };

    let token = await generateTokenForNeutriPOS();

    const apiReqUrl = apiEndpointPaths.neutriPostServer.updateRestaurantQRCodeSettings;
    let headers = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    };

    const response = await axios({
      method: 'POST',
      url: apiReqUrl,
      data: apiReqBody,
      headers,
    });

    const { Status, Result } = response.data;

    return Status ? Result : null;
  } catch (error) {
    let customErrMsg = error.customErrMsg || '';
    if (error.custmErrType === 'APIERROR') {
      switch (true) {
        default: {
          customErrMsg = 'Error Occurred. Please try again.';
          break;
        }
      }
    }
    errorLogger(error, { customErrMsg });
    return Promise.reject(APIError(customErrMsg, error));
  }
}
