import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Space,
  Stack,
  StackItem,
  Typography,
  Message,
  async,
} from "witmeg-ui-system";
import {
  PlayCircleFilled,
  DownOutlined,
  UserOutlined,
  EditOutlined,
} from "@ant-design/icons";
import "./Home.css";
import { Tooltip } from "antd";

import { Dropdown, Menu } from "antd";
import { routePaths } from "../../routes/routeConstants";
import { logoutUser } from "../utilities/userAuthentication";
import { publishHomelayout, sitePublishUnPublish } from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { useSelector, useDispatch } from 'react-redux';
import fullScreenImage from "../../images/fullScreen.png";
import { setCommonState } from "../../redux/commonReducer";


function GlobalHeaderPanel(props) {
  const [published, setPublished] = useState(false);

  const [publishing, setPublishing] = useState(false);

  const dispatch = useDispatch()

  console.log("props", props)

  useEffect(() => {
    if (props.companyData && props.companyData.IsPublished) {
      setPublished(props.companyData.IsPublished);

    }
  }, [props.companyData]);



  // console.log(props.companyData?.IsPublished);
  const menu = (
    <Menu
      items={[
        {
          label: "1440PX / 100%",
          key: "1",
          icon: <UserOutlined />,
        },
        {
          label: "2nd menu item",
          key: "2",
          icon: <UserOutlined />,
        },
        {
          label: "3rd menu item",
          key: "3",
          icon: <UserOutlined />,
        },
      ]}
    />
  );

  async function handlePublished(data) {
    const sitePublish = await publishHomelayout({
      CompanyID: sessionStorage.getItem("company"),
      // IsPublished: data,
    }).then(async (response) => {
      // Message.success("The Site was published");

      if (props.companyData.IsPublished == false) {
        await sitePublishUnPublish({
          ID: sessionStorage.getItem("company"),
          IsPublished: true,
        }).then((response) => {
          Message.success("The site was published");
          dispatch(setCommonState({
            publishState: true
          }))
          setPublished(true);
        });
      }

      // setPublished(true);
    });


  }

  async function handleRepublished() {
    setPublishing(true)
    const sitePublish = await publishHomelayout({
      CompanyID: sessionStorage.getItem("company"),
      // IsPublished: data,
    }).then(async (response) => {
      setPublishing(false)
      dispatch(setCommonState({
        publishState: true
      }))
      Message.success("The site was re-published");



      // setPublished(true);
    });


  }



  async function handleUnPublished(data) {
    const sitePublish = await publishHomelayout({
      ID: sessionStorage.getItem("company"),

    }).then(async (response) => {
      if (props.companyData.IsPublished == false) {
        await sitePublishUnPublish({
          ID: sessionStorage.getItem("company"),
          IsPublished: false,
        }).then((response) => {
          Message.success("The site was un published");

        });
      }


    });
  }
  return (
    <div>
      <header className='eco-header eco-header--main'>
        <div style={{ padding: "0 20px" }}>
          <Stack horizontalAlign='h_start'>
            <StackItem style={{ padding: "8px", borderRight: "1px solid #D8D8D8", height: '65px', display: 'flex', alignItems: 'center', flexGrow: '1' }} >
              <img style={{ width: "40px", height: "40px", marginRight: '10px', borderRadius: '5px' }} src={props.companyData?.Logo} draggable='false' />
              <Stack direction="column" verticalAlign="v_start">
                <Typography type="text" pattern="bold"> {props.companyData?.Name}</Typography>
                <Typography type="text" color="gray_base"> {props.companyData?.CustomerDomain}</Typography>
              </Stack>
            </StackItem>
            <StackItem style={{ padding: "8px 32px", height: '65px', display: 'flex', alignItems: 'center', flexGrow: '5' }}>
              <div style={{ width: '100%' }}>
                <Stack horizontalAlign="space_between" >
                  <div>
                    {props.showPreviewButton === false && (
                      <Button onClick={props.handlePreview} variant='primary' > Preview <PlayCircleFilled style={{ paddingLeft: "14px", paddingBottom: "4px" }} /></Button>
                    )}
                  </div>
                  <div>
                    {props.showPreviewButton === true && (
                      <Button
                        onClick={props.handleEditor}
                        variant='primary'
                      >
                        Back To Editor
                        <EditOutlined
                          style={{ paddingLeft: "14px", paddingBottom: "4px" }}
                        />
                      </Button>
                    )}
                  </div>
                  <div>
                    <Space size="middle">
                      <div onClick={props.showDesktopView}><Icon className={props.setDesktopView ? "colored-view" : ""} name='desktop' /></div>
                      <div onClick={props.showMobileView}><Icon className={props.setMobileView ? "colored-view" : ""} name='mobile' /></div>
                      <div onClick={props.showTabView}><Icon className={props.setTabView ? "colored-view-tab" : "tablet-rotate"} name='tablet' /></div>
                    </Space>
                  </div>
                  <div>
                    <Space size="large">
                      <Dropdown overlay={menu}>
                        <Button className='size__dropdown'>
                          1440PX / 100%
                          <DownOutlined className='eco-ml-24' />
                        </Button>
                      </Dropdown>
                      <div>
                        <img src={fullScreenImage} />
                      </div>
                    </Space>
                  </div>

                  <Stack direction='row' horizontalAlign='space_between' width={100} >
                    <StackItem style={{ width: "36px", height: "36px", backgroundColor: "#EAEAF1", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Icon name='undo' type='filled' size={16} />
                    </StackItem>
                    <StackItem style={{ width: "36px", height: "36px", backgroundColor: "#EAEAF1", borderRadius: "6px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Icon name='redo' type='filled' size={16} />
                    </StackItem>
                  </Stack>
                  {/* Publish and Un Published Buttons */}

                  <Stack>
                    <div onClick={() => { logoutUser({ redirectTo: routePaths.loginRoot }); }} style={{ cursor: "pointer" }}></div>
                  </Stack>

                </Stack>
              </div>
            </StackItem>
            <StackItem style={{ padding: "8px", height: '65px', display: 'flex', alignItems: 'center', flexGrow: '1', justifyContent: 'end' }}>
              <Stack>
                {published === true ? (
                  <Button
                    className={publishing && "publish-btn"}
                    category={publishing ? "load-text" : "text"}
                    text="Re Publishing"
                    variant='primary'

                    onClick={() => handleRepublished()}
                  >
                    Re Publish
                  </Button>
                ) : (
                  props.companyData?.IsPublished === false &&
                  <Button
                    // className='preview__btn__header'
                    className={publishing && "publish-btn"}
                    category={publishing ? "load-text" : "text"}
                    text="Publishing"
                    variant='primary'
                    onClick={() => handlePublished()}
                  >
                    Publish
                  </Button>
                )}
              </Stack>

            </StackItem>
          </Stack>
        </div>
      </header>
    </div>
  );
}

export default React.memo(GlobalHeaderPanel);
