import _ from 'lodash';
import { addRestaurantMenuSettingsData, getRestaurantMenuSettingsData, updateRestaurantMenuSettingsData } from './apiRequests/neutriPosServerRequests';

export const fetchRestaurantMenuSettings = async ({ type, cloudLocationsData }) => {
  const promises = cloudLocationsData.map(
    ({ CloudLocationID }) =>
      new Promise(async function (resolve, refuse) {
        resolve(
          (await getRestaurantMenuSettingsData({
            SettingType: type,
            CloudLocationID,
          })) ?? null
        );
      })
  );

  return await Promise.all(promises);
};

export const addRestaurantMenuSettings = async ({ type, data, cloudLocationsData, OrganizationID }) => {
  const promises = cloudLocationsData.map(
    ({ CloudLocationID }) =>
      new Promise(async function (resolve, refuse) {
        resolve(
          (await addRestaurantMenuSettingsData({
            SettingType: type,
            OrganizationID,
            CloudLocationID,
            SettingData: {
              ...data,
              SettingType: type,
              OrganizationID,
              CloudLocationID,
            },
          })) ?? null
        );
      })
  );

  return await Promise.all(promises);
};

export const updateRestaurantMenuSettings = async ({ type, data, cloudLocationsData }) => {
  let promises = [];

  for(const location of cloudLocationsData){
    const settings = _.find(data.list, {CloudLocationID:location.CloudLocationID});

    promises.push(
      new Promise(async function (resolve, refuse) {
        resolve(
          (await updateRestaurantMenuSettingsData({
            SettingType: type,
            CloudLocationID: location.CloudLocationID,
            SettingData: { ...settings, ...data.update },
          })) ?? null
        );
      })
    );
  }

  return await Promise.all(promises);
};