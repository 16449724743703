import PropTypes from "prop-types";
import React, { memo, useEffect, useState } from "react";
import {
  InputBox,
  Label,
  Row,
  Col,
  Switch,
  Typography,
  Card,
  TextArea,
  FileUploader,
  Stack,
  StackItem,
  ProgressBar,
  Button,
  Icon,
  Popup,
  Space,
  Spin,
  Tabs,
  Message,
} from "witmeg-ui-system";
import {
  createPages,
  getPageByMenuID,
  updatePageByID,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import BasicDetails from "./forms/BasicDetails";
import SeoDetails from "./forms/SeoDetails";
import globalValues from "../../configs/globalValues";
import { generateImageUploadServerToken } from "../utilities/tokenGenerators";
import Resizer from "react-image-file-resizer";
import * as htmlToImage from "html-to-image";
import Axios from "axios";

const PagePopup = memo((props) => {
  const {
    selectedPageID,
    showPagePopup,
    setShowPagePopup,
    formData,
    fetchAllPages,
  } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleForm = () => {
    selectedPageID ? updatePage() : createPage();
  };

  const [errors, setErrors] = useState({
    pageTitle: false,
  });
  const [form, setForm] = useState();

  const [selectdImage, setSelectdImage] = useState(null);
  const [showImageCropPopuop, setShowImageCropPopuo] = useState(false);
  const [processedImage, setProcessedImage] = useState(null);
  const [imageQuality, setImageQuality] = useState(1);
  const [progress, setProgress] = useState(0);
  const [uploadedImageUrls, setUploadedImageUrls] = useState();
  const [uploadedImage, setUploadedImage] = useState();

  const handleProcessImage = async (file) => {
    setSelectdImage(file);
    setShowImageCropPopuo(true);
    let uploadingImage = file.selectedFile;
    const image = await resizeImage(uploadingImage, 500, 500, "JPEG");

    setProcessedImage(image);
  };

  const resizeImage = async (uploadingImage, width, height, fileFormat) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        uploadingImage,
        width,
        height,
        fileFormat,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  };

  const handleGenerareImage = async () => {
    const element = document.getElementById("capture");
    htmlToImage
      .toBlob(element, {
        quality: imageQuality,
        backgroundColor: "#fff",
        width: 500,
        height: 500,
      })
      .then(async function (blob) {
        await uploadImage(blob);
        setShowImageCropPopuo(false);
      })
      .catch(function (error) {
        console.error("OOOPS something went wrong !", error);
      });
  };

  const uploadImage = async (file) => {
    const fmData = new FormData();
    const timeStamp = Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";
    const fileName = `P_${timeStamp}`;
    const filePath = `/${environmentFolderName}/cms/page/`;

    fmData.append("file", file);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const token = await generateImageUploadServerToken();

      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await Axios.post(
        // "http://35.228.251.106:14501/v1/image/uploadImage",
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
        fmData,
        config
      );
      console.log("server res: ", res);
      setUploadedImageUrls([res.data.Result]);
    } catch (err) {
      console.log("Some error");
    }
  };

  const handleDeleteImage = () => {
    setUploadedImageUrls();
  };

  const setField = (field, value) => {
    let currentForm = JSON.parse(JSON.stringify(form));

    setForm(Object.assign(currentForm, { [field]: value }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  useEffect(() => {
    setForm(formData);
    if (formData?.DraftMenuIconURL)
      setUploadedImageUrls([formData?.DraftMenuIconURL]);
    return () => {};
  }, []);

  useEffect(() => {
    handleChangeImage(uploadedImageUrls, "MenuImage");
  }, [uploadedImageUrls]);

  //Image Options
  const handleChangeImage = (data) => {
    if (data) {
      setUploadedImage(data[0]);
    }
  };

  const prePareData = () => {
    const slug = form?.DraftPageName.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

    return {
      ID: form.ID,
      CompanyID: sessionStorage.getItem("company"),
      PageType: "",
      PageName: form.DraftPageName,
      Slug: slug,
      NoLink: form.DraftNoLink ? form.DraftNoLink : false,
      IsLockedForClientEditing: form.DraftIsLockedForClientEditing
        ? form.DraftIsLockedForClientEditing
        : false,
      ShowInMainMenu: form.DraftShowInMainMenu
        ? form.DraftShowInMainMenu
        : false,
      Status: true,
      Visibility: true,
      MenuDescription: form.DraftMenuDescription
        ? form.DraftMenuDescription
        : "",
      MenuIconName: "",
      MenuIconURL: uploadedImage ? uploadedImage : "",
      HideOnAll: form.DraftHideOnAll ? form.DraftHideOnAll : false,
      HideOnDesktop: form.DraftHideOnDesktop ? form.DraftHideOnDesktop : false,
      HideOnTab: form.DraftHideOnTab ? form.DraftHideOnTab : false,
      HideOnMobile: form.DraftHideOnMobile ? form.DraftHideOnMobile : false,
      SEOInfo: {
        Preview: "",
        PageTitle: form.DraftSEOPageTitle ? form.DraftSEOPageTitle : "",
        MetaDescription: form.DraftMetaDescription
          ? form.DraftMetaDescription
          : "",
        PageURL: form.DraftPageURL ? form.DraftPageURL : "",
        HeaderHTML: form.DraftHeaderHTML ? form.DraftHeaderHTML : "",
      },
      MainPagesInfoID: form?.DraftMainPagesInfoID,
    };
  };
  const createPage = async () => {
    try {
      setIsLoading(true);

      await createPages(prePareData());

      await Message.success("Page has been added sucessfully !");
    } catch (error) {
      console.log("create page error:", error);
    } finally {
      setShowPagePopup(false);
      setIsLoading(false);
      // fetchPages
      fetchAllPages();
    }
  };

  const updatePage = async () => {
    try {
      setIsLoading(true);

      await updatePageByID(prePareData());

      await Message.success("Page has been edited sucessfully !");
    } catch (error) {
      console.log("edit page error", error);
    } finally {
      setShowPagePopup(false);
      setIsLoading(false);
      // fetchPages
      fetchAllPages();
    }
  };

  return (
    <>
      <Popup
        width={640}
        modalTitle={
          <Stack direction="row" horizontalAlign="space_between">
            <Typography type="title" level={4} pattern="bold">
              {selectedPageID ? "Edit Page" : "Add New Page"}
            </Typography>
          </Stack>
        }
        modalVisible={showPagePopup}
        maskClosable={false}
        footer={
          <Space size="middle">
            {isLoading ? (
              <div className="eco-mt-16 eco-mr-16">
                <Spin />
              </div>
            ) : (
              <Button
                onClick={() => handleForm()}
                variant="primary"
                className="save-btn"
              >
                {selectedPageID ? "Update" : "Save"}
              </Button>
            )}
            <Button
              variant="outline"
              onClick={() => setShowPagePopup(false)}
              className="cancel-btn"
            >
              Cancel
            </Button>
          </Space>
        }
        onCancel={() => setShowPagePopup(false)}
        closable={true}
      >
        <div className="eco-mt-4">
          <Tabs
            tabContent={[
              {
                tabID: "1",
                tabName: "Basic Details",
                content: (
                  <div>
                    <div style={{ padding: "20px" }}>
                      <Row className="form-row">
                        <Typography
                          type="title"
                          level={5}
                          pattern="bold"
                          className="eco-mb-24"
                        >
                          Basic Information
                        </Typography>
                      </Row>
                      <Row className="form-row eco-mb-32" align="middle">
                        <Col span={8}>
                          <div className="form-lbl-wrapper">
                            <Label required>Page Name</Label>
                          </div>
                        </Col>
                        <Col span={16}>
                          <InputBox
                            noBorder
                            backgroundOnly
                            type="text"
                            placeholder="Page Title"
                            onChange={(e) =>
                              setField("DraftPageName", e.target.value)
                            }
                            value={form?.DraftPageName}
                          />
                        </Col>
                      </Row>
                      <Row className="form-row eco-mb-32" align="middle">
                        <Col span={8}>
                          <div className="form-lbl-wrapper">
                            <Label>Lock for Client Editing</Label>
                          </div>
                        </Col>
                        <Col span={16}>
                          <Switch
                            checked={form?.DraftIsLockedForClientEditing}
                            onChange={(e) =>
                              setField("DraftIsLockedForClientEditing", e)
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                    <div
                      style={{
                        padding: "20px",
                        borderTop: "1px solid #C6C7D2",
                        borderBottom: "1px solid #C6C7D2",
                      }}
                    >
                      <Row
                        className="form-row eco-mb-24"
                        justify="space-between"
                      >
                        <Typography
                          type="title"
                          level={5}
                          pattern="bold"
                          className="eco-mb-24"
                        >
                          Main Menu
                        </Typography>
                      </Row>
                      <Row className="form-row eco-mb-24" align="middle">
                        <Col span={8}>
                          <div className="form-lbl-wrapper">
                            <Label>Show in Main Menu</Label>
                          </div>
                        </Col>
                        <Col span={16}>
                          <Switch
                            checked={form?.DraftShowInMainMenu}
                            onChange={(e) => setField("DraftShowInMainMenu", e)}
                          />
                        </Col>
                      </Row>
                      <Row className="form-row eco-mb-24" align="middle">
                        <Col span={8}>
                          <div className="form-lbl-wrapper">
                            <Label>No-link</Label>
                          </div>
                        </Col>
                        <Col span={16}>
                          <Switch
                            checked={form?.DraftNoLink}
                            onChange={(e) => setField("DraftNoLink", e)}
                          />
                        </Col>
                      </Row>
                      <Row className="form-row eco-mb-24">
                        <Col span={24}>
                          <div className="form-lbl-wrapper">
                            <Label>Menu Description</Label>
                          </div>
                        </Col>
                        <Col span={24}>
                          <Typography
                            type="text"
                            color="gray_lightest"
                            alignment="right"
                          >
                            20 - 100 characters
                          </Typography>
                          <TextArea
                            className="description-box"
                            onChange={(e) =>
                              setField("DraftMenuDescription", e.target.value)
                            }
                            value={form?.DraftMenuDescription || ""}
                          />
                        </Col>
                      </Row>
                      <Row className="form-row" align="middle">
                        <Col span={24}>
                          <div className="form-lbl-wrapper">
                            <Label>Menu Icon</Label>
                          </div>
                        </Col>
                        {/* TODO imaage uploader and image viewer */}
                        <Col span={24}>
                          {!uploadedImageUrls && (
                            <FileUploader
                              multiple={false}
                              onUpload={handleProcessImage}
                              acceptFiles="image/*"
                              maxSize={25}
                            />
                          )}

                          <div>
                            {uploadedImageUrls && (
                              <Typography type="title" level={4}>
                                Manage Uploads
                              </Typography>
                            )}
                            <br />
                            {uploadedImageUrls &&
                              uploadedImageUrls.map((fl, i) => {
                                return (
                                  <Card
                                    shadow="default"
                                    disabled={false}
                                    type={"complex"}
                                    content={
                                      <Stack
                                        className="eco-pa-4"
                                        direction="row"
                                        horizontalAlign="space_around"
                                        verticalAlign="v_center"
                                      >
                                        <StackItem
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            padding: "8px",
                                          }}
                                        >
                                          <img
                                            // src={fl ? fl : defaultImage}
                                            src={fl}
                                            alt="Product Images"
                                            style={{ width: "100%" }}
                                          />
                                        </StackItem>
                                        <StackItem
                                          style={{
                                            flexGrow: "1",
                                            height: "55px",
                                            padding: "10px",
                                          }}
                                        ></StackItem>
                                        <Stack
                                          width="300"
                                          horizontalAlign="space_evenly"
                                        >
                                          <StackItem
                                            style={{
                                              flexGrow: "5",
                                              padding: "8px",
                                              justifyContent: "center",
                                              display: "flex",
                                              alignItems: "center",
                                              width: "100px",
                                            }}
                                          >
                                            <ProgressBar
                                              type="circle"
                                              value={
                                                i === 2 - 1 ? progress : 100
                                              }
                                              style={{
                                                marginRight: "10%",
                                                width: "10px",
                                              }}
                                              width={20}
                                            />
                                          </StackItem>
                                          <StackItem
                                            style={{
                                              height: "100px",
                                              padding: "8px",
                                              justifyContent: "flex-end",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Button
                                              category="link"
                                              onClick={() =>
                                                handleDeleteImage(i)
                                              }
                                              customStyles={{
                                                border: "none",
                                              }}
                                            >
                                              <Icon
                                                name="trashcan"
                                                color="red"
                                              />
                                            </Button>
                                          </StackItem>
                                        </Stack>
                                      </Stack>
                                    }
                                    width="100%"
                                    height="fit-content"
                                  />
                                );
                              })}
                          </div>
                          <Popup
                            modalTitle={<h3>Image Upload</h3>}
                            visible={showImageCropPopuop}
                            onCancel={() => setShowImageCropPopuo(false)}
                            footer={
                              <Space>
                                <Button
                                  onClick={handleGenerareImage}
                                  variant="primary"
                                  size="small"
                                >
                                  Done
                                </Button>
                                <Button
                                  onClick={() => setShowImageCropPopuo(false)}
                                  variant="secondary"
                                  className="eco-mr-8"
                                  size="small"
                                >
                                  Cancel
                                </Button>
                              </Space>
                            }
                            style={{ height: "800px", width: "800px" }}
                            width={550}
                          >
                            <div>
                              <div
                                style={{
                                  width: "504px",
                                  height: "504px",
                                  border: "2px solid #666",
                                }}
                              >
                                {processedImage ? (
                                  <div
                                    id="capture"
                                    style={{
                                      width: "500px",
                                      height: "500px",
                                      display: "flex",
                                      background: "#fff",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        verticalAlign: "middle",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "auto",
                                      }}
                                    >
                                      <div>
                                        <img
                                          src={URL.createObjectURL(
                                            processedImage
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <hr />
                              <div>
                                <Row className="form-row">
                                  <Col md={8}>
                                    <div className="form-lbl-wrapper">
                                      <Label>Image Quality</Label>
                                    </div>
                                  </Col>
                                  <Col md={8}>
                                    <InputBox
                                      type="number"
                                      placeholder="Quality"
                                      minValue={0.1}
                                      maxValue={1}
                                      value={imageQuality}
                                      onChange={(e) =>
                                        setImageQuality(e.target.value)
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Popup>
                        </Col>
                      </Row>
                    </div>
                    <div style={{ padding: "20px" }}>
                      <Row className="form-row" justify="space-between">
                        <Typography
                          type="title"
                          level={5}
                          pattern="bold"
                          className="eco-mb-24"
                        >
                          Hide/Show in Navigation
                        </Typography>
                      </Row>
                      <Row className="eco-mb-24">
                        <Col span={12}>
                          <Row className="form-row" align="middle">
                            <Col span={12}>
                              <div className="form-lbl-wrapper">
                                <Label>Hide on All</Label>
                              </div>
                            </Col>
                            <Col span={12}>
                              <Switch
                                checked={form?.DraftHideOnAll}
                                onChange={(e) => setField("DraftHideOnAll", e)}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className="form-row" align="middle">
                            <Col span={12}>
                              <div className="form-lbl-wrapper">
                                <Label>Hide on Tab</Label>
                              </div>
                            </Col>
                            <Col span={12}>
                              <Switch
                                checked={form?.DraftHideOnTab}
                                onChange={(e) => setField("DraftHideOnTab", e)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="eco-mb-24">
                        <Col span={12}>
                          <Row className="form-row" align="middle">
                            <Col span={12}>
                              <div className="form-lbl-wrapper">
                                <Label>Hide on Desktop</Label>
                              </div>
                            </Col>
                            <Col span={12}>
                              <Switch
                                checked={form?.DraftHideOnDesktop}
                                onChange={(e) =>
                                  setField("DraftHideOnDesktop", e)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row className="form-row" align="middle">
                            <Col span={12}>
                              <div className="form-lbl-wrapper">
                                <Label>Hide on Mobile</Label>
                              </div>
                            </Col>
                            <Col span={12}>
                              <Switch
                                checked={form?.DraftHideOnMobile}
                                onChange={(e) =>
                                  setField("DraftHideOnMobile", e)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ),
              },
              {
                tabID: "2",
                tabName: "SEO Info",
                content: (
                  <SeoDetails setField ={setField} formData={formData} handleForm={handleForm} />
                ),
              },
            ]}
            size="small"
          />
        </div>
      </Popup>
    </>
  );
});
PagePopup.propTypes = {};

export default PagePopup;
