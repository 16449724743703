import React, { useState, useEffect } from "react";

import {
  async,
  Button,
  Card,
  Col,
  GlobalHeader,
  GlobalLayout,
  Icon,
  Message,
  Popup,
  RightPanel,
  Row,
  Space,
  Spin,
  Stack,
  StackItem,
  Typography,
} from "witmeg-ui-system";
import * as _ from "lodash";
import "./TabView.css";
import { WidthProvider, Responsive } from "react-grid-layout";
import { QuestionCircleOutlined, CloseSquareFilled } from "@ant-design/icons";
import ReportWidject from "../../ReportWiidjet/index";
import {
  deleteHomeLayout,
  getHomeLayoutByID,
  updateHomeLayout,
} from "../../utilities/apiRequests/witmegRetailPaxServerRequests";
import { Popconfirm } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setCommonState, setResizeWidth } from "../../../redux/commonReducer";
import { setGridBackground } from "../../utilities/helpers";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
class TabView extends React.Component {
  static defaultProps = {
    className: "layout",

    cols: { lg: 24, md: 12, sm: 12, xs: 12, xxs: 12 },
    breakpoints: { lg: 1024, md: 768, sm: 768, xs: 480, xxs: 0 },
    rowHeight: 30,
    // isResizable: true,
    // This turns off compaction so you can place items wherever.
    compactType: false,
    // This turns off rearrangement so items will not be pushed arround.
    preventCollision: true,
    verticalCompact: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmation: false,
      homelayoutData: null,
    };
  }

  onResizeStop = (layout, newItem) => {
    this.setState({ widgetHeight: newItem.h });
  };

  onResize = (layout, oldItem, element, newItem) => {
    let SingleIconID = newItem?.i.split(",")[0];
    console.log("Resizing Func Desktop View", SingleIconID);
    this.setState({
      IconID: SingleIconID,
      widgetHeight: newItem.h,
      widgetWidth: newItem.w,
    });
  };

  //this will only executes when the drag is over
  onDragStop = async (layout, newItem, layoutItem) => {
    console.log("onDragStop", layout, layoutItem);
    this.setState({ layoutTemp: layout });
    this.setState({ siteLoading: true });

    const x = _.split(layoutItem.i, ",");

    const data = await getHomeLayoutByID({
      ID: x[0],
      PageID: this.props.pageID,
      CompanyID: sessionStorage.getItem("company"),
      Start: 0,
      Limit: 1000,
    });
    const pageData = {
      ID: data[0]?.ID,
      ItemHeading: data[0]?.ItemHeading,
      Item: x[1],
      Width: layoutItem.w,
      Height: layoutItem.h,
      X: layoutItem.x,
      Y: layoutItem.y,
      Type: x[1],
      ItemData: data[0]?.ItemData,
      RatioW: "",
      RatioH: "",
      Link: "",
      PageID: data[0]?.PageID,
      CompanyID: sessionStorage.getItem("company"),
    };
    await updateHomeLayout(pageData);
  };

  deleteHomeLayout = async (item) => {
    if (this.props.showPreviewButton == false) {
      this.props.saveCommonData({
        draggableState: true,
      });

      console.log(this.state.homelayoutData);
      try {
        await deleteHomeLayout({ ID: this.state.homelayoutData.i[0] });

        await this.props.handleGetHomeLayout();
        Message.success("Widget deleted successfully.");
        this.setState({ deleteConfirmation: false });
        this.props.saveCommonData({
          draggableState: false,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  

  generateDOM(el) {
    const removeStyle = {
      position: "absolute",
      right: "0.5px",
      top: 0,
      cursor: "pointer",
      zIndex: "999",
      background: "white",
      // height: '28px'
    };
    const eXtraProps = {}; //{isDraggable :!this.props.showPreviewButton, isResizable : !this.props.showPreviewButton}
    const i = el.i;
    return (
      <div
        key={i}
        data-grid={{ ...el, ...eXtraProps }}
        onClick={this.props.onBoxClick.bind(this, el)}
        onMouseEnter={() => this.props.mouseEnter(i)}
        onMouseLeave={this.props.mouseLeave}
        // style={{ overflow: "auto" }}
      >
        <>
          <span
            className={
              this.props.showPreviewButton === false
                ? this.props.hover === i
                  ? "disBlock remove"
                  : "disNone remove"
                : ""
            }
            style={removeStyle}
          >
            {this.props.showPreviewButton === false && (
              <div
                onClick={() => {
                  this.setState({ homelayoutData: el });
                  this.setState({ deleteConfirmation: true });
                }}
                style={{ backgroundColor: "transparent" }}
              >
                <CloseSquareFilled
                  style={{ color: "#FF0000", fontSize: "24px" }}
                />
              </div>
            )}

            <Popup
              modalTitle="    "
              modalVisible={this.state.deleteConfirmation}
              onCancel={() => this.setState({ deleteConfirmation: false })}
              centered
              // mask={false}
              maskClosable={false}
              className="widget-delete-confirmation-popup"
            >
              <div>
                <Stack style={{ marginBottom: "62px" }} verticalAlign="v_top">
                  <Icon
                    name="cancel"
                    type="color"
                    className="eco-mr-16"
                    size={32}
                  />
                  <Stack direction="column" verticalAlign="v_top">
                    <Typography
                      type="title"
                      level={5}
                      pattern="bold"
                      className="eco-mb-16"
                    >
                      You're about to delete this Widgets!
                    </Typography>
                    <Typography type="text">
                      Do you really want to delete this item? This Process
                      cannot be undone.
                    </Typography>
                  </Stack>
                </Stack>
                <Stack horizontalAlign="h_end">
                  <Space size="middle">
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        this.state.deleteConfirmation == true &&
                          this.deleteHomeLayout();
                      }}
                    >
                      Confirm
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => {
                        this.setState({ deleteConfirmation: false });
                        this.props.saveCommonData({
                          draggableState: false,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Space>
                </Stack>
              </div>
            </Popup>

            <Popconfirm
              title="Are you sure you want to delete this item ?"
              icon={<QuestionCircleOutlined />}
              onConfirm={this.deleteHomeLayout}
            >
              {/* /*delete icon of widgets*/}
            </Popconfirm>
          </span>

          {this.props.showPreviewButton === false && (
            <span
              className={
                this.props.showPreviewButton === false
                  ? this.props.hover === i
                    ? "disBlock  remove rowremove"
                    : "disNone remove rowremove"
                  : ""
              }
              style={{ textTransform: "capitalize" }}
            >
              {/* {this.props.showPreviewButton === false && <div>{el.i[1]}</div>} */}
            </span>
          )}
          <ReportWidject
            params={{
              PreviewButton: this.props.showPreviewButton,
              layoutid: el.i,
              pageID: this.props?.pageID,
              LayoutID: this?.props?.layoutID,
              w: el.x,
              // h: el.y,
              widjetName: el.component ? el.component : el.i[1],
              widgetHeight: this.state.widgetHeight,
              widgetWidth: this.state.widgetWidth,
              IconID: this.state.IconID,
            }}
          />
        </>
      </div>
    );
  }

  render() {
    // console.log("TESTTT", this?.props)
    return (
      <div className="PreviewLayout layout-tablet">
        <ResponsiveReactGridLayout
          onDrop={this.props.onDrop}
          breakpoints={this.props.breakpoints}
          onResizeStop={this.onResizeStop}
          onResize={this.onResize}
          className="layout-mobile"
          // autoSize={true}
          allowOverlap={true}
          isDroppable={true}
          isDraggable={
            this.props.showPreviewButton || this?.props?.dragState
              ? false
              : true
          }
          isResizable={!this.props.showPreviewButton}
          droppingItem={this.props.item}
          measureBeforeMount={false}
          useCSSTransforms={false}
          onBreakpointChange={this.props.onBreakpointChange}
          compactType={this.props.compactType}
          layout={this.props.layouts}
          onDragStop={this.onDragStop}
          preventCollision={true}
          onLayoutChange={this.props.onLayoutChange}
          // isBounded={true}
          margin={[0, 0]}
          {...this.props}
          style={setGridBackground(this.props.themeSettings)}
        >
          {_.map(this.props.items, (el) => this.generateDOM(el))}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { commonData } = state;
  return {
    resizeWidth: commonData.resizeWidth,
    dragState: commonData.dragState,
  };
};

//SET TO REDUX STATE
const mapDispatchToProps = (dispatch) => ({
  saveResizeWidth: (data) => dispatch(setResizeWidth(data)),
  saveCommonData: (data) => dispatch(setCommonState(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TabView));
