import React from 'react';
import { Col, Row, Stack, Typography } from 'witmeg-ui-system';
import { useSelector } from 'react-redux';
import { RESTAURANT_SETTINGS_TYPES, getCurrencyFormat, getRestaurantMenuProductPrice } from '../../../utilities/helpers';
import '../../../utilities/stringOverride';
import IMAGE_PLACEHOLDER from '../../../../assets/img/image-placeholder.png';

/*
  Layout: 1
    Shows menu items in one column with images
*/
const OneColumnWithImageLayout = ({ isPopup = false }) => {

  const { menuWidgetData: { currency }, menuData: { selectedMainMenuData, uiCustomizations }, defaultOrderType } = useSelector((state) => state.resturantWidget);
  const { OPT } = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES;
  const orderType = defaultOrderType === OPT.CUSTOMER_CHOICE.name ? OPT.COLLECTION.name : defaultOrderType ?? OPT.COLLECTION.name;

  const currencyFormat = getCurrencyFormat(currency);

  return (
    <Row>
      {selectedMainMenuData && selectedMainMenuData.SubMenu && selectedMainMenuData.SubMenu.length > 0 && selectedMainMenuData.SubMenu.map((data, index) => {
        return (
          <>
            {
              uiCustomizations.subMenu.settings.subMenuEnabled &&
              <label className='eco-layout-sub-category' style={{ ...uiCustomizations.subMenu.style }}>
                {data?.SubMenuName.toCapitalFirst()}
              </label>
            }

            {data.MenuProductData.map((data, index) => {
              const productPrice = getRestaurantMenuProductPrice(data.ProductData, orderType);
              const productImage = data?.ProductData?.ImageURL && data?.ProductData?.ImageURL.POS && data?.ProductData?.ImageURL.POS.length > 0 ? data?.ProductData?.ImageURL.POS[0] : IMAGE_PLACEHOLDER;
              const productDescription = data?.ProductData?.ItemDescription?.Translations?.en


              return (
                <>
                  <Col span={24} style={{ padding: '10px', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px', height: '16rem', opacity: '0.8' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                        <div style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '16px' }}>{data.ProductName.toCapitalFirst()}</Typography>
                          </div>
                          <div>
                            <Typography type="title" level={5} style={{ padding: '5px 0', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>{productDescription}</Typography>
                          </div>
                        </div>
                        <img src={productImage} style={{ height: '10rem', width: '10rem', padding: '5px' }} />
                      </div>
                      <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                        <Typography type="title" level={5} pattern="bold">{currencyFormat(productPrice)}</Typography>
                      </div>
                    </div>
                  </Col>
                  {/* <div
                    className={`product-row layout-product-item ${isSelected ? 'product-row-active' : ''}`}
                    style={{ ...uiCustomizations.productItem.itemSpaceBetween }} >
                    <div className='eco-product-left-side'>
                      <div style={{ backgroundImage: `url(${productImage})` }} className="product-image"></div>
                      <div>
                        <div className="product-name-label">
                          <label style={uiCustomizations.productItem.name}>
                            {data.ProductName.toCapitalFirst()}
                          </label>
                        </div>
                        {
                          data.ProductData?.ServicePriceInfo?.Price !== null &&
                          <label className='eco-product-price-label' style={uiCustomizations.productItem.name}>{currencyFormat(productPrice)}</label>
                        }
                        <div className="product-description">
                          <label style={uiCustomizations.productItem.description}>
                            <ShowMoreText
                              lines={2}
                              more="More"
                              less="Less"
                              expanded={false}
                              width={360}
                              truncatedEndingComponent={"..."}>
                              {data.ProductData.ItemDescription.Translations.en.toCapitalFirst()}
                            </ShowMoreText>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='eco-product-right-side'>
                      {
                        data.ProductData?.ServicePriceInfo?.Price !== null &&
                        <label className='eco-product-price-label' style={uiCustomizations.productItem.name}>{currencyFormat(productPrice)}</label>
                      }
                      {window.location.hostname !== "kottuking.witmeg.net" ?
                        <Button variant="primary" className="eco-add-to-cart-label"
                          onClick={() => onProductItemClick(data)}>
                          <label className='desktop-but'>Add to Cart</label>
                          <label className='mobile-but'><Icon name="plus" size={24} /></label>
                        </Button>
                        : null}
                    </div>
                  </div> */}
                </>
              );
            })}
          </>
        );
      })}

    </Row>
  );
}
export default OneColumnWithImageLayout;