import shortid from "shortid";
import React from "react";
import TextEditor from "../../ReportWiidjet/TextEditor";
import DndTextEditor from "./widgets/DndTextEditor";
import DndButtonWidget from "./widgets/DndButtonWidget";
import DndContactUs from "./widgets/DndContactUs"
import DndImageWidget from "./widgets/DndImageWidget";
export const SIDEBAR_ITEM = "sidebarItem";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "text",
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "button",

    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "image",
    }
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "form",
    }
  },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: "html",
  //   }
  // },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: "space",
    }
  }
];
