import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  async,
  Card,
  Icon,
  Message,
  Stack,
  Typography,
} from "witmeg-ui-system";
import MapPopUp from "./MapPopUp";
import {
  getHomeLayoutByID,
  updateHomeLayout,
} from "../../utilities/apiRequests/witmegRetailPaxServerRequests";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { setCommonState } from "../../../redux/commonReducer";
import { useDispatch } from "react-redux";
import "../Widget.css";

const Map = (props) => {
  const { widgetID, pageID, PreviewButton } = props;

  const API_KEY = "AIzaSyClktNC7hK4mzmSUmloOhSQ7rY3L0XvzH4";

  const [isMapPopUpOpen, setIsMapPopUpOpen] = useState(false);
  const [mapData, setMapData] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [widgetData, setWidgetData] = useState({});
  const [mapValidation, setMapValidation] = useState(false);
  const [autocomplete, setAutocomplete] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      await fetchMapData();
    }
    fetchData();
    return () => {};
  }, []);

  const onOk = useCallback(
    (mapData) => {
      if(!autocomplete.getPlace()){
        setMapValidation(true)
        return
      }
      //update the map
      updateMapData(mapData);
    },
    [isMapPopUpOpen,autocomplete]
  );

  const handleShowPopup = () => {
    if (PreviewButton == false) {
      dispatch(
        setCommonState({
          draggableState: true,
        })
      );
      setIsMapPopUpOpen(true);
    }
  };

  const onCancel = useCallback(() => {
    setIsMapPopUpOpen((state) => !state);

    dispatch(
      setCommonState({
        draggableState: false,
      })
    );
  }, [isMapPopUpOpen]);

  const fetchMapData = async () => {
    try {
      const response = await getHomeLayoutByID({
        ID: widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      });

      setMapData(
        response[0]?.ItemData ? JSON.parse(response[0]?.ItemData) : ""
      );
      setWidgetData({
        Width: response[0].Width,
        Height: response[0].Height,
        X: response[0].X,
        Y: response[0].Y,
        Type: response[0].Type,
      });
      setMapValidation(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMapData = async (itemData) => {
    //  ;
    try {
      //set ok button loading state to true
      setConfirmLoading(true);
      await updateHomeLayout({
        ID: widgetID,
        ItemHeading: "maps",
        Item: "maps",
        Width: widgetData?.Width,
        Height: widgetData?.Height,
        X: widgetData?.X,
        Y: widgetData?.Y,
        Type: "maps",
        ItemData: JSON.stringify(itemData),
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
      });
      await fetchMapData();
    } catch (error) {
      console.log(error);
    } finally {
      //set ok button loading state to false
      setConfirmLoading(true);
      Message.success("Widget edited successfully");
      dispatch(
        setCommonState({
          draggableState: false,
        })
      );
      //close modal
      setIsMapPopUpOpen((state) => !state);
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: ["places"],
    version: "3",
  });

  const renderMap = () => {
    return (
      <>
        <Card
          className="map-widget"
          rounded="small"
          shadow="no_shadow"
          content={
            mapData ? (
              <GoogleMap
                id="map-with-address-search"
                mapContainerStyle={{
                  height: "100vh",
                  width: "100%",
                }}
                zoom={13}
                center={{ lat: mapData.lat, lng: mapData.lng }}
                onDblClick={() => handleShowPopup()}
              >
                <Marker
                  onLoad={() => {}}
                  position={{ lat: mapData.lat, lng: mapData.lng }}
                />
              </GoogleMap>
            ) : (
              <div
                onDoubleClick={() => handleShowPopup()}
                className="image-widget-component"
              >
                <Stack
                  direction="column"
                  horizontalAlign="space_between"
                  verticalAlign="v_center"
                >
                  <Icon name="map" size={32} type="filled" />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <Typography
                    level={4}
                    type="title"
                    alignment="center"
                    color="gray_base"
                  >
                    Double click to add an address, location
                  </Typography>
                </Stack>
              </div>
            )
          }
        />
        {isMapPopUpOpen && (
          <MapPopUp
            isOpen={isMapPopUpOpen}
            onOk={onOk}
            onCancel={onCancel}
            mapData={mapData}
            APIKEY={API_KEY}
            confirmLoading={confirmLoading}
            mapValidation={mapValidation}
            autocomplete={autocomplete}
            setAutocomplete={setAutocomplete}
            setMapValidation={setMapValidation}
          />
        )}
      </>
    );
  };

  return isLoaded ? renderMap() : "";
};

Map.propTypes = {};

export default Map;
