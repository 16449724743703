import React, { useState, useEffect, useRef } from "react";
import {
  FileUploader,
  ProgressBar,
  Row,
  Col,
  Select,
  Typography,
  Card,
  Stack,
  StackItem,
  Icon,
  Button,
  Alert,
} from "witmeg-ui-system";
import './VideoUpload.css'
function VideoUpload(props) {




  const validVideoUpload = (file) => {

    console.log("FIL", file.type)
    const validExtensions = ["video/mp4", "video/x-m4v", "video/*"];
    const fileExtension = file.type;
    return validExtensions.includes(fileExtension)
  }

  const handleUploadFiles = async (file) => {

    console.log("FILEEE", file);
    await props.setClearVideo(file);


    if (file.target.files && file.target.files.length > 0) {



      const video = file.target.files[0]


      const MAX_FILE_SIZE = 25000; //25MB
      const videoSizeValidation = video.size / 1024;
      if (videoSizeValidation > MAX_FILE_SIZE) {
        props.setVideoSizeValidation("Video Size is greater thatn 25 MB");
        props.setIsSuccess(true)
      } else {
        if (validVideoUpload(video)) {
          props.setVideoSizeValidation("");
          let data =
          // ...uploadedImageUrls,

          {
            id: Math.floor(100000 + Math.random() * 900000),
            videoUrl: file?.target?.files[0],
            // preview: file.preview,

            file: file.target.files[0],
          };
          props.setUploadedImageUrls(data);
          props.handleOnlineImageList(data);
          // props.setFaviconUpdated(true);
           
          const fileReader = new FileReader();
          fileReader.onloadstart = () => {
            props.setOutput(null);
          };
          fileReader.onloadend = () => {
            props.setOutput(fileReader.result);
          };

          if (file.target.files[0]) {
            fileReader.readAsDataURL(file.target.files[0]);
          }

        } else {
          props.setIsSuccess(true);
          props.setVideoTypeValidation("Video should be a MP4")
        }
      }
    }
  }

  const handleClearUpload = (event) => {


    event.target.value = null;
    props.setVideoTypeValidation()
    props.setVideoSizeValidation()
  }

  const inputFile = useRef(null);

  const onClick = () => {
    inputFile.current.click();
  };

  const handleDelete = () => {
    props.setOutput()
  }

  return (
    <div id='image_upload_container' style={{ height: '100%' }}>
      {/* <FileUploader
        multiple={false}
        acceptFiles='video/*'
        onUpload={handleUploadFiles}  
        maxSize={100}
      /> */}

      {props.isSuccess && (
        <>
          {props.videoSizeValidation && (
            <Alert message={props.videoSizeValidation} type='error' showIcon />
          )}
          {props.videoTypeValidaion && (
            <Alert message={props.videoTypeValidaion} type='error' showIcon />
          )}
        </>
      )}


      <div className="eco-mt-12">
        <Stack onClick={onClick} direction='column' horizontalAlign='h_center'
          className='video-upload-section'
          verticalAlign='v_center'>
          <input
            type='file'
            accept="video/mp4,video/x-m4v,video/*"
            // onChange={handleUploadFiles}
            onChange={(event) => {
              handleUploadFiles(event);
            }}
            onClick={(event) => {
              handleClearUpload(event)
            }}
            ref={inputFile}
            style={{ display: "none" }}
          />
          <Icon name='upload-outline' type='filled' />
          <Typography type='text'>Click here to upload a file</Typography>
          <Typography type="text" size="small" color="gray_base">(Max. file size 25MB)</Typography>
        </Stack>





        <input
          type='file'
          accept="video/mp4,video/x-m4v,video/*"
          onChange={handleUploadFiles}
          style={{ display: "none" }}
        />
      </div>

      {props.output && <>
        <div className="eco-mt-8">
          <Typography type="title" level={4}>Manage Uploads</Typography><br /><Card shadow='default'
            disabled={false}
            type={"complex"}
            width='100%'
            height='fit-content'
            content={<Stack className='eco-pa-4' style={{ flexWrap: 'wrap' }}>

              <StackItem style={{ display: 'flex', width: '170px  ' }} className="eco-mt-3 thumbnail-image">

                <video className='react-video-player'
                  style={{ objectFit: 'fill' }} autoPlay={true} loop={true} muted={true} controls={false} src={props.output} />

              </StackItem>
              <StackItem
                style={{
                  flexGrow: "3",
                  height: "100px",
                  padding: "8px",
                }}
              >
                {" "}
                <div className='eco-mt-4'>
                  {/* {renderImageSizeInfo(fl.imageType)} */}
                </div>
              </StackItem>

              <StackItem
                style={{
                  height: "100px",
                  padding: "8px",
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "center",
                }}
              >

                <Button
                  onClick={() => handleDelete()}
                  category='text-icon'
                  variant='subtle'
                  text=""
                  icon='delete'
                  size='large'
                  iconSize={20}
                  customStyles={{ border: "none" }}
                />



                {/* <Button onClick={onOk} customStyles={{ border: "none" }}>
<Icon name='trashcan' color='red' />
</Button> */}
              </StackItem>

            </Stack>} />
        </div>
      </>}
    </div>
  );
}

export default VideoUpload;
