
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
    name: "Common Slice",
    initialState: {
        dragState: false,
        publishState: false,
        desktopState: true,
        mobileState: false,
        tabletState: false,
        resizeWidth: null
    },
    reducers: {
        setCommonState: (state, action) => {
            const { draggableState } = action.payload || {};

            state.dragState = draggableState
        },

        setDesktopState: (state, action) => {
            const { desktopState } = action.payload || {};
            state.desktopState = desktopState
        },
        setMobileState: (state, action) => {
            const { mobileState } = action.payload || {};
            state.mobileState = mobileState
        },

        setTabletState: (state, action) => {
            const { tabletState } = action.payload || {};
            state.tabletState = tabletState
        },

        setResizeWidth: (state, action) => {
            const { resizeWidth } = action.payload || {};
            state.resizeWidth = resizeWidth
        },





    }
})

export const { setCommonState, setDesktopState, setMobileState, setTabletState, setResizeWidth } = commonSlice.actions;
export default commonSlice.reducer;