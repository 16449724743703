import React, { PureComponent } from "react";
import ReactDOM from "react-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Modal } from "antd";
import "./App.css";
import axios from "axios";
import * as bodyParser from "body-parser";
import config from "react-global-configuration";
import "./config.js";
import jwt from "jsonwebtoken";

class Application extends React.Component {
  state = {
    src: null,
    Title: this.props.params.link,
    TitleHeading: this.props.params.MenuItemId,
    crop: {
      unit: "%",
      width: 30,
      aspect: this.props.params.width / this.props.params.height,
    },
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onFileUpload = (image) => {
    if (this.state.src == null) {
      const pageData = {
        itemheading: this.state.TitleHeading,
        item: this.props.params.MenuItemId,
        width: this.props.params.w,
        height: this.props.params.h,
        x: this.props.params.x,
        y: this.props.params.y,
        order: this.props.params.order,
        type: "image",
        itemdata: this.props.params.MenuItemData,
        link: this.state.Title,
        pageid: this.props.params.pageid,
        companyid: sessionStorage.getItem("company"),
      };

      const timestamp = Math.floor(Date.now() / 1000) + 1000;
      var payload = {
        aud: process.env.REACT_APP_jwtAud,
        exp: timestamp,
        iss: process.env.REACT_APP_jwtIss,
      };

      var token = jwt.sign(payload, process.env.REACT_APP_secret);

      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ` + token,
        },
      };

      axios
        .post(
          config.get("backendURL") +
            "homelayout/update/" +
            this.props.params.itemid,
          pageData,
          requestOptions
        )
        .then(function (data) {
          console.log(data);
          if (data.statusText == "OK") {
            Modal.success({
              title: "Succes",
              content: "Image has been uploaded successfully",
              onOk() {
                window.location.reload(false);
              },
            });
          }
        });
    } else {
      const { blobFile } = this.state;
      const newImage = new File([blobFile], blobFile.name, {
        type: blobFile.type,
      });
      let fd = new FormData();
      fd.append(newImage.name, newImage);
      //console.log(newImage);

      const formData = new FormData();
      formData.append("retailpacx", newImage);

      formData.append("foldername", sessionStorage.getItem("companybrandname"));
      const axconfig = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      axios
        .post(
          config.get("backendURL") +
            "homelayout/upload/" +
            this.props.params.itemid,
          formData,
          axconfig
        )
        .then((res) => {
          const pageData = {
            itemheading: this.state.TitleHeading,
            item: this.props.params.MenuItemId,
            width: this.props.params.w,
            height: this.props.params.h,
            x: this.props.params.x,
            y: this.props.params.y,
            order: this.props.params.order,
            type: "image",
            itemdata: res.data.imageName,
            link: this.state.Title,
            pageid: this.props.params.pageid,
            companyid: sessionStorage.getItem("company"),
          };

          const timestamp = Math.floor(Date.now() / 1000) + 1000;
          var payload = {
            aud: process.env.REACT_APP_jwtAud,
            exp: timestamp,
            iss: process.env.REACT_APP_jwtIss,
          };

          var token = jwt.sign(payload, process.env.REACT_APP_secret);

          const requestOptions = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ` + token,
            },
          };

          axios
            .post(
              config.get("backendURL") +
                "homelayout/update/" +
                this.props.params.itemid,
              pageData,
              requestOptions
            )
            .then(function (data) {
              console.log(data);
              if (data.statusText == "OK") {
                Modal.success({
                  title: "Succes",
                  content: "Image has been uploaded successfully",
                  onOk() {
                    window.location.reload(false);
                  },
                });
              }
            });
        });
    }
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
    console.log(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          //console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        //console.log(blob.name)
        //console.log(this)
        resolve(this.fileUrl);
        this.setState({ blobFile: blob }); // Set blob image inside the state here
      }, "image/jpeg");
    });
  }
  onChangeTitleHandler = (event) => {
    var title = event.target.value;
    this.setState({
      Title: title,
    });
  };
  onChangeTitleHeadingHandler = (event) => {
    var title = event.target.value;
    this.setState({
      TitleHeading: title,
    });
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <div class='container'>
        <div class='row'>
          <div class='offset-md-3 col-md-6'>
            <div className='App'>
              <div class='form-group files'>
                <label>Image Heading</label>
                <input
                  type='text'
                  class='form-control'
                  defaultValue={this.state.TitleHeading}
                  onChange={this.onChangeTitleHeadingHandler}
                />
              </div>
              <div class='form-group files'>
                <label>Link</label>
                <input
                  type='text'
                  class='form-control'
                  defaultValue={this.state.Title}
                  onChange={this.onChangeTitleHandler}
                />
              </div>

              <div>
                <input
                  type='file'
                  accept='image/*'
                  onChange={this.onSelectFile}
                />
                <Button
                  type='primary'
                  htmlType='submit'
                  onClick={this.onFileUpload.bind(this)}
                >
                  Submit
                </Button>
              </div>
              {src && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              )}
              {croppedImageUrl && (
                <img
                  alt='Crop'
                  style={{ maxWidth: "100%" }}
                  src={croppedImageUrl}
                />
              )}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}
export default Application;
