import { createSlice } from "@reduxjs/toolkit";

export const companySlice = createSlice({
    name: "Comapny Data",
    initialState: {
        companyData: []
    },


    reducers: {
        setCompanyData: (state, action) => {

            const { companyData } = action.payload || [];

            state.companyData = companyData[0]
        }
    },
})

export const { setCompanyData } = companySlice.actions
export default companySlice.reducer;