import React, { useRef, useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import { Resizable } from 're-resizable';
// import { Rnd } from "react-rnd";
import { COMPONENT } from "./constants";
import DndTextEditor from "./widgets/DndTextEditor";
import DndButtonWidget from "./widgets/DndButtonWidget";
import DndContactUs from "./widgets/DndContactUs"
import DndImageWidget from "./widgets/DndImageWidget";
import DndBlankSpace from "./widgets/DndBlankSpace";
import { setWith } from "lodash";


const style = {
  border: "1px dashed black",
  padding: 0,
  backgroundColor: "transparent",
  cursor: "move",
  color: "#adc52a"
};

const rzstyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #ddd"
};
const Component = ({ row, column, data, components, path, handleItemDataUpdate, handleItemSizeUpdate, initialHeight, initialWidth }) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(initialHeight)
  const [width, setWidth] = useState(initialWidth)

  const [isDraggable, setIsDraggable] = useState(true)
  const sizeRef = useRef(null)

  // useEffect(() => {
  //   setHeight(initialHeight)
  //   setWidth(initialWidth)
  // }, [initialHeight, initialWidth ])

  useEffect(() => {
    if (data.size) {
      setHeight(data && data.size && data.size.height ? data.size.height : height)
      setWidth(data && data.size && data.size.width ? data.size.width : width)
    } 
  }, [data.size, initialHeight, initialWidth])


  // useEffect(() => {

  //   console.log("ref.current.clientHeight====>>>>", ref.current.clientHeight)
  //   setHeight(ref.current.clientHeight)
  //   setWidth(ref.current.offsetWidth)
  // })

  const [textVal, setTextVal] = useState('')



  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: { id: data.id, path },
    canDrag: isDraggable,
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });



  const opacity = isDragging ? 0 : 1;
  drag(ref);

  const component = components[data.id] || data.component;


  const handleUploadImage = (obj) => {
    console.log("img data ===>>", obj)
    handleItemDataUpdate(row, column, data, components, path, obj, width, height, 'image')
  }

  const handleSaveText = (text) => {
    handleItemDataUpdate(row, column, data, components, path, text, width, height, 'text')
  }

  const handleAddContactForm = () => {
    handleItemDataUpdate(row, column, data, components, path, "", width, height, 'contact form')
  }

  const handleBtnAdd = (text) => {
    handleItemDataUpdate(row, column, data, components, path, text, width, height, 'button')
  }

  const handleAddBlankSpace = () => {
    handleItemDataUpdate(row, column, data, components, path, '', width, height, 'space')
  }

  const onResize = (e, direction, ref, d) => {
    setIsDraggable(false)
  }
  const onResizeStop = (e, direction, ref, d) => {
    setIsDraggable(true)
    console.log("event===>>", e)
    console.log("direction===>>", direction)
    console.log("ref===>>", ref)
    console.log("d===>>", d)
    console.log("PREV size====>>", width, height)
    // let newSize={width: width+d.width, height: height+d.height}
    // handleSetSize(newSize.width, newSize.height, true)
    setWidth(width + d.width)
    setHeight(height + d.height)
    handleItemSizeUpdate(row, column, data, components, width + d.width, height + d.height)
  }

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className="dnd-component draggable"
    >
      {/* <div>{data.id}</div> */}
      {/* <div>{component.type}</div> */}

      <div style={{ position: 'relative' }}>

        {component ?
          <>
            {(() => {
              switch (component.type) {
                case 'form':
                  return (<Resizable
                    // size={{ width: width, height: height }}
                    defaultSize={{width:'100%', height:440}}
                    onResize={onResize}
                    onResizeStop={onResizeStop}
                    style={rzstyle}
                  >
                    <DndContactUs handleAddContactForm={handleAddContactForm} />
                  </Resizable>)
                case 'image':
                  // return <div>Image Here</div>;
                  return (<Resizable
                    // size={{ width: width, height: height }}
                    defaultSize={{width:'100%', height:240}}
                    onResize={onResize}
                    onResizeStop={onResizeStop}
                    style={rzstyle}
                  >
                    <DndImageWidget
                      params={{
                        widgetID: 'dsds',
                        PreviewButton: '',
                        pageID: "id"
                        // w: props.params.w,
                        // h: props.params.h,
                      }}
                      defaultValues={data?.itemData?.data || {}}
                      handleUploadImage={handleUploadImage}
                    />
                  </Resizable>)
                case 'button':
                  return (<Resizable
                    size={{ width: width, height: height }}
                    onResize={onResize}
                    onResizeStop={onResizeStop}
                    style={rzstyle}
                  >
                    <DndButtonWidget
                      params={{
                        widgetID: 'dsds',
                        PreviewButton: '',
                        pageID: "id"
                        // w: props.params.w,
                        // h: props.params.h,
                      }}
                      handleBtnAdd={handleBtnAdd}
                      defaultValues={data?.itemData?.data || ''}
                    />
                  </Resizable>)
                case 'text':
                  return (<Resizable
                    size={{ width: width, height: height }}
                    onResize={onResize}
                    onResizeStop={onResizeStop}
                    style={rzstyle}
                  >
                    <DndTextEditor
                      params={{
                        widgetID: 'dsds',
                        PreviewButton: '',
                        pageID: "id"
                        // w: props.params.w,
                        // h: props.params.h,
                      }}
                      handleSaveText={handleSaveText}
                      defaultText={data?.itemData?.data || ''}
                    />
                  </Resizable>)
                default:
                  return (<Resizable
                    size={{ width: width, height: height }}
                    onResize={onResize}
                    onResizeStop={onResizeStop}
                    style={rzstyle}
                  >
                    <DndBlankSpace handleAddBlankSpace={handleAddBlankSpace} />
                  </Resizable>)
              }
            })()}
          </>
          : null}
      </div>

    </div>
  );
};
export default Component;
