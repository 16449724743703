import React, { Component } from "react";
import { Modal, Layout, Input, Menu, Button, Select, Popover } from "antd";
import ReactDragListView from "react-drag-listview/lib/index.js";
import axios from "axios";
import {
  DragOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import config from "react-global-configuration";

const { Header, Sider } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const { confirm } = Modal;

export default class header extends Component {
  constructor(props) {
    super(props);

    const datax = [];
    this.state = {
      datax,
      menus: [],
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  componentDidMount() {
    axios
      .get(
        config.get("backendURL") +
          "category/" +
          sessionStorage.getItem("company")
      )
      .then((response) => {
        console.log(response);
        this.setState({ datax: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleOk = (id) => {
    this.setState({ loading: true });

    if (!document.getElementById("clicked" + id).value) {
      Modal.error({
        title: "Error",
        content: "Please enter a value",
        onOk() {},
      });
    } else {
      var updatecategory = {
        category: document.getElementById("clicked" + id).value,
        slug: this.state.slug,
        status: this.state.status,
        description: this.state.description,
        visibility: this.state.visibility,
      };

      console.log(updatecategory);
      axios
        .post(
          config.get("backendURL") + "category/update/" + id,
          updatecategory
        )
        .then(function (data) {
          Modal.success({
            title: "Success",
            content: "Category renamed",
            onOk() {
              // window.location.href = '/home';
            },
          });
        });
    }
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  onPopClick = (id, category, slug, description, status, visibility) => {
    this.setState({
      clickedid: id,
      category: category,
      slug: slug,
      description: description,
      status: status,
      visibility: visibility,
    });
  };

  onPopClickDelete = (id) => {
    this.setState({ clickedid: id });

    confirm({
      title: "Are you sure delete this category?",
      icon: <ExclamationCircleOutlined />,
      content:
        "You can not restore this item once you delete it. You will also loose all the articles under this category",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .delete(config.get("backendURL") + "category/" + id)
          .then((response) => {
            Modal.success({
              title: "Success",
              content: "Category has been deleted",
              onOk() {
                // window.location.href = '/home';
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  onPopClickAdd = () => {
    // window.location.href = "/new/category/"
  };

  //href="/new/page"

  render() {
    const { visible, loading, clickedid } = this.state;

    const content = (
      <div>
        <p>
          <a href='#' onClick={this.showModal}>
            Rename
          </a>

          <Modal
            visible={visible}
            title='Rename Page'
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
              <Button key='back' onClick={this.handleCancel}>
                Return
              </Button>,
              <Button
                key='submit'
                type='primary'
                loading={loading}
                onClick={this.handleOk.bind(this, this.state.clickedid)}
              >
                Submit
              </Button>,
            ]}
          >
            <Input id={"clicked" + this.state.clickedid} placeholder='Rename' />
            Renaming this category does not change the URL. To change it, go to
            Category URL.
          </Modal>
        </p>
        <p>
          <a
            href='#'
            onClick={this.onPopClickDelete.bind(this, this.state.clickedid)}
          >
            Delete
          </a>
        </p>
      </div>
    );

    const that = this;

    return (
      <ReactDragListView>
        <div className='ant-list ant-list-split'></div>
        <div className='ant-spin-container'>
          <ul className='ant-list-items'>
            {this.state.datax.map((item, index) => (
              <li key={index} className='ant-list-item'>
                <div>
                  <div className='ant-list-item-meta-avatar'>
                    {" "}
                    <a href='#'>
                      <DragOutlined style={{ fontSize: "15px" }} />
                    </a>
                  </div>
                </div>
                <div className='ant-list-item-meta-content'>
                  <div className='ant-list-item-meta-description'>
                    <a href={"/category/" + item.slug}>{item.category}</a>
                  </div>
                </div>
                <div>
                  <Popover
                    placement='right'
                    title={item.category}
                    content={content}
                    trigger='focus'
                    onClick={this.onPopClick.bind(
                      this,
                      item._id,
                      item.category,
                      item.slug,
                      item.description,
                      item.status,
                      item.visibility
                    )}
                  >
                    <SettingOutlined />
                  </Popover>
                </div>
                <hr />
              </li>
            ))}
          </ul>

          <div className='float-right'>
            {" "}
            <Button type='primary' onClick={this.onPopClickAdd.bind(this)}>
              Add New
            </Button>
          </div>
        </div>
      </ReactDragListView>
    );
  }
}
