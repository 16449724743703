import Axios from "axios";
import config from "react-global-configuration";
import React, { useEffect } from "react";
import { getCompanyByID } from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCompanyID } from "../../redux/organiserReducer";
import { setCommonState } from "../../redux/commonReducer";
const SiteSelect = (props) => {
  let history = useHistory();
  // console.log("props.match.params.id", props.match.params.id);
  const companyID = useSelector((state) => state.organisation);
  const dispatch = useDispatch()
  // console.log("companyID", companyID)
  useEffect(async () => {
    const cachedCompany = sessionStorage.getItem("company");
    if (cachedCompany) {
      sessionStorage.setItem("company", props.match.params.id);
    } else {
      sessionStorage.setItem("company", props.match.params.id);
    }

    // API CALL
    const getCompanyID = await getCompanyByID({
      ID: sessionStorage.getItem("company"),
      OrganisationID: "",
      SubDomain: "",
      CustomerDomain: "",
      Start: 0,
      Limit: 0,
    }).then((response) => {

      console.log("response[0].IsPublished", response[0].IsPublished)

      dispatch(setCommonState({
        publishedState: response[0].IsPublished
      }))

      let baseURL = ""
      if (response[0].CustomerDomain)
        baseURL = response[0].CustomerDomain
      else
        baseURL = response[0].SubDomain ? `${response[0].SubDomain}/` : ""
      sessionStorage.setItem("companyBaseURL", baseURL)
      sessionStorage.setItem("theme", response[0].Theme);
      sessionStorage.setItem(
        "companybrandname",
        response[0].CDNPath.replace(/ /g, "")
          .replace("&", "")
          .replace(/(^\&)|,/g, "")
          .toLowerCase()
      );
      // window.location.href = "/home";
      history.push("/home");
    });
  }, []);


  return <></>;
};






export default SiteSelect