import React, { useState, useEffect } from "react";
import {
  FileUploader,
  ProgressBar,
  Row,
  Col,
  Select,
  Typography,
  Card,
  Stack,
  StackItem,
  Icon,
  Button,
  async,
  Popup,
  Label,
  InputBox,
  Space,
  Spin,
  Message,
  Alert,
} from "witmeg-ui-system";
import Resizer from "react-image-file-resizer";
import * as htmlToImage from "html-to-image";
import { generateImageUploadServerToken } from "../../utilities/tokenGenerators";
import globalValues from "../../../configs/globalValues";
import Axios from "axios";

const MAX_FILE_SIZE = 25;

function ImageUpload(props) {
  const [favicon, setFavicon] = useState("");
  const [showImageCropPopup, setShowImageCropPopup] = useState(false);
  const [processedImage, setProcesssedImage] = useState(null);
  const [imageQuality, setImageQuality] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [fileTypeValidation, setFileTypeValidation] = useState(false);
  const [imageSizeValidation, setImageSizeValidation] = useState(false)

  useEffect(() => {
    const { selectedProduct } = props;
    setFavicon(selectedProduct?.Favicon);
  }, [props.selectedProduct?.ID]);

  const handleProcessImage = async (file) => {
    setFileTypeValidation(false);
    setImageSizeValidation(false);
    let uploadingImage = file.selectedFile;
    var allowedExtensions = /(\/png|\/jpg|\/jpeg)$/i;
    if (!allowedExtensions.exec(uploadingImage.type)) {
      setFileTypeValidation(true);
      return;
    }
    const fileSieValidation = uploadingImage.size / (1024 * 1024);

    if (fileSieValidation > MAX_FILE_SIZE) {
      setImageSizeValidation(true);
      return;
    }

    setShowImageCropPopup(true);
    const image = await resizeImage(uploadingImage, 500, 500, "JPEG");
    setProcesssedImage(image);
  };

  const resizeImage = async (uploadingImage, width, height, fileFormat) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        uploadingImage,
        width,
        height,
        fileFormat,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  };

  const handleGenerareImage = async () => {
    setIsLoading(true);
    const element = document.getElementById("capture");
    htmlToImage
      .toBlob(element, {
        quality: imageQuality,
        backgroundColor: "#fff",
        width: 500,
        height: 500,
      })
      .then(async function (blob) {
        const img = await uploadImage(blob);
        setFavicon(img);
        props.onSetFavicon(img);
        setShowImageCropPopup(false);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error("OOPS something went wrong !", error);
      });
  };

  const handleDeleteImage = () => {
    setFavicon("");
    props.onSetFavicon("");
    Message.success("Favicon deleted succuessfuly.");
  };

  const uploadImage = async (file) => {
    const fmData = new FormData();
    const timeStamp = Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";
    const fileName = `P_${timeStamp}`;
    const filePath = `/${environmentFolderName}/cms/page/`;

    fmData.append("file", file);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const token = await generateImageUploadServerToken();

      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await Axios.post(
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
        fmData,
        config
      );

      return res.data.Result;
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
    }
  };

  return (
    <div id="image_upload_container">
      {!favicon && (
        <>
          <FileUploader
            multiple={false}
            acceptFiles="image/*"
            onUpload={handleProcessImage}
            maxSize={25}
          />
          {fileTypeValidation && (
            <div className="eco-mt-20">
              <Alert
                showIcon
                type="error"
                message="Image should be JPG, PNG OR JPEG"
                closable
                onClose ={ () =>setFileTypeValidation(false) }
              />
            </div>
          )}
          {imageSizeValidation && (<div className="eco-mt-20">
              <Alert
                showIcon
                type="error"
                message="Image size is greater than 25 MB"
                closable
                onClose ={ () =>setImageSizeValidation(false) }
              />
            </div>)}
        </>
      )}
      {favicon && (
        <div style={{ marginTop: "20px" }}>
          <>
            <Typography type="title" level={4}>
              Manage Uploads
            </Typography>
          </>
          <Card
            shadow="default"
            disabled={false}
            type={"complex"}
            content={
              <Stack
                className="eco-pa-4"
                direction="row"
                horizontalAlign="space_around"
                verticalAlign="v_center"
              >
                <StackItem
                  style={{
                    width: "100px",
                    height: "100px",
                    padding: "8px",
                  }}
                >
                  <img
                    // src={fl ? fl : defaultImage}
                    src={favicon}
                    alt="Product Images"
                    style={{ width: "100%" }}
                  />
                </StackItem>
                <StackItem
                  style={{
                    flexGrow: "1",
                    height: "55px",
                    padding: "10px",
                  }}
                ></StackItem>
                <Stack width="300" horizontalAlign="space_evenly">
                  <StackItem
                    style={{
                      flexGrow: "5",
                      padding: "8px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      width: "100px",
                    }}
                  >
                    <ProgressBar
                      type="circle"
                      value={100}
                      style={{
                        marginRight: "10%",
                        width: "10px",
                      }}
                      width={20}
                    />
                  </StackItem>
                  <StackItem
                    style={{
                      height: "100px",
                      padding: "8px",
                      justifyContent: "flex-end",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      category="link"
                      onClick={() => handleDeleteImage()}
                      customStyles={{ border: "none" }}
                    >
                      <Icon name="trashcan" color="red" />
                    </Button>
                  </StackItem>
                </Stack>
              </Stack>
            }
            width="100%"
            height="fit-content"
          />
        </div>
      )}
      {showImageCropPopup && (
        <Popup
          modalTitle={<h3>Image Upload</h3>}
          visible={showImageCropPopup}
          onCancel={() => setShowImageCropPopup(false)}
          footer={
            <Space>
              {/* {isLoading && <Spin className='eco-mt-20  login-spinner' />} */}

              <Button
                onClick={handleGenerareImage}
                variant="primary"
                size="small"
                disabled={isLoading}
              >
                Done
              </Button>
              <Button
                onClick={() => setShowImageCropPopup(false)}
                variant="secondary"
                className="eco-mr-8"
                size="small"
              >
                Cancel
              </Button>
            </Space>
          }
          style={{ height: "800px", width: "800px" }}
          width={550}
        >
          <div>
            <div
              style={{
                width: "504px",
                height: "504px",
                border: "2px solid #666",
              }}
            >
              {processedImage ? (
                <div
                  id="capture"
                  style={{
                    width: "500px",
                    height: "500px",
                    display: "flex",
                    background: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      verticalAlign: "middle",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "auto",
                    }}
                  >
                    <div>
                      <img src={URL.createObjectURL(processedImage)} />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <hr />
            <div>
              <Row className="form-row">
                <Col md={8}>
                  <div className="form-lbl-wrapper">
                    <Label>Image Quality</Label>
                  </div>
                </Col>
                <Col md={8}>
                  <InputBox
                    type="number"
                    placeholder="Quality"
                    minValue={0.1}
                    maxValue={1}
                    value={imageQuality}
                    onChange={(e) => setImageQuality(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default ImageUpload;
