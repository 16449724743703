import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Popup } from "witmeg-ui-system";
import { Button } from "antd";
import './Layouts/layout.css';
import FontPicker from "font-picker-react";

const RestaurantPopupWrapper = ({ children, isPopup = false, menuDoubleClick }) => {
  const [popupState, setPopupState] = useState({
    visible: false,
    isHover: false,
  });

  const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";

  const { uiCustomizations } = useSelector((state) => state.resturantWidget.menuData);

  const changePopupVisibility = (_value) => {
    setPopupState(prevState => ({ ...prevState, visible: _value, }));
  }

  const changeMouseHoverState = (_value) => {
    setPopupState(prevState => ({ ...prevState, isHover: _value, }));
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <FontPicker apiKey={API_KEY} limit={100} pickerId="all" />
      </div>
      {
        isPopup ? 
          (
            <div style={{ height: '100%' }} onDoubleClick={menuDoubleClick}>
              <Button
                type="primary"
                onClick={() => changePopupVisibility(true)}
                style={{
                  ...uiCustomizations?.popup?.buttonStyle,
                  background: 
                    (popupState.isHover && uiCustomizations?.popup?.hoverBackgroundColor) ?
                      uiCustomizations?.popup?.hoverBackgroundColor :
                      uiCustomizations?.popup?.buttonStyle?.background ?? ''
                }}
                onMouseEnter={() => changeMouseHoverState(true)}
                onMouseLeave={() => changeMouseHoverState(false)}>
                <div style={{ fontFamily: uiCustomizations?.popup?.buttonStyle?.fontFamily }}>
                  <span style={{ fontSize: uiCustomizations?.popup?.buttonStyle?.fontSize, color: uiCustomizations?.popup?.buttonStyle?.color }}>
                    { uiCustomizations?.popup?.settings?.name }
                  </span>
                </div>
              </Button>
              <Popup
                width={1200}
                className="menu-popup"
                modalVisible={popupState.visible}
                onCancel={() => changePopupVisibility(false)}>
                {/* <div className="one-col-layout eco-theme-dashbord" style={ uiCustomizations?.layout?.settings?.theme === 'removeTheme' ? uiCustomizations?.layout?.default : uiCustomizations?.layout?.custom }> */}
                <div className="one-col-layout eco-theme-dashbord" style={ uiCustomizations?.layout?.custom }>
                  { children }
                </div>
              </Popup>
            </div>
          ) :
          (
            <div
              onDoubleClick={menuDoubleClick}
              className="one-col-layout eco-theme-dashbord"
              style={ uiCustomizations?.layout?.settings?.theme === 'removeTheme' ? uiCustomizations.layout?.default: uiCustomizations.layout?.custom }>
              { children }
            </div>
          )
      }
    </>
  );
}

export default RestaurantPopupWrapper;