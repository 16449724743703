import React, { useState, useEffect } from "react";

import {
  async,
  Button,
  Card,
  Col,
  GlobalHeader,
  GlobalLayout,
  Icon,
  Message,
  Popup,
  RightPanel,
  Row,
  Space,
  Spin,
  Stack,
  StackItem,
  Typography,
} from "witmeg-ui-system";
import { Modal, Popconfirm, Tabs } from "antd";
import { QuestionCircleOutlined, CloseSquareFilled } from "@ant-design/icons";
import * as _ from "lodash";
import "../../Home/Home.css";
import { WidthProvider, Responsive } from "react-grid-layout";
// import GridLayout from "react-grid-layout";
import ReportWidject from "../../ReportWiidjet/index";
import {
  deleteHomeLayout,
  getHomeLayoutByID,
  updateHomeLayout,
} from "../../utilities/apiRequests/witmegRetailPaxServerRequests";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { setCommonState, setResizeWidth } from "../../../redux/commonReducer";
import { setGridBackground } from "../../utilities/helpers";
import NavigationLinksWidgets from "../../ReportWiidjet/NavigationLinksWidgets";

const ResponsiveReactGridLayout = React.memo(WidthProvider(Responsive));
class DesktopView extends React.Component {
  static defaultProps = {
    className: "layout",

    cols: { lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 },
    breakpoints: { lg: 1024, md: 768, sm: 768, xs: 480, xxs: 0 },
    widgetHeight: "",
    rowHeight: 30,
    // isResizable: true,
    // This turns off compaction so you can place items wherever.
    // This turns off rearrangement so items will not be pushed arround.
    // preventCollision: false,
    verticalCompact: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      deleteConfirmation: false,
      homelayoutData: null,
    };
  }

  //THIS WILL GET THE DATA ONCE THE RESIZE IS STOPPED
  onResizeStop = async (layout, newItem, layoutItem) => {
    this.setState({ widgetHeight: newItem.h });

    console.log("onDragStop", this.props.pageID);
    this.setState({ layoutTemp: layout });
    this.setState({ siteLoading: true });
    const x = _.split(layoutItem.i, ",");

    const data = await getHomeLayoutByID({
      ID: x[0],
      PageID: this.props.pageID,
      CompanyID: sessionStorage.getItem("company"),
      Start: 0,
      Limit: 1000,

    });
    const pageData = {
      ID: data[0]?.ID,
      ItemHeading: data[0]?.ItemHeading,
      Item: x[1],
      Width: layoutItem.w,
      Height: layoutItem.h,
      X: layoutItem.x,
      Y: layoutItem.y,
      Type: x[1],
      ItemData: data[0]?.ItemData,
      RatioW: "",
      RatioH: "",
      Link: "",
      PageID: this.props.pageID,
      CompanyID: sessionStorage.getItem("company"),
    };
    await updateHomeLayout(pageData);
  };

  onResize = (layout, oldItem, element, newItem) => {
    let SingleIconID = newItem?.i.split(",")[0];
    console.log("Resizing Func Desktop View", SingleIconID);
    this.setState({
      IconID: SingleIconID,
      widgetHeight: newItem.h,
      widgetWidth: newItem.w,
    });
  };

  //this will only executes when the drag is over
  onDragStop = async (layout, newItem, layoutItem) => {
    console.log("onDragStop", layout, layoutItem);
    this.setState({ layoutTemp: layout });
    this.setState({ siteLoading: true });

    const x = _.split(layoutItem.i, ",");
    const data = await getHomeLayoutByID({
      ID: x[0],
      PageID: this.props.pageID,
      CompanyID: sessionStorage.getItem("company"),
      Start: 0,
      Limit: 1000,

    });
    const pageData = {
      ID: data[0]?.ID,
      ItemHeading: data[0]?.ItemHeading,
      Item: x[1],
      Width: layoutItem.w,
      Height: layoutItem.h,
      X: layoutItem.x,
      Y: layoutItem.y,
      Type: x[1],
      ItemData: data[0]?.ItemData,
      RatioW: "",
      RatioH: "",
      Link: "",
      PageID: data[0]?.PageID,
      CompanyID: sessionStorage.getItem("company"),
    };
    await updateHomeLayout(pageData);
  };

  deleteHomeLayout = async (item) => {
    if (this.props.showPreviewButton == false) {
      this.props.saveCommonData({
        draggableState: true,
      });

      console.log(this.state.homelayoutData);
      try {
        await deleteHomeLayout({ ID: this.state.homelayoutData.i[0] }).then(async (response) => {
          if (response === true) {


            Message.success("Widget deleted successfully.");
            this.setState({ deleteConfirmation: false });
            await this.props.handleGetHomeLayout();
          }
        })



      } catch (error) {
        console.log(error);
      }
    }
  };

  //THIS WILL GENERATE THE CANVAS
  generateDOM(el) {
    const removeStyle = {
      position: "absolute",
      right: "0.5px",
      top: 0,
      cursor: "pointer",
      zIndex: "999",
      background: "white",
      // height: '28px'
    };
    const eXtraProps = {}; //{isDraggable :!this.props.showPreviewButton, isResizable : !this.props.showPreviewButton}
    const i = el.i;
    console.log(el);

    return (
      

        <div
          key={i}
          data-grid={{ ...el, ...eXtraProps }}
          onClick={this.props.onBoxClick.bind(this, el)}
          onMouseEnter={() => this.props.mouseEnter(i)}
          onMouseLeave={this.props.mouseLeave}
          style={{ overflow: "hidden" }}
        >
          <>
            <span
              className={this.props.showPreviewButton === false
                ? this.props.hover === i
                  ? "disBlock remove"
                  : "disNone remove"
                : ""}
              style={removeStyle}
            >
              {this.props.showPreviewButton === false && (
                <div
                  onClick={() => {
                    this.setState({ homelayoutData: el });
                    this.setState({ deleteConfirmation: true });
                  }}
                  style={{ backgroundColor: "transparent" }}
                >
                  <CloseSquareFilled
                    style={{ color: "#FF0000", fontSize: "24px" }} />
                </div>
              )}

              <Popup
                modalTitle="    "
                modalVisible={this.state.deleteConfirmation}
                onCancel={() => this.setState({ deleteConfirmation: false })}
                centered
                // mask={false}
                maskClosable={false}
                className="widget-delete-confirmation-popup"
              >
                <div>
                  <Stack style={{ marginBottom: "62px" }} verticalAlign="v_top">
                    <Icon
                      name="cancel"
                      type="color"
                      className="eco-mr-16"
                      size={32} />
                    <Stack direction="column" verticalAlign="v_top">
                      <Typography
                        type="title"
                        level={5}
                        pattern="bold"
                        className="eco-mb-16"
                      >
                        You're about to delete this Widget!
                      </Typography>
                      <Typography type="text">
                        Do you really want to delete this item? This Process
                        cannot be undone.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack horizontalAlign="h_end">
                    <Space size="middle">
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          this.state.deleteConfirmation == true &&
                            this.deleteHomeLayout();
                        }}
                      >
                        Confirm
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => {
                          this.setState({ deleteConfirmation: false });
                          this.props.saveCommonData({
                            draggableState: false,
                          });
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Stack>
                </div>
              </Popup>

              <Popconfirm
                title="Are you sure you want to delete this item ?"
                icon={<QuestionCircleOutlined />}
                onConfirm={this.deleteHomeLayout}
              >
                {/* /*delete icon of widgets*/}
              </Popconfirm>
            </span>

            {this.props.showPreviewButton === false && (
              <span
                className={this.props.showPreviewButton === false
                  ? this.props.hover === i
                    ? "disBlock  remove rowremove"
                    : "disNone remove rowremove"
                  : ""}
                style={{ textTransform: "capitalize" }}
              >
                {/* {this.props.showPreviewButton === false && <div>{el.i[1]}</div>} */}
              </span>
            )}
            <ReportWidject
              params={{
                PreviewButton: this.props.showPreviewButton,
                layoutid: el.i,
                pageID: this.props?.pageID,
                LayoutID: this?.props?.layoutID,
                w: el.x,
                // h: el.y,
                widjetName: el.component ? el.component : el.i[1],
                widgetHeight: this.state.widgetHeight,
                widgetWidth: this.state.widgetWidth,
                IconID: this.state.IconID,
              }} />
          </>
        </div> 
    );
  }

  render() {
    console.log(this.props.themeSettings)
    return (
      <div className="PreviewLayout layout-desktop">
        <ResponsiveReactGridLayout
          onDrop={this.props.onDrop}
          onResizeStop={this.onResizeStop}
          breakpoints={this.props.breakpoints}
          onResize={this.onResize}
          className="layout-desktop"
         
          isDroppable={
            this.props.showPreviewButton || this?.props?.dragState
              ? false
              : true
          }
          isDraggable={
            this.props.showPreviewButton || this?.props?.dragState
              ? false
              : true
          }

          isResizable={this.props.showPreviewButton || this?.props?.dragState
            ? false
            : true}

          // droppingItem={this.props.item}
          // measureBeforeMount={true}
          useCSSTransforms={false}
          onBreakpointChange={this.props.onBreakpointChange}
          onDragStop={this.onDragStop}
          layouts={this.props.layouts}
          // onDropDragOver={e => ({ w: 2, h: 4 })} // need to get exact size of the place holder
          
          onLayoutChange={this.onLayoutChange}
          // isBounded={true}
          margin={[0, 0]}
          {...this.props}
          preventCollision={false}
          compactType={null}
          allowOverlap={true}
          style={{...setGridBackground(this.props.themeSettings), maxHeight: "250px !important" }}
        >
          {/* Hard Coded Navigation bar */}
          <div key="nav" draggable="false"     data-grid={{ x: 0, y: 0, w: 24, h: 3 }}>
             <NavigationLinksWidgets />
          </div>
          {_.map(this.props.items, (el) => this.generateDOM(el))}
        </ResponsiveReactGridLayout>
      </div >
    );
  }
}

//GET FROM REDUX STATE
const mapStateToProps = (state) => {
  const { commonData } = state;
  return {
    resizeWidth: commonData.resizeWidth,
    dragState: commonData.dragState,
  };
};

//SET TO REDUX STATE
const mapDispatchToProps = (dispatch) => ({
  saveResizeWidth: (data) => dispatch(setResizeWidth(data)),
  saveCommonData: (data) => dispatch(setCommonState(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DesktopView));
