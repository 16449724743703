import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Label, InputBox, Slider, Switch, Button, Typography, Popover, Stack, Space, ColorPicker, Alert } from 'witmeg-ui-system';
import { spaceValidation } from '../../../utilities/helpers';


function SpaceingSettings({ paddingValidation,
    setPaddingValidation, setPaddingValidations, paddingValidations,
    errorMsg, setSpaceField, spaceFormData, setSpaceDataForm }) {

    const [sliderValue, setSliderValue] = useState(0)

    const onValueChange = (newValue) => {
         
        setSpaceField("top", newValue + "px")
        setSpaceField("left", newValue + "px")
        setSpaceField("right", newValue + "px")
        setSpaceField("bottom", newValue + "px")

        setSliderValue(newValue)
        setPaddingValidations(false)
        setPaddingValidation(false)
    }


    const handleReset = () => {

        console.log("spaceFomrData", spaceFormData)
        setSliderValue(0)
        setSpaceDataForm({
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px"
        })


    }

    //Top Padding
    const handleTop = (data) => {
        //Helper Function to check CSS unit Validation
        const isValid = spaceValidation(data)
        if (isValid === false && data != "") {
            setPaddingValidation(true)
        } else {
            if (data.endsWith("px") && data != "" || data.endsWith("em") && data != "" || data.endsWith("rem") && data != "" || data.endsWith("vw") && data != "" || data.endsWith("vh") && data != "" || data.endsWith("%") && data != "" || data.endsWith("pt") && data != "") {
                setSpaceField("top", data)
                setPaddingValidations(false)
            } else {
                setPaddingValidations(true)
            }
            setPaddingValidation(false)
        }

    }

    //Bottom Padding
    const handleBottom = (data) => {
        //Helper Function to check CSS unit Validation
        const isValid = spaceValidation(data)
        if (isValid === false && data != "") {
            setPaddingValidation(true)
        } else {
            if (data.endsWith("px") && data != "" || data.endsWith("em") && data != "" || data.endsWith("rem") && data != "" || data.endsWith("vw") && data != "" || data.endsWith("vh") && data != "" || data.endsWith("%") && data != "" || data.endsWith("pt") && data != "") {
                setSpaceField("bottom", data)
                setPaddingValidations(false)

            } else {
                setPaddingValidations(true)
            }
            setPaddingValidation(false)
        }

    }
    console.log("spaceFomrData", spaceFormData)

    //Left Padding
    const handleLeft = (data) => {
        //Helper Function to check CSS unit Validation
        const isValid = spaceValidation(data)
        if (isValid === false && data != "") {
            setPaddingValidation(true)
        } else {
            if (data.endsWith("px") && data != "" || data.endsWith("em") && data != "" || data.endsWith("rem") && data != "" || data.endsWith("vw") && data != "" || data.endsWith("vh") && data != "" || data.endsWith("%") && data != "" || data.endsWith("pt") && data != "") {
                setSpaceField("left", data)
                setPaddingValidations(false)
            } else {
                setPaddingValidations(true)
            }
            setPaddingValidation(false)
        }

    }

    //Right Padding
    const handleRight = (data) => {
        //Helper Function to check CSS unit Validation
        const isValid = spaceValidation(data)
        if (isValid === false && data != "") {
            setPaddingValidation(true)
        } else {
            if (data.endsWith("px") && data != "" || data.endsWith("em") && data != "" || data.endsWith("rem") && data != "" || data.endsWith("vw") && data != "" || data.endsWith("vh") && data != "" || data.endsWith("%") && data != "" || data.endsWith("pt") && data != "") {
                setSpaceField("right", data)
                setPaddingValidations(false)
            } else {
                setPaddingValidations(true)
            }
            setPaddingValidation(false)
        }

    }

    return (
        <div style={{ width: '100%' }}>
            <Row gutter={[36, 36]} justify="center">
                <Col span={24}>
                    <Typography type="title" pattern="bold" level={5} color="gray_darker">Padding</Typography>
                </Col>
                <Col span={18}>
                    {paddingValidation && <Alert className="eco-mb-8" message="Error" type="error" showIcon />}
                    {errorMsg && <Alert className="eco-mb-8" message="Padding Error" type="error" showIcon />}
                    <Stack direction="column" style={{ border: '1px solid #cecece', padding: '20px' }}>
                        <Stack>
                            <div style={{ backgroundColor: '#e7eff2', flexGrow: '4', padding: '20px', margin: '20px', width: '300px' }}>
                                <Stack direction="column">

                                    <div style={{ width: '57px' }}><InputBox maxLength="5" onChange={(e) => handleTop(e.target.value)} type="text" value={spaceFormData?.top} /></div>
                                    <Stack horizontalAlign="space_between" width={250}>
                                        <div style={{ width: '57px' }}><InputBox maxLength="5" onChange={(e) => handleLeft(e.target.value)} type="text" value={spaceFormData?.left} /></div>
                                        <div style={{ width: '57px' }}><InputBox maxLength="5" type="text" onChange={(e) => handleRight(e.target.value)} value={spaceFormData?.right} /></div>
                                    </Stack>
                                    <div style={{ width: '57px' }}><InputBox maxLength="5" type="text" onChange={(e) => handleBottom(e.target.value)} value={spaceFormData?.bottom} /></div>
                                </Stack>
                            </div>
                        </Stack>
                    </Stack>
                </Col>
                <Col span={10}>
                    <Slider value={sliderValue} onChange={(newValue) => onValueChange(newValue)} />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button onClick={() => handleReset()} variant="link">Reset padding</Button>
                </Col>
            </Row>

        </div>
    )
}



export default SpaceingSettings