import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import {
  Alert,
  Button,
  Col,
  InputBox,
  Popup,
  Row,
  Space,
  Typography,
} from "witmeg-ui-system";

console.log("map popup");
const MAP_ZOOM_LEVEL = 13;
function MapPopUp({
  autocomplete,
  setAutocomplete,
  mapValidation,
  isOpen,
  onOk,
  onCancel,
  mapData,
  APIKEY,
  confirmLoading,
  setMapValidation,
  ...props
}) {
  const searchRef = useRef();

  const defaultLat = mapData ? mapData.lat : 6.9361;
  const defaultLng = mapData ? mapData.lng : 79.845;

  const [markerPosition, setMarkerPosition] = useState({
    lat: defaultLat,
    lng: defaultLng,
  });

  const [mapZoom, setMapZoom] = useState(MAP_ZOOM_LEVEL);

  const handleOnPlacesChange = () => {
    if (autocomplete) {
      const geometry = autocomplete.getPlace().geometry;
      if (geometry) {
        setMapValidation(false);
        const { lat, lng } = geometry.location;
        setMarkerPosition({ lat: lat(), lng: lng() });
        setMapZoom(MAP_ZOOM_LEVEL);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const onClickMap = (e) => {
    const { lat, lng } = e.latLng;
    setMarkerPosition({ lat: lat(), lng: lng() });
    setMapZoom(MAP_ZOOM_LEVEL);
  };

  const onMarkerDragEnd = (e) => {
    const { lat, lng } = e.latLng;
    setMarkerPosition({ lat: lat(), lng: lng() });
  };

  return (
    <Modal
      title="Map"
      visible={isOpen}
      // onOk={() => onOk(markerPosition)}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      footer={
        <Space>
          <Button
            variant="primary"
            size="small"
            onClick={() => onOk(markerPosition)}
          >
            Confirm
          </Button>
          <Button
            variant="secondary"
            className="eco-mr-8"
            size="small"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>
        </Space>
      }
    >
  
  

    
         
 
        <div>
          {mapValidation && <Alert className = "eco-mb-12" showIcon type = "error" message = "Business address is missing" />}
          
          <Row >
            <Col span="12">
              <Typography>Enter your business address</Typography>
            </Col>
            <Col span="12">
              <Autocomplete
                onLoad={(data) => {
                  setAutocomplete(data);
                }}
                status = {mapValidation === true && "error"}
                onPlaceChanged={handleOnPlacesChange}
              >
                <input
                  ref={searchRef}
                  type="text"
                  placeholder="Enter an address"
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    padding: `0 12px`,
                    borderRadius: `4px`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: "50%",
                    marginLeft: "-120px",
                    background:"#F4F4F4"
                  }}
                />
              </Autocomplete>
            </Col>
          </Row>
        
        <Row style={{ height: "50vh", width: "100%", marginTop: "30px" }}>
          <GoogleMap
            id="map-with-address-search"
            mapContainerStyle={{ height: "50vh", width: "100%" }}
            zoom={mapZoom}
            center={markerPosition}
            onClick={onClickMap}
          >
            <Marker
              onLoad={() => {}}
              position={markerPosition}
              onDragEnd={onMarkerDragEnd}
              draggable
            />
          </GoogleMap>
        </Row>
      </div>
     
    </Modal>
  );
}

MapPopUp.propTypes = {};

export default React.memo(MapPopUp);
