import React from "react";
import { GlobalHeader, GlobalLayout } from "witmeg-ui-system";
import GlobalLeftMenu from "../GlobalLeftMenu/GlobalLeftMenu";
import SettingsOverlay from "../Settings/SettingsOverlay";
import EcommerceOverlay from "../EcommerceOverlay/App";
import {
  getHomeLayoutByCOMID,
  getAllWidgets,
  getCompanyByID,
  addHomeLayout,
  updateHomeLayout,
  getHomeLayoutByPageID,
  getHomeLayoutByID,
  getAllPages,
  getSettingsByCompanyID,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import * as _ from "lodash";
import { logoutUser } from "../utilities/userAuthentication";
import { routePaths } from "../../routes/routeConstants";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";
import { withRouter } from "react-router";
import GlobalHeaderPanel from "./GlobalHeaderPanel";
import MobileView from "../Responsive/MobileView";
import TabView from "../Responsive/TabView";
import { connect } from "react-redux";
import { setOrganisation } from "../../redux/organiserReducer";
import { setWidgetData } from "../../redux/widgetReducer";
import { hot } from "react-hot-loader/root";
import {
  setCommonState,
  setDesktopState,
  setMobileState,
  setTabletState,
} from "../../redux/commonReducer";
import DesktopView from "../Responsive/DesktopView/DesktopView";
import { setComponentSize } from "../utilities/helpers";
import { setCompanyData } from "../../redux/companyDataReducer";
import Pages from "../PagesOverlay/Pages";
import RestaurantSettingsOverlay from "../RestaurantSettingsOverlay/RestaurantSettingsOverlay";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorItems: [],
      currentBreakpoint: "lg",
      compactType: "",
      mounted: false,
      layouts: this.generateLayout.bind(),
      newCounter: 0,
      activeKey: 2,
      styleClass: "parentgrid",
      splitPosition: 0.6 * window.innerHeight,
      selectedComponent: "",
      filters: true,
      fullscreen: false,
      refresh: false,
      autorefresh: false,
      visible: false,
      value: "",
      layoutTemp: [],
      activeWidgetName: "table",
      menuState: true,
      viewRightPanel: false,
      items: [],
      showHide: "",
      hover: null,
      plusText: "+",
      widgetsData: [],
      widgetsIsLoading: false,
      pageID: null,
      layoutID: null,
      eComWidgets: [],
      restaurantWidgets: [],
      widgetoverlay: false,
      companyData: null,
      showPreviewButton: false,
      filteredWidgetID: null,
      siteLoading: false,
      deletePopConfirmation: false,
      settingsOverlay: false,
      restaurantSettingsOverlay: false,
      setMobileView: false,
      setTabView: false,
      setDesktopView: true,
      reduxOrgnisation: null,
      reduxWidgetID: null,
      reduxWidgetItem: null,
      reduxWidgetType: null,
      reduxWidgetData: null,
      reduxDragState: false,
      deleteConfirmation: false,
      homelayoutData: null,
      deviceLoading: false,
      homelayoutLoading: false,
      themeSettings: {},
    };
  }

  //Layout changes detect
  onLayoutChange = async (layout, layoutItem) => {
    if (!layoutItem) return;
    // this.setState({ layoutTemp: layout });
    this.setState({ siteLoading: true });

    const pageID = await this.state.pageID;
    const x = _.split(layoutItem.i, ",");
    const getWidgetData = {
      ID: x[0],
      PageID: pageID,
      CompanyID: sessionStorage.getItem("company"),
      Start: 0,
      Limit: 1000,
      HOME: "LINE 168",
    };
    const data = await getHomeLayoutByID(getWidgetData);
    let deviceState = sessionStorage.getItem("deviceState");
    let device = deviceState.charAt(0).toUpperCase() + deviceState.slice(1);
    const pageData = {
      layouts: [{
        ID: data[0]?.ID,
        ItemHeading: data[0]?.ItemHeading,
        Item: x[1],
        Width: layoutItem.w,
        Height: layoutItem.h,
        X: layoutItem.x,
        Y: layoutItem.y,
        Type: x[1],
        ItemData: data[0]?.ItemData,
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: data[0]?.PageID,
        CompanyID: sessionStorage.getItem("company"),
        DeviceType: device
      }]
    };
    await updateHomeLayout(pageData);
  };

  onBoxClick = (el) => {
    // console.log(el);
    this.setState({ activeKey: 1 }); // <- set key sent by the handler to the state
  };

  handleGetHomeLayout = () => {
    let deviceState = sessionStorage.getItem("deviceState");
    let device = deviceState.charAt(0).toUpperCase() + deviceState.slice(1);
    //Check if Page ID exists
    if (this.state.pageID) {
      this.setState({ siteLoading: true });
      let pageID = this.state.pageID;
      getHomeLayoutByPageID({
        ID: "",
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      }).then((response) => {
        this.setState({ deviceLoading: true });
        if (response[device]) {

          this.setState({ homelayoutLoading: true });
          this.setState({
            items: response[device].map(function (i, key, list) {
              return {
                i: [i.ID, i.Item, i.ItemHeading, i.ItemData, i.Type],
                x: i.X,
                y: parseInt(i.Y),
                w: i.Width,
                h: i.Height,
                link: i.Link,
              };
            }),
          });

          this.setState({ homelayoutLoading: false });
          this.setState({ siteLoading: false });
          this.setState({ deviceLoading: false });
        } else {
          this.setState({ items: [] });
        }
      });
    } else {
      this.setState({ siteLoading: true });
      getHomeLayoutByCOMID({
        ID: "",
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      }).then((response) => {
        this.setState({ deviceLoading: true });



        if (response[device]) {

          this.setState({ homelayoutLoading: true });
          this.setState({
            items: response[device]
              .filter((widget) => widget.PageID === "000000000000000000000000")
              .map(function (i, key, list) {
                return {
                  i: [i.ID, i.Item, i.ItemHeading, i.ItemData, i.Type],
                  x: i.X,
                  y: parseInt(i.Y),
                  w: i.Width,
                  h: i.Height,
                  link: i.Link,
                };
              }),
          });
          this.props.saveCommonData({
            draggableState: false
          });
          this.setState({ homelayoutLoading: false });
          this.setState({ siteLoading: false });
          this.setState({ deviceLoading: false });
        } else {
          this.setState({ items: [] });
          this.props.saveCommonData({
            draggableState: false
          });
        }
      });
    }
  };

  //Component Did Mount
  async componentDidMount() {
    this.setState({
      widgetsIsLoading: true,
    });
    const id = this.props.location.pathname;

    sessionStorage.setItem("deviceState", "desktop");
    var idx = id.split("/")[2];
    if (idx) {
      const response = await getAllPages({
        ID: "",
        CompanyID: sessionStorage.getItem("company"),
      });
      let pageID = response.filter((w) => w.DraftSlug === idx);
      this.setState({ pageID: pageID[0]?.ID });
    }

    this.handleGetHomeLayout();
    // GET ALL WIDGETS
    const response = await getAllWidgets({
      ID: "",
      Item: "",
      Start: 0,
      Limit: 100,
    });
    this.setState({ widgetsIsLoading: false });

    console.log(response, "CHANGES");

    let widgets = response.filter((x) => x.Type === "Widgets");
    let eComWidgets = response.filter((x) => x.Type === "E-Commerce");
    let restaurantWidgets = response.filter((x) => x.Type === "Restaurant");
    this.setState({ widgetsData: widgets, eComWidgets: eComWidgets, restaurantWidgets: restaurantWidgets });

    // GET COMPANY BY ID
    const responseData = await getCompanyByID({
      ID: sessionStorage.getItem("company"),
      OrganisationID: "",
      SubDomain: "",
      CustomerDomain: "",
      Start: 0,
      Limit: 100,
      IsSearchApplicable: false,
      Search: {
        Key: "",
        Value: "",
      },
    });

    this.setState({ companyData: responseData[0] });

    this.props.saveCompanyData({
      companyData: responseData,
    });
    console.log(responseData[0].IsPublished)

    if (responseData[0].IsPublished === true) {
      this.props.saveCommonData({
        publishState: true
      })
    } else {
      this.props.saveCommonData({
        publishState: false
      })
    }


    sessionStorage.setItem("ORGID", responseData[0].OrganisationID);
    let ORGID = responseData[0].OrganisationID;

    this.props.saveOrganisationID({
      OrganisationID: ORGID,
    });
    this.fetchThemeSettings();

  }



  generateLayout() {
    const p = this.props;
    return _.map(new Array(p.items), function (item, i) {
      const minW = _.random(1, 6),
        minH = _.random(1, 6);
      const maxW = _.random(minW, 6),
        maxH = _.random(minH, 6);
      const w = _.random(minW, maxW);
      const y = _.random(minH, maxH);
      return {
        x: (i * 2) % 12,
        y: Math.floor(i / 6) * y,
        w,
        h: y,
        i: i.toString(),
        minW,
        maxW,
        minH,
        maxH,
      };
    });
  }

  toggleMenu = () => {
    this.setState({ menuState: !this.state.menuState });
  };

  mouseEnter = () => {
    this.setState({
      plusText: "+ Add  Section",
    });
  };
  mouseLeaveAdd = () => {
    this.setState({
      plusText: "+",
    });
  };

  // RIGHT PANAL
  showRightPanel() {
    this.setState({ viewRightPanel: true });
  }
  closeRightPanel = () => {
    this.setState({ viewRightPanel: false });
  };

  // Widget Overlay
  showWidgetOverlay = () => {
    this.setState({
      widgetoverlay: true,
      settingsOverlay: false,
      restaurantSettingsOverlay: false,
      pagesOverlay: false,
      eCommerceOverlay: false,
    });
  };

  closeWidgetOverlay = () => {
    this.setState({ widgetoverlay: false });
  };

  //E-Commerce Widget Overlay
  showEcommerceOverlay = () => {
    this.setState({
      eCommerceOverlay: true,
      settingsOverlay: false,
      restaurantSettingsOverlay: false,
      widgetoverlay: false,
      pagesOverlay: false,
    });
  };

  closeEcommerceOverlay = () => {
    this.setState({ eCommerceOverlay: false });
  };

  //Pages overlay

  showPagesOverlay = () => {
    this.setState({
      pagesOverlay: true,
      restaurantSettingsOverlay: false,
      settingsOverlay: false,
      eCommerceOverlay: false,
      widgetoverlay: false,
    });
  };

  hidePagesOverlay = () => {
    this.setState({ pagesOverlay: false });
  };

  //Settings overlay

  showSettingsOverlay = () => {
    this.setState({
      settingsOverlay: true,
      restaurantSettingsOverlay: false,
      pagesOverlay: false,
      eCommerceOverlay: false,
      widgetoverlay: false,
    });
  };

  hideSettingsOverlay = () => {
    this.setState({ settingsOverlay: false });
  };

  showRestaurantSettingsOverlay = () => {
    this.setState({
      restaurantSettingsOverlay: true,
      settingsOverlay: false,
      pagesOverlay: false,
      eCommerceOverlay: false,
      widgetoverlay: false,
    });
  };

  hideRestaurantSettingsOverlay = () => {
    this.setState({ restaurantSettingsOverlay: false });
  };

  //onDragStart
  onDragStartWidget = (ev, item) => {
    // console.log("ev ===<<<<>>>", item);

    this.setState({ components: item.Item });
    this.props.saveWidgetID({
      WidgetID: item.ID,
      WidgetItem: item.Item,
      WidgetType: item.Type,
      WidgetData: item.ItemDetails,
    });
  };

  onRemoveItem = async (item) => {
    // console.log("ITEM====>", item);
    this.setState({ deleteConfirmation: true });

    this.setState({ homelayoutData: item });
  };

  handleCancelWidget = async () => {
    this.setState({ deleteConfirmation: false });
  };

  // COMPONENT DID UPDATE
  componentDidUpdate(prevProps, prevState) {
    // Orgnisation ID
    if (
      prevProps.OrganisationID !== this.props.OrganisationID &&
      this.props.OrganisationID !== null
    ) {
      // Now fetch the new data here.
      this.setState({ reduxOrgnisation: this.props.OrganisationID });
    }

    // Widget Data from redux state
    if (
      prevProps.WidgetData !== this.props.WidgetData &&
      this.props.WidgetData !== null
    ) {
      this.setState({ reduxWidgetData: this.props.WidgetData });
    }
    // Widget ID from redux state
    if (
      prevProps.WidgetID !== this.props.WidgetID &&
      this.props.WidgetID !== null
    ) {
      this.setState({ reduxWidgetID: this.props.WidgetID });
    }

    // Widget Ite, from redux state
    if (
      prevProps.WidgetItem !== this.props.WidgetItem &&
      this.props.WidgetItem !== null
    ) {
      this.setState({ reduxWidgetItem: this.props.WidgetItem });
    }
    // Widget Type from redux state
    if (
      prevProps.WidgetTpye !== this.props.WidgetTpye &&
      this.props.WidgetType !== null
    ) {
      this.setState({ reduxWidgetType: this.props.WidgetType });
    }
  }

  //Widget Drop Function
  onDrop = async (layout, layoutItem, _event) => {
    console.log("on Drop", this.state.items, layout)
    if (!layoutItem) return;
    const { width, height, minW, maxW, minH, maxH } = setComponentSize(
      this.state.components
    );
    let deviceState = sessionStorage.getItem("deviceState");
    let device = deviceState.charAt(0).toUpperCase() + deviceState.slice(1);

    addHomeLayout({
      layouts: [{
        ItemHeading: "",
        Item: this.state.components,
        Width: width,
        Height: height,
        X: layoutItem.x,
        Y: layoutItem.y,
        Type: this?.state?.reduxWidgetType,
        ItemData: "",
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: this.state.pageID,
        RenderSource: device,
        CompanyID: sessionStorage.getItem("company"),
        DeviceType: device
        // CompanyID: this.state.reduxOrgnisation
      }]
    }).then((addhomeLayout) => {
      const item = {
        i: [addhomeLayout, this.state.components],
        x: layoutItem.x,
        y: layoutItem.y,
        w: width,
        h: height,
        component: this.state.components,
      }
      this.setState({
        layoutID: addhomeLayout,
        items: [item, ...this.state.items],
        widgetoverlay: false,
        layoutTemp: [item, ...this.state.items]
        //   newCounter: this.state.newCounter + 1,
      });
    });
  };

  //Preivew Button
  handlePreview = () => {
    this.setState({ showPreviewButton: true });
    this.setState({ widgetoverlay: false });
  };

  handleEditor = () => {
    this.setState({ showPreviewButton: false });
  };

  onBreakpointChange = (breakpoint) => {
    this.setState({
      currentBreakpoint: breakpoint,
    });
  };

  showMobileView = async () => {
    this.setState({
      setMobileView: true,
      setTabView: false,
      setDesktopView: false,
      items: [],
    });
    await sessionStorage.setItem("deviceState", "mobile");
    this.handleGetHomeLayout();

    this.props.saveMobileState({
      mobileState: true,
    });
    this.props.saveTabletState({
      tabletState: false,
    });
    this.props.saveDesktopState({
      desktopState: false,
    });
  };

  showTabView = async () => {
    this.setState({
      setMobileView: false,
      setTabView: true,
      setDesktopView: false,
      items: [],
    });
    await sessionStorage.setItem("deviceState", "tablet");
    this.handleGetHomeLayout();

    this.props.saveMobileState({
      mobileState: false,
    });
    this.props.saveTabletState({
      tabletState: true,
    });
    this.props.saveDesktopState({
      desktopState: false,
    });
  };

  showDesktopView = async () => {
    this.setState({
      setMobileView: false,
      setTabView: false,
      setDesktopView: true,
      items: [],
    });

    await sessionStorage.setItem("deviceState", "desktop");
    this.handleGetHomeLayout();

    this.props.saveMobileState({
      mobileState: false,
    });
    this.props.saveTabletState({
      tabletState: false,
    });
    this.props.saveDesktopState({
      desktopState: true,
    });
  };

  fetchThemeSettings = async () => {
    const result = await getSettingsByCompanyID({ CompanyID: sessionStorage.getItem("company") });
    this.setState({ themeSettings: result })
  }
  helpMenuContent = [
    {
      key: 1,
      icon: "light",
      content: "Whatâ€™s new in the product?",
      onClick: () => {
        alert("Guidline 1");
      },
    },
    {
      key: 2,
      icon: "mission",
      content: "How to set targets?",
      onClick: () => {
        alert("Guidline 2");
      },
    },
    {
      key: 3,
      icon: "news",
      content: "New Releases",
      onClick: () => {
        alert("Guidline 3");
      },
    },
    {
      key: 4,
      icon: "settings",
      content: "More about product setting?",
      onClick: () => {
        alert("Guidline 4");
      },
    },
  ];

  profileMenuContent = [
    {
      key: 1,
      icon: "user",
      content: "Profile",
      onClick: () => {
        alert("Profile");
      },
    },
    {
      key: 2,
      icon: "settings",
      content: "Settings",
      onClick: () => {
        alert("Settings");
      },
    },
    {
      key: 3,
      icon: "logout",
      content: "Logout",
      onClick: () => {
        logoutUser({ redirectTo: routePaths.loginRoot });
      },
    },
  ];

  render() {
    return (
      <GlobalLayout>
        {this.state.showPreviewButton === false && (
          <GlobalLeftMenu
            menuState={this.state.menuState}
            showWidgetOverlay={this.showWidgetOverlay}
            showEcommerceOverlay={this.showEcommerceOverlay}
            showPagesOverlay={this.showPagesOverlay}
            showSettingsOverlay={this.showSettingsOverlay}
            showRestaurantSettingsOverlay={this.showRestaurantSettingsOverlay}
          />
        )}

        {/* {this.state.siteLoading && (
          <div className='eco-mt-16 eco-mr-16'>
            <Spin />
          </div>
        )} */}
        <div className="eco-layout__content">
          <GlobalHeader
            type="complex"
            helpMenuContent={this.helpMenuContent}
            profileMenuContent={this.profileMenuContent}
            pageTitleProps={{
              pageTitle: "SHANKAR WHOLE SALE",
              pageSubtitle: "KGC FOODS LTD",
            }}
            hasIcon={false}
            breadcrumbProperties={{
              breadcrumbRoutes: [],
            }}
          />
          <GlobalHeaderPanel
            handlePreview={this.handlePreview}
            showPreviewButton={this.state.showPreviewButton}
            handleEditor={this.handleEditor}
            companyData={this.state.companyData}
            profileMenuContent={this.profileMenuContent}
            showMobileView={this.showMobileView}
            showTabView={this.showTabView}
            showDesktopView={this.showDesktopView}
            setMobileView={this.state.setMobileView}
            setTabView={this.state.setTabView}
            setDesktopView={this.state.setDesktopView}
          />

          {/* <div
            className='product-page-header'
            style={{ paddingBottom: "20px" }}
          ></div> */}

          {/* GRID LAYOUT STARTS */}

          <div className="home-page-content">
            {" "}
            {this.state.widgetoverlay ? (
              <WidgetOverlay
                showWidgetOverlay={this.showWidgetOverlay}
                closeWidgetOverlay={this.closeWidgetOverlay}
                widgetoverlay={this.state.widgetoverlay}
                onDragStartWidget={this.onDragStartWidget}
                widgetsData={this.state.widgetsData}
                eComWidgets={this.state.eComWidgets}
                restaurantWidgets={this.state.restaurantWidgets}
                widgetsIsLoading={this.state.widgetsIsLoading}
                companyData={this.props.companyData}
              />
            ) : (
              ""
            )}
            {this.state.eCommerceOverlay && (
              <EcommerceOverlay
                closeEcommerceOverlay={this.closeEcommerceOverlay}
                eCommerceOverlay={this.state.eCommerceOverlay}
              />
            )}
            {this.state.pagesOverlay && (
              <Pages
                showPagesOverlay={this.showPagesOverlay}
                closePagesOverlay={this.hidePagesOverlay}
              />
            )}
            {this.state.settingsOverlay && (
              <SettingsOverlay
                showSettingsOverlay={this.showSettingsOverlay}
                hideSettingsOverlay={this.hideSettingsOverlay}
                onChangeThemeSettings={this.fetchThemeSettings}
              />
            )}
            {this.state.restaurantSettingsOverlay && (
              <RestaurantSettingsOverlay
                showRestaurantSettingsOverlay={this.showRestaurantSettingsOverlay}
                hideRestaurantSettingsOverlay={this.hideRestaurantSettingsOverlay}
                companyLogo={this.state.companyData.Logo}
              />
            )}
            {/* DESKTOP VIEW */}
            {this.state.setDesktopView == true &&
              sessionStorage.getItem("deviceState") === "desktop" && (
                <DesktopView
                  mouseEnter={this.mouseEnter}
                  handleGetHomeLayout={this.handleGetHomeLayout}
                  dragState={this?.props?.dragState}
                  deviceLoading={this.state.deviceLoading}
                  pageID={this.state?.pageID}
                  deleteConfirmation={this.state.deleteConfirmation}
                  handelCancelWidget={this.handleCancelWidget}
                  setDesktopView={this.state.setMobileView}
                  layoutID={this?.state?.layoutID}
                  onRemoveItem={this.onRemoveItem}
                  showPreviewButton={this.state.showPreviewButton}
                  mouseLeave={this.mouseLeave}
                  hover={this.state.hover}
                  onBoxClick={this.onBoxClick}
                  onLayoutChange={this.onLayoutChange}
                  layouts={this.state.layouts}
                  compactType={this.state.compactType}
                  onBreakpointChange={this.onBreakpointChange}
                  item={this.props.item}
                  items={this.state.items}
                  onDrop={this.onDrop}
                  mounted={this.state.mounted}
                  layoutTemp={this.state.layoutTemp}
                  themeSettings={this.state.themeSettings}
                />
              )}
            {/* MOBILE VIEW */}
            {this.state.setMobileView == true &&
              this.state.setDesktopView == false &&
              sessionStorage.getItem("deviceState") === "mobile" && (
                <MobileView
                  dragState={this?.props?.dragState}
                  mouseEnter={this.mouseEnter}
                  pageID={this.state?.pageID}
                  layoutID={this?.state?.layoutID}
                  onRemoveItem={this.onRemoveItem}
                  showPreviewButton={this.state.showPreviewButton}
                  handleGetHomeLayout={this.handleGetHomeLayout}
                  mouseLeave={this.mouseLeave}
                  hover={this.state.hover}
                  onBoxClick={this.onBoxClick}
                  onLayoutChange={this.onLayoutChange}
                  layouts={this.state.layouts}
                  compactType={this.state.compactType}
                  onBreakpointChange={this.onBreakpointChange}
                  item={this.props.item}
                  items={this.state.items}
                  onDrop={this.onDrop}
                  mounted={this.state.mounted}
                  themeSettings={this.state.themeSettings}
                />
              )}
            {/* TAB VIEW */}
            {this.state.setTabView == true &&
              this.state.setDesktopView == false &&
              sessionStorage.getItem("deviceState") === "tablet" && (
                <TabView
                  dragState={this?.props?.dragState}
                  mouseEnter={this.mouseEnter}
                  pageID={this.state?.pageID}
                  layoutID={this?.state?.layoutID}
                  onRemoveItem={this.onRemoveItem}
                  showPreviewButton={this.state.showPreviewButton}
                  handleGetHomeLayout={this.handleGetHomeLayout}
                  mouseLeave={this.mouseLeave}
                  hover={this.state.hover}
                  onBoxClick={this.onBoxClick}
                  onLayoutChange={this.onLayoutChange}
                  layouts={this.state.layouts}
                  compactType={this.state.compactType}
                  onBreakpointChange={this.onBreakpointChange}
                  item={this.props.item}
                  items={this.state.items}
                  onDrop={this.onDrop}
                  mounted={this.state.mounted}
                  themeSettings={this.state.themeSettings}
                />
              )}
          </div>

          {/* GRID LAYOUT ENDS */}
        </div>
      </GlobalLayout>
    );
  }
}

const mapStateToProps = (state) => {
  // GET FROM REDUX STORE
  const { organisation, widgetData, commonData, panelOverlay, companyData } = state;

  return {
    OrganisationID: organisation.ORGID,
    WidgetData: widgetData.WIDGETDATA,
    WidgetID: widgetData.WIDGETID,
    WidgetItem: widgetData.WIDGETITEM,
    WidgetType: widgetData.WIDGETTYPE,

    dragState: commonData.dragState,
    companyData: companyData.companyData
  };
};

const mapDispatchToProps = (dispatch) => ({
  //SET TO REDUX STATE

  saveOrganisationID: (data) => dispatch(setOrganisation(data)),
  saveWidgetID: (data) => dispatch(setWidgetData(data)),
  saveCommonData: (data) => dispatch(setCommonState(data)),
  saveDesktopState: (data) => dispatch(setDesktopState(data)),

  saveMobileState: (data) => dispatch(setMobileState(data)),
  saveTabletState: (data) => dispatch(setTabletState(data)),
  saveCompanyData: (data) => dispatch(setCompanyData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(hot(Home)));