import React, { useState, useEffect } from 'react'
import { Accordion, Typography, Row, Col, Stack, Label, InputBox, ColorPicker } from 'witmeg-ui-system';
import noPreviewImage from "../../../../assets/img/no-preview.png";
import theme1 from "../../../../images/resturant-menu/resturant-menu-theme-1.jpg";
import theme2 from "../../../../images/resturant-menu/resturant-menu-theme-2.jpg";
import theme3 from "../../../../images/resturant-menu/resturant-menu-theme-3.jpg";
import theme4 from "../../../../images/resturant-menu/resturant-menu-theme-4.jpg"
import "./forms.css"

const StyleForm = ({ selectedTheme,
  setSelectedTheme,
  backgroundColor,
  setBackgroundColor }) => {


  const [themeBackgroundColor, setThemeBackgroundColor] = useState("");

  const [theme, setTheme] = useState("");


  //SELECTED THEME  TO HIGHLIGHT
  function handleSelectedTheme(theme) {
    setTheme(theme)
  }




  return (
    <div style={{ margin: '34px 22px' }}>
      <div className="eco-mb-16">
        <Accordion
          theme='default'
          type='content_only'
          title='Themes'
          collapsed={true}>
          <div style={{ width: '100%' }}>
            <Typography type="text" style={{ marginBottom: '60px' }}>Choose a theme below to use on the Menu Background</Typography>
            <Row gutter={[16, 32]}>
              <Col span={8} onClick={() => { setSelectedTheme("removeTheme"); setTheme("removeTheme") }}>
                <Stack direction="column" className={theme === "removeTheme" ? 'selected-theme' : ""} style={{ borderRadius: '4px', border: '1px solid #ECECF2', padding: '20px' }}>
                  <div style={{
                    width: '100%',
                    height: '90px',
                    backgroundImage: `url(${noPreviewImage})`,
                    backgroundRepeat: 'no-repeat',
                    marginBottom: '22px',
                    borderRadius: '4px',
                    backgroundSize: 'cover'
                  }}>
                  </div>
                  <Typography type="title" level={5} pattern="bold">Remove Theme</Typography>

                </Stack>
              </Col>
              <Col span={8} onClick={() => { setSelectedTheme("themeOne"); handleSelectedTheme("themeOne") }}>
                <Stack direction="column" className={theme === "themeOne" ? 'selected-theme' : ""} style={{ borderRadius: '4px', border: '1px solid #ECECF2', padding: '20px' }}>
                  <div style={{
                    width: '100%',
                    height: '90px',
                    backgroundImage: `url(${theme1})`,
                    backgroundRepeat: 'no-repeat',
                    marginBottom: '22px',
                    borderRadius: '4px',
                    backgroundSize: 'cover'
                  }}>
                  </div>
                  <Typography type="title" level={5} pattern="bold">Theme 1</Typography>

                </Stack>
              </Col>
              <Col span={8} onClick={() => { setSelectedTheme("themeTwo"); handleSelectedTheme("themeTwo") }}>
                <Stack direction="column" className={theme === "themeTwo" ? 'selected-theme' : ""} style={{ borderRadius: '4px', border: '1px solid #ECECF2', padding: '20px' }}>
                  <div style={{
                    width: '100%',
                    height: '90px',
                    backgroundImage: `url(${theme2})`,
                    backgroundRepeat: 'no-repeat',
                    marginBottom: '22px',
                    borderRadius: '4px',
                    backgroundSize: 'cover'
                  }}>
                  </div>
                  <Typography type="title" level={5} pattern="bold">Theme 2</Typography>
                </Stack>
              </Col>
              <Col span={8} onClick={() => { setSelectedTheme("themeThree"); handleSelectedTheme("themeThree") }}>
                <Stack direction="column" className={theme === "themeThree" ? 'selected-theme' : ""} style={{ borderRadius: '4px', border: '1px solid #ECECF2', padding: '20px' }}>
                  <div style={{
                    width: '100%',
                    height: '90px',
                    backgroundImage: `url(${theme3})`,
                    backgroundRepeat: 'no-repeat',
                    marginBottom: '22px',
                    borderRadius: '4px',
                    backgroundSize: 'cover'
                  }}>
                  </div>
                  <Typography type="title" level={5} pattern="bold">Theme 3</Typography>
                </Stack>
              </Col>
              <Col span={8} onClick={() => { setSelectedTheme("themeFour"); handleSelectedTheme("themeFour") }}>
                <Stack direction="column" className={theme === "themeFour" ? 'selected-theme' : ""} style={{ borderRadius: '4px', border: '1px solid #ECECF2', padding: '20px' }}>
                  <div style={{
                    width: '100%',
                    height: '90px',
                    backgroundImage: `url(${theme4})`,
                    backgroundRepeat: 'no-repeat',
                    marginBottom: '22px',
                    borderRadius: '4px',
                    backgroundSize: 'cover'
                  }}>
                  </div>
                  <Typography type="title" level={5} pattern="bold">Theme 4</Typography>
                </Stack>
              </Col>
            </Row>
          </div>
        </Accordion>
      </div>
      <div className="eco-mb-16">
        <Accordion
          theme='default'
          type='content_only'
          title='Background'
          collapsed={true}>
          <div style={{ width: '100%' }}>
            <Row align="middle" className="eco-mb-16" gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Color</Label></div>
              </Col>
              <Col span={8}>
                <InputBox disabled style={{ color: "white !important" }} noBorder backgroundOnly setDefaultColor value={themeBackgroundColor} type="text" />
              </Col>
              <Col span={8}>
                <ColorPicker defaultColor={themeBackgroundColor}
                  setDefaultColor={setThemeBackgroundColor}
                  onChangeComplete={(color) => setBackgroundColor(color.hex)}
                />
              </Col>
            </Row>
            <Row align="middle" className="eco-mb-16" gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Gradient</Label></div>
              </Col>
              <Col span={8}>
                <InputBox type="text" />
              </Col>
              <Col span={8}>
                <ColorPicker />
              </Col>
            </Row>
          </div>
        </Accordion>
      </div>

    </div>

  )
}

export default StyleForm;