import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Stack, Typography } from 'witmeg-ui-system';
import { RESTAURANT_SETTINGS_TYPES, getCurrencyFormat, getRestaurantMenuProductPrice } from '../../../utilities/helpers';
import '../../../utilities/stringOverride';
import IMAGE_PLACEHOLDER from '../../../../assets/img/image-placeholder.png';

/*
  Layout: 6
    Shows menu items in one column without images
*/
const OneColumnCenterLayout = ({ isPopup = false }) => {

    const { menuWidgetData: { currency }, menuData: { selectedMainMenuData, uiCustomizations }, defaultOrderType } = useSelector((state) => state.resturantWidget);
    const { OPT } = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES;
    const orderType = defaultOrderType === OPT.CUSTOMER_CHOICE.name ? OPT.COLLECTION.name : defaultOrderType ?? OPT.COLLECTION.name;

    const currencyFormat = getCurrencyFormat(currency);

    return (
        <div style={{ maxHeight: isPopup ? '65vh' : '90vh', overflowY: 'auto', overflowX: 'hidden', backgroundColor: '#000' }}>
            {selectedMainMenuData && selectedMainMenuData.SubMenu?.length > 0 && selectedMainMenuData.SubMenu.map((data, index) => {
                return (
                    <>
                        {uiCustomizations.subMenu.settings.subMenuEnabled &&
                            <Row align="middle">
                                <Col span={24}>
                                    <Stack horizontalAlign="h_center"><label className="eco-mr-16" style={uiCustomizations.subMenu.style}>{data?.SubMenuName.toCapitalFirst()}</label></Stack>
                                </Col>
                            </Row>}
                        {data.MenuProductData.map((data, index) => {
                            const productPrice = getRestaurantMenuProductPrice(data.ProductData, orderType);
                            const productImage = data?.ProductData?.ImageURL && data?.ProductData?.ImageURL.POS && data?.ProductData?.ImageURL.POS.length > 0 ? data?.ProductData?.ImageURL.POS[0] : IMAGE_PLACEHOLDER;
                            const productDescription = data?.ProductData?.ItemDescription?.Translations?.en

                            return (
                                <Row style={{ padding: "0 40rem" }} >
                                    <Col span={24} style={{ padding: '10px', }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                                                <div style={{ padding: '0.5rem' }}>
                                                    <Typography type="title" level={4} pattern="bold">{data.ProductName.toCapitalFirst()}</Typography>
                                                    <Typography type="title" level={5} style={{ padding: '5px 0' }} ellipsis={{
                                                        rows: 2
                                                    }}>{productDescription}</Typography>
                                                    {/* <Typography type="text" alignment="left" style={{ padding: '5px 0' }}>{item.menuDescription}</Typography> */}
                                                </div>
                                                <img src={productImage} style={{ height: '10rem', width: '10rem', padding: '5px' }} />
                                            </div>
                                            <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                                                <Typography type="title" level={5} pattern="bold">{currencyFormat(productPrice)}</Typography>
                                                <Typography type="title" level={5} pattern="bold">Halal</Typography>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <Col span={24}>
                                        <Stack {...(isPopup ? {} : { direction: 'row' })} horizontalAlign="h_center">
                                            <div style={{marginRight:'10px'}} className='eco-product-label'><label style={uiCustomizations.productItem.name}>{data.ProductName.toCapitalFirst()}</label></div>
                                            <Stack horizontalAlign="h_center">
                                                { data.ProductData?.ServicePriceInfo?.Price !== null && <label style={uiCustomizations.productItem.name}>{currencyFormat(productPrice)}</label> }
                                            </Stack>
                                        </Stack>
                                    </Col>
                                    <Col span={24}>
                                        <div style={{textAlign:'center'}}><label style={uiCustomizations.productItem.description}>{data.ProductData.ItemDescription.Translations.en.toCapitalFirst()}</label></div>
                                    </Col> */}
                                </Row>
                            )
                        })}
                    </>
                )
            })}
        </div>
    )
}

export default OneColumnCenterLayout;