import { Modal } from "antd";
import React, { useState, useEffect } from "react";

import {
  Message,
  Alert,
  Row,
  Col,
  Button,
  Space,
  Spin,
} from "witmeg-ui-system";
import globalValues from "../../configs/globalValues";
import axios from "axios";
import { generateImageUploadServerToken } from "../utilities/tokenGenerators";
import VideoUpload from "./VideoUpload/VideoUpload";
import {
  getHomeLayoutByID,
  updateHomeLayout,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import "./Widget.css";
import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";

import "./Widget.css"
function VideoWidget(props) {
  const [viewOverlay, setViewOverlay] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imageInfomationValues, setImageInfomationValues] = useState([]);
  const [videoValidation, setVideoValidation] = useState(false);
  const [clearVideo, setClearVideo] = useState(null);
  const [videoSizeValidation, setVideoSizeValidation] = useState(false);
  const [videoTypeValidaion, setVideoTypeValidation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false)
  const [output, setOutput] = useState(null)
  const [isloading, setIsloading] = useState(false)


  const count = useSelector((state) => state.commonData.dragState)
  const dispatch = useDispatch()
  const showWidgetEditOverlay = () => {

    //
    if (props.params.PreviewButton == false) {

      //IF THIS IS TRUE THEN YOU CANNOT RESIZE OR DRAG OR DO ANYTHING FOR THE WIDGET
      dispatch(setCommonState({
        draggableState: true
      }))
      setViewOverlay(true)
    }
  };

  const [uploadedImageUrls, setUploadedImageUrls] = useState(null);

  const handleCancel = () => {
    setViewOverlay(false);


    setOutput()
    setVideoTypeValidation()
    setVideoSizeValidation()
    setVideoValidation()
    dispatch(setCommonState({
      draggableState: false
    }))
  };

  const handleOnlineImageList = (data) => {
    console.log(data);
    setImageInfomationValues(data);
  };

  useEffect(async () => {
    console.log(props);
    await fetchData();
  }, []);


  const fetchData = async () => {
     
    let deviceState = sessionStorage.getItem('deviceState')

    let widID = await props.params.widgetID;
    let pageID = await props.params.pageID;

    const getData = await getHomeLayoutByID({
      ID: widID,
      PageID: pageID,
      CompanyID: sessionStorage.getItem("company"),
      Start: 0,
      Limit: 1000,
    })
      .then((res) => {
        console.log("WE HAVE TOUCH DOWN", res[0]);
        if (deviceState === 'desktop') {
          if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

            setData(res[0]);

          }
        } else if (deviceState == 'mobile') {
          if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
            setData(res[0])
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });



  };


  const handleOk = async () => {
    console.log("WE CAME HERE", imageInfomationValues);
     

    if (imageInfomationValues.length == 0) {
      setVideoValidation(true);

    } else {
      await fetchData();

      setConfirmLoading(true);
      let video = await upload(imageInfomationValues);
      imageInfomationValues.length != 0 && setVideoValidation(false);

      let widID = await props.params.widgetID;
      let pageID = await props.params.pageID;
      const updateWidjet = await updateHomeLayout({
        ID: widID,
        ItemHeading: "video",
        Item: "video",
        Width: data?.Width,
        Height: data?.Height,
        X: data?.X,
        Y: data?.Y,
        Type: "video",
        ItemData: video,
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
      }).then(async (response) => {
        console.log(response);
        Message.success("Widget edited successfully");
        dispatch(setCommonState({
          draggableState: false
        }))
        fetchData();
        setViewOverlay(false);
        setConfirmLoading(false);
        dispatch(setCommonState({
          draggableState: false
        }))
        // window.location.reload();
      });
    }

  };

  //FAVICON IMAGE UPLOAD STARTS...

  const upload = async (uploadingVideo) => {
    const token = await generateImageUploadServerToken();

    const fmData = new FormData();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    const timeStamp =
      Math.floor(Math.random() * 10000 + 1) +
      Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";

    const fileName = `P_${timeStamp}`;
    const companyID = sessionStorage.getItem("company");
    const filePath = `/${environmentFolderName}/retailpacx/videos/`;

    fmData.append("file", uploadingVideo.videoUrl);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const res = await axios.post(
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/video/uploadVideo`,
        fmData,
        config
      );

      console.log("server res: ", res);
      return res.data.Result;
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
    }
  };

  //FAVICON IMAGE UPLOAD ENDS

  return (
    <>
      {/* <Row style={{ height: '100%' }}>
        <Col span={24}> */}
      <Row onDoubleClick={showWidgetEditOverlay} style={{ height: '100%' }}>
        <Col span={24}>


          <video className='react-video-player'
            style={{ objectFit: 'fill' }} autoPlay={true} loop={true} muted={true} controls={false} src={data?.ItemData
              ? data?.ItemData
              : "https://cdn.neurolage.com/retailpacx/dev/images/Dssfsfsfsf.qt"} />


        </Col>
      </Row>
      {/* </Col>
      </Row> */}

      <Modal
        title='Video Upload'
        maskClosable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        visible={viewOverlay}
        // confirmLoading={confirmLoading}

        footer={
          <Space>
            <Button
              onClick={handleOk}
              variant="primary"
              color="white"
              customStyles={{ width: "100px" }}
              size='small'
              category={confirmLoading ? "load-text" : "text"}
              text="Confirm"
            >

              Confirm
            </Button>
            <Button
              onClick={handleCancel}
              variant="secondary"
              className="eco-mr-8"
              customStyles={{ width: "100px" }}
              size="small"

            >
              Cancel
            </Button>
          </Space>
        }
      >
        {videoValidation && (
          <Alert message='Video is not uploaded' type='error' showIcon />
        )}
        <div className='eco-mt-12'>
          <VideoUpload
            setUploadedImageUrls={setUploadedImageUrls}
            handleOnlineImageList={handleOnlineImageList}
            setClearVideo={setClearVideo}
            videoSizeValidation={videoSizeValidation}
            setVideoSizeValidation={setVideoSizeValidation}
            videoTypeValidaion={videoTypeValidaion}
            setVideoTypeValidation={setVideoTypeValidation}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            setOutput={setOutput}
            output={output}
          />
        </div>
      </Modal>
    </>
  );
}

export default VideoWidget;
