import React, { useState } from 'react'
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Typography, Label, InputBox, Stack, Space, Button, Message, async, Alert } from 'witmeg-ui-system'
import { setOrganizationData, setUserAuthenticated } from '../../../../redux/resturantWidgetReducer';
import { NeutriPosLogin } from '../../../utilities/apiRequests/witmegOrgServerRequests';

const AuthenticationPopup = ({ form, setForm, autherrors, setAuthErrors, hideCredentialsPopUp, setShowOrderTypePopup, authValidation, setAuthValidation, setShowCredentialsPopup }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [authSucess, setAuthSucess] = useState(null)
    const dispatch = useDispatch();

    const companyData = useSelector((state) => state.companyData.companyData);

    const setField = (field, value) => {
      setForm({ ...form, [field]: value });
      // Check and see if errors exist, and remove them from the error object:
      if (!!autherrors[field]) setAuthErrors({ ...autherrors, [field]: null });
    };

    //LOGIN VALIDATION
    const findFormErrors = () => {
      //Validation Messages
      const { email, password } = form;
      const newErrors = {};

      // name errors
      if (!email || email === '') newErrors.email = 'Email Cannot Be Empty';
      if (!password || password === '') newErrors.password = 'Password cannot be blank!';

      return newErrors;
    };


    //Login the User to Neutripos
    async function handleLogin() {
      setIsLoading(true);
      const newErrors = findFormErrors();
      if (Object.keys(newErrors).length > 0) {
        setAuthErrors(newErrors);
        setIsLoading(false);
      } else {
        const apiReqBody = {
          UserName: form.email,
          Password: form.password,
          OrganizationID: companyData.OrganisationID,
        };

        try {
          const _response = await NeutriPosLogin(apiReqBody);
          if (_response === 'User email does not exist!' || _response === 'Password does not match') {
            setAuthValidation(_response);
            setIsLoading(false);
          } else {
            if (_response && _response.length !== 0) {
              dispatch(setUserAuthenticated({ userAuthenticated: true }));
              localStorage.setItem('userAuthenticated', true);
              const _cloudLocations = _response.map(
                ({ CloudLocationID, CloudLocationName }) => ({ CloudLocationID, CloudLocationName })
              );
              
              dispatch(setOrganizationData({
                CloudLocations: _cloudLocations,
                OrganizationID: _response[0].OrganizationID,
              }));
              localStorage.setItem('CloudLocations', JSON.stringify(_cloudLocations));
              localStorage.setItem('RMOrganization', _response[0].OrganizationID);

              await setAuthSucess('User logged in successfully');

              await setShowOrderTypePopup(true);
              await setShowCredentialsPopup(false);
            }

            setIsLoading(false);
          }
        } catch (error) {}
      }
    }

    //WILL OPEN IN NEW Tab
    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };

    //WHEN CLICK CANCEL REDIRECT TO NEUTRIPOS
    const handelNavigate = () => {
      openInNewTab('https://uat-webneutripos.throbox.com/login');
    };

    return (
        <div>
            {/* AUTH VALIDATION */}
            {authValidation && <Alert message={authValidation} type='error' showIcon />}
            {authSucess && <Alert message={authSucess} type='success' showIcon />}
            <Row className="eco-mt-8" style={{ marginBottom: '40px' }}>
                <Col span={24}>
                    <Typography type="title" level={5} pattern="bold" style={{ marginBottom: '65px', fontSize: '16px', fontWeight: 'bold' }} alignment="center">Enter your WITMEG Restaurant credentials to get the menu</Typography>
                </Col>
                <Col span={24}>
                    <Row className="form-row" align="middle">
                        <Col span={8}>
                            <div className="form-lbl-wrapper"><Label required>User Name</Label></div>
                        </Col>
                        <Col span={16}>
                            <InputBox type="text" error={autherrors.email} onChange={(e) => setField("email", e.target.value)} noBorder backgroundOnly />

                            {autherrors.email && (
                                <span style={{ color: 'red' }}>* Email Is Required</span>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row className="form-row" align="middle">
                        <Col span={8}>
                            <div className="form-lbl-wrapper"><Label required>Password</Label></div>
                        </Col>
                        <Col span={16}>
                            <InputBox type="password" error={autherrors.password} onChange={(e) => setField("password", e.target.value)} noBorder backgroundOnly />

                            {autherrors.password && (
                                <span style={{ color: 'red' }}>* Password Is Required</span>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Stack horizontalAlign="h_center" direction="column">
                <Space size="middle" className="eco-mb-16">
                    <Button
                        disabled={isLoading}
                        variant="primary"
                        className="long-btn"
                        onClick={() => { handleLogin() }} >Login
                    </Button>
                    <Button variant="secondary" onClick={() => hideCredentialsPopUp()} className="long-btn">Cancel</Button>
                </Space>
                <Typography type="text" color="gray_lightest">If you do not have the credentials please click <span onClick={handelNavigate} style={{ cursor: "pointer", color: '#039BE5', textDecoration: 'underline' }}>Here</span></Typography>
            </Stack>
        </div>
    )
}

export default AuthenticationPopup;
