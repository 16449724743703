import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faShoppingCart,
  faEnvelope,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import * as NumericInput from "react-numeric-input";
// import { Row, Col, Breadcrumb, Table } from "react-bootstrap";
import jwt from "jsonwebtoken";
import LazyLoad from "react-lazyload";
import { Skeleton, message, Button, Form, Input, Modal,  Row, Col } from "antd";
import config from "react-global-configuration";
import { Menu } from "antd";
import axios from "axios";

import { LoadingOutlined } from "@ant-design/icons";
import {
  getAllProductDetails,
  getCompanyByID,
  getRequestBody,
  updateCompanyByProduct,
  getSettings,
} from "./Pages/utilities/apiRequests/witmegRetailPaxServerRequests";
import { async } from "witmeg-ui-system";
const { SubMenu } = Menu;
const key = "updatable";
function handleChange(value) {
  console.log(`selected ${value}`);
}

const catid = 1;
function removeValue(list, value) {
  return list.replace(new RegExp(",?" + value + ",?"), function (match) {
    var first_comma = match.charAt(0) === ",",
      second_comma;

    if (
      first_comma &&
      (second_comma = match.charAt(match.length - 1) === ",")
    ) {
      return ",";
    }
    return "";
  });
}

class ProductsHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      add: 0,
      ShopNowItems: "",
      categories: [],
      products: [],
      basketitem: [],
      basketqty: [],
      loaded: false,
      ploaded: false,
      companyData: null,
      regexp: /^[0-9\b]+$/,
    };
  }

  handleAddProductClick = (e) => {
    document.getElementById("key" + e).style.display = "none";
    var ShopNowItems = this.state.ShopNowItems;
    ShopNowItems = ShopNowItems + "," + e;
    this.setState({ ShopNowItems: ShopNowItems });
    const company = {
      ShopNowItems: ShopNowItems,
    };

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: process.env.REACT_APP_jwtAud,
      exp: timestamp,
      iss: process.env.REACT_APP_jwtIss,
    };

    var token = jwt.sign(payload, process.env.REACT_APP_secret);

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    axios
      .post(
        config.get("backendURL") +
        "company/update/product/" +
        sessionStorage.getItem("company"),
        company,
        requestOptions
      )
      .then(function (data) {
        Modal.success({
          title: "Success",
          content: "Products details has been updated",
          onOk() { },
        });
      });
  };

  // THIS WILL FETCH ALL THE PRODUCTS LIST
  handleHomeClick = (e) => {
    this.setState({ add: 0, ploaded: false });
    var ShopNowItems = this.state.ShopNowItems;

    ShopNowItems = "[" + ShopNowItems + "]";

    const getAllProdutc = getAllProductDetails({
      OrgID: JSON.parse(localStorage.getItem("OrganizationID"))[0].OrganizationID,
      ProductIDs: JSON.parse(ShopNowItems),
      LocationID: 52,
    }).then((response) => this.setState({ products: response }));
  };

  // MINUS REMOVE ICON ON TOP OF THE ITEM
  handleRemoveProductClick = async (e) => {
    this.setState({ add: 0 });
    const ShopNowItems = this.state.ShopNowItems;

    const company = {
      ShopNowItems: removeValue(ShopNowItems, e),
    };

    const updateProduct = await updateCompanyByProduct({
      ID: sessionStorage.getItem("company"),
      ShopNowItems: removeValue(ShopNowItems, e),
    }).then(function (data) {
      Modal.success({
        title: "Success",
        content: "Products details has been updated",
        onOk() { },
      });
    });

    var ShopNowItemss = [];
    ShopNowItemss = "[" + removeValue(ShopNowItems, e) + "]";

    this.setState({ ShopNowItems: removeValue(ShopNowItems, e) });

    const getAllProduct = getAllProductDetails({
      OrgID: JSON.parse(localStorage.getItem("OrganizationID"))[0].OrganizationID,
      ProductIDs: JSON.parse(ShopNowItemss),
      LocationID: 52,
    })
      .then((response) => {
        console.log(response);
        this.setState({ products: response, ploaded: true });
      })
      .catch((error) => {
        console.log(error);
      });

    this.onGetRequestData();
  };

  // SUB MENU NAVIGATION
  handleClick = (e) => {
    this.setState({ add: 1, ploaded: false });
    // const timestamp = Math.floor(Date.now() / 1000) + 1000;

    // var payload = {
    //   aud: config.get("ProductjwtAud"),
    //   exp: timestamp,
    //   iss: config.get("ProductjwtIss"),
    // };

    // var signOptions = {
    //   algorithm: "RS256",
    // };
    var ShopNowItems = [];

    ShopNowItems = "[" + config.get("ShopNowItems") + "]";

    // var privateKey = config.get("ProductprivateKey");
    // var token = jwt.sign(payload, privateKey, signOptions);
    // const requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ` + token,
    //   },
    // };
    // console.log(
    //   config.get("rest_API_productregistry_V2") +
    //     "/category/pagination/" +
    //     config.get("OrganisationID") +
    //     "/52/" +
    //     e +
    //     "/0/12"
    // );
    // fetch(
    //   config.get("rest_API_productregistry_V2") +  
    //     "/category/pagination/" +
    //     config.get("OrganisationID") +
    //     "/52/" +
    //     e +
    //     "/0/12",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((data) => this.setState({ products: data, ploaded: true }));
  };

  async componentDidMount() {
    let selectedOrganizationLocation=JSON.parse(localStorage.getItem("selectedOrganizationLocation"));
    console.log("selectedOrganizationLocation", selectedOrganizationLocation)

    let orgSettings=await getSettings(selectedOrganizationLocation.OrganizationID)

    console.log("orgSettings", orgSettings)
    if(orgSettings.Status) {
      const salesLocationID=orgSettings.Result[0].SalesLocationID
      getAllProductDetails({
        // OrgID: sessionStorage.getItem("company"),
        OrgID: selectedOrganizationLocation.OrganizationID,
        // ProductIDs: [
        //   137, 1275, 1042, 1373, 1769, 370, 1778, 1591, 611, 275, 276, 1773,
        // ],
        LocationID: salesLocationID,
      })
        .then((response) => {
          console.log(response);
          this.setState({ products: response, ploaded: true });
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log("SD", this.props.compnayData);

      var ShopNowItems = [];
      ShopNowItems = "[" + config.get("ShopNowItems") + "]";
      this.setState({ ShopNowItems: config.get("ShopNowItems") });

      this.onGetRequestData();

      // this.onSetCategoryResult(response.productcategories);
    }
  }

  onGetRequestData = async () => {
    let selectedOrganizationLocation=JSON.parse(localStorage.getItem("selectedOrganizationLocation"));
    let ORGDI = selectedOrganizationLocation.OrganizationID

    getRequestBody({
      Message:
        'query{productcategories(input:{orgid:"' +
        ORGDI +
        '"}){ID,ProductCategory{Category,ProductCategoryID,ProductDescription,CreatedDate,CreatedBy,ModifiedDate,ModifiedBy,VatRate,WarningMessage,SoundFile,IsAlwaysVisibleWarning,IsPercentageDiscount,IsPointsBasedDiscount,IsproductCategoryDeleted,OrgID},ProductSubCategory{ID,Name,ProductSubCategoryID,CategoryID,SubcategoryDescription,CreatedDate,CreatedBy,ModifiedDate,ModifiedBy,IsSubCaregoryDeleted}}}',
    }).then((response) => {
      console.log("HELLO ===>", response);
      this.onSetCategoryResult(response);
    });
  };

  onSetCategoryResult = (result, key) => {
    this.setState({ categories: result });
  };

  render() {
    const onFinish = (values) => { };

    const { categories, products } = this.state;
    const { basketitem } = this.state;
    return (
      <Row>
        <Col sm={6}>
          <b>
            <a onClick={this.handleHomeClick.bind(this)}>Products Home</a>
          </b>
          <Menu
            style={{ width: 256 }}
            defaultSelectedKeys={["1"]}
            mode='inline'
          >
            {categories?.map((category) => (
              <SubMenu
                key={category.ProductCategory.ProductCategoryID}
                title={
                  <a
                    onClick={this.handleClick.bind(
                      this,
                      category.ProductCategory.ProductCategoryID
                    )}
                  >
                    {" "}
                    {category.ProductCategory.Category}{" "}
                  </a>
                }
              >
                {category.ProductSubCategory.map((subcategory) => (
                  <Menu.Item key={subcategory.Name}>
                    {subcategory.Name !== null ? <p>{subcategory.Name}</p> : ""}
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </Col>
        <Col sm={18}>

            <Row>
              {/* // this.state.products.Status ? ( */}
              {products.length>0 && products.map((product) => (
                <Col xs={24} md={12} lg={6} id={"key" + product.ProductID}>
                  <div className='list'>
                    <div className='card'>
                      {/* <div md={{ span: 3, offset: 3 }}>
                        {" "}
                        {product.IsOutOfStock ? (
                          <>
                            {" "}
                            <span class='outofstock'>OUT OF STOCK</span>
                          </>
                        ) : (
                          ""
                        )}{" "}
                        {this.state.add == 0 ? (
                          <FontAwesomeIcon
                            onClick={this.handleRemoveProductClick.bind(
                              this,
                              product.ProductID
                            )}
                            color='red'
                            style={{ cursor: "pointer" }}
                            size='lg'
                            icon={faMinus}
                          />
                        ) : (
                          <FontAwesomeIcon
                            size='lg'
                            onClick={this.handleAddProductClick.bind(
                              this,
                              product.ProductID
                            )}
                            color='green'
                            style={{ cursor: "pointer" }}
                            icon={faPlus}
                          />
                        )}
                      </div> */}
                      {product.OnLineImages.PrimaryImages !== null ? (
                        <div style={{ width: "auto" }}>
                          {/* {this.state.loaded ? null : (
                            <div className='divLoader'>
                              <LoadingOutlined
                                style={{ fontSize: "56px", color: "#08c" }}
                              />
                              <br />
                              Loading
                            </div>
                          )} */}
                          <img
                            src={product.OnLineImages.PrimaryImages.Main}
                            alt={product.Name}
                            onLoad={() => this.setState({ loaded: true })}
                            style={
                              this.state.loaded
                                ? { width: "90%" }
                                : { display: "none" }
                            }
                          />
                          <input
                            type='hidden'
                            id={"prodprimimage" + product.ProductID}
                            value={product.OnLineImages.PrimaryImages.Main}
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            src='/images/noimage.jpg'
                            style={{ width: "90%" }}
                            alt={product.Name}
                          />
                          <input
                            type='hidden'
                            id={"prodprimimage" + product.ProductID}
                            value=''
                          />
                        </div>
                      )}

                      <h4>{product.Name}</h4>
                      <p>
                        <b>Item Code:</b>
                        <br /> {product.ProductRefNo}
                      </p>
                    </div>
                    <br />
                  </div>
                </Col>
              ))}
            </Row>
        </Col>
      </Row>
    );
  }
}

export default ProductsHome;
