const {
  REACT_APP__WITMEG_RETAILPAXSERVER__PRIVATE_KEY,
  REACT_APP__WITMEG_RETAILPAXSERVER__ISSUER,
  REACT_APP__WITMEG_RETAILPAXSERVER__AUDIENCE,
  REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL,

  REACT_APP__WITMEG_ORGSERVER___PRIVATE_KEY,
  REACT_APP__WITMEG_ORGSERVER___ISSUER,
  REACT_APP__WITMEG_ORGSERVER___AUDIENCE,
  REACT_APP__WITMEG_ORGSERVER___BASEURL,


  WITMEG_ENTITLEMENT_REGISTRY_SERVER___PRIVATE_KEY,
  WITEMG_ENTITLEMENT_REGISTRY_SERVER___ISSUER,
  WITMEG_ENTITLEMENT_REGISTRY_SERVER___AUDIENCE,




  NODE_ENV,

  REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL,
  REACT_APP_TOKENSERVICE_URL,

  REACT_APP_TEMP_TOKEN_URL,

  REACT_APP_rest_API_productregistry_V2,
  REACT_APP_rest_API_productregistry,
  REACT_APP_API_productregistry,


  REACT_APP_API_salesregistry,
  REACT_APP_ENTITLEMENTREG__URL,
  REACT_APP__NEUTRIPOS_BASEURL,
  REACT_APP_CONTROL_CENTER_BASE_URL,
  REACT_APP_PAYMENT_BASE_URL

} = process.env;

const globalValues = {
  credentials: {
    WITMEG_ORGSERVER___PRIVATE_KEY: REACT_APP__WITMEG_ORGSERVER___PRIVATE_KEY,
    WITMEG_ORGSERVER___ISSUER: REACT_APP__WITMEG_ORGSERVER___ISSUER,
    WITMEG_ORGSERVER___AUDIENCE: REACT_APP__WITMEG_ORGSERVER___AUDIENCE,

    WIMEG_RETAILPAXSERVER__PRIVATE_KEY:
      REACT_APP__WITMEG_RETAILPAXSERVER__PRIVATE_KEY,
    WIMEG_RETAILPAXSERVER__ISSUER: REACT_APP__WITMEG_RETAILPAXSERVER__ISSUER,
    WIMEG_RETAILPAXSERVER__AUDIENCE: REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL,
  },

  baseURLS: {
    WITMEG_ORGSERVER___BASEURL: REACT_APP__WITMEG_ORGSERVER___BASEURL,
    WIMEGG_RETAILPAX__BASEURL: REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL,

    REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL:
      REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL,

    REACT_APP_TOKENSERVICE_URL: REACT_APP_TOKENSERVICE_URL,

    WITMEG_ENTITLEMENT_REGISTRY_SERVER___PRIVATE_KEY: WITMEG_ENTITLEMENT_REGISTRY_SERVER___PRIVATE_KEY,
    WITEMG_ENTITLEMENT_REGISTRY_SERVER___ISSUER: WITEMG_ENTITLEMENT_REGISTRY_SERVER___ISSUER,
    WITMEG_ENTITLEMENT_REGISTRY_SERVER___AUDIENCE: WITMEG_ENTITLEMENT_REGISTRY_SERVER___AUDIENCE,

    REACT_APP_TEMP_TOKEN_URL: REACT_APP_TEMP_TOKEN_URL,

    REACT_APP_rest_API_productregistry_V2:
      REACT_APP_rest_API_productregistry_V2,
    REACT_APP_rest_API_productregistry: REACT_APP_rest_API_productregistry,
    REACT_APP_API_productregistry: REACT_APP_API_productregistry,

    REACT_APP_API_salesregistry: REACT_APP_API_salesregistry,
    REACT_APP_ENTITLEMENTREG__URL: REACT_APP_ENTITLEMENTREG__URL,
    REACT_APP__NEUTRIPOS_BASEURL: REACT_APP__NEUTRIPOS_BASEURL,
    REACT_APP_CONTROL_CENTER_BASE_URL: REACT_APP_CONTROL_CENTER_BASE_URL,
    REACT_APP_PAYMENT_BASE_URL: REACT_APP_PAYMENT_BASE_URL

  },

  environment: {
    CURRENT_ENVIRONMENT: NODE_ENV,
    IS_DEVELOPMENT: NODE_ENV === "development",
  },
};
export default globalValues;
