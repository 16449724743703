import React from "react";
import {
  Layout,
  Breadcrumb,
  Select,
  Drawer,
  Space,
  Input,
  Modal,
  Skeleton,
  Popover,
  List,
  Avatar,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import LeftMenu from "./leftmenu";
import config from "react-global-configuration";
import LayoutInclude from "./layout-include";
import { Form, Button, Row, Col } from "react-bootstrap";
import * as _ from "lodash";
import { WidthProvider, Responsive } from "react-grid-layout";
import { PlusCircleOutlined } from "@ant-design/icons";
import jwt from "jsonwebtoken";
import closeIcon from "./images/Windows-Close-Program-icon.png"; // with import

const { Content } = Layout;
const ResponsiveReactGridLayout = WidthProvider(Responsive);
class Application extends React.Component {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 2 },
    rowHeight: 20,
  };

  constructor(props) {
    super(props);
    const datax = [],
      datay = [];
    const loading = true;
    this.onChangeHeading = this.onChangeHeading.bind(this);
    this.state = {
      pageorder: "",
      id: "",
      pages: [],
      datax,
      loading,
      datay,
      menus: [],
      layout: [],
      widgets: [],
      hover: null,
      showHide: "disBlock",
      items: [],
      newCounter: 0,
    };

    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
  }

  createElement(el) {
    console.log(el);
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
    };
    const i = el.i;
    return (
      <div
        key={i}
        data-grid={el}
        onMouseEnter={() => this.mouseEnter(i)}
        onMouseLeave={this.mouseLeave}
      >
        <span
          className={
            this.state.hover === i
              ? "disBlock  remove rowremove"
              : "disNone" + " remove rowremove"
          }
        >
          ROW{" "}
        </span>

        <LayoutInclude
          params={{ layoutid: JSON.parse(i)[0], w: el.w, h: el.h }}
        />
        <span
          className={
            this.state.hover === i ? "disBlock  remove" : "disNone" + " remove"
          }
          style={removeStyle}
          onClick={this.onLRemoveItem.bind(this, i)}
        >
          <img src={closeIcon} />
        </span>

        <span
          className={
            this.state.hover === i
              ? "disBlock  remove rowadd "
              : "disNone" + " remove rowadd "
          }
          onClick={this.onAddItem.bind(this, "newsection", "", "newsection")}
          onMouseEnter={this.mouseEnterAdd}
          onMouseLeave={this.mouseLeaveAdd}
        >
          {this.state.plusText}
        </span>
      </div>
    );
  }

  onAddItem(items, itemdetails, itemheading) {
    /*eslint no-console: 0*/
    // console.log("adding", "n" + this.state.newCounter);

    const item = "ITEM";
    const width = 4;
    const height = 2;
    const itemdata = itemdetails;
    const type = items;
    const pageid = "";
    const x = (this.state.items.length * 2) % (this.state.cols || 12);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: "n" + this.state.newCounter,
        i: JSON.stringify([
          this.state.newCounter.toString(),
          itemheading,
          item,
          itemdata,
          type,
          pageid,
        ]),
        x: x,
        y: Infinity, // puts it at the bottom
        w: width,
        h: height,
      }),
      // Increment the counter to ensure key is always unique.

      newCounter: this.state.newCounter + 1,
    });

    var WidgetsDtl = {
      itemheading: itemheading,
      item: item,
      width: width,
      itemdata: itemdata,
      type: type,
      companyid: sessionStorage.getItem("company"),
      height: height,
      x: x,
      y: 1000,
      pageid: this.state.id,
    };

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: process.env.REACT_APP_jwtAud,
      exp: timestamp,
      iss: process.env.REACT_APP_jwtIss,
    };

    var token = jwt.sign(payload, process.env.REACT_APP_secret);

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    axios
      .post(
        config.get("backendURL") + "homelayout/add",
        WidgetsDtl,
        requestOptions
      )
      .then((res) => {
        Modal.success({
          title: "Success",
          content: "New items has been added",
          onOk() {
            window.location.reload(false);
          },
        });
      });
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {}

  onLRemoveItem(i) {
    // console.log("removing", i);
    this.setState({ items: _.reject(this.state.items, { i: i }) });

    axios
      .delete(config.get("backendURL") + "homelayout/" + JSON.parse(i)[0])
      .then((res) => {
        Modal.success({
          title: "Success",
          content: "Item has been deleted",
          onOk() {
            window.location.reload(false);
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onwidgetClose = () => {
    this.setState({
      widgetvisible: false,
    });
  };

  mouseEnter = (i) => {
    this.setState({
      showHide: "disBlock",
      hover: i,
    });
  };
  mouseLeave = () => {
    this.setState({
      showHide: "disNone",
    });
  };
  mouseEnterAdd = () => {
    this.setState({
      plusText: "+ Add  Section",
    });
  };
  mouseLeaveAdd = () => {
    this.setState({
      plusText: "+",
    });
  };

  componentDidMount() {
    document.title = "RetailPacx - Site Editor";

    axios
      .get(
        config.get("backendURL") +
          "pages/" +
          this.props.match.params.id +
          "/" +
          sessionStorage.getItem("company")
      )
      .then((response) => {
        this.setState({
          pagetitle: response.data.pagetitle,
          pagecontent: response.data.content,
          featuredimage: response.data.featuredimage,
          slug: response.data.slug,
          author: response.data.author,
          status: response.data.status,
          visibility: response.data.visibility,
          publishon: response.data.publishon,
          menus: response.data.menus,
          pageorder: response.data.order,
          companyid: response.data.companyid,
          id: response.data._id,
        });

        axios
          .get(
            config.get("backendURL") +
              "homelayout/bypageid/" +
              response.data._id
          )
          .then((response) => {
            if (response.data.length > 0) {
              this.setState({
                items: response.data.map(function (i, key, list) {
                  return {
                    i: JSON.stringify([
                      i._id.toString(),
                      i.itemheading.toString(),
                      i.item.toString(),
                      i.itemdata.toString(),
                      i.type.toString(),
                      i.pageid.toString(),
                    ]),
                    x: i.x,
                    y: i.y,
                    w: i.width,
                    h: i.height,
                    link: i.link,
                    add: i === list.length - 1,
                  };
                }),
                loading: true,
              });
            } else {
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            console.log(error);
          });

        const requestMenu = axios
          .get(config.get("backendURL") + "widgets")
          .then((response) => {
            this.setState({ widgets: response.data });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onLayoutChange = (layout) => {
    //console.log(layout)

    let currentHideNav = window.innerWidth <= 1024;

    //  console.log(currentHideNav)
    if (!currentHideNav) {
      this.setState({ layout: layout });
      layout.map(function (j, key, list) {
        const pageData = {
          width: j.w,
          height: j.h,
          x: j.x,
          y: j.y,
          itemheading: JSON.parse(j.i)[1],
          item: JSON.parse(j.i)[2],
          type: JSON.parse(j.i)[4],
          itemdata: JSON.parse(j.i)[3],
          pageid: JSON.parse(j.i)[5],
          companyid: sessionStorage.getItem("company"),
        };

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
          aud: process.env.REACT_APP_jwtAud,
          exp: timestamp,
          iss: process.env.REACT_APP_jwtIss,
        };

        var token = jwt.sign(payload, process.env.REACT_APP_secret);

        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + token,
          },
        };

        axios
          .post(
            config.get("backendURL") +
              "homelayout/update/" +
              JSON.parse(j.i)[0],
            pageData,
            requestOptions
          )
          .then((res) => {
            // console.log(res.data)
          });
      });
    }
    /*
         
       
     */
  };

  handleEditorChange = (content, editor) => {
    console.log("Content was updated:", content);

    const pageData = {
      pagetitle: this.state.pagetitle,
      content: content,
      featuredimage: this.state.featuredimage,
      slug: this.state.slug,
      author: this.state.author,
      status: this.state.status,
      visibility: this.state.visibility,
      publishon: this.state.publishon,
      menus: this.state.menus,
      pageorder: this.state.order,
      companyid: this.state.companyid,
      pageid: this.state.id,
    };

    axios
      .post(
        config.get("backendURL") + "pages/update/" + this.state.id,
        pageData
      )
      .then((res) => console.log(res.data));
  };

  onChangeHeading(e) {
    this.setState({
      pagetitle: e.target.value,
    });
  }
  render() {
    return (
      <>
        <LeftMenu />
        <Layout className='site-layout'>
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Pages</Breadcrumb.Item>
              <Breadcrumb.Item>{this.state.pagetitle}</Breadcrumb.Item>
            </Breadcrumb>

            <Form onSubmit={this.onSubmit}>
              <Form.Row>
                <Form.Group as={Col} controlId='formGridHeading'>
                  <Form.Label>Heading</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    value={this.state.pagetitle}
                    onChange={this.onChangeHeading}
                    placeholder='Menu Name'
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId='formGridMenuName'>
                  <Form.Label>Page Content</Form.Label>

                  <div
                    className='site-layout-background'
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <Layout className='site-layout'>
                      <Row>
                        <Col sm={12}>
                          <Popover
                            placement='bottom'
                            trigger='focus'
                            content={
                              <List itemLayout='horizontal'>
                                {this.state.widgets.map((widget) => (
                                  <List.Item>
                                    <List.Item.Meta
                                      onClick={this.onAddItem.bind(
                                        this,
                                        widget.item,
                                        widget.itemdetails,
                                        widget.item
                                      )}
                                      title={
                                        <a
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {widget.item}
                                        </a>
                                      }
                                      description={
                                        "Create a " + widget.item + " widget"
                                      }
                                    />
                                  </List.Item>
                                ))}
                              </List>
                            }
                          >
                            <Button
                              variant='outlined'
                              color='primary'
                              className=''
                              startIcon={<PlusCircleOutlined />}
                            >
                              Add widget
                            </Button>
                          </Popover>

                          <Popover
                            placement='bottom'
                            trigger='focus'
                            content={
                              <List itemLayout='horizontal'>
                                <List.Item>
                                  <List.Item.Meta
                                    onClick={this.onAddItem.bind(
                                      this,
                                      "bestselling",
                                      "",
                                      "Best Selling Products"
                                    )}
                                    title={
                                      <a
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Best Selling
                                      </a>
                                    }
                                    description={"Add a best selling widget"}
                                  />
                                </List.Item>
                                <List.Item>
                                  <List.Item.Meta
                                    onClick={this.onAddItem.bind(
                                      this,
                                      "featured",
                                      "",
                                      "Featured Products"
                                    )}
                                    title={
                                      <a
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Featured
                                      </a>
                                    }
                                    description={"Add a featured widget"}
                                  />
                                </List.Item>
                                <List.Item>
                                  <List.Item.Meta
                                    onClick={this.onAddItem.bind(
                                      this,
                                      "featuredoffers",
                                      "",
                                      "Featured offers"
                                    )}
                                    title={
                                      <a
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Featured offers
                                      </a>
                                    }
                                    description={"Add a featured offers widget"}
                                  />
                                </List.Item>
                                <List.Item>
                                  <List.Item.Meta
                                    onClick={this.onAddItem.bind(
                                      this,
                                      "offers",
                                      "",
                                      "Offers"
                                    )}
                                    title={
                                      <a
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        Offers
                                      </a>
                                    }
                                    description={"Add a offers widget"}
                                  />
                                </List.Item>
                              </List>
                            }
                          >
                            <Button
                              variant='outlined'
                              color='primary'
                              className=''
                              startIcon={<PlusCircleOutlined />}
                            >
                              Add E-Commerce widget
                            </Button>
                          </Popover>
                        </Col>
                      </Row>

                      <ResponsiveReactGridLayout
                        onLayoutChange={this.onLayoutChange}
                        onBreakpointChange={this.onBreakpointChange}
                        {...this.props}
                      >
                        {_.map(this.state.items, (el) =>
                          this.createElement(el)
                        )}
                      </ResponsiveReactGridLayout>
                    </Layout>
                  </div>
                </Form.Group>
              </Form.Row>
            </Form>
          </Content>
        </Layout>
      </>
    );
  }
}

export default Application;
