import React, { useState, useEffect } from 'react'
import { Input } from 'antd';

import { Button, Col, InputBox, Label, Row, Stack, StackItem, Typography, Icon, Alert, Popup, Space } from 'witmeg-ui-system'

function AccordianSettings({ queserror, maximumAlert, errorOptions,  
    setMaximAlert,
    anserror, accordianData, setAccordianData, accordianTopic, setAccordianTopic, errors }) {
    const { TextArea } = Input;
    const [deleteConfirm, setDeleteConfirm] = useState(false)

    
    
    const   moreAccordians = () => {
        if (accordianData.length < 5) {
            setAccordianData([
                ...accordianData, {
                    accordian_question: "",
                    accordian_answer: '',

                }
            ])
        } else {
            setMaximAlert(true)
        }
    }
    console.log("E", errors)
    const handleDeleteAccordian = (index) => {

        if (index > -1) {
            var result = accordianData.filter((data, idx) => idx !== index);
            setAccordianData(result)
            setDeleteConfirm(false)
            setMaximAlert(false)

        }
    }


    const updateAccordianDetails = (index, key, val, fieldName) => {
        console.log("updateAddressList", index, key, val, fieldName);

        // accordian_topic: val.accordian_topic
        let updateAccordianList = accordianData.map((a, i) => {
            return {
                //  accordian_topic: index === i && key === 'accordian_topic' ? val : a.accordian_topic,
                accordian_question: index === i && key === 'accordian_question' ? val : a.accordian_question,
                accordian_answer: index === i && key === 'accordian_answer' ? val : a.accordian_answer,

            }
        })


        setAccordianData(updateAccordianList)
    }




    return (
        <div>

            {queserror && anserror && <Alert className="eco-mb-8"
                message="Required field(s) missing"
                description="At least one question and answer is required."
                type="error"
                showIcon
            />}

            {errorOptions.length > 0   && <Alert  className="eco-mb-8"
                message="Required field(s) missing"
                description={`${errorOptions.join(
                    ", "
                  )} fields are missing.`}
              
                type="error"
                showIcon/>}
            {/* <div style={{ background: "#F4F4F4", padding: '14px', borderRadius: "4px", marginBottom: "16px" }}>
                <Typography>Title 1</Typography>
            </div> */}
            <div style={{ padding: "20px 14px", border: "1px solid #C6C7D2", borderRadius: "4px" }}>
                <Row allign="middle" className="eco-mb-16">
                    <Col span={8}>
                        <div className="form-lbl-wrapper"><Label>Accordion Title</Label></div>
                    </Col>
                    <Col span={16}>
                        <InputBox id="accordian_name" type="text" value={accordianTopic} onChange={(e) => setAccordianTopic(e.target.value)} noBorder backgroundOnly />
                    </Col>
                </Row>
                <div className='accordian-section'>
                    {accordianData.map((item, i) => {

                        { console.log(i) }
                        return (
                            <div style={{ background: "#E6F6FF", border: "1px solid #039BE5", borderRadius: '4px', padding: '10px 14px', marginBottom: '24px' }}>
                                <Row key={item.i} allign="middle" className="eco-mb-16">
                                    <Col span={8}>
                                        <div className="form-lbl-wrapper">
                                            <Label required>Accordion Name</Label>
                                        </div>
                                    </Col>
                                    <Col span={16}>
                                        <InputBox id="accordian_question" value={item.accordian_question} error ={errors.find(x => x.index == i && x.name == "accordian_question") ? true : false} type="text" onChange={(e) => updateAccordianDetails(i, "accordian_question", e.target.value)} noBorder />

                                    </Col>
                                </Row>
                                <Row key={item.i} allign="middle" className="eco-mb-16">
                                    <Col span={8}>
                                        <div className="form-lbl-wrapper"><Label required>Description</Label></div>
                                    </Col>
                                    <Col span={16}>
                                        <TextArea
                                            showCount
                                            maxLength={1000}
                                            style={{
                                                height: 120,
                                                marginBottom: 24,
                                            }}
                                            value={item.accordian_answer}
                                            onChange={(e) => updateAccordianDetails(i, "accordian_answer", e.target.value)}
                                            status = {errors.find(x => x.index == i && x.name == "accordian_answer") ? "error" : ""}
                                        />

                                    </Col>
                                </Row>

                                {/* Delete Popup */}
                                <Popup
                                    modalTitle=""
                                    modalVisible={deleteConfirm}
                                    onCancel={() => setDeleteConfirm(false)}
                                    centered
                                    // mask={false}
                                    maskClosable={false}
                                    className="widget-delete-confirmation-popup"
                                >
                                    <div>
                                        <Stack style={{ marginBottom: "62px" }} verticalAlign="v_top">
                                            <Icon
                                                name="cancel"
                                                type="color"
                                                className="eco-mr-16"
                                                size={32}
                                            />
                                            <Stack direction="column" verticalAlign="v_top">
                                                <Typography
                                                    type="title"
                                                    level={5}
                                                    pattern="bold"
                                                    className="eco-mb-16"
                                                >
                                                    You're about to delete this Accordion!
                                                </Typography>
                                                <Typography type="text">
                                                    Do you really want to delete this item? this process
                                                    cannot be undone.
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Stack horizontalAlign="h_end">
                                            <Space size="middle">
                                                <Button
                                                    variant="primary"
                                                    onClick={(e) => {
                                                        {
                                                            setDeleteConfirm(true)
                                                            handleDeleteAccordian(i)
                                                        }
                                                    }}
                                                >
                                                    Confirm
                                                </Button>
                                                <Button
                                                    variant="outline"
                                                    onClick={() => {
                                                        setDeleteConfirm(false)

                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Space>
                                        </Stack>
                                    </div>
                                </Popup>
                                {i != 0 && <Row>
                                    <Col span={24}>
                                        <Stack horizontalAlign="h_end">
                                            <div onClick={() => setDeleteConfirm(true)}>
                                                <Icon name="delete" color="gray" />
                                            </div>
                                        </Stack>
                                    </Col>
                                </Row>}
                            </div>
                        )
                    })}
                </div>
                <div style={{ padding: "10px 14px" }}>
                    <Row allign="middle" className="eco-mb-16">
                        <Col span={24}>
                            <div >


                                <Button disabled ={maximumAlert} category='icon-text' text="Add Accordion" size='medium' color = "white" iconSize={20} icon="plus" variant="primary" onClick={moreAccordians} style={{ cursor: 'pointer' }}>

                                </Button>
                                {/* <Stack horizontalAlign="h_start" direction="row">
                                    <Icon name="plus" color="blue" className="eco-mr-12" />
                                    <Typography type="text" color="blue_base" pattern="bold">Add Accordion</Typography>
                                </Stack> */}
                            </div>
                        </Col>
                    </Row>
                </div>
                {maximumAlert && <div><Alert message="Maximum limit has been reached" type="info" showIcon /></div>}
            </div>


        </div>
    )
}

export default AccordianSettings