
import React, { useMemo, useState } from "react";
import { isEqual } from "lodash";
export default function RedoUndo(init) {

    const [states, setStates] = useState([init]) // Use to store history of all states
    const [index, setIndex] = useState(0) //Index of current state

    const state = useMemo(() => states[index], [states, index]) //Current state


    const setState = (value) => {
        //Use lodash isEqual to check for deep equalit
        //if state has not changed, return to avoid triggering a re-render
        if (isEqual(state, value)) {
            return;
        }

        const copy = states.slice(0, index + 1); // This removes all future (redo) states after current index
        copy.push(value);
        setStates(copy);
        setIndex(copy.length - 1);
    }

    // Clear all state history
    const resetState = (init) => {
        setIndex(0);
        setStates([init]);
    };

    // Allows you to go back (undo) N steps
    const goBack = (steps = 1) => {
        setIndex(Math.max(0, Number(index) - (Number(steps) || 1)));
    };
    // Allows you to go forward (redo) N steps
    const goForward = (steps = 1) => {
        setIndex(Math.min(states.length - 1, Number(index) + (Number(steps) || 1)));
    };

    return {
        state,
        setState,
        resetState,
        index,
        lastIndex: states.length - 1,
        goBack,
        goForward,
    };
}
