import React, { useState } from "react";
import {
  Accordion,
  Col,
  Label,
  Row,
  Select,
  Space,
  Button,
  Stack,
  InputBox,
  ColorPicker,
} from "witmeg-ui-system";
import { Popover } from "antd";
import Combobox from "react-widgets/Combobox";
import layout1 from "../../../../images/popup-layout/layout-1.png";
import layout2 from "../../../../images/popup-layout/layout-2.png";
import layout3 from "../../../../images/popup-layout/layout-3.png";
import layout4 from "../../../../images/popup-layout/layout-4.png";
import layout5 from "../../../../images/popup-layout/layout-5.png";
import { useFormContext } from "react-hook-form";
import CardDescriptionForm from "./CardDescriptionForm";
import CardTitleForm from "./CardTitleForm";

const DesignForm = (props) => {
  const { data } = props;
  const { setValue } = useFormContext();

  const [selectedLayout, setSelectedLayout] = useState(data.layout??"CARD_LAYOUT_FIVE");

  const onSelectLayout = (val) => {
    setSelectedLayout(val);
    setValue("layout", val);
  };

  const layoutContent = (
    <Stack direction="column" horizontalAlign="h_center">
      {/* <div className={selectedLayout ==="layout1" ? "eco-mb-24 selected-layout":"eco-mb-24"} onClick={()=> setSelectedLayout("layout1")}>
                <img src={layout1}/>
            </div> */}
      <div
        className={
          selectedLayout === "CARD_LAYOUT_TWO"
            ? "eco-mb-24 selected-layout"
            : "eco-mb-24"
        }
        onClick={() => onSelectLayout("CARD_LAYOUT_TWO")}
      >
        <img src={layout2} />
      </div>
      {/* <div className={selectedLayout ==="layout3" ? "eco-mb-24 selected-layout":"eco-mb-24"} onClick={()=> setSelectedLayout("layout3")}>
                <img src={layout3}/>
            </div>
            <div className={selectedLayout ==="layout4" ? "eco-mb-24 selected-layout":"eco-mb-24"} onClick={()=> setSelectedLayout("layout4")}>
                <img src={layout4}/>
            </div> */}
      <div
        className={
          selectedLayout === "CARD_LAYOUT_FIVE"
            ? "eco-mb-24 selected-layout"
            : "eco-mb-24"
        }
        onClick={() => onSelectLayout("CARD_LAYOUT_FIVE")}
      >
        <img src={layout5} />
      </div>
    </Stack>
  );

  return (
    <div style={{ margin: "32px 20px" }}>
      <Accordion title="Layout" className="eco-mb-16">
        <div style={{ width: "100%" }}>
          <Row>
            <Col span={24}>
              <Stack horizontalAlign="h_center">
                <div className="eco-mr-24">
                  <div
                    style={{
                      width: "100%",
                      border: "1px solid #DBDCE3",
                      padding: "10px",
                    }}
                  >
                    {selectedLayout === "layout1" && <img src={layout1} />}
                    {selectedLayout === "CARD_LAYOUT_TWO" && (
                      <img src={layout2} />
                    )}
                    {selectedLayout === "layout3" && <img src={layout3} />}
                    {selectedLayout === "layout4" && <img src={layout4} />}
                    {selectedLayout === "CARD_LAYOUT_FIVE" && (
                      <img src={layout5} />
                    )}
                  </div>
                </div>
                <Popover
                  placement="right"
                  content={layoutContent}
                  trigger="click"
                  getPopupContainer={(triggerNode) => triggerNode}
                  overlayStyle={{ width: "300px" }}
                >
                  <Button
                    category="icon"
                    children="chevron-right"
                    variant="subtle"
                    className="show-layout-btn"
                  />
                </Popover>
              </Stack>
            </Col>
          </Row>
        </div>
      </Accordion>
      <CardTitleForm data ={data}/>
      <CardDescriptionForm data={data}/>
    </div>
  );
};

export default DesignForm;
