import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import {
  Typography,
  StackItem,
  InputBox,
  Button,
  Spin,
  Alert,
  Stack,
  Row,
  Col,
  Label,
  Checkbox,
} from "witmeg-ui-system";
import {
  getLoggedUserLocationDetails,
  getRegisteredUserData,
  loginUser,
} from "../utilities/apiRequests/witmegOrgServerRequests";
import { saveLoggedUser } from "../utilities/userAuthentication";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { routePaths } from "../../routes/routeConstants";
import { Redirect } from "react-router-dom";
import { assignWith } from "lodash";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { setOrganizationList } from "../../redux/organiserReducer";
function LoginForm() {
  const history = useHistory();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [rememberMe, setRememberMe] = useState(false)
  const [userName, setUserName] = useState("");
  const [password, setPassowrd] = useState("")
  let orglist = [];

  const dispatch = useDispatch()


  //Get Cookie Value
  function getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  // Remember Me funcationality 
  useEffect(() => {
    const cookieValue = getCookieValue('loginData');

    // Check if there are stored credentials in the cookie
    const storedCredentials = getCredentialsCookie();
    if (storedCredentials !== null) {
      setIsLoading(true)
      // Try to log in with the stored credentials
      setRememberMe(true)
      setForm({
        'email': storedCredentials.UserName,
        'password': storedCredentials.Password
      })
      const apiReqBody = {
        UserName: storedCredentials.UserName,
        Password: storedCredentials.Password,
      };

      const resData = loginUser(apiReqBody).then(async (response) => {
        if (
          response === "User email does not exist!" ||
          response === "Password does not match"
        ) {
          setValidationError(response);
        } else {
          let AccessToken = response.AccessToken;
          let RefreshToken = response.RefreshToken;

          console.log(AccessToken, RefreshToken);

          await saveLoggedUser({
            AccessToken,
            RefreshToken,
          });

          let tempToken = await localStorage.getItem("user");

          let userID = await JSON.parse(tempToken)

          const reqBody = {
            UserID: userID.userid,
          };


          //CHECKING IF THE USER LOGGED IS A ORGANISATION ADMIN
          const resData = await getLoggedUserLocationDetails({
            UserID: userID.userid
          }).then(
            (response) => {

              console.log(response);



              if (response.IsOrganizationAdmin == true) {

                const organizationlist = response?.OrganizationDetails?.map((o) => ({
                  value: o.OrganizationName, label: o.OrganizationName, id: o.OrganizationID, ...o
                }))
                const OrganisationDetails = JSON.stringify(response.OrganizationDetails);
                const Organisation = JSON.stringify(organizationlist);

                console.log("organizationlist", organizationlist);

                dispatch(setOrganizationList({ organisationList: organizationlist }))
                localStorage.setItem("OrganisationID", OrganisationDetails);
                localStorage.setItem("OrganisationDetails", Organisation);
              }
              // Set cookie if rememberMe is checked
              if (rememberMe) {
                document.cookie = `loginData=${JSON.stringify(apiReqBody)};  path=/`;
              } else {
                //If not remove any exsisting cookies
                document.cookie = 'loginData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
              }

              history.push('/welcome');

              setTimeout(() => {
                let redirectTo = routePaths.dashboardRoot;


                window.location.href = redirectTo;
              });


            }
          );



        }
        setIsLoading(false);

        console.log("loginUser");

      });


    }
  }, [])

  // let mockedUsername = "superadmin";
  // let mockedPassword = "@er123111Kk";
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };


  function getCredentialsCookie() {
    const name = 'loginData=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return JSON.parse(cookie.substring(name.length, cookie.length));
      }
    }
    return null;
  }


  const findFormErrors = () => {
    //Validation Messages
    const { email, password } = form;
    const newErrors = {};

    // name errors
    if (!email || email === "") newErrors.email = "Email Cannot Be Empty";
    if (!password || password === "")
      newErrors.password = "Password cannot be blank!";

    return newErrors;
  };
  async function handleFormSubmitting(e) {
    setIsLoading(true);
    e.preventDefault();
    const apiReqBody = {
      UserName: form.email,
      Password: form.password,
    };
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // No errors!

      try {
        const resData = await loginUser(apiReqBody).then(async (response) => {
          if (
            response === "User email does not exist!" ||
            response === "Password does not match"
          ) {
            setValidationError(response);
          } else {
            let AccessToken = response.AccessToken;
            let RefreshToken = response.RefreshToken;

            console.log(AccessToken, RefreshToken);

            await saveLoggedUser({
              AccessToken,
              RefreshToken,
            });

            let tempToken = await localStorage.getItem("user");

            let userID = await JSON.parse(tempToken)

            const reqBody = {
              UserID: userID.userid,
            };


            //CHECKING IF THE USER LOGGED IS A ORGANISATION ADMIN
            const resData = getLoggedUserLocationDetails({
              UserID: userID.userid
            }).then(
              (response) => {

                console.log(response);



                if (response.IsOrganizationAdmin == true) {

                  const organizationlist = response?.OrganizationDetails?.map((o) => ({
                    value: o.OrganizationName, label: o.OrganizationName, id: o.OrganizationID, ...o
                  }))
                  const OrganisationDetails = JSON.stringify(response.OrganizationDetails);
                  const Organisation = JSON.stringify(organizationlist);

                  console.log("organizationlist", organizationlist);

                  dispatch(setOrganizationList({ organisationList: organizationlist }))
                  localStorage.setItem("OrganisationID", OrganisationDetails);
                  localStorage.setItem("OrganisationDetails", Organisation);
                }
                // Set cookie if rememberMe is checked
                if (rememberMe) {
                  document.cookie = `loginData=${JSON.stringify(apiReqBody)};  path=/`;
                } else {
                  //If not remove any exsisting cookies
                  document.cookie = 'loginData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
                }


                history.push('/welcome');

                // setTimeout(() => {
                //   let redirectTo = routePaths.dashboardRoot;
                //   window.location.href = redirectTo;
                // });


              }
            );



          }

          console.log("loginUser");

        });
      } catch (error) {
        console.log(error);
      }
    }
    setIsLoading(false);
  }

  const changeFormat = () => {
    console.log(form.password);
    setPasswordShown(!passwordShown);
  };

  const hidePassword = () => {
    setPasswordShown(false);
  };
  return (
    <div>
      {validationError && (
        <Alert message={validationError} type='error' showIcon />
      )}
      <Row align="middle" className="eco-mb-32">
        <Col span={24}>
          <div className="form-lb-wrapper"><Label>Your Email</Label></div>
        </Col>
        <Col span={24}>
          <InputBox
            id='email_address'
            type='text'
            placeholder='Enter your email'
            onChange={(e) => setField("email", e.target.value)}
            noBorder
            backgroundOnly
            error={errors.email}
            value={form?.email}
          />
          {errors.email && (
            <span className='help-text-with-errors'>* Email Is Required</span>
          )}
        </Col>
        <Col span={24}>
          <div className=""></div>
        </Col>
      </Row>

      <Row align="middle" className="eco-mb-32">
        <Col span={24}>
          <div className="form=lb-wrapper"><Label>Password</Label></div>
        </Col>
        <Col span={24}>
          <StackItem>
            <InputBox
              id='password'
              iconName={passwordShown ? "preview" : "no-preview"}
              icon="right"
              size={16}
              type={passwordShown ? "text" : "password"}
              placeholder='Enter your password'
              onChange={(e) => setField("password", e.target.value)}
              error={errors.password}
              value={form?.password}
              className='input__box_icons'
              noBorder
              backgroundOnly
              handleIconClick={() => setPasswordShown(!passwordShown)}

            />

            {errors.password && (
              <span className='help-text-with-errors'>* Password Is Required</span>
            )}
          </StackItem>
        </Col>
      </Row>


      <StackItem className="eco-mb-32">
        <Stack>
          <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} className="eco-mr-16" />
          <Typography type="text">Remember me</Typography>
        </Stack>
      </StackItem>

      <StackItem>
        <Button
          category='text'
          className='signIn__btn'
          fullWidth={true}
          onClick={handleFormSubmitting}
          disabled={errors.email || errors.password || isLoading}
        >
          Sign In
        </Button>
      </StackItem>

      {isLoading && <Spin className='eco-mt-20  login-spinner' />}
      {/* <StackItem>
          <Button
            category='text'
            className='signUp__btn'
            fullWidth={false}
            // onClick={handleFormSubmitting}
            // disabled={errors.email || errors.password}
          >
            Sign up {isLoading && <Spin />}
          </Button>
        </StackItem> */}
      {/* </Stack> */}
    </div>
  );
}

export default LoginForm;
