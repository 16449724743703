import React from "react";
import {
  Modal,
  Layout,
  Breadcrumb,
  Button,
  Skeleton,
  List,
  Avatar,
} from "antd";
import axios from "axios";
import LeftMenu from "./leftmenu";
import config from "react-global-configuration";
import { Row, Col } from "react-bootstrap";
import * as _ from "lodash";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { confirm } = Modal;

class Application extends React.Component {
  state = {
    initLoading: true,
    loading: false,
    data: [],
    list: [],
  };

  componentDidMount() {
    axios
      .get(
        config.get("backendURL") +
          "articles/bycomid/" +
          sessionStorage.getItem("company")
      )
      .then((response) => {
        console.log(response);
        this.setState({
          initLoading: false,
          data: response.data,
          list: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onPopClickDelete = (id) => {
    this.setState({ clickedid: id });

    confirm({
      title: "Are you sure delete this article?",
      icon: <ExclamationCircleOutlined />,
      content: "You can not restore this item once you delete it.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios
          .delete(config.get("backendURL") + "articles/" + id)
          .then((response) => {
            Modal.success({
              title: "Success",
              content: "Article has been deleted",
              onOk() {
                // window.location.href = '/articleslist';
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  onPopClickAdd = () => {
    // window.location.href = "/new/article/"
  };

  render() {
    const { initLoading, loading, list } = this.state;
    const loadMore =
      !initLoading && !loading ? (
        <div
          style={{
            textAlign: "center",
            marginTop: 12,
            height: 32,
            lineHeight: "32px",
          }}
        ></div>
      ) : null;

    return (
      <>
        <LeftMenu />
        <Layout className='site-layout'>
          <Content style={{ margin: "0 16px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Articles</Breadcrumb.Item>
              <Breadcrumb.Item>List of articles</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
              <Col>
                <h1>Articles</h1>
              </Col>
              <Col md={{ span: 3, offset: 3 }}>
                <Button onClick={this.onPopClickAdd.bind(this)}>Add New</Button>{" "}
              </Col>
            </Row>

            <List
              className='demo-loadmore-list'
              loading={initLoading}
              itemLayout='horizontal'
              loadMore={loadMore}
              dataSource={list}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a href={"/articles/" + item.slug} key='list-loadmore-edit'>
                      {item.pagetitle}
                      <EditOutlined />
                    </a>,
                    <a
                      href='#'
                      onClick={this.onPopClickDelete.bind(this, item._id)}
                      key='list-loadmore-more'
                    >
                      <DeleteOutlined />
                    </a>,
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            "https://cdn.neurolage.com/retailpacx/" +
                            sessionStorage.getItem("companybrandname") +
                            "/" +
                            item.featuredimage
                          }
                        />
                      }
                      title={<a href='#'>{item.title}</a>}
                      description={item.tags.join(", ")}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Content>
        </Layout>
      </>
    );
  }
}

export default Application;
