import React, { useState, useEffect } from "react";
import "../WidgetOverlay/widgetOverlay.css";
import { CloseOutlined, DingdingOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import ProductsHome from "../../products-home";

import { getCompanyByID } from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { async } from "witmeg-ui-system";
// import ProductConfig from "../../product-config";
function App(props) {
  const [compnayData, setcompnayData] = useState(null);

  return (
    <div>
      <Drawer
        title='Products Page Config'
        width={"100%"}
        closable={true}
        placement='left'
        visible={props.eCommerceOverlay}
        onClose={props.closeEcommerceOverlay}
      >
        {/* <Products compnayData={compnayData} /> */}
        <ProductsHome />
      </Drawer>
    </div>
  );
}

export default App;
