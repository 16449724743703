import React, { useEffect, useState } from "react";
import { Row, Col, Stack, Typography, Button, Message, Spin } from "witmeg-ui-system";
import { getAllThemes, getCompanyByID, editCompanyById } from "../../utilities/apiRequests/witmegRetailPaxServerRequests";
import "./themes.css";

const LandingPages = (props) => {
  const [loadSaving, setLoadSaving] = useState(false)
  const [data, setData] = useState([]);
  const [themesList, setThemesList] = useState([])
  const [activeThemeCard, setActiveThemeCard] = useState(null)
  const[isThemesLoading,setIsThemesLoading] = useState(false); 

  useEffect(() => {
    handleFetchData();
  }, []);

  const handleFetchData = async () => {
    setIsThemesLoading(true);
    const getAllThemess = await getAllThemes({
      ID: "",
      Start: 0,
      Limit: 1000,
    }).then((response) => {
      setIsThemesLoading(false)
      let activeThemesList =response.filter(r => r.ThemeName!==undefined)
      setThemesList(activeThemesList)
      setActiveThemeCard(activeThemesList[0])
    });
  };

  return (
    <div style={{margin:"32px 22px"}}>
      {isThemesLoading ? <div style={{display:'flex',justifyContent:'center'}}><Spin/> </div>:
      <Row  gutter={[16,16]}>
        {activeThemeCard && themesList.map((thm) => {
          return (
            <Col span={8} key={`thm_${thm.ID}`}>
              <Stack direction='column' className='themes-card'>
                  <div style={{
                    width: '100%',
                    height: '125px',
                    backgroundImage: `url(${thm.Image.URL})`,
                    backgroundRepeat: 'no-repeat',
                    marginBottom: '22px',
                    borderRadius: '4px',
                    backgroundSize:'cover'
                  }}>
                  </div>
                  <Typography type="title" level={5} pattern="bold">{thm.ThemeName}</Typography>
              </Stack>
            </Col>
          )
        })}
      </Row>
}
    </div>
  );
};

export default LandingPages;
