import React, { useState, useEffect } from "react";
import {
  GlobalLayout,
  Icon,
  SideMenuWithToggle,
  GlobalHeader,
  Typography,
} from "witmeg-ui-system";
import DashboardTable from "./DashboardTable";
import Logo from "../../assets/img/logo.svg";
import { logoutUser } from "../utilities/userAuthentication";
import { routePaths } from "../../routes/routeConstants";
import routerHistory from "../utilities/routerHistory";

function Dashboard() {
  const [menuState, setMenuState] = useState(true);

  /* Holds all the `OrganisationDetails` */
  const localData = localStorage.getItem("OrganisationDetails");
  let org = localData ? JSON.parse(localData) : [];;

  const [selectedLocation, setSelectedLocation] = useState(() => {
    return localStorage.getItem('selectedOrganizationLocation')? JSON.parse(localStorage.getItem('selectedOrganizationLocation')) : org[0] || [];
  });

  const hasToggleIcon = false;

  const toggleMenu = () => {
    // console.log("onToggle ", menuState);
    setMenuState(!menuState);
  };


  const profileMenuContent = [
    {
      key: 1,
      icon: "user",
      content: "Profile",
      onClick: () => {
        alert("Profile");
      },
    },
    {
      key: 2,
      icon: "settings",
      content: "Settings",
      onClick: () => {
        alert("Settings");
      },
    },
    {
      key: 3,
      icon: "logout",
      content: "Logout",
      onClick: () => {
        logoutUser({ redirectTo: routePaths.loginRoot });
      },
    },
  ];
  const helpMenuContent = [
    {
      key: 1,
      icon: "light",
      content: "What’s new in the product?",
      onClick: () => {
        alert("Guidline 1");
      },
    },
    {
      key: 2,
      icon: "mission",
      content: "How to set targets?",
      onClick: () => {
        alert("Guidline 2");
      },
    },
    {
      key: 3,
      icon: "news",
      content: "New Releases",
      onClick: () => {
        alert("Guidline 3");
      },
    },
    {
      key: 4,
      icon: "settings",
      content: "More about product setting?",
      onClick: () => {
        alert("Guidline 4");
      },
    },
  ];

  /* Commented out for future use */
  // const getLocationDetails = () => {
  //   const locationData = org?.map((x, index) => {
  //     console.log("XX", x)
  //     return (
  //       {
  //         key: index,
  //         icon: "marker-place",
  //         label: x?.OrganizationName,
  //         value: x?.OrganizationID,
  //         organizationDetails: x?.organizationDetails,
  //         companyDetails: x?.companyDetails,
  //         locationDetails: x.LocationDetails,
  //       }
  //     )
  //   })

  //   return [{
  //     label: org[0] || '',
  //     options: locationData,
  //   }];
  // }

  const handleLocationChange =(e) => {
    console.log("handleLocationChange ====>>", e)
    setSelectedLocation(e)
    localStorage.setItem('selectedOrganizationLocation', JSON.stringify(e))
  }


  return (
    <GlobalLayout>
      <SideMenuWithToggle collapsed={menuState} logoUrlPath={routePaths.dashboardRoot} logoUrl={Logo} />
      <div className='eco-layout__content'>
        <GlobalHeader
          type='complex'
          //   leftContent={<Typography>Left Content</Typography>}
          //   rightContent={<Typography>Right Content</Typography>}
          helpMenuContent={helpMenuContent}
          profileMenuContent={profileMenuContent}
          pageTitleProps={{
            pageTitle: "SHANKAR WHOLE SALE",
            pageSubtitle: "KGC FOODS LTD",
          }}
          locationSelectorProps={{
            groupedItems: org,
            handleLocationChange: (e) => { handleLocationChange(e) },
            defaultValue: selectedLocation || org[0]


          }}
          collapsed={true}
          onToggle={toggleMenu}
          hasIcon={hasToggleIcon}
          breadcrumbProperties={{
            breadcrumbRoutes: [
              // {
              //   icon: "home",
              //   title: "Home",
              //   onClick: () => {
              //     routerHistory.push(routePaths.dashboardRoot);
              //   },
              //   isActive: true,
              // },
            ],
          }}
        />
        <div className='eco-app-content'>
          <DashboardTable selectedLocation={selectedLocation} />
        </div>
      </div>
    </GlobalLayout>
  );
}

export default Dashboard;
