import React, { useState, useEffect } from "react";
import {
  Col,
  InputBox,
  Label,
  Row,
  Slider,
  Switch,
  ColorPicker,
  Icon,
  Stack,
  Button,
} from "witmeg-ui-system";
import { Tooltip } from "antd";
import { Combobox } from "react-widgets";
import { useFormContext } from "react-hook-form";

const StyleForm = ({ data }) => {
  const {
    cardBorderRadiusTopleft = 0,
    cardBorderRadiusTopRight = 0,
    cardBorderRadiusBottomRight = 0,
    cardBorderRadiusBottomLeft = 0,
    cardBorderColor,
    cardBackgroundColor,
    cardHoverBackgroundColor,
    cardBackgroundShadow,
  } = data;

  const [borderRadius, setBorderRadius] = useState(false);
  const [borderRadiusVal, setBorderRadiusVal] = useState(0);

  const [topLeftRadius, setTopLeftRadius] = useState(0);
  const [topRightRadius, setTopRightRadius] = useState(0);
  const [bottomRightRadius, setBottomRightRadius] = useState(0);
  const [bottomLeftRadius, setBottomLeftRadius] = useState(0);
  const [borderColor, setBorderColor] = useState(cardBorderColor ?? "#FFF");
  const [backgroundColor, setBackgroundColor] = useState(
    cardBackgroundColor ?? "#FFF"
  );
  const [hoverBackgroundColor, setHoverBackgroundColor] = useState(
    cardHoverBackgroundColor ?? "#FFF"
  );
  const [backgroundShadow, setBackgroundShadow] = useState(
    cardBackgroundShadow ?? false
  );

  const onChangeBorderRadius =(val) =>{
    setBorderRadiusVal(val)
    setBorderAll(val);
  }

  const setBorderAll = (val)=>{
    setTopLeftRadius(val)
    setTopRightRadius(val)
    setBottomRightRadius(val)
    setBottomLeftRadius(val)

    setValue("cardBorderRadiusTopleft", val);
    setValue("cardBorderRadiusTopRight", val);
    setValue("cardBorderRadiusBottomRight", val);
    setValue("cardBorderRadiusBottomLeft", val);


  }

  useEffect(()=>{
    setTopLeftRadius(cardBorderRadiusTopleft)
    setTopRightRadius(cardBorderRadiusTopRight)
    setBottomRightRadius(cardBorderRadiusBottomRight)
    setBottomLeftRadius(cardBorderRadiusBottomLeft)
    if (new Set([cardBorderRadiusTopleft, cardBorderRadiusTopRight, cardBorderRadiusBottomRight, cardBorderRadiusBottomLeft]).size === 1) {
        setBorderRadiusVal(cardBorderRadiusBottomLeft.toString().replace(/[^\d]/g, ""));
      }
    
  },[cardBorderRadiusTopleft,cardBorderRadiusTopRight,cardBorderRadiusBottomRight,cardBorderRadiusBottomLeft])

  useEffect(() => {
    if (borderRadiusVal) {
      setTopLeftRadius(borderRadiusVal);
      setTopRightRadius(borderRadiusVal);
      setBottomRightRadius(borderRadiusVal);
      setBottomLeftRadius(borderRadiusVal);
    }
  }, [borderRadiusVal]);

  const { setValue } = useFormContext();

  return (
    <div style={{ width: "100%", padding: "32px 20px" }}>
      <Row className="form-row eco-mb-16" align="middle">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Border Radius</Label>
          </div>
        </Col>
        <Col span={8}>
          <Slider
            value={borderRadiusVal}
            onChange={(e) => onChangeBorderRadius(e)}
          />
        </Col>
        <Col span={4}>
          <Combobox
            disabled={borderRadius ? true : false}
            defaultValue={borderRadiusVal}
            value={borderRadiusVal}
            onChange={(e) => onChangeBorderRadius(e)}
            data={[
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "20",
              "24",
              "32",
              "36",
              "40",
              "48",
              "64",
              "96",
              "100",
            ]}
            hideEmptyPopup={true}
          />
        </Col>
        <Col span={4}>
          <div
            onClick={() => setBorderRadius(!borderRadius)}
            className={borderRadius ? "selected-border-radius" : ""}
          >
            <Icon name="border-radius" color={borderRadius ? "blue" : ""} />
          </div>
        </Col>
      </Row>
      {borderRadius && (
        <Row align="middle">
          <Col span={12}></Col>
          <Col span={12}>
            <Row justify="space-between" align="middle">
              <Col span={4}>
                <div>
                  <Icon name="top-right" className="top-right-icon" />
                </div>
              </Col>
              <Col span={4}>
                <Tooltip
                  placement="topRight"
                  title="Top Left"
                  getPopupContainer={(triggerNode) => triggerNode}
                >
                  <div>
                    <InputBox
                      type="text"
                      value={topLeftRadius}
                      onChange={(e) => setValue("cardBorderRadiusTopleft",e.target.value)}
                      textAlign="center"
                      noBorder
                      backgroundOnly
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col span={4}>
                <Tooltip
                  placement="topRight"
                  title="Top Right"
                  getPopupContainer={(triggerNode) => triggerNode}
                >
                  <div>
                    <InputBox
                      type="text"
                      value={topRightRadius}
                      onChange={(e) => setValue("cardBorderRadiusTopRight",e.target.value)}
                      textAlign="center"
                      noBorder
                      backgroundOnly
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col span={4}>
                <Tooltip
                  placement="topRight"
                  title="Bottom Right"
                  getPopupContainer={(triggerNode) => triggerNode}
                >
                  <div>
                    <InputBox
                      type="text"
                      value={bottomRightRadius}
                      onChange={(e) => setValue("cardBorderRadiusBottomRight",e.target.value)}
                      textAlign="center"
                      noBorder
                      backgroundOnly
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col span={4}>
                <Tooltip
                  placement="topRight"
                  title="Bottom Left"
                  getPopupContainer={(triggerNode) => triggerNode}
                >
                  <div>
                    <InputBox
                      type="text"
                      value={bottomLeftRadius}
                      onChange={(e) => setValue("cardBorderRadiusBottomLeft",e.target.value)}
                      textAlign="center"
                      noBorder
                      backgroundOnly
                    />
                  </div>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row className="form-row eco-mb-16" align="middle">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Border Color</Label>
          </div>
        </Col>
        <Col span={8}>
          <InputBox type="text" noBorder backgroundOnly value={borderColor} />
        </Col>
        <Col span={4}>
          <ColorPicker
            value={borderColor}
            setDefaultColor={ (val) =>{ setBorderColor(val); setValue('cardBorderColor',val);}}
          />
        </Col>
      </Row>
      <Row className="form-row eco-mb-16" align="middle">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Background Shadow</Label>
          </div>
        </Col>
        <Col span={8}>
          <Switch
            checked={backgroundShadow}
            onChange={(checked, event) => { setBackgroundShadow(checked);setValue('cardBackgroundShadow',checked)}}
          />
        </Col>
      </Row>
      <Row className="form-row eco-mb-16" align="middle">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Background Color</Label>
          </div>
        </Col>
        <Col span={8}>
          <InputBox
            type="text"
            noBorder
            backgroundOnly
            value={backgroundColor}
          />
        </Col>
        <Col span={4}>
          <ColorPicker
            value={backgroundColor}
            setDefaultColor={ (val) =>{ setBackgroundColor(val); setValue('cardBackgroundColor',val);}}
          />
        </Col>
      </Row>
      <Row className="form-row eco-mb-16" align="middle">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Hover Background Color</Label>
          </div>
        </Col>
        <Col span={8}>
          <InputBox
            type="text"
            noBorder
            backgroundOnly
            value={hoverBackgroundColor}
          />
        </Col>
        <Col span={4}>
          <ColorPicker
            value={hoverBackgroundColor}
            setDefaultColor={ (val) =>{ setHoverBackgroundColor(val); setValue('cardHoverBackgroundColor',val);}}
          />
        </Col>
      </Row>
      <Row justify="center" className="form-row eco-mb-16">
        <Col span={24}>
          <div className="form-lbl-wrapper">
            <Label>Spacing</Label>
          </div>
        </Col>
        <Col span={18}>
          <Stack
            direction="column"
            style={{ border: "1px solid #cecece", padding: "10px" }}
          >
            <div style={{ width: "50px" }}>
              <InputBox type="text" value="0px" className="element-input" />
            </div>
            <Stack>
              <div style={{ flexGrow: "1", width: "50px" }}>
                <InputBox type="text" value="40px" className="element-input" />
              </div>
              <div
                style={{
                  backgroundColor: "#e7eff2",
                  flexGrow: "4",
                  padding: "10px",
                  margin: "10px",
                  width: "270px",
                }}
              >
                <Stack direction="column">
                  <div style={{ width: "50px" }}>
                    <InputBox
                      type="text"
                      value="40px"
                      className="element-input"
                    />
                  </div>
                  <Stack horizontalAlign="space_between" width={250}>
                    <div style={{ width: "50px" }}>
                      <InputBox
                        type="text"
                        value="40px"
                        className="element-input"
                      />
                    </div>
                    <div style={{ width: "50px" }}>
                      <InputBox
                        type="text"
                        value="40px"
                        className="element-input"
                      />
                    </div>
                  </Stack>
                  <div style={{ width: "50px" }}>
                    <InputBox
                      type="text"
                      value="0px"
                      className="element-input"
                    />
                  </div>
                </Stack>
              </div>
              <div style={{ flexGrow: "1", width: "50px" }}>
                <InputBox type="text" value="0px" className="element-input" />
              </div>
            </Stack>
            <div style={{ width: "50px" }}>
              <InputBox type="text" value="0px" className="element-input" />
            </div>
          </Stack>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={10}>
          <Slider />
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <Button variant="link">Reset padding</Button>
        </Col>
      </Row>
    </div>
  );
};

export default StyleForm;
