import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './commonReducer';
import organiserReducer from './organiserReducer';
import widgetReducer from './widgetReducer';
import dragReducer from './dragReducer'
import companyDataReducer from './companyDataReducer'
import menuWidgetReducer from './resturantWidgetReducer';
export default configureStore({
    reducer: {
        organisation: organiserReducer,
        widgetData: widgetReducer,
        commonData: commonReducer,
        dragData: dragReducer,
        companyData: companyDataReducer,
        resturantWidget: menuWidgetReducer
    }
});