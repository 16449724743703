import { red } from '@material-ui/core/colors';
import React, { useEffect } from 'react'
import { useState } from 'react';
import {
  Typography,
  InputBox,
  Label,
  Form,
  Col,
  Select,
  Row
} from "witmeg-ui-system";
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

const EditIcon = (props) => {
  const [captionText, setCaptionText] = useState('')
  const [captionFontSize, setCaptionFontSize] = useState('20')
  const [captionFontSizeOBJ, setCaptionFontSizeOBJ] = useState({
    value: '14',
    label: '14pt'
  })

  useEffect(() => {
    props.setIconText(captionText)
  }, [captionText])

  useEffect(() => {
    props.setTextFontSize(captionFontSize)
  }, [captionFontSize])

  const fontSizes = [{
    value: '8',
    label: '8pt'
  },
  {
    value: '10',
    label: '10pt'
  },
  {
    value: '12',
    label: '12pt'
  },
  {
    value: '14',
    label: '14pt'
  },
  {
    value: '18',
    label: '18pt'
  },
  {
    value: '24',
    label: '24pt'
  },
  {
    value: '30',
    label: '30pt'
  },
  {
    value: '36',
    label: '36pt'
  },
  {
    value: '72',
    label: '72pt'
  },
  ]

  const changeFontSize=(e)=>{
    console.log('selectedFontSize==>> ', e)
    setCaptionFontSize(e.value)
    setCaptionFontSizeOBJ(e)
  }
  
  return (
    <div style={{padding:"32px 20px"}}>
      {props.uploadedIcon ?
        <>
          <Col className="eco-mb-24">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div id='iconDiv' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p style={{ fontSize: captionFontSize + 'px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>{captionText}</p>
                <img
                  alt='icon'
                  style={{ width: "100%", margin: 'auto' }}
                  src={props.uploadedIcon}
                  draggable='false'
                />
              </div>
            </div>
          </Col>
          <Form>
            <Row align="middle" className="eco-mb-16" gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Text</Label></div>
              </Col>
              <Col span={16}>
                <InputBox type="text" noBorder backgroundOnly name="text" placeholder="Enter some text" id="text" onChange={(e) => setCaptionText(e.target.value)} />
              </Col>
            </Row>
            <Row align="middle" className="eco-mb-16" gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Tooltip</Label></div>
              </Col>
              <Col span={16}>
                <InputBox type="text" noBorder backgroundOnly name="tool-tip" placeholder="Give a Tool Tip" id="tool-tip" onChange={(e) => props.setToolTip(e.target.value)} />
              </Col>
            </Row>
            <Row align="middle" className="eco-mb-16" gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Font Size</Label></div>
              </Col>
              <Col span={4}>
                <Select
                  noBorder={true}
                  options={fontSizes}
                  placeholder='Select a Font Size'
                  onChange={(e)=> changeFontSize(e)}
                  value={captionFontSizeOBJ}
              />              
            </Col>
            </Row>
            
            {/* <Row align="middle" className="eco-mb-16" gutter={[16, 16]}>
              <Col span={8}>
                <div className="form-lbl-wrapper"><Label>Background Color</Label></div>
              </Col>
              <Col span={8}>
                <InputBox type="text" noBorder backgroundOnly />
              </Col>
              <Col span={8}>
                <ColorPicker
                  defaultColor={defaultColor}
                  setDefaultColor={(e) => setDefaultColor(e)}
                />
              </Col>
            </Row> */}
          </Form>
        </>
        :
        <Typography
          pattern='italic'
          color='gray_base'
          type='text'
          size='small'
        >
          Please crop the Icon to Edit
        </Typography>}

    </div>
  )
}

export default EditIcon