import { Modal } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Alert, Button, Message, Stack, Space } from "witmeg-ui-system";
import { Editor } from "@tinymce/tinymce-react";
// import style from "./textEditor.css"
import ReactHtmlParser from "react-html-parser";
import "./Widget.css"
import {
  getHomeLayoutByID,
  getHomeLayoutByPageID,
  updateHomeLayout,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";

import "./Widget.css";
import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";

export default function TextEditor(props) {
  const [viewEditor, setViewEditor] = useState(false);
  const [data, setData] = useState([]);
  const [editorError, setEditorError] = useState(false);
  const [typedData, setTypedData] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showhideEditor, setShowEditor] = useState(false);
  const [showToolbar, setShowToolBar] = useState(true);

  const editorRef = useRef(null);

  const dispatch = useDispatch()

  useEffect(async () => {
    await fetchData();
  }, []);

  const fetchData = async () => {
    let deviceState = sessionStorage.getItem('deviceState')
    if (props?.params?.PageID) {
      let widID = await props.params.widgetID;
      let pageID = await props.params.pageID;

      const getData = await getHomeLayoutByID({
        ID: widID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      })
        .then((res) => {
          console.log("WE HAVE TOUCH DOWN", res[0]);
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              setTypedData(res[0].ItemData);

            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0]);
              setTypedData(res[0].ItemData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let widID = await props.params.widgetID;

      const getData = await getHomeLayoutByID({
        ID: widID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      })
        .then((res) => {
          console.log("WE HAVE TOUCH DOWN", res[0]);
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              setTypedData(res[0].ItemData);
              
            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0]);
              setTypedData(res[0].ItemData);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  console.log("I AM PROPS TRU", props.params.PreviewButton);

  const log = async () => {
    setShowEditor(false);
    if (typedData == "") {
      setEditorError(true);
    } else {
      setEditorError(false);

      if (props.params.pageID) {
        setConfirmLoading(true);

        let widID = await props.params.widgetID;
        let pageID = await props.params.pageID;
        const updateWidjet = await updateHomeLayout({
          ID: widID,
          ItemHeading: "text editor",
          Item: "text editor",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.X,
          Type: "text editor",
          ItemData: typedData,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: pageID,
          CompanyID: sessionStorage.getItem("company"),
        }).then((response) => {
          console.log(response);
          dispatch(setCommonState({
            draggableState: false
          }))
          Message.success("Widget edited successfully");
          setViewEditor(false);
          setConfirmLoading(false);

          fetchData();
          // window.location.reload();
          setShowEditor(false);
        });
      } else {
        let widID = await props.params.widgetID;
        setConfirmLoading(true);
        const updateWidjet = await updateHomeLayout({
          ID: widID,
          ItemHeading: "text editor",
          Item: "text editor",
          Width: data?.Width,
          Height: data?.Height,
          X: data?.X,
          Y: data?.X,
          Type: "text editor",
          ItemData: typedData,
          RatioW: "",
          RatioH: "",
          Link: "",
          PageID: "",
          CompanyID: sessionStorage.getItem("company"),
        }).then((response) => {
          console.log(response);
          dispatch(setCommonState({
            draggableState: false
          }))
          Message.success("Widget edited successfully");
          setViewEditor(false);
          setConfirmLoading(false);

          fetchData();
          // window.location.reload();
        });
      }
    }
  };
  console.log("PROPS", data);
  const showEditor = () => {
    if (props.params.PreviewButton == false) {
      setTypedData(data?.ItemData);
      setShowEditor(true);
      dispatch(setCommonState({
        draggableState: true
      }))
    } else {
      setShowEditor(false);
    }
  };
  
  const handleEditorChange = (e) => {
    // console.log("Content was updated:", e.target.getContent());

    var temp = e.target.getContent();
    //This will remove all the unnesaccery html tags
    console.log("Content was updated:", temp)
    setTypedData(temp);
    // console.log(newContent);

    setEditorError(false);
  };

  const cancelText = () => {
    setShowEditor(false);
    dispatch(setCommonState({
      draggableState: false
    }))
    setShowToolBar(false)

    editorRef.current.setContent(data?.ItemData);
  };
  return (
    <div onDoubleClick={showEditor}>
      <div >
        {showhideEditor === false && (
          <div>
            {ReactHtmlParser(
              data?.ItemData ? data?.ItemData.replace("\r \n", "<br>") : "Neque porro quisquam"
            )}
          </div>
        )}

        {/* {showhideEditor === false && (
          <div
            style={{ width: "100%" }}
            dangerouslySetInnerHTML={{
              __html: data?.ItemData ? data?.ItemData : "Neque porro quisquam",
            }}
          ></div>
        )} */}
      </div>

      <Modal
        width={1046}
        title='Text Editor'
        maskClosable={false}
        onOk={log}
        onCancel={cancelText}
        visible={showhideEditor}

        footer={
          <Space>
            <Button
              onClick={log}
              variant="primary"
              color="white"
              customStyles={{ width: "100px" }}
              size='small'
              category={confirmLoading ? "load-text" : "text"}
              text="Confirm"
            >

              Confirm
            </Button>
            <Button

              onClick={cancelText}
              variant="secondary"
              className="eco-mr-8"
              customStyles={{ width: "100px" }}
              size="small"

            >
              Cancel
            </Button>
          </Space>
        }

      >
        {editorError && (
          <Alert message='Editor cannot be blank' type='error' showIcon />
        )}
        {showhideEditor === true && (
          <div className='eco-mt-12' style={{ position: "inherit", zIndex: "10000000" }}>
            <Editor
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={
                data?.ItemData ? data?.ItemData : "<p>Neque porro quisquam</p>"
              }

              init={{
                branding: false,
                statusbar: false,
                indent: false,
                content_css: "writer",
                editor_css: showhideEditor ? "/textEditor.css" : "",
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image",
                  "charmap print preview anchor help",
                  "searchreplace visualblocks code",
                  "insertdatetime media table paste wordcount", "textcolor",
                ],
                toolbar:
                  "undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist |      template link anchor codesample  ",
              }}
              onChange={handleEditorChange}
              apiKey='ssv2iarxt5sjvufjszf9lrat3wu395eynq4hpttb0gdyzbnh'
            />


          </div>
        )}
      </Modal>

    </div>
  );
}
