import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  Col,
  Label,
  Row,
  Space,
  Button,
  Stack,
  InputBox,
  ColorPicker,
} from "witmeg-ui-system";
import Combobox from "react-widgets/Combobox";
import FontPicker from "../../../../Components/FontPicker";

import { useFormContext } from "react-hook-form";

function CardTitleForm({data}) {
  const {
    titleFontFamily = "Open Sans",
    titleFontSize = 0,
    titleStyleItalic = false,
    titleStyleTextUnderline = false,
    titleStyleBold = false,
    titleFontAlign = "",
  } = data;

  const { setValue } = useFormContext();
  const [titleFontColor, setTitleFpntColor] = useState("#cecece");
  const [textFontFamily, setTextFontFamily] = useState(titleFontFamily);
  const [textFontSize, setTextFontSize] = useState(titleFontSize);
  const [descFontAlign, setDescFontAlign] = useState(titleFontAlign);

  const [titleTextBold, setDescriptionTextBold] = useState(titleStyleBold);
  const [titleTextItalic, setDescriptionTextItalic] =
    useState(titleStyleItalic);
  const [titleTextUnderline, setDescriptionTextUnderline] = useState(
    titleStyleTextUnderline
  );

  const onChangeTitleFontColor = (val) => {
    setTitleFpntColor(val);
    setValue("titleFontColor", val);
  };

  return (
    <Accordion title="Card Title" className="eco-mb-16">
      <div style={{ width: "100%" }}>
        <Row align="middle" className="eco-mb-16 form-row">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Family</Label>
            </div>
          </Col>
          <Col span={16}>
            <FontPicker
              id="cardTitleFontPicker"
              font={textFontFamily}
              onChange={(val) => {
                setValue("titleFontFamily", val);
                setTextFontFamily(val);
              }}
            />
          </Col>
        </Row>
        <Row align="middle" className="eco-mb-16 form-row">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Size</Label>
            </div>
          </Col>
          <Col span={8}>
            <Combobox
              value={textFontSize}
              defaultValue={0}
              onChange={(e) => {
                setValue("titleFontSize", e);
                setTextFontSize(e);
              }}
              data={[
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "20",
                "24",
                "32",
                "36",
                "40",
                "48",
                "64",
                "96",
                "100",
              ]}
              hideEmptyPopup={true}
            />
          </Col>
        </Row>
        <Row align="middle" className="eco-mb-16 form-row">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Color</Label>
            </div>
          </Col>
          <Col span={4}>
            <InputBox
              value={titleFontColor}
              type="text"
              noBorder
              backgroundOnly
              onChange={(e) => onChangeTitleFontColor(e.target.value)}
            />
          </Col>
          <Col span={3}>
            <ColorPicker
              defaultColor={titleFontColor}
              setDefaultColor={onChangeTitleFontColor}
            />
          </Col>
        </Row>
        <Row className="eco-mb-16 form-row" align="middle">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Style</Label>
            </div>
          </Col>
          <Col span={16}>
            <Space size="middle">
              <Button
                className="font-style-btn"
                category="icon"
                children="bold"
                variant={titleTextBold ? "primary" : "secondary"}
                color={titleTextBold && "white"}
                onClick={() => {
                  setValue("titleStyleBold", !titleTextBold);
                  setDescriptionTextBold(!titleTextBold);
                }}
              />
              <Button
                className="font-style-btn"
                category="icon"
                children="italic"
                variant={titleTextItalic ? "primary" : "secondary"}
                color={titleTextItalic && "white"}
                onClick={() => {
                  setValue("titleStyleItalic", !titleTextItalic);
                  setDescriptionTextItalic(!titleTextItalic);
                }}
              />
              <Button
                className="font-style-btn"
                category="icon"
                children="underline"
                variant={titleTextUnderline ? "primary" : "secondary"}
                color={titleTextUnderline && "white"}
                onClick={() => {
                  setValue("titleStyleTextUnderline", !titleTextUnderline);
                  setDescriptionTextUnderline(!titleTextUnderline);
                }}
              />
            </Space>
          </Col>
        </Row>
        <Row align="middle" className="eco-mb-16 form-row">
          <Col span={8}>
            <div className="form-lbl-wrapper">
              <Label>Font Alignment</Label>
            </div>
          </Col>
          <Col span={16}>
            <Stack>
              <Button
                category="icon"
                children="align-left"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "left" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("titleFontAlign", "left");
                  setDescFontAlign("left");
                }}
              />
              <Button
                category="icon"
                children="align-center"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "center" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("titleFontAlign", "center");
                  setDescFontAlign("center");
                }}
              />
              <Button
                category="icon"
                children="align-right"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "right" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("titleFontAlign", "right");
                  setDescFontAlign("right");
                }}
              />
              <Button
                category="icon"
                children="align-justify"
                className="eco-mr-20 text-direction-btn"
                size="small"
                iconSize={20}
                variant={descFontAlign === "justify" ? "primary" : "secondary"}
                onClick={() => {
                  setValue("titleFontAlign", "justify");
                  setDescFontAlign("justify");
                }}
              />
            </Stack>
          </Col>
        </Row>
      </div>
    </Accordion>
  );
}

CardTitleForm.propTypes = {};

export default CardTitleForm;
