import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Label, InputBox, Slider, Switch, Button, Stack, Space, ColorPicker } from 'witmeg-ui-system';
import { Popover } from 'antd';
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import divider1 from "../../../../images/divider-1.png"
import divider2 from "../../../../images/divider-2.png"
import divider3 from "../../../../images/divider-3.png"
import divider4 from "../../../../images/divider-4.png"
import FontPicker from "font-picker-react";
import { DividerStyleCategory } from '../../../utilities/helpers';

const dividerInitialState = {
    enabled: false,
    category: DividerStyleCategory.DIVIDER_1,
    style: 1,
    color: '#000000',
};

export default function Subcategories({ setSubCategoriesField, subCatFormData }) {
    const [subCategoryFontColor, setSubCategoryFontColor] = useState("")
    const [subBold, setSubBold] = useState(false)
    const [subItalic, setSubItalic] = useState(false)
    const [subUnderlinde, setSubUnderline] = useState(false)
    const [FontSize, setFontSize] = useState(null)
    const [fontList, setFontList] = useState(subCatFormData?.subCatFontFamily ? subCatFormData?.subCatFontFamily : "Open Sans")

    const [dividerState, setDividerState] = useState(subCatFormData?.divider ?? dividerInitialState);

    const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";
    const [showSubMenuSwitch, setShowSubMenuSwitch] = useState(subCatFormData.showSubMenu ?? false);

    const changeDividerState = (value) => {
        setDividerState((prevState) => {
            const newState = { ...prevState, ...value };
            setSubCategoriesField('divider', newState);
            return newState;
        });
    };


    useEffect(() => {
        setSubCategoryFontColor(subCatFormData?.subFontColor)
        setSubBold(subCatFormData?.bold)
        setSubItalic(subCatFormData?.italic)
        setSubUnderline(subCatFormData?.underline)
        setFontSize(subCatFormData?.subCatFontSize)
    }, [subCatFormData])

    const handleFontColorChange = (e) => {
        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setSubCategoriesField("subFontColor", `#${e}`)
        } else {
            setSubCategoriesField("subFontColor", "#000")
            setSubCategoryFontColor("#000")
        }
    }

    const onDividerSwitchChange = (enabled) => changeDividerState({ enabled });

    const onDividerCategoryChange = (category) => changeDividerState({ category });

    const onDividerStyleChange = (style) => changeDividerState({ style });

    const onDividerColorChange = (color, colorPicker = true) => {
        if (colorPicker) {
            changeDividerState({ color });
            return;
        }

        const regex = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$';
        const validatedColor = regex.match(color) ? `#${color}` : '#000000';

        changeDividerState({ color: validatedColor });
    };

    const dividerContent = (
        <Stack direction="column" horizontalAlign="h_center">
            <div className={dividerState.category === "divider1" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_1)}>
                <img alt={DividerStyleCategory.DIVIDER_1} src={divider1} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_1)} />
            </div>
            <div className={dividerState.category === "divider2" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_2)}>
                <img alt={DividerStyleCategory.DIVIDER_2} src={divider2} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_2)} />
            </div>
            <div className={dividerState.category === "divider3" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_3)}>
                <img alt={DividerStyleCategory.DIVIDER_3} src={divider3} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_3)} />
            </div>
            {/* <div className={dividerState.category === "divider4" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_4)}>
                <img alt={DividerStyleCategory.DIVIDER_4} src={divider4} onClick={() => onDividerCategoryChange(DividerStyleCategory.DIVIDER_4)} />
            </div> */}
        </Stack>
    )

    return (
        <div style={{ width: '100%' }}>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Display Sub Menu</Label></div>
                </Col>
                <Col span={16}>
                    <Switch
                        onChange={(e) => { setSubCategoriesField("showSubMenu", e); setShowSubMenuSwitch(e) }}
                        checked={showSubMenuSwitch} defaultChecked={showSubMenuSwitch}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Family</Label></div>
                </Col>
                <Col style={{ zIndex: "999999" }} span={16}>
                    <FontPicker
                        apiKey={API_KEY}
                        activeFontFamily={fontList}
                        onChange={(nextFont) => {
                            setSubCategoriesField("subCatFontFamily", nextFont.family);
                            setFontList(nextFont.family)
                        }
                        }
                        limit={100}
                        // sort="popularity"
                        pickerId="subcat"

                    />


                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Size</Label></div>
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue="16"
                        value={FontSize}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => setSubCategoriesField("subCatFontSize", e)}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} onChange={(e) => { handleFontColorChange(e.target.value) }} type="text" noBorder backgroundOnly value={subCategoryFontColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => setSubCategoriesField("subFontColor", color.hex)} defaultColor={subCategoryFontColor} setDefaultColor={setSubCategoryFontColor} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '32px' }}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Style</Label></div>
                </Col>
                <Col span={16}>
                    <Space size="middle">
                        <Button onClick={() => { subBold ? setSubCategoriesField('bold', false) : setSubCategoriesField("bold", true); subBold ? setSubBold(false) : setSubBold(true) }} category="icon" variant={subBold ? "primary" : 'secondary'} children="bold" color={subBold ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button onClick={() => { subItalic ? setSubCategoriesField('italic', false) : setSubCategoriesField("italic", true); subItalic ? setSubItalic(false) : setSubItalic(true) }} category="icon" variant={subItalic ? 'primary' : 'secondary'} children="italic" color={subItalic ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button onClick={() => { subUnderlinde ? setSubCategoriesField('underline', false) : setSubCategoriesField("underline", true); subUnderlinde ? setSubUnderline(false) : setSubUnderline(true) }} category="icon" variant={subUnderlinde ? 'primary' : 'secondary'} children="underline" color={subUnderlinde ? "white" : "gray"} className="font-style-btn"></Button>
                    </Space>
                </Col>
            </Row>

            {/* Divider */}
            <Row className="form-row eco-mb-16" align="middle" gutter={[16, 32]}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Divider</Label></div>
                </Col>
                <Col span={16}>
                    <Switch checked={dividerState.enabled} onChange={(value) => onDividerSwitchChange(value)} />
                </Col>
                <Col span={24}>
                    <Stack horizontalAlign="h_center">
                        <div className="eco-mr-24">
                            <div style={{ width: '100%', border: '1px solid #DBDCE3', padding: '10px' }}>
                                {(!dividerState || dividerState.category === "") && <img alt={DividerStyleCategory.DIVIDER_1} src={divider1} />}
                                {dividerState.category === DividerStyleCategory.DIVIDER_1 && <img alt={DividerStyleCategory.DIVIDER_1} src={divider1} />}
                                {dividerState.category === DividerStyleCategory.DIVIDER_2 && <img alt={DividerStyleCategory.DIVIDER_2} src={divider2} />}
                                {dividerState.category === DividerStyleCategory.DIVIDER_3 && <img alt={DividerStyleCategory.DIVIDER_3} src={divider3} />}
                                {/* {dividerState.category === DividerStyleCategory.DIVIDER_4 && <img alt={DividerStyleCategory.DIVIDER_4} src={divider4} />} */}
                            </div>
                        </div>
                        <Popover placement="right" content={dividerContent} trigger="click" getPopupContainer={(triggerNode) => triggerNode} overlayStyle={{ width: '300px' }}>
                            <Button category="icon" children="chevron-right" variant="subtle" className="show-layout-btn" />
                        </Popover>
                    </Stack>
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle" justify="space-between">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Divider Style</Label></div>
                </Col>
                <Col span={12}>
                    <Slider min={1} max={6} value={dividerState.style} onChange={(value) => onDividerStyleChange(value)} />
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue={dividerInitialState.style}
                        value={dividerState.style}
                        data={[1, 2, 3, 4, 5, 6]}
                        hideEmptyPopup={true}
                        onChange={(value) => onDividerStyleChange(value)}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Colour</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox allowPaste={false} type="text" noBorder backgroundOnly value={dividerState.color} disabled setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => onDividerColorChange(color.hex)} defaultColor={dividerState.color} setDefaultColor={dividerState.color} />
                </Col>
            </Row>

        </div>
    )
}
