import { hot } from 'react-hot-loader/root';
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Layout, Menu, Breadcrumb } from "antd";
import Header from "./header";
import Footer from "./footer";
import HomePage from "./home";
import Pages from "./pages";
import Articles from "./articles";
import ArticlesList from "./articles-list";
import NewArticle from "./new-article";
import NewCategory from "./new-category";
import Category from "./category";
import Site from "./siteselect";
import NewPage from "./new-page";
import UploadImage from "./imageupload";
import Preview from "./preview";
import Landing from "./dashboard";
import "antd/dist/antd.css";

//NEW URLS
import SiteSelect from "./Pages/SiteSelect/SiteSelect";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Home from "./Pages/Home/Home";
import PrivateRoute from "./routes/helpers/PrivateRoute/PrivateRoute";
import TestGrid from "./Pages/Home/TestGrud";
import PricingPlan from "./Pages/ReportWiidjet/PricePlan";
import TestRedo from "./Pages/Hooks/TestRedo";
import VerticalTwoColWithoutImageForm from './Pages/ReportWiidjet/ResturantMenu/Common/VerticalTwoColWithoutImageForm';
import CustomTabs from './Pages/ReportWiidjet/ResturantMenu/Common/CustomTabs';

class Application extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          {(() => {
            switch (window.location.pathname) {
              case "/":
                return <></>;
              default:
                return <>{/* <Header />     */}</>;
            }
          })()}
{/* , overflowY: "scroll" */}
          <Layout style={{ minHeight: "100vh", maxHeight: "100vh" }}>
            <Route exact path='/' component={Login} />

            <Route path='/price-plan' component={PricingPlan} />

            {/* <Route exact path='/welcome' component={Dashboard} /> */}

            <PrivateRoute path='/welcome'>
              <Dashboard />
            </PrivateRoute>

            <Route exact path='/welcome-2' component={Landing} />
            <Route exact path='/testRedo' component={TestRedo} />
            <Route exact path='/home-2' component={HomePage} />
            <Route exact path='/home-3' component={TestGrid} />

            <PrivateRoute exact path='/home/:id'>
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path='/home'>
              <Home />
            </PrivateRoute>



            <Route exact path='/sites/:id' component={SiteSelect} />
            <Route exact path='/sites-2/:id' component={Site} />
            <Route exact path='/pages/:id' component={Pages} />
            <Route exact path='/articleslist' component={ArticlesList} />
            <Route exact path='/articles/:id' component={Articles} />
            <Route exact path='/category/:id' component={Category} />
            <Route exact path='/new/article' component={NewArticle} />
            <Route exact path='/new/category' component={NewCategory} />
            <Route exact path='/new/page/:id' component={NewPage} />
            <Route exact path='/upload' component={UploadImage} />
            <Route exact path='/preview/:id' component={Preview} />
            <Route exact path='/layout/one-col-without-image' component={CustomTabs} />
          </Layout>
          {/* <Footer /> */}
        </Router>
      </React.Fragment>
    );
  }
}

export default hot(Application);
