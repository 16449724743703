import React from "react";
import { Row, Col, Label, InputBox } from "witmeg-ui-system";
import "./BasicInformation.css";

function DomainInformation({ setField, errors, selectedProduct }) {
  console.log(selectedProduct);
  return (
    <div>
      {" "}
      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Sub Domain </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            placeholder='Enter your subdomain'
            noBorder backgroundOnly
            value={selectedProduct?.SubDomain}
            onChange={(e) => setField("subDomain", e.target.value)}
            error={errors.subDomain}
          />

          {/* {errors.companyName && (
        <span className='help-text-with-errors'>
          Company Name Is Required
        </span>
      )} */}
        </Col>
      </Row>
      <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label required>Customer Domain </Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            placeholder='Enter your customer domain'
            noBorder backgroundOnly
            value={selectedProduct?.CustomerDomain}
            onChange={(e) => setField("customerDomain", e.target.value)}
            error={errors.customerDomain}
          />

          {/* {errors.companyName && (
        <span className='help-text-with-errors'>
          Company Name Is Required
        </span>
      )} */}
        </Col>
      </Row>
      {/* <Row className='form-row'>
        <Col span={8}>
          <div className='form-lbl-wrapper'>
            <Label>Organisation ID</Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type='text'
            placeholder=''
            value={selectedProduct?.OrganisationID}
            onChange={(e) => setField("organisationID", e.target.value)}
          />

          
        </Col>
      </Row> */}
    </div>
  );
}

export default DomainInformation;
