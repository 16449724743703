import React, { Component } from "react";
import { Layout, Menu, Select, Modal, Popover, List, Dropdown } from "antd";
import { Button, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import LeftMenu from "./leftmenu";
import LayoutInclude from "./layout-include";
import config from "react-global-configuration";
import ReactGridLayout from "react-grid-layout";
import * as _ from "lodash";
import jwt from "jsonwebtoken";
import closeIcon from "./images/Windows-Close-Program-icon.png"; // with import
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
const { SubMenu } = Menu;

const onLRemoveItem = (i) => {
  alert(i);
  this.setState({ items: _.reject(this.state.items, { i: i }) });

  //console.log(i)
  axios
    .delete(config.get("backendURL") + "homelayout/" + JSON.parse(i)[0])
    .then((res) => {
      Modal.success({
        title: "Success",
        content: "Item has been deleted",
        onOk() {
          window.location.reload(false);
        },
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

const menu = (i) => (
  <Menu>
    <Menu.ItemGroup title='Group title'>
      <Menu.Item icon={<UserOutlined />}>1st menu item</Menu.Item>
      <Menu.Item icon={<UserOutlined />}>2nd menu item</Menu.Item>
    </Menu.ItemGroup>
    <Menu.Divider />
    <SubMenu title='sub menu'>
      <Menu.Item>3rd menu item</Menu.Item>
      <Menu.Item>4th menu item</Menu.Item>
    </SubMenu>
    <Menu.Divider />
    <SubMenu title='disabled sub menu' disabled>
      <Menu.Item>5d menu item</Menu.Item>
      <Menu.Item>6th menu item</Menu.Item>
    </SubMenu>
    <Menu.Item danger onClick={(i) => onLRemoveItem}>
      Delete
    </Menu.Item>
  </Menu>
);

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
// const ResponsiveReactGridLayout = WidthProvider(Responsive);
require("dotenv").config();
const { Header, Sider } = Layout;
const { Option } = Select;

export default class Application extends Component {
  static defaultProps = {
    className: "layout",
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 2 },
    rowHeight: 20,
  };

  constructor(props) {
    super(props);

    const datax = [],
      datay = [];
    const loading = true;
    this.state = {
      datax,
      loading,
      datay,
      menus: [],
      layout: [],
      widgets: [],
      showHide: "",
      hover: null,
      plusText: "+",
      items: [],
      newCounter: 0,
    };

    this.onAddItem = this.onAddItem.bind(this);
  }

  createElement(el) {
    console.log("I AM EL BIII", el);
    const removeStyle = {
      position: "absolute",
      right: "2px",
      top: 0,
      cursor: "pointer",
    };
    const i = el.i;
    return (
      <div
        key={i}
        data-grid={el}
        onMouseEnter={() => this.mouseEnter(i)}
        onMouseLeave={this.mouseLeave}
      >
        <span
          className={
            this.state.hover === i
              ? "disBlock  remove rowremove"
              : "disNone" + " remove rowremove"
          }
        >
          ROW{" "}
        </span>
        <Dropdown overlay={menu(i)} trigger={["contextMenu"]}>
          <div
            className='site-dropdown-context-menu'
            style={{
              textAlign: "center",
              height: 200,
              lineHeight: "200px",
            }}
          >
            <LayoutInclude
              params={{ layoutid: JSON.parse(i)[0], w: el.x, h: el.y }}
            />
          </div>
        </Dropdown>
        <span
          className={
            this.state.hover === i ? "disBlock  remove" : "disNone" + " remove"
          }
          style={removeStyle}
          onClick={this.onLRemoveItem.bind(this, i)}
        >
          <img src={closeIcon} />
        </span>

        <span
          className={
            this.state.hover === i
              ? "disBlock  remove rowadd "
              : "disNone" + " remove rowadd "
          }
          onClick={this.onAddItem.bind(this, "newsection", "", "newsection")}
          // Thiss Will the Add the Plus Icon Text
          onMouseEnter={this.mouseEnterAdd}
          // This Will Add the Plus Icon Only
          onMouseLeave={this.mouseLeaveAdd}
        >
          {this.state.plusText}
        </span>
      </div>
    );
  }

  onAddItem(items, itemdetails, itemheading) {
    /*eslint no-console: 0*/
    // console.log("adding", "n" + this.state.newCounter);
    console.log("ITEMS_--===> ", itemdetails);
    const item = "ITEM";
    const width = 12;
    const height = 4;
    const itemdata = itemdetails;
    const type = items;
    const x = (this.state.items.length * 2) % (this.state.cols || 12);
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: "n" + this.state.newCounter,
        i: JSON.stringify([
          this.state.newCounter.toString(),
          itemheading,
          item,
          itemdata,
          type,
        ]),
        x: x,
        y: Infinity, // puts it at the bottom
        w: width,
        h: height,
      }),
      // Increment the counter to ensure key is always unique.

      newCounter: this.state.newCounter + 1,
    });

    var WidgetsDtl = {
      itemheading: itemheading,
      item: item,
      width: width,
      itemdata: itemdata,
      type: type,
      companyid: sessionStorage.getItem("company"),
      height: height,
      x: x,
      y: 1000,
    };
    // console.log(items)

    const timestamp = Math.floor(Date.now() / 1000) + 1000;
    var payload = {
      aud: process.env.REACT_APP_jwtAud,
      exp: timestamp,
      iss: process.env.REACT_APP_jwtIss,
    };

    var token = jwt.sign(payload, process.env.REACT_APP_secret);

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ` + token,
      },
    };

    axios
      .post(
        config.get("backendURL") + "homelayout/add",
        WidgetsDtl,
        requestOptions
      )
      .then((res) => {
        // Modal.success({
        //   title: "Success",
        //   content: "New items has been added",
        //   // onOk() {
        //   //   window.location.reload(false);
        //   // },
        // });
      });
  }

  onLRemoveItem(i) {
    // alert(i);
    // return;
    // console.log("removing", i);
    this.setState({ items: _.reject(this.state.items, { i: i }) });

    //console.log(i)
    axios
      .delete(config.get("backendURL") + "homelayout/" + JSON.parse(i)[0])
      .then((res) => {
        Modal.success({
          title: "Success",
          content: "Item has been deleted",
          onOk() {
            window.location.reload(false);
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onwidgetClose = () => {
    this.setState({
      widgetvisible: false,
    });
  };

  mouseEnter = (i) => {
    this.setState({
      showHide: "disBlock",
      hover: i,
    });
  };
  mouseLeave = () => {
    this.setState({
      showHide: "disNone",
    });
  };
  mouseEnterAdd = () => {
    this.setState({
      plusText: "+ Add  Section",
    });
  };
  mouseLeaveAdd = () => {
    this.setState({
      plusText: "+",
    });
  };
  componentDidMount() {
    this.setState(() => {
      axios
        .get(
          config.get("backendURL") +
            "homelayout/" +
            sessionStorage.getItem("company")
        )
        .then((response) => {
          console.log(response.data);
          if (response.data.length > 0) {
            this.setState({
              items: response.data.map(function (i, key, list) {
                return {
                  i: JSON.stringify([
                    i._id.toString(),
                    i.itemheading.toString(),
                    i.item.toString(),
                    i.itemdata.toString(),
                    i.type.toString(),
                  ]),
                  x: i.x,
                  y: i.y,
                  w: i.width,
                  h: i.height,
                  link: i.link,
                  add: i === list.length - 1,
                };
              }),
              loading: true,
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });

    const requestMenu = axios
      .get(config.get("backendURL") + "widgets")
      .then((response) => {
        this.setState({ widgets: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    if (sessionStorage.getItem("theme") == "Apparel-casi") {
      require("./templates/apparel-casi/css/core_styles.css");
    } else if (sessionStorage.getItem("theme") == "Spiceway") {
      require("./templates/spiceway/css/style.css");
    } else if (sessionStorage.getItem("theme") == "MeatShop") {
      require("./templates/meatshop/css/meatshop.css");
    }
  }

  onLayoutChange = (layout) => {
    console.log(layout);

    let currentHideNav = window.innerWidth <= 1024;

    //  console.log(currentHideNav)
    if (!currentHideNav) {
      this.setState({ layout: layout });
      layout.map(function (j, key, list) {
        console.log(j.i[1]);
        const pageData = {
          width: j.w,
          height: j.h,
          x: j.x,
          y: j.y,
          itemheading: JSON.parse(j.i)[1],
          item: JSON.parse(j.i)[2],
          type: JSON.parse(j.i)[4],
          itemdata: JSON.parse(j.i)[3],
          companyid: sessionStorage.getItem("company"),
        };
        //console.log(JSON.parse(j.i))

        const timestamp = Math.floor(Date.now() / 1000) + 1000;
        var payload = {
          aud: process.env.REACT_APP_jwtAud,
          exp: timestamp,
          iss: process.env.REACT_APP_jwtIss,
        };

        var token = jwt.sign(payload, process.env.REACT_APP_secret);

        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + token,
          },
        };

        axios
          .post(
            config.get("backendURL") +
              "homelayout/update/" +
              JSON.parse(j.i)[0],
            pageData,
            requestOptions
          )
          .then((res) => {
            // console.log(res.data)
          });
      });
    }
  };

  render() {
    const { widgets } = this.state;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {},
      nodeSelector: "div",
      handleSelector: "a",
    };
    const { loading } = this.state;
    return (
      <>
        {/* LEFT SIDE BAR STARTS */}
        <LeftMenu />
        {/* LEFT SIDE BAR ENDS */}
        <Layout className='site-layout Apparel-casi'>
          <Row>
            <Col sm={12}>
              {/* POPOVER THAT APPEARS WHEN THE ADD WIDGET BTN CLICKS START */}
              <Popover
                placement='bottom'
                trigger='focus'
                content={
                  <List itemLayout='horizontal'>
                    {this.state.widgets.map((widget) => (
                      <List.Item>
                        <List.Item.Meta
                          onClick={this.onAddItem.bind(
                            this,
                            widget.item,
                            widget.itemdetails,
                            widget.item
                          )}
                          title={
                            <a style={{ textTransform: "capitalize" }}>
                              {widget.item}
                            </a>
                          }
                          description={"Create a " + widget.item + " widget"}
                        />
                      </List.Item>
                    ))}
                  </List>
                }
              >
                <Button
                  variant='outlined'
                  color='primary'
                  className=''
                  startIcon={<PlusCircleOutlined />}
                >
                  Add widget
                </Button>
              </Popover>

              <Popover
                placement='bottom'
                trigger='focus'
                content={
                  <List itemLayout='horizontal'>
                    <List.Item>
                      <List.Item.Meta
                        onClick={this.onAddItem.bind(
                          this,
                          "bestselling",
                          "",
                          "Best Selling Products"
                        )}
                        title={
                          <a style={{ textTransform: "capitalize" }}>
                            Best Selling
                          </a>
                        }
                        description={"Add a best selling widget"}
                      />
                    </List.Item>
                    <List.Item>
                      <List.Item.Meta
                        onClick={this.onAddItem.bind(
                          this,
                          "featured",
                          "",
                          "Featured Products"
                        )}
                        title={
                          <a style={{ textTransform: "capitalize" }}>
                            Featured
                          </a>
                        }
                        description={"Add a featured widget"}
                      />
                    </List.Item>
                    <List.Item>
                      <List.Item.Meta
                        onClick={this.onAddItem.bind(
                          this,
                          "featuredoffers",
                          "",
                          "Featured offers"
                        )}
                        title={
                          <a style={{ textTransform: "capitalize" }}>
                            Featured offers
                          </a>
                        }
                        description={"Add a featured offers widget"}
                      />
                    </List.Item>
                    <List.Item>
                      <List.Item.Meta
                        onClick={this.onAddItem.bind(
                          this,
                          "offers",
                          "",
                          "Offers"
                        )}
                        title={
                          <a style={{ textTransform: "capitalize" }}>Offers</a>
                        }
                        description={"Add a offers widget"}
                      />
                    </List.Item>
                  </List>
                }
              >
                <Button
                  variant='outlined'
                  color='primary'
                  className=''
                  startIcon={<PlusCircleOutlined />}
                >
                  Add E-Commerce widget
                </Button>
              </Popover>
            </Col>
          </Row>

          {/* POPOVER THAT APPEARS WHEN THE ADD WIDGET BTN CLICKS END */}

          {/* GRID LAYOUT STARTS */}
          <ReactGridLayout
            onLayoutChange={this.onLayoutChange}
            onBreakpointChange={this.onBreakpointChange}
            compactType="vertical"
            {...this.props}
          >
            {_.map(this.state.items, (el) => this.createElement(el))}
          </ReactGridLayout>
          {/* GRID LAYOUT ENDS */}
        </Layout>
      </>
    );
  }
}
