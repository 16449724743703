
import { Modal } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Accordion, Button, Message, Space, Typography } from 'witmeg-ui-system'
import { setCommonState } from '../../redux/commonReducer'
import AccordianSettings from './AccordianSettings/index'
import { getHomeLayoutByID, getHomeLayoutByPageID, updateHomeLayout } from '../utilities/apiRequests/witmegRetailPaxServerRequests';
import { parse } from '@fortawesome/fontawesome-svg-core'
function AccordianWidget(props) {

    const { widgetID, checkData, PreviewButton, pageID } = props.params
    const [showPopup, setShowPopUp] = useState(false)
    const [data, setData] = useState(null)
    const [parsedData, setParsedData] = useState(null)
    const [maximumAlert, setMaximAlert] = useState(false)
    const [errorOptions, setErrorOptions] = useState([])
    const [errors, setErrors] = useState([]);
    const dispatch = useDispatch();
    const handlePopup = () => {
        if (PreviewButton === false) {
            dispatch(
                setCommonState({
                    draggableState: true,
                })
            );
            setErrorOptions([])
            setShowPopUp(true)

        }
    }
    const [accordianData, setAccordianData] = useState([
        {
            accordian_question: "",
            accordian_answer: '',

        }
    ]);


    const [queserror, setQuestionError] = useState(false)
    const [anserror, setAnswerError] = useState(false)
    const [accordianTopic, setAccordianTopic] = useState(null)
    const onCancel = () => {
        setShowPopUp(false)
        dispatch(
            setCommonState({
                draggableState: false,
            })
        );
        setAnswerError(false)
        setQuestionError(false)
    }

    // console.log("accordianData", data);


    const fetchData = async () => {
        let deviceState = sessionStorage.getItem("deviceState");

        const getData = await getHomeLayoutByID({
            ID: widgetID,
            PageID: pageID,
            CompanyID: sessionStorage.getItem("company"),
            Start: 0,
            Limit: 1000,
        })
            .then(async (res) => {
                if (deviceState === "desktop") {
                    if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {
                         
                        setData(res[0]);
                        const parsedBtnData = await JSON.parse(res[0].ItemData)
                        setParsedData(parsedBtnData)
                        console.log("parse", parsedBtnData)
                        setAccordianTopic(parsedBtnData.accordianHeader);
                        setAccordianData(parsedBtnData.accordian)

                    }
                } else if (deviceState == "mobile") {
                    if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
                        setData(res[0]);

                        const parsedBtnData = await JSON.parse(res[0].ItemData)
                        setParsedData(parsedBtnData)
                        console.log("parse", parsedBtnData)
                        setAccordianTopic(parsedBtnData.accordianHeader);
                        setAccordianData(parsedBtnData.accordianData)


                    }
                }
            })
            .catch((error) => {
                // console.log(error);
            });

    };

    useEffect(() => {

        fetchData();
    }, [])


    const findFormErrors = () => {
         
        let newErrors = []
        let missingBasicFieldNames = []
        for (let index = 0; index < accordianData.length; index++) {
            const element = accordianData[index];
            console.log("element", element);
            
        //   if(index != 0) {
            if (!element.accordian_answer || element.accordian_answer.trim() === "") {
                newErrors.push({
                    index: index,
                    name: "accordian_answer",
                });
                missingBasicFieldNames.push(`Accordion Answer  in ${index + 1}`);
            }
            if (!element.accordian_question || element.accordian_question.trim() === "") {
                newErrors.push({
                    index: index,
                    name: "accordian_question",
                });
                missingBasicFieldNames.push(`Accordion Question in ${index + 1}`);
            }
            
        //   }
        }

        setErrors(newErrors)     
        return missingBasicFieldNames
    }

    const handleSubmit = async () => {
        
        
       
        // if (accordianData[0].accordian_question === "" || accordianData[0].accordian_answer === "") {
        //     setAnswerError(true)
        //     setQuestionError(true)
        // } else {
             
            if(accordianData.length > 0){
           const accordionErrors  = await findFormErrors()
           console.log(accordionErrors, errorOptions)

           if(accordionErrors.length > 0) {
            setErrorOptions(accordionErrors)
           
           } else {
            await fetchData();
            setAnswerError(false)
            setQuestionError(false)
            let ItemData = { accordian: accordianData, accordianHeader: accordianTopic }
            let jsonData = await JSON.stringify(ItemData);
 
 
            let PropspageID = await pageID;
            const updateWidget = await updateHomeLayout({
                ID: widgetID,
                ItemHeading: "accordion",
                Item: "accordion",
                Width: data?.Width,
                Height: data?.Height,
                X: data?.X,
                Y: data?.Y,
                Type: "accordion",
                ItemData: jsonData,
                RatioW: "",
                RatioH: "",
                Link: "",
                ACC: "I AM CALLING",
                PageID: PropspageID,
                CompanyID: sessionStorage.getItem("company"),
 
            }).then(async (response) => {
                Message.success("Widget edited successfully");
                setShowPopUp(false)
                dispatch(
                    setCommonState({
                        draggableState: false,
                    })
                );
                setMaximAlert(false)
                setErrorOptions([])
                // window.dispatchEvent(new Event("resize"));
                fetchData();
 
            })
             }
           }
        // }

    }



    return (
        <div onDoubleClick={handlePopup}>
            <div>


                {!data?.ItemData && <>
                    <Accordion
                        theme='default'
                        type='content_only'
                        title='Title 1'
                        collapsed={true}
                    />
                    <Accordion
                        theme='default'
                        type='content_only'
                        title='Title 1'
                        collapsed={true}
                    />
                    <Accordion
                        theme='default'
                        type='content_only'
                        title='Title 1'
                        collapsed={true}
                    />
                </>
                }

                {parsedData && parsedData.accordian &&
                    <>
                        <Typography type="title" pattern="bold" textTransform="capitalize">{parsedData.accordianHeader}</Typography>
                        {parsedData?.accordian?.map((data, index) => {
                            return (
                                <Accordion
                                    theme='default'
                                    type='content_only'
                                    title={data.accordian_question}
                                    collapsed={false}
                                >
                                    <div style={{ width: "100%" }}>

                                        {data.accordian_answer}
                                    </div>
                                </Accordion>
                            )
                        })}
                    </>
                }
            </div>



            <div>
                <Modal
                    width={644}
                    maskClosable={false}
                    title="Accordion"
                    onCancel={onCancel}
                    visible={showPopup}
                    footer={
                        <Space size="middle">
                            <Button onClick={handleSubmit} variant="primary" className="save-btn">Save</Button>
                            <Button onClick={onCancel} variant="outline" className="cancel-btn">Cancel</Button>
                        </Space>
                    }
                >
                    <AccordianSettings errorOptions = {errorOptions} errors = {errors} queserror={queserror}
                       maximumAlert ={maximumAlert}
                       setMaximAlert = {setMaximAlert} anserror={anserror} accordianData={accordianData} setAccordianData={setAccordianData} accordianTopic={accordianTopic} setAccordianTopic={setAccordianTopic} />
                </Modal>
            </div>
        </div>
    )
}

export default AccordianWidget