import React, { useState, useEffect } from 'react';
import { Stack, Typography, Tag, Icon, Button, Toggle, Spin, Select } from 'witmeg-ui-system';
import { PlusCircleOutlined, MinusOutlined } from '@ant-design/icons';
import './Widget.css';
import { getFrequencyDetails, getTierDetails, getVerticalListPlan, getVerticalPlanFeatures } from '../utilities/apiRequests/witmegRetailPaxServerRequests';
import globalValues from '../../configs/globalValues';
import _ from 'lodash';

const PricingPlan = () => {
  const [frequencies, setFrequencies] = useState([]);
  const [selectedFrequency, setSelectedFrequency] = useState(null);
  const [verticalPlan, setVerticalPlan] = useState([]);
  const [selectedVerticalPlan, setSelectedVerticalPlan] = useState(null);
  const [tierDetails, setTierDetails] = useState();
  const [featureList, setFeatureList] = useState([]);
  const [featureDataRows, setFeatureDataRows] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // const [pageState, setPageState] = useState({
  //   init: {
  //     frequencies: [],
  //     verticalPlans: [],
  //     featureList: [],
  //     tierDetails: {},
  //   },
  //   selected: {
  //     frequency: null,
  //     verticalPlan: null,
  //   },
  //   featureDataRows: [],
  //   showMore: false,
  //   isLoading: true,
  // });

  const toggleShowMore = () => setShowMore((prevState) => !prevState);

  const fetchFrequencyData = async () => {
    const frequencyData = await getFrequencyDetails();
    if (frequencyData) {
      setFrequencies(frequencyData);
      setSelectedFrequency(frequencyData[0]);
    }
  };

  const fetchVerticalPlansData = async () => {
    const verticalPlansData = await getVerticalListPlan();
    if (verticalPlansData) {
      const planOptions = verticalPlansData?.map((data) => {
        return {
          value: { _id: data._id, AppID: data.PrimaryAppID },
          label: data.VerticalName,
        };
      });
      setSelectedVerticalPlan(planOptions[0]);
      setVerticalPlan(planOptions);
    }
  };

  const fetchFeatures = async () => {
    const featuresData = await getVerticalPlanFeatures({
      CurrencyType: 'GBP',
      FrequencyID: selectedFrequency._id,
      VerticalID: selectedVerticalPlan.value._id,
    });

    return featuresData ?? null;
  };

  const fetchTierDetails = async () => {
    const tierData = await getTierDetails({
      CurrencyType: 'GBP',
      FrequencyID: selectedFrequency._id,
      VerticalID: selectedVerticalPlan.value._id,
    });

    return tierData ?? null;
  };

  /* Fetch initial data: Frequencies & Vertical Plans */
  useEffect(() => {
    const _fetchData = async () => {
      await Promise.all([fetchFrequencyData(), fetchVerticalPlansData()]);
    }
    _fetchData();
  }, []);

  /* Runs when `Selected Frequency` or `Selected Vertical Plan` changed */
  useEffect(() => {
    const _initData = async () => {
      /* Check if both `selectedFrequency` & `selectedVerticalPlan` are available */
      if (selectedFrequency && selectedVerticalPlan) {
        setIsLoading(true);
  
        /* Fetch `All features` & `All tier details` */
        const paymentPlanFeatures = await Promise.all([
          fetchFeatures(),
          fetchTierDetails(),
        ]);
  
        /* Compare `All features` with `Tier features` */
        const comparedFeatures = await featuresListComparison(
          paymentPlanFeatures[0],
          paymentPlanFeatures[1]
        );
  
        if (paymentPlanFeatures[0]) setFeatureList(paymentPlanFeatures[0]);
        if (paymentPlanFeatures[1]) setTierDetails(paymentPlanFeatures[1]);
        if (comparedFeatures) setFeatureDataRows(comparedFeatures);
  
        setIsLoading(false);
      }
    }
    _initData();
  }, [selectedVerticalPlan, selectedFrequency]);

  /* Compares `Tier features` with `All features` */
  const featuresListComparison = async (features, tiers) => {
    if (!features || features.length === 0) return;
    // if (!tier.Catalogues || tier.Catalogues.length === 0) return;

    /* Feature data row list */
    const featureRows = [];

    /* Loops through all `features` */
    for (let feature of features) {
      if (!feature.IsActive) continue;

      let rowData = {
        title: feature.MarketingTerm,
        type: feature.Type,
        values: [],
      };

      /* Feature row value compared with tiers */
      let tierFeatureValues = [];

      for (let tier of tiers) {
        // if (!tier.Catalogues || tier.Catalogues.length === 0) return;

        let tierFeatureValue = { isRecommended: tier.Tier.IsRecommendedTier ?? false };

        if (!tier.Catalogues || tier.Catalogues.length === 0) {
          tierFeatureValue = {
            ...tierFeatureValue,
            isEmpty: true,
          };
          tierFeatureValues.push(tierFeatureValue);
          continue;
        }

        /* Check if whether feature is available in `tier` */
        const _tierValue = _.find(tier.Catalogues, { FeatureID: feature._id });

        /* If `feature` is not available, set as empty */
        if (!_tierValue || !_tierValue.FeatureID) {
          tierFeatureValue = {
            ...tierFeatureValue,
            isEmpty: true,
          };
          tierFeatureValues.push(tierFeatureValue);
          continue;
        }
        
        //   if (!tierValue.IsVisible) continue;

        // /* `Type` => `Features` */
        // if (_tierValue.Type === 'Features') {
        //   tierFeatureValue = {
        //     ...tierFeatureValue,
        //     isEmpty: false,
        //   };
        //   tierFeatureValues.push(tierFeatureValue);
        //   continue;
        // }

        // /* `Type` => `Optional` */
        // if (_tierValue.Type === 'Optional') {
        //   continue;
        // }

        // /* `Type` => `Default` */
        // if (_tierValue.Type === 'Default') {
        //   continue;
        // }

        if(_tierValue.Pricing && _tierValue.Pricing.length > 0){
          tierFeatureValue = {
            ...tierFeatureValue,
            displayText: _tierValue.Pricing[0].Cost.gbp.DisplayText,
          };
          tierFeatureValues.push(tierFeatureValue);
          continue;
        }

        tierFeatureValue = {
          ...tierFeatureValue,
          isEmpty: false,
        };
        tierFeatureValues.push(tierFeatureValue);
      } 

      rowData = { ...rowData, values: tierFeatureValues };
      featureRows.push(rowData);
    }

    return featureRows;
  };

  /* Opens the link in new tab */
  const openInNewTab = (_url) => {
    const newWindow = window.open(_url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleFrequencyToggle = (_value) =>
    setSelectedFrequency(_value ? frequencies[1] : frequencies[0]);

  const handleSelectPricePlan = (_data) => setSelectedVerticalPlan(_data);

  // Redirect To Control Center
  const handleChangePage = (_tier) => {
    openInNewTab(`${globalValues.baseURLS.REACT_APP_CONTROL_CENTER_BASE_URL}signup-process-landing-page/${selectedVerticalPlan.value._id}/${_tier.Tier._id}/GBP/${selectedFrequency._id}`);
  };

  return (
    <div className="pricing-plan-page" style={{ overflowY: 'scroll' }}>
      <Stack direction="column" verticalAlign="v_center">
        <Typography type="title" level={5} pattern="bold" className="eco-mb-24">
          Join the Innovation
        </Typography>
        <Typography type="title" level={1} pattern="bold" className="eco-mb-24">
          Pricing Plan
        </Typography>

        <div style={{ width: '30%' }} className="eco-mb-24">
          <Stack horizontalAlign="space_evenly">
            {/* `Frequencies` toggle button */}
            {frequencies && frequencies.length > 0 && (
              <>
                <Tag
                  label={frequencies[0].FrequencyName}
                  variant="blue"
                  className={frequencies[0] === selectedFrequency ? 'org-tag' : 'org-tag-update'} />
                <Toggle onChange={(e) => handleFrequencyToggle(e.target.checked)} className="on-toggle-btn" />
                <Tag
                  label={frequencies[1].FrequencyName}
                  variant="blue"
                  className={frequencies[1] === selectedFrequency ? 'org-tag' : 'org-tag-update'} />
              </>
            )}

            <Tag label="SAVE 20%" variant="green" className="tier-tag" />
          </Stack>
        </div>

        <div className="eco-mb-24">
          <Typography type="title" level={4} color="gray_darker">
            Flexible pricing plans and dedicated solutions for businesses
          </Typography>
          <div className="eco-mt-12">
            <Select
              type="options-with-links"
              options={verticalPlan}
              value={selectedVerticalPlan ?? {}}
              isMulti={false}
              onChange={(e) => handleSelectPricePlan(e)} />
          </div>
        </div>
      </Stack>

      <br />

      {isLoading ? 
        <Spin className="price-spinner" size="large" />
       : (
        <div className="table-container">
          <table className="pricing-plan-table">
            {/* Payment Plan title */}
            <tr>
              <th>&nbsp;</th>
              {tierDetails && tierDetails.map((item, index) => (
                  <th
                    key={index}
                    style={{ borderTop: '1px solid #cecece' }}
                    className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}>
                    {item.Tier.IsRecommendedTier ? 
                      <Tag label={item.Tier.TierName} variant="green" className="tier-tag-card" />
                     : 
                      <Typography alignment="center" type="text" color="gray_base">
                        {item.Tier.TierName}
                      </Typography>
                    }
                  </th>
                ))}
            </tr>

            {/* Payment Plan cost */}
            <tr>
              <th>&nbsp;</th>
              {tierDetails &&
                tierDetails.map((item, index) => (
                  <th key={index} className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}>
                    <Typography
                      alignment="center"
                      type="title"
                      level={1}
                      pattern="bold"
                      color={item.Tier.IsRecommendedTier ? 'white_base' : 'gray_darkest'}>
                      £ &nbsp;{item.TierTotal}
                    </Typography>
                  </th>
                ))}
            </tr>

            {/* Payment Plan frequency: Per month / Per annual */}
            <tr>
              <th>&nbsp;</th>
              {tierDetails && tierDetails.map((item, index) => (
                  <th key={index} className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}>
                    <Typography alignment="center" type="title" level={5} color={ item.Tier.IsRecommendedTier ? 'white_base' : 'gray_darkest' }>
                      {
                        (selectedFrequency.FrequencyName === 'Monthly') ? `Per month` :
                        (selectedFrequency.FrequencyName === 'Annual') ? `Per year` : ``
                      }
                    </Typography>
                  </th>
                ))}
            </tr>

            {/* Features list */}
            {featureDataRows &&
              featureDataRows.length > 0 &&
              featureDataRows
                .slice(0, showMore ? featureDataRows.length : 7)
                .map((row) => (
                  <tr>
                    <td style={{ textAlign: 'left', width: '30%', padding: '10px 5px' }}>
                      {row.title}
                    </td>

                    {row.values.map((item) => (
                      <td style={{ textAlign: 'center', width: '10%' }} className={item.isRecommended ? 'selected-tier' : ''}>
                        {
                          item.isEmpty ? <Icon name="minus" size={16} color={item.isRecommended ? 'white' : ''} />
                            : (item.displayText && item.displayText !== "") ? 
                                <p>{item.displayText}</p>
                                : <Icon name="check" size={16} color={item.isRecommended ? 'white' : ''} />
                        }
                      </td>
                    ))}
                  </tr>
                ))}

            {/* Buy now buttons */}
            <tr>
              <th>&nbsp;</th>
              {tierDetails &&
                tierDetails.map((item) => (
                  <th className={item.Tier.IsRecommendedTier ? 'selected-tier' : ''}>
                    <Button
                      category="text-icon"
                      text={item.Tier.IsRecommendedTier ? 'Buy Now' : 'Buy Now'}
                      icon={item.Tier.IsRecommendedTier ? 'check' : 'arrow-right'}
                      className={item.Tier.IsRecommendedTier ? 'current-plan-btn' : 'select-plan-btn'}
                      color="blue_base"
                      iconSize={16}
                      onClick={() => handleChangePage(item)} />
                  </th>
                ))}
            </tr>
          </table>

          {/* Show more/less button */}
          <Stack className="eco-mt-12" horizontalAlign="h_center">
            <Button variant="primary" onClick={toggleShowMore} color="white" category="text">
              {showMore ? (
                <>
                  <MinusOutlined className="plus_icon_dash" /> See Less Features List
                </>
              ) : (
                <>
                  <PlusCircleOutlined className="plus_icon_dash" /> See Full Features List
                </>
              )}
            </Button>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default PricingPlan;
