import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Stack,
  TextArea,
  Typography,
} from "witmeg-ui-system";
import { getHomeLayoutByID } from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import "./Widget.css";
function ContactUs(props) {

  const mobileState = useSelector((state) => state.commonData.mobileState)
  const desktopState = useSelector((state) => state.commonData.desktopState)
  const tabletState = useSelector((state) => state.commonData.tabletState)


  // console.log("devicestate", mobileState);
  useEffect(async () => {
    let deviceState = sessionStorage.getItem('deviceState')

    if (props.params.pageID) {
      const getData = await getHomeLayoutByID({
        ID: props.params.widgetID,
        PageID: props.params.pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 0,
      }).then((res) => {
        console.log("WE HAVE TOU  CH DOWN", res);
      });
    } else {
      const getData = await getHomeLayoutByID({
        ID: props.params.widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 0,
      }).then((res) => {
        console.log("WE HAVE TOU  CH DOWN", res);
        if (deviceState === 'desktop') {
          if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

            // setData(res[0]);

          }
        } else if (deviceState == 'mobile') {
          if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
            // setData(res[0])
          }
        }
      });
    }
  }, []);

  return (
    <>
      <div style={{ height: '100%' }}>
        <Typography
          type='title'
          level={2}
          textTransform='capitalize'
          className='eco-mb-24'
          alignment="center"
          pattern="bold"
        >
          Contact Us
        </Typography>
        <Typography
          type='title'
          level={5}
          pattern="bold"
          className='eco-mb-28'
          alignment="center"
        >
          Our friendly team would love to hear from you!
        </Typography>
        <Row gutter={[16, 16]}>
          <Col span={mobileState ? 24 : tabletState ? 12 : desktopState ? 12 : ''}>
            <Row className="form-row">
              <Col span={24}>
                <div className="form-lbl-wrapper">
                  <Label>Name</Label>
                </div>
              </Col>
              <Col span={24}>
                <Input disabled placeholder = "Please enter a name" />
              </Col>
            </Row>
          </Col>
          <Col span={mobileState ? 24 : tabletState ? 12 : desktopState ? 12 : ''}>
            <Row className="form-row">
              <Col span={24}>
                <div className="form-lbl-wrapper">
                  <Label>Phone</Label>
                </div>
              </Col>
              <Col span={24}>
                <Input 
                placeholder ="Please enter a phone number" disabled />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="form-lbl-wrapper">
              <Label>Email</Label>
            </div>
          </Col>
          <Col span={24}>
            <Input placeholder = "please enter an email address" disabled />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="form-lbl-wrapper">
              <Label>Message</Label>
            </div>
          </Col>
          <Col span={24}>
            <TextArea rows={6}  placeholder = "Get in touch with us. Send us a message using the form below and we will get back to you shortly" disabled />
          </Col>
        </Row>
        <Button className='submit__btn' type='submit'>
          Get In Touch
        </Button>
      </div>
    </>
  );
}

export default ContactUs;
