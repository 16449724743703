import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Stack, Typography } from 'witmeg-ui-system';
import { RESTAURANT_SETTINGS_TYPES, getCurrencyFormat, getRestaurantMenuProductPrice } from '../../../utilities/helpers';
import '../../../utilities/stringOverride';

/*
  Layout: 5
    Shows menu items in two columns without images in rows
*/
const TwoColumnWithoutImageRowLayout = ({ isPopup = false }) => {

  const { menuWidgetData: { currency }, menuData: { selectedMainMenuData, uiCustomizations }, defaultOrderType } = useSelector((state) => state.resturantWidget);
  const { OPT } = RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES;
  const orderType = defaultOrderType === OPT.CUSTOMER_CHOICE.name ? OPT.COLLECTION.name : defaultOrderType ?? OPT.COLLECTION.name;

  const currencyFormat = getCurrencyFormat(currency);

  return (
    <Row>
      {selectedMainMenuData && selectedMainMenuData.SubMenu && selectedMainMenuData.SubMenu.length > 0 && selectedMainMenuData.SubMenu.map((data, index) => {
        return (
          <>
            {/* Shows if `Sub Menu` enabled in settings */}
            {uiCustomizations.subMenu.settings.subMenuEnabled && <label className='eco-menu-heading' style={uiCustomizations.subMenu.style}>{data?.SubMenuName.toCapitalFirst()}</label>}


            {data.MenuProductData.map((data, index) => {
              const productPrice = getRestaurantMenuProductPrice(data.ProductData, orderType);
              const productDescription = data?.ProductData?.ItemDescription?.Translations?.en


              return (
                <Col span={12} style={{ padding: '10px', }}>
                  <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px', height: '16rem', opacity: '0.8' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                      <div style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column' }}>
                        <div>
                          <Typography type="title" level={4} pattern="bold" style={{ fontWeight: 'bold', fontSize: '16px' }}>{data.ProductName.toCapitalFirst()}</Typography>
                        </div>
                        <div>
                          <Typography type="title" level={5} style={{ padding: '5px 0 0', height: '6.5rem', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>{productDescription}</Typography>
                        </div>
                      </div>
                    </div>
                    <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                      <Typography type="title" level={5} pattern="bold">{currencyFormat(productPrice)}</Typography>

                    </div>
                  </div>
                </Col>

              )
            })}
            <hr />
          </>
        )
      })}

    </Row>
  )
}

export default TwoColumnWithoutImageRowLayout;