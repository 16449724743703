import React, { Component } from "react";

import {
  Button,
  Col,
  GlobalHeader,
  GlobalLayout,
  Input,
  Popup,
  RightPanel,
  Row,
  Search,
  Space,
  Spin,
  Stack,
  StackItem,
  Typography,
} from "witmeg-ui-system";
import { Card, Tabs } from "antd";
import { CloseOutlined, DingdingOutlined } from "@ant-design/icons";
import "./widgetOverlay.css";
import { Icon } from "witmeg-ui-system";
const { TabPane } = Tabs;
export default class WidgetOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      widgetData: "",
      eComWidgetData: "",
      ref: React.createRef(),
      menuModalVisible: false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.state.ref.current && !this.state.ref.current.contains(event.target)) {
      this.props.closeWidgetOverlay();
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {

    console.log("this.props.widgetsData", this.props.widgetsData)
    return (
      <div className='widget__overlay' ref={this.state.ref}>
        <div>
          <Stack className='widget__overlay-heading' horizontalAlign='space_between' style={{ padding: "15px", borderBottom: '1px solid #D8D8D8' }}>
            <Typography type='text' pattern="bold" color='gray_base'>
              WIDGETS
            </Typography>
            <div onClick={this.props.closeWidgetOverlay}>
              <CloseOutlined className='close-name' />
            </div>
          </Stack>
          <Tabs defaultActiveKey='1' className='widget-tabs' heightLightTab={true} type="card">
            {/* WIDGET PANE STARTS */}

            <TabPane className='widget-tab' tab='STANDARD' key='1'>
              {
                this.props.widgetsIsLoading ?
                  <div style={{ height: 'calc(85vh - 22rem)', width: '352px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}><Spin /></div> :
                  <div style={{ padding: "10px" }}>
                    <Row>
                      <Col span={24}>
                        <div onChange={(e) => { this.setState({ widgetData: e.target.value }) }} className="eco-mb-24 search-input">
                          <Search
                            icon='right'
                            fullWidth
                            bordered
                            placeholder="Search widget"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="widget-div" style={{ maxHeight: 'calc(85vh - 22rem)', overflowY: 'scroll' }}>
                      <Row gutter={[0, 0]}>
                        {this.props.widgetsData.filter(widget => {

                          if (this.state.widgetData === "") {
                            return this.props.widgetsData
                          } else if (widget.Item.includes(this.state.widgetData.toLowerCase())) {
                            return widget
                          }
                        }).map((widget) => (

                          <Col md={24} lg={12}>
                            <div
                              draggable={true}
                              onDragStart={(e) =>
                                this.props.onDragStartWidget(e, widget)


                              }
                            >
                              <Card className='card__overlay'>
                                <div style={{ marginTop: "14px" }}>
                                  <Stack
                                    direction='column'
                                    verticalAlign='v_center'
                                  >
                                    {widget.Item == "carousel" && (
                                      <Icon name='carousel'
                                        className='eco-mb-24' />
                                    )}

                                    {widget.Item == "navigation links" && (
                                      <Icon
                                        name='navigation-links'
                                        className='eco-mb-24'
                                      />
                                    )}


                                    {widget.Item == "text editor" && (
                                      <Icon
                                        name='text-editor'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "image" && (
                                      <Icon name='images' className='eco-mb-24' />
                                    )}
                                    {widget.Item == "video" && (
                                      <Icon name='video' className='eco-mb-24' />
                                    )}
                                    {widget.Item == "gallery" && (
                                      <Icon
                                        name='gallery'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "contact form" && (
                                      <Icon
                                        name='contact-form'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "blog" && (
                                      <Icon
                                        name='blog'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "owlcarousel" && (
                                      <Icon name='carousel' className='eco-mb-24' />
                                    )}
                                    {widget.Item == "newssection" && (
                                      <Icon
                                        name='text-editor'
                                        className='eco-mb-24'
                                      />
                                    )}

                                    {widget.Item == "Price Plan" && (
                                      <Icon
                                        name='price-plan'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "maps" && (
                                      <Icon
                                        name='map'
                                        className='eco-mb-24'
                                      />
                                    )}


                                    {widget.Item == "button" && (
                                      <Icon
                                        name='button'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "accordion" && (
                                      <Icon
                                        name='accordian'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "icon" && (
                                      <Icon
                                        name='icon'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "resturant menu" && (
                                      <Icon
                                        name='resturant-menu'
                                        className='eco-mb-24'
                                      />
                                    )}
                                    {widget.Item == "card" && (
                                      <Icon
                                        name='resturant-menu'
                                        className='eco-mb-24'
                                      />
                                    )}

                                    {/* {widget.Item == "tab" && (
                                    <Icon
                                      name='tab'
                                      className='eco-mb-24'
                                    />
                                  )} */}


                                    <Typography
                                      textTransform='capitalize'
                                      type='title'
                                      level={5}
                                      pattern='bold'
                                      className='widget__name'
                                      alignment="center"
                                    >
                                      {widget.Item}
                                    </Typography>
                                  </Stack>
                                </div>
                              </Card>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
              }
            </TabPane>
            {/* WIDGET PANE ENDS */}

            {/* E COMMERCE WIDGET PANE STARTS */}
            {this.props.companyData.WebSiteType==="E-Commerce"?
              <TabPane tab='RETAIL' key='2'>
                <div style={{ padding: "10px" }}>
                  <Row>
                    <Col span={24}>
                      <div onChange={(e) => { this.setState({ eComWidgetData: e.target.value }) }} className="eco-mb-24 search-input">
                        <Search icon='right' fullWidth bordered placeholder="Search widget" />
                      </div>
                    </Col>
                  </Row>
                  <div className="widget-div" style={{ minHeight: 'calc(100vh - 22rem)', overflowY: 'auto' }}>

                    <Row gutter={[0, 0]}>


                      {this.props.eComWidgets?.filter(widget => {
                        if (this.state.eComWidgetData === "") {
                          return this.props.eComWidgets
                        } else if (widget.Item.includes(this.state.eComWidgetData.toLowerCase())) {
                          return widget
                        }
                      }).map((widget) => (
                        <Col md={24} lg={12}>
                          <div
                            draggable={true}
                            onDragStart={(e) =>
                              this.props.onDragStartWidget(e, widget)
                            }
                          >
                            <Card className='card__overlay'>
                              <div style={{ marginTop: "14px" }}>
                                <Stack direction='column' verticalAlign='v_center'>

                                  {widget.Item == 'featuredoffers' && <Icon
                                    name='featured-offers'
                                    className='eco-mb-24'
                                  />}

                                  {widget.Item == 'offers' && <Icon name='offers' className='eco-mb-24' />}

                                  {widget.Item == 'featured' && <Icon name='featured' className='eco-mb-24' />}
                                  {widget.Item == 'bestselling' && <Icon name='best-selling' className='eco-mb-24' />}
                                  <Typography
                                    textTransform='capitalize'
                                    type='title'
                                    level={5}
                                    pattern='bold'
                                    className='widget__name'
                                    alignment="center"
                                  >
                                    {widget.Item}
                                  </Typography>
                                </Stack>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      ))
                      }



                    </Row>
                  </div>
                </div>
              </TabPane>
            :null}

            {/* E COMMERCE WIDGET PANE ENDS */}

            {/* Restaurant WIDGET PANE STARTS */}
            {this.props.companyData.WebSiteType==="Restaurant"?
              <TabPane tab='Restaurant ' key='2'>
                <div style={{ padding: "10px" }}>
                  <Row>
                    <Col span={24}>
                      <div onChange={(e) => { this.setState({ eComWidgetData: e.target.value }) }} className="eco-mb-24 search-input">
                        <Search icon='right' fullWidth bordered placeholder="Search widget" />
                      </div>
                    </Col>
                  </Row>
                  <div className="widget-div" style={{ minHeight: 'calc(100vh - 22rem)', overflowY: 'auto' }}>

                    <Row gutter={[0, 0]}>


                      {this.props.restaurantWidgets?.map((widget) => (
                        <Col md={24} lg={12}>
                          <div
                            draggable={true}
                            onDragStart={(e) =>
                              this.props.onDragStartWidget(e, widget)
                            }
                          >
                            <Card className='card__overlay'>
                              <div style={{ marginTop: "14px" }}>
                                <Stack direction='column' verticalAlign='v_center'>

                                  {widget.Item == "resturant menu" && (
                                      <Icon
                                        name='resturant-menu'
                                        className='eco-mb-24'
                                      />
                                    )}
                                  <Typography
                                    textTransform='capitalize'
                                    type='title'
                                    level={5}
                                    pattern='bold'
                                    className='widget__name'
                                    alignment="center"
                                  >
                                    {widget.Item}
                                  </Typography>
                                </Stack>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      ))
                      }



                    </Row>
                  </div>
                </div>
              </TabPane>
            :null}

            {/* Restaurant Widget PANE ENDS */}
          </Tabs>
        </div>
      </div>
    );
  }
}
