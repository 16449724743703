import React, { useEffect } from "react";
import { useRef } from "react";
import ReactDOM from 'react-dom';

/**
 * 
 * @param {React.ref} ref 
 * @param {[React.ref]} otherElementArray
 * @param {Function} callback 
 */
function useOutsideClick(ref,otherElementArray,callback) {
  const callBackRef = useRef();
  callBackRef.current = callback;

  useEffect(() => {    
    function handleClickOutside(event) {
      // console.log(ReactDOM.findDOMNode(this)
      if ((ref.current && !ref.current.contains(event.target)) && !otherElementArray.filter(e=>e).length ) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref,otherElementArray,callBackRef]);
}

export {useOutsideClick};