import React, { useState, useCallback, useEffect, useRef } from "react";

import DropZone from "./DndDropZone";
import TrashDropZone from "./TrashDropZone";
import SideBarItem from "./SideBarItem";
import DndRow from "./DndRow";
import initialData from "./initial-data";
import {
  handleMoveWithinParent,
  handleMoveToDifferentParent,
  handleMoveSidebarComponentIntoParent,
  handleRemoveItemFromLayout
} from "./helpers";

import { SIDEBAR_ITEMS, SIDEBAR_ITEM, COMPONENT, COLUMN } from "./constants";
import shortid from "shortid";
import { Button } from "witmeg-ui-system";

const Container = (props) => {
  const containerRef = useRef(null);
  const initialLayout = props.gridData.Layout?props.gridData.Layout:initialData.layout;
  const initialComponents = props.gridData.components?props.gridData.components:initialData.components;
  const [layout, setLayout] = useState(initialLayout);
  const [components, setComponents] = useState(initialComponents);
  const [useFullWidth, setUseFullWidth] = useState(false)
  const [fullWidth, setFullWidth] = useState(0)
  const [imgSrc, setImgSrc] = useState(null)
  const [imgFile, setImgFile] = useState(null)

  const handleDropToTrashBin = useCallback(
    (dropZone, item) => {
      const splitItemPath = item.path.split("-");

      let updatedLayout =handleRemoveItemFromLayout(layout, splitItemPath)
      setLayout(updatedLayout);
      props.updateLayout(updatedLayout, imgFile, useFullWidth, components)
    },
    [layout]
  );

  useEffect(() => {
    console.log("containerRef.current====>>", containerRef.current.offsetWidth)
    setUseFullWidth(containerRef.current.offsetWidth)
  }, [])
  

  const handleDrop = useCallback(
    (dropZone, item) => {
      console.log('dropZone', dropZone)
      console.log('item', item)

      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

      const newItem = { ...item.data, id: item.id, type: item.type};
      if (item.type === COLUMN) {
        newItem.children = item.children;
      }

      // sidebar into
      if (item.type === SIDEBAR_ITEM) {
        // 1. Move sidebar item into page
        const newComponent = {
          id: shortid.generate(),
          ...item.component
        };
        const newItem = {
          id: newComponent.id,
          type: COMPONENT,
          component: item.component 
          
        };
        setComponents({
          ...components,
          [newComponent.id]: newComponent
        });
        let updatedLayout =handleMoveSidebarComponentIntoParent(
          layout,
          splitDropZonePath,
          newItem
        )
        setLayout(updatedLayout);
        props.updateLayout(updatedLayout, imgFile, useFullWidth, fullWidth, components)
        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          setLayout(
            handleMoveWithinParent(layout, splitDropZonePath, fullWidth, splitItemPath)
          );
          return;
        }

        // 2.b. OR move different parent
        // TODO FIX columns. item includes children
        setLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        );
        return;
      }

      // 3. Move + Create
      setLayout(
        handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,
          newItem
        )
      );
    },
    [layout, components]
  );

  const handleItemDataUpdate = ( row, column, data, components, path, obj, width, height, widget) => {
    let updatedLayout=layout.map((r, index) => {
      return {
        "type": r.type,
        "id": r.id,
        "children": r.children.map((c, j) => {
          return {
            "type": c.type,
            "id": c.id,
            "children": c.children.map((comp, k) => {
              return {
                "id": comp.id,
                "type": comp.type,
                "component": comp.component,
                "size": r.id===row.id&& column.id===c.id && data.id===comp.id?{"width": width, "height": height}:comp.size?comp.size:{},
                "itemData": r.id===row.id&& column.id===c.id && data.id===comp.id?({data:obj}):comp.itemData?comp.itemData:null
              }
            })
          }
        })
      }
    })
    
    setLayout(updatedLayout)
    setComponents(components)
    props.updateLayout(updatedLayout, imgFile, useFullWidth, fullWidth, components)
  }

  const handleItemSizeUpdate = ( row, column, data, components, width, height) => {
    let updatedLayout=layout.map((r, index) => {
      return {
        "type": r.type,
        "id": r.id,
        "children": r.children.map((c, j) => {
          return {
            "type": c.type,
            "id": c.id,
            "children": c.children.map((comp, k) => {
              return {
                "id": comp.id,
                "type": comp.type,
                "component": comp.component,
                "size": r.id===row.id&& column.id===c.id && data.id===comp.id?{"width": width, "height": height}:comp.size?comp.size:null,
                "itemData": comp.itemData?comp.itemData:null
              }
            })
          }
        })
      }
    })
    setLayout(updatedLayout)
    setComponents(components)
    props.updateLayout(updatedLayout, imgFile, useFullWidth, components)
  }

  /*{
  "type": "row",
  "id": "row0",
  "children": [
    {
      "type": "column",
      "id": "Uf_5lrMwIf",
      "children": [
        {
          "id": "yBNAyQ_41",
          "type": "component"
        }
      ]
    },
    {
      "type": "column",
      "id": "column0",
      "children": [
        {
          "id": "ofIYFyYCS",
          "type": "component"
        },
        {
          "id": "8YBp3CujD",
          "type": "component"
        }
      ]
    }
  ]
}
*/

  const renderRow = (row, currentPath) => {
    return (
      <DndRow
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        useFullWidth={useFullWidth}
        handleItemDataUpdate={handleItemDataUpdate}
        handleItemSizeUpdate={handleItemSizeUpdate}
        gridData={props.gridData}
      />
    );
  };

  const changeHandler = (e) => {
    const { files } = e.target
    for (let i = 0; i < files.length; i++) {
      const file = files[i]; // OR const file = files.item(i);
      setImgFile(files[i])
      setImgSrc( URL.createObjectURL(file))
      props.handleChangeImage(layout, files[i], useFullWidth, fullWidth, components)
    }
  }

  const handleDelete = () => {
    setImgFile(null)
    setImgSrc( null)
    setImgFile(null)
    props.handleChangeImage(layout, null, useFullWidth, fullWidth, components)
  }
  // dont use index for key when mapping over items
  // causes this issue - https://github.com/react-dnd/react-dnd/issues/342
  return (
    <div className="advanced-container" >
      {/* <div className="sideBar">
        {Object.values(SIDEBAR_ITEMS).map((sideBarItem, index) => (
          <SideBarItem key={sideBarItem.id} data={sideBarItem} />
        ))}
      </div> */}
      <div className="top-bar">
        {Object.values(SIDEBAR_ITEMS).map((sideBarItem, index) => (
          <SideBarItem key={sideBarItem.id} data={sideBarItem} />
        ))}

        <div style={{marginLeft:'12px'}}><input type="checkbox" onChange={(e) => setUseFullWidth(e.target.checked)} value={useFullWidth}/> Use Full Width</div>
        <div>
        <input type="file" accept="image/*" onChange={changeHandler} multiple/>
        <Button variant="link" className="eco-mr-8" size="small" onClick={handleDelete}>Delete Image</Button>
        </div>
      </div>
      <div className="pageContainer">
        <div className="page" style={{
          backgroundImage: imgSrc?`url(${imgSrc})`:props.currentImage==="deleted"?'':props.gridData.imageData?`url(${props.gridData.imageData})`:'none',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          maxHeight: '540px',
          overflowY:'scroll'
          }}
          ref={containerRef}
        >
          {layout.map((row, index) => {
            const currentPath = `${index}`;

            return (
              <React.Fragment key={row.id}>
                <DropZone
                  data={{
                    path: currentPath,
                    childrenCount: layout.length
                  }}
                  onDrop={handleDrop}
                  path={currentPath}
                />
                {renderRow(row, currentPath)}
              </React.Fragment>
            );
          })}
          <DropZone
            data={{
              path: `${layout.length}`,
              childrenCount: layout.length
            }}
            onDrop={handleDrop}
            isLast
          />
        </div>

        <TrashDropZone
          data={{
            layout
          }}
          onDrop={handleDropToTrashBin}
        />
      </div>
    </div>
  );
};
export default Container;
