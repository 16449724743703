export const commonErrorHandler = (
  error,
  defaultErrorMsg = "Sorry there was a problem. Please Try Again"
) => {
  let errorMsg = "";

  switch (true) {
    case error.message.includes("Network Error"):
      errorMsg = "Network Error. Please check your connection";
      break;

    default:
      errorMsg = defaultErrorMsg;
      break;
  }

  return { errorMsg };
};

export function APIError(
  customErrMsg = "Error Occurred. Please try again.",
  orgError = "",
  otherDetails = {}
) {
  const error = new Error(orgError || customErrMsg);

  error.customErrType = "APIERROR";
  error.customErrMsg = customErrMsg;

  return error;
}

// Helper utility to log errors to console. (To make it easy to track errors.)
// Logs only shown on developer mode.
export function errorLogger(error, options = {}) {
  console.log("DEV", new Error(error), options);
}
