import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const organiserSlice = createSlice({
    name: "Organisation",
    initialState: {
        ORGID: "",
        COMPANYID: "",

        organisationList: {
            organizationList: []
        }
    },
    reducers: {
        setOrganisation: (state, action) => {

            const { OrganisationID } = action.payload || {};

            console.log("pw", OrganisationID);
            state.ORGID = OrganisationID;


        },

        setOrganizationList: (state, action) => {

            const { organisationList } = action.payload || {}

            state.organisationList.organizationList = organisationList
        },

        setCompanyID: (state, action) => {
            console.log("ACTUIBS", action)
            const { CompanyID } = action.payload || {};
            state.COMPANYID = CompanyID;
        }
    },
})


export const { setOrganisation, setCompanyID, setOrganizationList } = organiserSlice.actions;
export default organiserSlice.reducer;