export const dummyMenu = [
    {
        menuItem: "Brewed Coffee",
        menuDescription: "One of our light, medium, dark, decaffeinated.",
        itemPrice: "£4.50"
    },
    {
        menuItem: "Salted Toffee Latte",
        menuDescription: "A combination of our signature espresso with rich, buttery toffee.",
        itemPrice: "£4.50"
    },
    {
        menuItem: "Cappuccino",
        menuDescription: "A freshly pulled shot of espresso layered with steamed whole milk.",
        itemPrice: "£5.50"
    },
    {
        menuItem: "Cold Brew Coffee",
        menuDescription: "premium blend of Bali Blue Moon and Ethiopia Yirgacheffe coffees.",
        itemPrice: "£8.50"
    },
    {
        menuItem: "AMERICANO",
        menuDescription: "Freshly pulled shots of espresso combined with hot water.",
        itemPrice: "£8.50"
    },
    {
        menuItem: "ESPRESSO",
        menuDescription: "Perfectly balanced with a golden crema.",
        itemPrice: "£8.50"
    },
    {
        menuItem: "Fresh Bread",
        menuDescription: "Lacus nisi, et ac dapibus velit in consequat.",
        itemPrice: "£8.50"
    },
    {
        menuItem: "PURE ICE BLENDED DRINK",
        menuDescription: "Non-fat milk blended with our signature powders and ice and then topped with whipped cream.",
        itemPrice: "£8.50"
    }
]