import React, { useState, useEffect } from "react";
import { Row, Col, Accordion, Button, Alert, Message, Space, async } from "witmeg-ui-system";
import "./Widget.css";
import { Modal } from "antd";

import {
  getHomeLayoutByID,
  updateHomeLayout,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import GalleryImageUpload from "./GalleryImageUpload/GalleryImageUpload";
import GalleryImageUpoad2 from "./GalleryImageUpload/GalleryImageUpload2"
import GalleryImageUpload3 from "./GalleryImageUpload/GalleryImageUpload3"

import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";


function GalleryWidjet(props) {

  const mobileState = useSelector((state) => state.commonData.mobileState)
  const desktopState = useSelector((state) => state.commonData.desktopState)
  const tabletState = useSelector((state) => state.commonData.tabletState)


  const [showImageUpload, setshowImageUpload] = useState(false);
  const dispatch = useDispatch()
  const [imageTempArray, setImageTempArray] = useState([]);
  const [imageError, setImageError] = useState(false);

  const [src, setSrc] = useState(null);
  const [src1, setSrc1] = useState(null);
  const [src2, setSrc2] = useState(null);
  const [output, setOutput] = useState(null);
  const [output1, setOutput1] = useState(null);
  const [output2, setOutput2] = useState(null);
  const [imageArray, setImageArray] = useState([]);
  const [parsedData, setParsedData] = useState([]);
  //Upload One
  const [uploadDone, setUploadDone] = useState(false);
  //Upload Two
  const [uploadDone1, setUploadDone1] = useState(false);

  //Image Data
  const [imageSet, setImageSet] = useState({ data: [] })

  //Upload Three
  const [uploadDone2, setUploadDone2] = useState(false);
  const [uploadToCloud, setUploadToCloud] = useState(false)
  const [data, setData] = useState(null);
  const [visible, setVisible] = useState(false);

  const [imageSizeValidation, setImageSizeValidation] = useState(false);
  const [imageTypeValidation, setImageTypeValidation] = useState(false);


  //CROP FUNCTIONS FOR IMAGES 
  const [crop0, setCrop0] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,

  });


  const [crop1, setCrop1] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,

  });

  const [crop2, setCrop2] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 10,
    y: 10,
    width: 80,
    height: 80,

  });

  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleImageUploadPopup = () => {
    if (props.params.PreviewButton == false) {
      setshowImageUpload(true);
      dispatch(setCommonState({
        draggableState: true
      }))
      {
        imageTempArray && setVisible(true);
      }
      setOutput(null)
    }
  };

  useEffect(async () => {
    await fetchData();


  }, []);
  const fetchData = async () => {
    let deviceState = sessionStorage.getItem('deviceState')

    if (props.params.pageID) {
      let widID = await props.params.widgetID;
      let pageID = await props.params.pageID;

      const getData = await getHomeLayoutByID({
        ID: widID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 0,
      })
        .then((res) => {
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)

            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0])
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)
            }
          }


        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const getData = await getHomeLayoutByID({
        ID: props.params.widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 0,
      })
        .then((res) => {
          if (deviceState === 'desktop') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Desktop") {

              setData(res[0]);
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)

            }
          } else if (deviceState == 'mobile') {
            if (res[0]?.RenderSource && res[0]?.RenderSource === "Mobile") {
              setData(res[0])
              tempArray = decodeURIComponent(res[0].ItemData);

              var newArray = JSON.parse(tempArray);
              setImageSet(newArray)
            }
          }


        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleTempArray = (value, field) => {
    setImageTempArray(value);
    {
      imageTempArray && setVisible(false);
    }
  };


  const handleOk = async () => {

    //  
    console.log("IAM TEMPARRAY", imageSet, imageTempArray);

    let images
    if (imageSet.data.length > 0) {
      const result = Object.values(
        [].concat(imageSet.data, imageTempArray)
          .reduce((r, c) => (r[c.index] = Object.assign((r[c.index] || {}), c), r), {})
      )

      // let updatedArr=a1.map(x => {return {index: x.index, imageUrl: u1.find(y => y.index===x.index)?u1.find(y => y.index===x.index).imageUrl: x.imageUrl}})
      let imageData = JSON.stringify({ data: result });
      images = encodeURIComponent(imageData);
    } else {

      let imageData = JSON.stringify({ data: imageTempArray });
      images = encodeURIComponent(imageData);
    }


    // console.log("IMAGES", images)


    let widID = await props.params.widgetID;
    let pageID = await props.params.pageID;


    if (imageTempArray.length == 0) {
      setImageError(true);
    } else {
      setConfirmLoading(true);
      const updateWidjet = await updateHomeLayout({
        ID: widID,
        ItemHeading: "gallery",
        Item: "gallery",
        Width: data?.Width,
        Height: data?.Height,
        X: data?.X,
        Y: data?.Y,
        Type: "gallery",
        ItemData: images,
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
      }).then(async (response) => {
        console.log(response);
        dispatch(setCommonState({
          draggableState: false
        }))
        fetchData();
        setSrc(null);
        setOutput(null)
        setOutput1(null);
        setOutput2(null);

        setSrc1(null);
        setSrc2();

        setConfirmLoading(false);
        setImageError(false);
        setshowImageUpload(false);
        await Message.success("Widget edited successfully");
        // window.location.reload();
      });
    }

  };

  const handleCancel = () => {
    setshowImageUpload(false);
    setImageError(false);
    setSrc(null);
    setOutput();
    setOutput1();
    setOutput2();
    setSrc1(null)
    setSrc2(null)
    setCrop0({
      unit: '%', // Can be 'px' or '%'
      x: 10,
      y: 10,
      width: 80,
      height: 80
    });
    setCrop1({
      unit: '%', // Can be 'px' or '%'
      x: 10,
      y: 10,
      width: 80,
      height: 80
    });
    setCrop2({
      unit: '%', // Can be 'px' or '%'
      x: 10,
      y: 10,
      width: 80,
      height: 80
    });

    dispatch(setCommonState({
      draggableState: false
    }))
    setImageTypeValidation(false);
    setImageSizeValidation(false)
  };

  if (data != null && data?.ItemData) {
    var tempArray;

    tempArray = decodeURIComponent(data?.ItemData);

    var newArray = JSON.parse(tempArray);

    console.log("myObject===>>>", newArray);

    // setParsedData(tempArray);
  }

  const handleSetSrc = (key, imgObj) => {
    //  

    var tempArray = src

    if (key == 0) {
      tempArray = [
        ...src, {
          tempUrl0: imgObj,

        }
      ]

    }

    if (key == 1) {
      tempArray = [
        ...src, {
          tempUrl1: imgObj
        }
      ]

    }

    if (key == 2) {
      tempArray = [
        ...src, {
          tempUrl2: imgObj
        }
      ]
    }
    setSrc(tempArray)



  }

  return (
    <>
      <Row gutter={[16, 16]} justify="space-evenly" style={{ height: '100%' }}>
        {newArray &&
          newArray.data.map((data, index) => (
            <Col style={{ position: "relative" }} span={mobileState ? 24 : tabletState ? 8 : desktopState ? 8 : ''}>

              <img
                onDoubleClick={handleImageUploadPopup}
                alt=""
                src={data?.imageUrl}
                draggable="false"
                style={{ width: "100%", height: "100%" }}
              />

              {/* <div

                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  backgroundImage: `url(${data?.imageUrl})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover"
                }}
              >
              </div> */}
            </Col>
          ))}

        {!newArray && (
          <Col span={mobileState ? 24 : tabletState ? 8 : desktopState ? 8 : ''}>
            <div
              onDoubleClick={handleImageUploadPopup}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                backgroundImage: `url("https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
              }}
            >

            </div>
          </Col>
        )}

        {!newArray && (
          <Col span={mobileState ? 24 : tabletState ? 8 : desktopState ? 8 : ''}>
            <div
              onDoubleClick={handleImageUploadPopup}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                backgroundImage: `url("https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
              }}
            >

            </div>
          </Col>
        )}

        {!newArray && (
          <Col span={mobileState ? 24 : tabletState ? 8 : desktopState ? 8 : ''}>
            <div
              onDoubleClick={handleImageUploadPopup}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                backgroundImage: `url("https://images.unsplash.com/photo-1497032628192-86f99bcd76bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
              }}
            >

            </div>
          </Col>
        )}
      </Row>

      <Modal
        title='Gallery Image Upload'

        onCancel={handleCancel}
        visible={showImageUpload}
        confirmLoading={confirmLoading}
        maskClosable={false}

        footer={
          <Space>
            <Button
              onClick={handleOk}
              variant="primary"
              size="small"
              disabled={uploadToCloud}
            >
              Confirm
            </Button>
            <Button
              onClick={handleCancel}
              variant="secondary"
              className="eco-mr-8"
              size="small"
            >
              Cancel
            </Button>
          </Space>
        }

      >
        {imageError && (
          <Alert
            message='At least one image is required'
            type='error'
            showIcon
          />
        )}

        <div className='eco-mt-12'>
          <Accordion
            theme='default'
            type='content_only'
            title='Gallery Image One'
            collapsed={true}
          >
            <div style={{ width: "100%" }}>
              <GalleryImageUpload
                imageTempArray={imageTempArray}
                handleTempArray={handleTempArray}
                setImageError={setImageError}
                output={output}
                setOutput={setOutput}
                src={src}
                setSrc={setSrc}
                index={0}
                setUploadToCloud={setUploadToCloud}
                setUploadDone={setUploadDone}
                uploadDone={uploadDone}
                crop={crop0}
                setCrop={setCrop0}
                imageSizeValidation={imageSizeValidation}
                setImageSizeValidation={setImageSizeValidation}
                imageTypeValidation={imageTypeValidation}
                setImageTypeValidation={setImageTypeValidation}

              />
            </div>
          </Accordion>

          <Accordion
            theme='default'
            type='content_only'
            title='Gallery Image Two'
            collapsed={true}
          >
            <div style={{ width: "100%" }}>
              <GalleryImageUpoad2
                setImageError={setImageError}
                imageTempArray={imageTempArray}
                handleTempArray={handleTempArray}


                src1={src1}
                setSrc1={setSrc1}
                index={1}
                output1={output1}
                setOutput1={setOutput1}

                setUploadToCloud={setUploadToCloud}
                setUploadDone1={setUploadDone1}
                uploadDone1={uploadDone1}
                crop={crop1}
                setCrop={setCrop1}
                imageSizeValidation={imageSizeValidation}
                setImageSizeValidation={setImageSizeValidation}
                imageTypeValidation={imageTypeValidation}
                setImageTypeValidation={setImageTypeValidation}
              />
            </div>
          </Accordion>

          <Accordion
            theme='default'
            type='content_only'
            title='Gallery Image Three'
            collapsed={true}
          >
            <div style={{ width: "100%" }}>
              <GalleryImageUpload3
                imageTempArray={imageTempArray}
                setImageError={setImageError}
                handleTempArray={handleTempArray}
                output2={output2}
                setOutput2={setOutput2}
                src2={src2}
                setSrc2={setSrc2}

                index={2}
                setUploadToCloud={setUploadToCloud}
                crop={crop2}
                setCrop={setCrop2}
                setUploadDone2={setUploadDone2}

                uploadDone2={uploadDone2}

                imageSizeValidation={imageSizeValidation}
                setImageSizeValidation={setImageSizeValidation}
                imageTypeValidation={imageTypeValidation}
                setImageTypeValidation={setImageTypeValidation}
              />
            </div>
          </Accordion>
        </div>
      </Modal>
    </>
  );
}

export default GalleryWidjet;
