import Axios from "axios";
import globalValues from "../../configs/globalValues";
import { checkIsUserLoggedIn, getLoggedUserDetails } from "./userAuthentication";

const getGenericExpTime = () => (new Date().getTime() + 1000 * 60 * 15) / 1000; // Fifteen Minutes (NOTE : Need to be seconds. Not miliseconds)
const genericSignOptions = {
  algorithm: "RS256",
};

export async function generateToken(isLoginServerRequest) {
  try {
    const isLoggedUser = checkIsUserLoggedIn();
    let usertoken;
    if (isLoggedUser) {
      usertoken = getLoggedUserDetails().AccessToken;
    }
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      { usertoken, isLoginServerRequest }
    );
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}

export async function generateRetailPaxToken() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "retailpacx",
      usertoken: getLoggedUserDetails().AccessToken,
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );

    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}

export async function generateImageUploadServerToken() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "ImageUpload",
      usertoken: getLoggedUserDetails().AccessToken,
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );

    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}

export async function generateProdToken() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "productRegistry",
      usertoken: getLoggedUserDetails().AccessToken,
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );

    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}

export async function generateSalesRegistryToken() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "salesRegistry",
      usertoken: getLoggedUserDetails().AccessToken,
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );

    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}


export async function generateEntitlementRegistry() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: "entitlementRegistry",
      // usertoken: getLoggedUserDetails().AccessToken,
    };
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );

    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error In Generating Token", error);
  }
}

//generate toekn to get the location detials
export async function generateTokenForlocation() {


  try {
    const apiObject = {
      isLoginServerRequest: true,
      usertoken: getLoggedUserDetails().AccessToken
    }
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error in Generating Token", error)
  }
}

//Generate Token From ORG Registry
export async function generateTokenOrgRegistry() {
  try {
    const apiObject = {
      isLoginServerRequest: true,
      serviceName: 'default'
    }
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error in Generating Token", error)
  }
}


//Generate Token From ORG Registry
export async function generateTokenForMenuFromNeutriPost() {
  try {
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: 'default'
    }
    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );
    return tokenResponse.data.token;
  } catch (error) {
    console.error("Error in Generating Token", error)
  }
}

/* 
  Generate Token to access NeutriPOS
*/
export async function generateTokenForNeutriPOS  () {
  try{
    const apiObject = {
      isLoginServerRequest: false,
      serviceName: 'default',
      usertoken: getLoggedUserDetails().AccessToken,
    };

    const tokenResponse = await Axios.post(
      globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
      apiObject
    );

    return tokenResponse.data.token;
  }catch(error){
    console.error("Error in Generating Token for NeutriPOS", error)
  }
};