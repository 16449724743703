import React, { useState, useEffect, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Card,
  Icon,
  ProgressBar,
  Spin,
  Button,
  Stack,
  StackItem,
  Typography,
  Alert,
} from "witmeg-ui-system";

import "../../GlobalImageUploader/ImageUploader.css";
import axios from "axios";
import { generateImageUploadServerToken } from "../../utilities/tokenGenerators";
import globalValues from "../../../configs/globalValues";
import {
  getHomeLayoutByID,
  updateHomeLayout,
} from "../../utilities/apiRequests/witmegRetailPaxServerRequests";

function GalleryImageUpload({
  imageTempArray,
  handleTempArray,
  setImageError,
  output,
  setOutput,
  src,
  setSrc,
  setUploadToCloud,
  setUploadDone,
  index,
  uploadDone,
  crop,
  setCrop,
  imageSizeValidation,
  setImageSizeValidation,
  imageTypeValidation,
  setImageTypeValidation,
}) {
  // const [src, setSrc] = useState(null);
  // { unit: "%", width: 50, aspect: 16 / 9 }

  const [image, setImage] = useState(null);


  const [testCrop, setTestCrop] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blobFile, setBlobFile] = useState([]);
  const [getImage, setGetImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  // console.log(props.selectedProduct?.Logo);

  // console.log("src 123", Object.values(src));
  //File Validation On TYPE
  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };


  const selectImage = (e) => {
    setImage(null)
    setCrop({
      unit: '%', // Can be 'px' or '%'
      x: 10,
      y: 10,
      width: 80,
      height: 80,

    })
    setImageSizeValidation(false)
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];

      const MAX_FILE_SIZE = 25000; //25MB
      const fileSizeValidation = image.size / 1024;

      if (fileSizeValidation > MAX_FILE_SIZE) {
        setImageSizeValidation("Image size is greaer than 25 MB");
        setIsSuccess(true)
      } else {
        if (isValidFileUploaded(image)) {
          const reader = new FileReader();
          console.log("sdgsgs", reader.result);

          reader.addEventListener("load", () => setSrc(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        } else {
          setIsSuccess(true);
          setImageTypeValidation("Image should be JPG, PNG OR JPEG");
        }
      }
    }
    // setSrc(URL.createObjectURL(file));
    setIsModalVisible(true);
    setImageError(false);
  };

  const onCropComplete = (crop) => {
    console.log("IAM ", crop);
    makeClientCrop(crop);
    setTestCrop(crop);
  };

  async function makeClientCrop(crop) {

    if (image && crop.width && crop.height) {
      const croppedImageUrl = await cropImageNow(image, crop, "newFile.jpeg");
      console.log("croppedImageUrl", image);


      var tempArray = output
      if (index == 0) {
        tempArray = {
          tempOutPut0: croppedImageUrl
        }

      }

      if (index == 1) {
        tempArray = {
          tempOutPut1: croppedImageUrl
        }

      }

      if (index == 2) {
        tempArray = {
          tempOutPut2: croppedImageUrl
        }

      }

      console.log("TEMP", tempArray)
      setOutput(croppedImageUrl)
      setUploadDone(false)
    }

    // setIsModalVisible(false);
  }

  const cropImageNow = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          //console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        let fileUrl = window.URL.createObjectURL(blob);
        //console.log(blob.name)
        //console.log(this)
        resolve(fileUrl);
        setBlobFile(blob); // Set blob image inside the state here
      }, "image/png");
    });
  };



  async function handleUpload() {
    // setImageInformationValues(blobFile);

    Image = await handleImageUpload(blobFile);
    var count = 0;
    for (var i = 0; i < imageTempArray.length; i++) {
      if (i in imageTempArray) {
        count += 1;
      }
    }
    let tempArray = [
      ...imageTempArray,
      {
        imageUrl: Image,
        index: 0,
      },
    ];

    handleTempArray(tempArray);
  }

  // LOGO ICON UPLOAD

  const handleImageUpload = async (uploadingImage) => {
    setIsLoading(true);
    setUploadToCloud(true)
    const token = await generateImageUploadServerToken();

    const fmData = new FormData();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    const timeStamp =
      Math.floor(Math.random() * 10000 + 1) +
      Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";

    const fileName = `P_${timeStamp}`;
    const companyID = sessionStorage.getItem("company");
    const filePath = `/${environmentFolderName}/retailpacx/widgets/${companyID}/gallery-widget/`;

    fmData.append("file", uploadingImage);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const res = await axios.post(
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
        fmData,
        config
      );

      console.log("server res: ", res.data.Result);
      setIsLoading(false);
      setUploadToCloud(false)
      setUploadDone(true)
      return res.data.Result;
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
    }
  };
  // LOGO ICON UPLOAD
  function onCancel() {
    setIsModalVisible(false);
  }

  const inputFile = useRef(null);

  const onClick = () => {
    inputFile.current.click();
  };

  console.log("src", src)
  return (
    <div>
      {isSuccess && (
        <>
          {imageSizeValidation && (
            <Alert message={imageSizeValidation} type='error' showIcon />
          )}
          {imageTypeValidation && (
            <Alert message={imageTypeValidation} type='error' showIcon />
          )}
        </>
      )}

      <div style={{ marginTop: '10px' }}>
        <Stack
          className='image-upload-section'
          direction='column'
          onClick={onClick}
          horizontalAlign='h_center'
          verticalAlign='v_center'
        >
          <input
            type='file'
            accept='image/*'
            // onChange={selectImage}
            onChange={(event) => {
              selectImage(event);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
            ref={inputFile}
            style={{ display: "none" }}
          />
          <Icon name='upload-outline' type='filled' />
          <Typography type='text'>Click here to upload a file</Typography>
          <Typography type="text" size="small" color="gray_base">(Max. file size 25MB)</Typography>
          {/* <Typography type='text' size='small' color='gray_base'>
          (Max. file size 25MB)
        </Typography> */}
        </Stack>
        <input
          type='file'
          accept='image/*'
          onChange={selectImage}
          style={{ display: "none" }}
        />

        <div style={{ marginTop: "20px" }}>
          {src &&

            <>
              <div>

                <Typography
                  pattern='italic'
                  color='gray_base'
                  type='text'
                  size='small'
                >
                  Note : Please crop the image before uploading.
                </Typography>

                <br />
                <ReactCrop
                  src={src}
                  onImageLoaded={setImage}
                  crop={crop}
                  maxWidth={384}
                  maxHeight={220}
                  onChange={setCrop}
                  onComplete={onCropComplete} />

                <br />

                <br />
                <br />
              </div>

              <div style={{ marginTop: "20px" }}>
                {/* {getImage === false && (
          <>
            <Typography type='title' level={4}>
              Manage Uploads
            </Typography>
            <br />
            <Card
              shadow='default'
              disabled={false}
              type={"complex"}
              content={
                <Stack className='eco-pa-4' style={{ flexWrap: "wrap" }}>
                  <StackItem className='thumbnail-image'>
                    <img
                      alt='Crop'
                      style={{ width: "100%" }}
                      src={props.selectedProduct?.Logo}
                    />
                  </StackItem>

                  <StackItem
                    style={{
                      flexGrow: "3",
                      height: "100px",
                      padding: "8px",
                    }}
                  >
                    {" "}
                    <div className='eco-mt-4'>
                      {renderImageSizeInfo(fl.imageType)}
                    </div>
                  </StackItem>
                  <StackItem
                    style={{
                      height: "100px",
                      padding: "8px",
                      justifyContent: "flex-end",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isLoading === false && (
                      <Button
                        onClick={handleUpload}
                        customStyles={{ border: "none" }}
                      >
                        <Icon name='upload' color='green' />
                      </Button>
                    )}
                    {isLoading && <Spin />}
                    {isLoading === true && <Icon name='marker' />}
                     
                  </StackItem>
                </Stack>
              }
              width='100%'
              height='fit-content'
            />
          </>
        )} */}

                {output &&


                  <>
                    {output && <><Typography type='title' level={4}>
                      Manage Uploads
                    </Typography><br />
                      <Card
                        shadow='default'
                        disabled={false}
                        type={"complex"}
                        content={<Stack className='eco-pa-4' style={{ flexWrap: "wrap" }}>
                          <StackItem className=' eco-mt-3 thumbnail-image'>
                            <img
                              alt='Crop'
                              style={{ width: "100%" }}
                              src={output}
                              draggable='false' />
                          </StackItem>

                          <StackItem
                            style={{
                              flexGrow: "3",
                              height: "100px",
                              padding: "8px",
                            }}
                          >
                            {" "}
                            <div className='eco-mt-4'>
                              {/* {renderImageSizeInfo(fl.imageType)} */}
                            </div>
                          </StackItem>
                          <StackItem
                            style={{
                              height: "100px",
                              padding: "8px",
                              justifyContent: "flex-end",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {isLoading === false && uploadDone != true && (
                              <Button
                                onClick={handleUpload}

                              >
                                Click To Upload
                              </Button>
                            )}
                            {isLoading && <Spin />}
                            {uploadDone === true && <ProgressBar type="circle" size="small" variant="success" width={50} value="100" />}
                            {/* <Button onClick={onOk} customStyles={{ border: "none" }}>
<Icon name='trashcan' color='red' />
</Button> */}
                          </StackItem>
                        </Stack>}
                        width='100%'
                        height='fit-content' /></>}
                  </>

                }
              </div>

            </>

          }


        </div>

      </div>




    </div>
  );
}

export default GalleryImageUpload;
