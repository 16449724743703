import React, { useState, useEffect, useReducer } from "react";
import { Stack, Typography, Icon, Card, Message, Row, Col, Button } from "witmeg-ui-system";
import { getHomeLayoutByID, updateHomeLayout } from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import { useDispatch } from "react-redux";
import { setCommonState } from "../../redux/commonReducer";
// import { hot } from "react-hot-loader/root";
import ReactHtmlParser from 'react-html-parser';
import * as _ from "lodash";
import GlobalImageWithContent from "../GlobalImageWithContent/GlobalImageWithContent";
import ContactUs from "./ContactUs";
import { auto } from "@popperjs/core";

const ImageWithContentWidget = ({ widgetID, checkData, PreviewButton, pageID }) => {
  const [ImageUpload, setImageUpload] = useState(false);

  const [imageInformationValues, setImageInformationValues] = useState();
  const [data, setData] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const dispatch = useDispatch();
  const [imageError, setImageError] = useState(false);
  const [gridData, setGridData] = useState({})
  // const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleShowPopup = () => {
    if (PreviewButton == false) {
      dispatch(setCommonState({ draggableState: true }));
      setImageUpload(true);
    }
  };

  const handelLogoImage = (data) => {
    setImageInformationValues(data);
  };

  useEffect(async () => await fetchData(), []);

  useEffect(() => {
    if (checkData) setData(checkData);
  }, [checkData]);

  const fetchData = async () => {
    let deviceState = sessionStorage.getItem("deviceState");
    if (pageID) {
      const _response = await getHomeLayoutByID({
        ID: widgetID,
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
        IMAGE: "LINE 86"
      });

      if(!_response[0]?.RenderSource) return;

      if((deviceState === 'desktop' && _response[0]?.RenderSource === "Desktop") || (deviceState === 'mobile' && _response[0]?.RenderSource === "Mobile")){
        setData(_response[0]);
        setGridData(JSON.parse(_response[0].ItemData))
      }

    } else {
      const _response = await getHomeLayoutByID({
        ID: widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
        IMAGE: "LINE 111"
      });

      if(!_response[0]?.RenderSource) return;

      if ((deviceState === "desktop" && _response[0]?.RenderSource === "Desktop") || (deviceState === "mobile" && _response[0]?.RenderSource === "Mobile")) {
        setData(_response[0]);
        setGridData(JSON.parse(_response[0].ItemData))
      }
    }
  };

  const onClickConfirm = async (_imageData, _crop = false) => {
    setImageUpload(false);
      setImageError(false);
      setConfirmLoading(false);
      await fetchData();


    
  }

  const handleSavaData = async (grdata) => {
    setGridData(grdata)
    if (pageID) {
      const propsPageID = await pageID;

      setConfirmLoading(true);

      await updateHomeLayout({
        ID: widgetID,
        ItemHeading: "Image with Text",
        Item: "Image with Text",
        Width: data?.Width,
        Height: data?.Height,
        X: data?.X,
        Y: data?.Y,
        Type: "Image with Text",
        ItemData: JSON.stringify(grdata),
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: propsPageID,
        RenderSource: "Desktop",
        CompanyID: sessionStorage.getItem("company"),
      });
      Message.success("Widget edited successfully");
      dispatch(setCommonState({ draggableState: false }));

      await fetchData();

      setImageUpload(false);
      setImageError(false);
      setConfirmLoading(false);
    } else {
      const _reqData = {
        ID: widgetID,
        ItemHeading: "Image with Text",
        Item: "Image with Text",
        Width: data?.Width,
        Height: data?.Height,
        X: data?.X,
        Y: data?.Y,
        Type: 'Image with Text',
        ItemData: JSON.stringify(grdata),
        RatioW: '',
        RatioH: '',
        Link: '',
        RenderSource: 'Desktop',
        PageID: '',
        CompanyID: sessionStorage.getItem('company'),
      };
      setConfirmLoading(true);
      await updateHomeLayout({ ..._reqData, RenderSource: 'Desktop' });
      await updateHomeLayout({ ..._reqData, RenderSource: 'Mobile' });

      Message.success("Widget edited successfully");
      dispatch(setCommonState({ draggableState: false }));

      await fetchData();

      setImageUpload(false);
      setImageError(false);
      setConfirmLoading(false);

    }
  }

  const renderSwitch =(param) => {
    if(param.component && param.component.type) {
      switch(param.component.type) {
        case 'text':
          return <div style={{width:'100%'}}>{ReactHtmlParser(param?.itemData?.data || '<p></p>')}</div>;
        case 'button':
          return <div style={param.itemData && param.itemData.data && param.itemData.data.styles?
                      JSON.parse(param.itemData.data.styles):
                      {}
                    }
                  ><Button 
                    variant="primary" 
                    customStyles={param.itemData && param.itemData.data && param.itemData.data.styles?JSON.parse(param.itemData.data.styles):{}} 
                  >{param.itemData && param.itemData.data?ReactHtmlParser(param.itemData.data.text || param.itemData.data):""}</Button></div>;
        case 'image':
          return <div style={{minHeight:'240px'}}><img src={param?.itemData?.data || ''} /></div>;
        case 'form':
          return <div><ContactUs params={{
            widgetID: '232323',
            // w: props.params.w,
            // h: props.params.h,
            PreviewButton: false,
            pageID: 'dddd',
          }}/></div>;
        case 'space':
           return <div style={{width:'100%', minHeight:'24px'}}></div>;
        default:
          return '';
      }
    }
  }

  return (
    <>
      <Row style={{ height: '100%' }}>
        <Col span={24}>
          {_.isEmpty(gridData) && (
            <Card
              rounded="small"
              shadow="no_shadow"
              content={
                <div
                  onDoubleClick={handleShowPopup}
                  className="image-widget-component eco-mt-40"
                >
                  <Stack
                    direction="column"
                    horizontalAlign="space_between"
                    verticalAlign="v_center"
                  >
                    <Icon name="upload-outline" size={32} type="filled" />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                    />
                    <Typography
                      level={4}
                      type="title"
                      alignment="center"
                      color="gray_base"
                    >
                      Double Click To Add Content
                    </Typography>
                  </Stack>
                </div>
              }
            />
          )}

          {gridData && (
            <>
              <div style={{
                backgroundImage: `url(${gridData?.imageData || ''})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '100%',
              }}
              onDoubleClick={handleShowPopup}>
                <div style={{width: gridData.isFullWidth?'100%': '1320px', margin:'auto', display:'flex', flexDirection:'column'}}>
                  {gridData.Layout ?
                    gridData?.Layout.map((g, i) => {
                        return (<div style={{width:'100%', display:'flex', minHeight:'100px'}} key={`gd_${g.id}_${i}`}>{
                          g?.children.map((c,j) => {
                            return (<div style={{flex:1, padding:'8px', textAlign:'center'}}  key={`gd_${g.id}_${i}_${c.id}`}>
                              {
                                c?.children.map(cl => {
                                  return (<div style={ cl.size && cl.size.width && cl.size.height?{padding:8, width:cl.size.width, height:cl.size.height, minHeight:cl?.component?.type==="image"?240:cl?.component?.type==="form"?440:60}:{padding:8}} key={`gd_${g.id}_${i}_${c.id}_${cl.id}`}>
                                    {renderSwitch(cl)}
                                  </div>)
                                })
                              }
                            </div>)
                          })
                        }</div>)
                    })
                  :null}
                </div>

              </div>
            </>
          )}
        </Col>
      </Row>

        <GlobalImageWithContent 
          ImageUpload={ImageUpload}
          setImageUpload={setImageUpload}
          handelLogoImage={handelLogoImage}
          onClickConfirm={onClickConfirm}
          confirmLoading={confirmLoading}
          imageError={imageError}
          setImageError={setImageError}
          handleSavaData={handleSavaData}
          gridData={gridData}
        />
    </>
  );
}

export default ImageWithContentWidget;
