import React from 'react'
import { useState } from 'react'
import { Typography, Stack, Icon } from 'witmeg-ui-system'

export default function SiteSettings({webSiteType, setWebSiteType}) {
    // const [webSiteType, setwebSiteType] = useState(webSiteType)

    const selectCardOnClick = (val) => {
        setWebSiteType(val)
    }

    return (
        <div>
            <Stack horizontalAlign="space_evenly">
                <div style={{ height: '108px', width: '108px', borderRadius: '4px', border: '1px solid #5A5C72', padding: '10px' }} onClick={() => selectCardOnClick("Standard")} className={webSiteType === "Standard" ? "selected-menu" : ""}>
                    <Stack direction="column" verticalAlign="v_center">
                        <Icon name="standard-website" size={44} className="eco-mb-16" color={webSiteType === "Standard" ? "white" : ""} />
                        <Typography type="text" color={webSiteType === "Standard" ? "white_base" : "gray_darkest"}>Standard</Typography>
                    </Stack>
                </div>
                <div style={{ height: '108px', width: '108px', borderRadius: '4px', border: '1px solid #5A5C72', padding: '10px' }} onClick={() => selectCardOnClick("Restaurant")} className={webSiteType === "Restaurant" ? "selected-menu" : ""}>
                    <Stack direction="column" verticalAlign="v_center">
                        <Icon name="restaurant-building" size={44} className="eco-mb-16" color={webSiteType === "Restaurant" ? "white" : ""} />
                        <Typography type="text" color={webSiteType === "Restaurant" ? "white_base" : "gray_darkest"}>Resturant</Typography>
                    </Stack>
                </div>
                <div style={{ height: '108px', width: '108px', borderRadius: '4px', border: '1px solid #5A5C72', padding: '10px' }} onClick={() => selectCardOnClick("E-Commerce")} className={webSiteType === "E-Commerce" ? "selected-menu" : ""}>
                    <Stack direction="column" verticalAlign="v_center">
                        <Icon name="retail-website" size={44} className="eco-mb-16" color={webSiteType === "E-Commerce" ? "white" : ""} />
                        <Typography type="text" color={webSiteType === "E-Commerce" ? "white_base" : "gray_darkest"}>E-Commerce</Typography>
                    </Stack>
                </div>
            </Stack>
        </div>
    )
}
