import { Input } from "antd";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Col,
  InputBox,
  Label,
  Row,
} from "witmeg-ui-system";
import GlobalImageUploader from "../../../GlobalImageUploader/GlobalImageUploader";
import ImageUploader from "../../../Settings/ImageUploader";

const UploadForm = ({ image, data }) => {
  const { setValue } = useFormContext();

  const onChangeImage = (val) => {
    setValue("image",val);
  };

  const handleProcessdImage = (file) => {
    console.log("image", file);
  };
  const [imageUpload, setImageUpload] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  return (
    <div style={{ margin: "32px 20px" }}>
      <Row className="eco-mb-16">
        <Col span={24}>
          <ImageUploader
            file={image}
            onSetImage={(img) => {
              onChangeImage(img);
            }}
            onSetIsValidImageType={(err) => {
              console.log(err);
            }}
            width="500"
            height="500"
          />
        </Col>
      </Row>
      <Row className="eco-mb-16">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Title</Label>
          </div>
        </Col>
        <Col span={16}>
          <InputBox
            type="text"
            noBorder
            backgroundOnly
            value={data?.cardTitle}
            onChange={(e) => setValue("cardTitle", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="eco-mb-16">
        <Col span={8}>
          <div className="form-lbl-wrapper">
            <Label>Description</Label>
          </div>
        </Col>
        <Col span={16}>
          <Input.TextArea
            className="custom-textarea"
            defaultValue={data?.cardDescription}
            onChange={(e) => setValue("cardDescription", e.target.value)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UploadForm;
