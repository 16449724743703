
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Popup, Row, Space, Stack, StackItem, Tabs, Typography, Label, InputBox, Icon, Message, Alert, Spin } from 'witmeg-ui-system'
import { Card, Tag } from 'antd';
import { setCommonState } from '../../../redux/commonReducer'
import DesignForm from './Forms/DesignForm'
import StyleForm from './Forms/StyleForm'
import TypeForm from './Forms/TypeForm'
import { fetchAllRestaurantMenuData, setMenuDataAvailability, setOrderType, setOrganizationData, setRestaurantDefaultOrderType, setRestaurantMenuUICustomizations, setShowPopupData } from '../../../redux/resturantWidgetReducer'
import AuthenticationPopup from './AuthenticationPopUp'
import { dummyMenu } from '../dummyMenu'
import "../Widget.css"
import { getHomeLayoutByID, updateHomeLayout } from '../../utilities/apiRequests/witmegRetailPaxServerRequests'
import theme1 from "../../../images/resturant-menu/resturant-menu-theme-2.jpg"
import { addRestaurantMenuSettingsData, getRestaurantMainMenuIDs, getRestaurantMenuSettingsData, updateRestaurantMenuSettingsData } from '../../utilities/apiRequests/neutriPosServerRequests'
import TwoColumnWithImageLayout from './Layouts/TwoColumnWithImage.Layout'
import { getRestaurantMenuUICustomizations } from '../../utilities/restaurantMenuCustomizationsUtil'
import RestaurantPopupWrapper from './RestaurantPopupWrapper'
import CustomTabs from './Common/CustomTabs'
import OneColumnCenterLayout from './Layouts/OneColumnCenter.Layout'
import OneColumnWithImageLayout from './Layouts/OneColumnWithImage.Layout'
import OneColumnWithoutImageLayout from './Layouts/OneColumnWithoutImage.Layout'
import TwoColumnWithoutImageLayout from './Layouts/TwoColumnWithoutImage.Layout'
import TwoColumnWithoutImageRowLayout from './Layouts/TwoColumnWithoutImageRow.Layout'
import IMAGE_PLACEHOLDER from '../../../assets/img/image-placeholder.png';
import { RESTAURANT_SETTINGS_TYPES } from '../../utilities/helpers';
import { fetchRestaurantMenuSettings } from '../../utilities/restaurantSettingsActions'
import _ from 'lodash';

const RestaurantMenu = (props) => {

  const dispatch = useDispatch();
  const { menuData, defaultOrderType } = useSelector((state) => state.resturantWidget);

  const [showPopup, setShowPopup] = useState(false);
  const [showAuthenticationpopup, setShowAuthenticationpopup] = useState(false);
  const [showCredentialsPopup, setShowCredentialsPopup] = useState(false);
  const [showOrderTypePopup, setShowOrderTypePopup] = useState(false);
  // const [defaultOrderType, setDefaultOrderType] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [widgetInitialized, setWidgetInitialized] = useState(false);

  //Loaidng 
  const [isLoading, setIsLoading] = useState(false)

  //Button Data Form
  const [buttonFormData, setButtonFormData] = useState({})
  //Categories Data Form
  const [categoriesFormData, setCategoriesFormData] = useState({})
  //Sub Categories Data Form
  const [subCatFormData, setSubCatFormData] = useState({})
  //Items Data Form
  const [itemsFormData, setItemsFormData] = useState({})

  //Space Data Form
  const [spaceFormData, setSpaceDataForm] = useState({
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
  });

  //Layout
  const [selectedLayout, setSelectedLayout] = useState(null)

  //Themes 
  const [selectedTheme, setSelectedTheme] = useState('removeTheme')

  //Background Color 
  const [backgroundColor, setBackgroundColor] = useState(null)

  //Card Type 
  const [selectedCard, setSelectedCard] = useState("popup");


  //Get Menu ItemData
  //Global Variable
  const [parsedMenuData, setParsedMenuData] = useState()
  const [isPopup, setIsPopup] = useState(false);

  //Button Form
  const setButtonField = (field, value) => {
    setButtonFormData(prevState => ({
      ...prevState,
      [field]: value
    }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field]) setErrors({ ...errors, [field]: null });
  };

  //Categories Form
  const setCategoriesField = (field, value) => {
    setCategoriesFormData(Object.assign(categoriesFormData, { [field]: value }));
  };

  //Sub Categories Form
  const setSubCategoriesField = (field, value) => {
    setSubCatFormData(Object.assign(subCatFormData, { [field]: value }));
  };

  //Items Form
  const setItemsField = (field, value) => {
    setItemsFormData(Object.assign(itemsFormData, { [field]: value }));
  };

  //Space Form
  const setSpaceField = (field, value) => {
    setSpaceDataForm(Object.assign(spaceFormData, { [field]: value }));
  };


  //Disable Tab Pane
  const [disableTab, setDisableTab] = useState(true)

  const [errors, setErrors] = useState({})
  const [paddingValidations, setPaddingValidations] = useState(false)
  const [paddingValidation, setPaddingValidation] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false)

  //Design popup
  function handleChangePopupsettings() {
    let temp = localStorage.getItem('userAuthenticated');
    if (temp == true || temp != undefined) {
      setDisableTab(false);
    }
    if (paddingValidations === true || paddingValidation === true) {
      setErrorMsg(true);
    } else {
      setActiveTab('3');
      setPaddingValidations(false);
      setErrorMsg(false);
    }
  }

  // Selecting the Order Type
  const getOrderType = (orderType) => {
    dispatch(setOrderType({ menuOrderType: orderType }));
    dispatch(setRestaurantDefaultOrderType({ defaultOrderType: orderType }));
  };

  const defaultMenuList = dummyMenu
  const [missingvalidationFields, setMissingValidationFields] = useState([]);

  const [orderTypeValidation, setOrderTypeValidation] = useState(false)

  //Validations
  const findFormErrors = () => {
    const { buttonName } = buttonFormData;

    const newErrors = {};

    const tempArray = [];
    if (!buttonName || buttonName === '') {
      newErrors.buttonName = true;
      tempArray.push('Button Name');
    }

    setErrors(newErrors);
    setMissingValidationFields(tempArray);

    return tempArray;
  };


  function onDoubleClickRestaurantMenu() {
    if (props.params.PreviewButton == false) {
      setShowPopup(true);
      dispatch(setCommonState({ draggableState: true }));

      let temp = localStorage.getItem('userAuthenticated') === 'true';
      if (temp) {
        setDisableTab(false);
      }
      setMissingValidationFields([]);
    }
  }

  const userIsAuthenticated = (localStorage.getItem("userAuthenticated") === 'true');

  //Order Type
  function handleTypePopUp() {
    let temp = localStorage.getItem('userAuthenticated') === 'true';

    setShowPopup(false);

    if (userIsAuthenticated) {
      if (!widgetInitialized) {
        setShowOrderTypePopup(true);
        setDisableTab(false);
      } else {
        handleOrderPopup();
      }
    } else {
      setShowAuthenticationpopup(true);
    }

    if (temp) {
      setDisableTab(false);
    }
  }

  //Authentication popup
  function handleAuthPopup() {
    setShowAuthenticationpopup(false);
    if (userIsAuthenticated) {
      setShowOrderTypePopup(true);
    } else {
      setShowCredentialsPopup(true);
    }
  }

  function handleOrderPopup() {
    if (defaultOrderType === '') {
      setOrderTypeValidation(true);
    } else {
      setShowOrderTypePopup(false);
      setShowPopup(true);
      setActiveTab('2');
      setOrderTypeValidation(false);
    }
  }

  // MENU TABS
  const menuTabs = [
    {
      tabID: '1',
      tabName: 'Type',
      content: (
        <TypeForm
          orderTypeValidation={orderTypeValidation}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />
      ),
    },
    {
      tabID: '2',
      tabName: 'Design',
      disabled: disableTab ? true : false,
      content: (
        <DesignForm
          paddingValidation={paddingValidation}
          setPaddingValidation={setPaddingValidation}
          paddingValidations={paddingValidations}
          setPaddingValidations={setPaddingValidations}
          errorMsg={errorMsg}
          errors={errors}
          missingvalidationFields={missingvalidationFields}
          handleChangePopupsettings={handleChangePopupsettings}
          spaceFormData={spaceFormData}
          subCatFormData={subCatFormData}
          itemsFormData={itemsFormData}
          categoriesFormData={categoriesFormData}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          selectedCard={selectedCard}
          setSpaceField={setSpaceField}
          setSpaceDataForm={setSpaceDataForm}
          setSubCategoriesField={setSubCategoriesField}
          setCategoriesField={setCategoriesField}
          buttonFormData={buttonFormData}
          setButtonField={setButtonField}
          setItemsField={setItemsField}
        />
      ),
    },
    {
      tabID: '3',
      tabName: 'Style',

      content: (
        <StyleForm
          selectedTheme={selectedTheme}
          setSelectedTheme={setSelectedTheme}
          backgroundColor={backgroundColor}
          setBackgroundColor={setBackgroundColor}
        />
      ),
      disabled: disableTab ? true : false,
    },
  ];

  //Authentication Popup
  const authentionPopUp = () => {
    dispatch(setCommonState({ draggableState: false }));
    dispatch(setShowPopupData({ showPopUp: false }));
    setShowAuthenticationpopup(false);
  };

  //Hide the Popup or Embedded Popup
  const hidePopUp = () => {
    if (selectedCard === 'embedded' || parsedMenuData?.selectedCard === 'embedded') {
      dispatch(setCommonState({ draggableState: true }));
    } else {
      dispatch(setCommonState({ draggableState: false }));
    }

    setShowPopup(false);
    setGetMenu(false);
  };

  //Hide Order Type Popup
  const hideOrderTypePopUp = () => {
    if (selectedCard === 'embedded' || parsedMenuData?.selectedCard === 'embedded') {
      dispatch(setCommonState({ draggableState: true }));
    } else {
      dispatch(setCommonState({ draggableState: false }));
    }

    setShowOrderTypePopup(false);
    setOrderTypeValidation(false);
  };

  const [form, setForm] = useState({});
  const [autherrors, setAuthErrors] = useState({});
  const [authValidation, setAuthValidation] = useState(null)

  //Hide Auth Popup
  const hideCredentialsPopUp = () => {
    dispatch(setCommonState({ draggableState: false }));

    setShowCredentialsPopup(false);
    setAuthValidation(null);
    setAuthErrors({});
    setForm({});
  };

  //WILL OPEN IN NEW Tab
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  //Redirect to WEB NEUTRIPOS
  function handelNavigate() {
    openInNewTab('https://uat-webneutripos.throbox.com/login');
  }

  if (selectedLayout === null) {
    setSelectedLayout('layout2');
  }

  const [data, setData] = useState([])
  const [getMenu, setGetMenu] = useState(false)

  const retrieveOrderTypeData = async (CloudLocationID) => {
    const _response = await getRestaurantMenuSettingsData({
      SettingType: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
      CloudLocationID,
    });

    if (!_response || _response.length === 0) return;

    const _defaultOrderType = _.find(_response, { IsDefault: true });
    dispatch(setRestaurantDefaultOrderType({ defaultOrderType: _defaultOrderType.OrderTypeName }));
  };

  const initializeOrderTypeData = async (_cloudLocations, _organizationID, _orderType) => {
    const _existOrderTypeSettings = await fetchRestaurantMenuSettings({
      type: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
      cloudLocationsData: _cloudLocations,
    });

    if (_existOrderTypeSettings && _existOrderTypeSettings.length > 0 && _existOrderTypeSettings[0].length > 0) {
      let _deleteExistPromises = [];

      for (const __data of _existOrderTypeSettings.flat()) {
        _deleteExistPromises.push(
          new Promise(async function (resolve, refuse) {
            resolve(
              (await updateRestaurantMenuSettingsData({
                SettingType: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
                CloudLocationID: __data.CloudLocationID,
                SettingData: { ...__data, IsActive: false },
              })) ?? null
            );
          })
        );
      }
      Promise.all(_deleteExistPromises);
    }

    const _orderTypeOptions = Object.values(RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.OPT);
    const _selectedOrderType = _.find(_orderTypeOptions, { name: _orderType });

    let __addNewPromises = [];

    for (const __location of _cloudLocations) {
      __addNewPromises.push(
        new Promise(async function (resolve, refuse) {
          resolve(
            (await addRestaurantMenuSettingsData({
              SettingType: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
              OrganizationID: _organizationID,
              CloudLocationID: __location.CloudLocationID,
              SettingData: {
                SettingType: RESTAURANT_SETTINGS_TYPES.MAIN_ORDER_TYPES.KEY,
                OrderTypeName: _selectedOrderType.name,
                DisplayName: _selectedOrderType.title,
                OrganizationID: _organizationID,
                CloudLocationID: __location.CloudLocationID,
                IsDefault: true,
              },
            })) ?? null
          );
        })
      );
    }
    Promise.all(__addNewPromises);
  };

  const fetchRestaurantMenuData = async (_payload) => {
    retrieveOrderTypeData(_payload.CloudLocationID);
    dispatch(fetchAllRestaurantMenuData(_payload));
  };

  //Fetch data of the menu customization
  const fetchData = async () => {
    let deviceState = sessionStorage.getItem('deviceState');
    //IF Type is Embedded Stop Drag
    if (selectedCard === 'embedded') {
      dispatch(setCommonState({ draggableState: true }));
    }

    const _response = await getHomeLayoutByID({
      ID: props.params.widgetID,
      PageID: props.params.pageID,
      CompanyID: sessionStorage.getItem('company'),
      Start: 0,
      Limit: 1000,
      IMAGE: 'LINE 111',
    });
    if (!_response || _response.length === 0 || !_response[0].RenderSource) return;

    //if (deviceState === 'desktop' && _response[0].RenderSource === 'Desktop') {
    if (_response[0].ItemData && _response[0].ItemData.trim() !== '') {
      setWidgetInitialized(true);
      const _parsedData = JSON.parse(_response[0].ItemData);
      setParsedMenuData(_parsedData);
      setData(_response[0]);

      const _menuUICustomizations = getRestaurantMenuUICustomizations(_parsedData);
      dispatch(setRestaurantMenuUICustomizations({ uiCustomizations: _menuUICustomizations }));

      dispatch(setOrganizationData({
        CloudLocations: _parsedData.CloudLocations,
        OrganizationID: _parsedData.OrganizationID
      }));

      await fetchRestaurantMenuData({
        CloudLocationID: _parsedData.CloudLocations[0].CloudLocationID,
        OrganizationID: _parsedData.OrganizationID,
      });

      setIsPopup(_parsedData?.selectedCard === 'popup' ? true : false);

      if (_parsedData.CloudLocations.length > 0) {
        dispatch(setMenuDataAvailability({ menuDataAvailable: true }));
      }

      //IF Type is Embedded Stop Drag
      if (_parsedData.selectedCard === 'embedded') {
        dispatch(setCommonState({ draggableState: true }));
      }

      dispatch(setOrderType({ menuOrderType: _parsedData?.defaultOrderType }));

      setSelectedLayout(_parsedData?.selectedLayout);
      setButtonFormData(_parsedData?.buttonFormData);
      setCategoriesFormData(_parsedData?.categoriesFormData);
      setItemsFormData(_parsedData?.itemsFormData);
      setSubCatFormData(_parsedData?.subCatFormData);
      setSpaceDataForm(_parsedData?.spaceFormData);
      //   setDefaultOrderType(_parsedData?.defaultOrderType);
      setSelectedCard(_parsedData?.selectedCard);
      setSelectedTheme(_parsedData?.selectedTheme);
      // forceUpdate()
      setErrors(false);
    }
    // } else if (deviceState == 'mobile' && _response[0].RenderSource === 'Mobile') {
    //   //   setData(_response[0]);
    // }
  };

  useEffect(() => fetchData(), []);

  //Submit All The Chanages
  async function handleOnSubmit(e) {
    const newErrors = findFormErrors();
    if ((parsedMenuData?.selectedCard === 'popup' || selectedCard === 'popup') && newErrors.length > 0) {
      //WE GO ERRORS
      setMissingValidationFields(newErrors);
      setActiveTab('2');
    } else {
      setIsLoading(true);

      const temp = localStorage.getItem('CloudLocations');
      const CloudLocations = await JSON.parse(temp);
      const OrganizationID = await localStorage.getItem('RMOrganization');

      if (!widgetInitialized && CloudLocations && CloudLocations.length > 0) {
        initializeOrderTypeData(CloudLocations, OrganizationID, defaultOrderType);
      }

      const resturantWidgetCustomization = {
        buttonFormData,
        categoriesFormData,
        subCatFormData,
        itemsFormData,
        spaceFormData,
        selectedLayout,
        selectedTheme,
        backgroundColor,
        selectedCard,
        defaultOrderType,
        CloudLocations,
        OrganizationID,
      };
      const restuantWidgetData = JSON.stringify(resturantWidgetCustomization);
      let PropspageID = await props.params.pageID;

      const _layoutSizes = {
        height: isPopup ? data?.Width : 32,
        width: isPopup ? data?.Height : 24,
      };
      await updateHomeLayout({
        ID: props.params.widgetID,
        ItemHeading: 'resturant menu',
        Item: 'resturant menu',
        Width: _layoutSizes.width,
        Height: _layoutSizes.height,
        X: data?.X,
        Y: data?.Y,
        Type: 'resturant menu',
        ItemData: restuantWidgetData,
        RatioW: '',
        RatioH: '',
        Link: '',
        PageID: PropspageID,
        RESTEDIT: 'REST EDIT',
        CompanyID: sessionStorage.getItem('company'),
      });
      Message.success('Widget edited successfully');
      if (selectedCard === 'embedded') {
        dispatch(setCommonState({ draggableState: true }));
      } else {
        dispatch(setCommonState({ draggableState: false }));
      }
      await fetchData();
      setGetMenu(true);
      dispatch(setShowPopupData({ showPopUp: false }));
      setIsLoading(false);
      hidePopUp();
      setErrors(false);
    }
  }

  const LoadingSpinner = () => (
    <div className='eco-restaurant-menu-loader'>
      <Spin size="large" />
    </div>
  );
  const { Meta } = Card;

  return (
    <>
      {/* layout-1 img: one col with image */}
      {/* layout-2 img : one col without image */}
      {/*  layout-3 img: two col with image*/}
      {/* layout-4  img: two col without image no space */}
      {/* layout-5 img :  two col without image no space*/}
      {/* layout-6 img :  one col without image space*/}

      {
        parsedMenuData ? (
          <RestaurantPopupWrapper menuDoubleClick={onDoubleClickRestaurantMenu} isPopup={isPopup}>
            <CustomTabs isPopup={isPopup}>
              {
                menuData.isLoading ?
                  <LoadingSpinner /> : (
                    {
                      layout1: <OneColumnWithImageLayout isPopup={isPopup} orderType={parsedMenuData?.defaultOrderType} />,
                      layout2: <OneColumnWithoutImageLayout isPopup={isPopup} orderType={parsedMenuData?.defaultOrderType} />,
                      layout3: <TwoColumnWithImageLayout isPopup={isPopup} orderType={parsedMenuData?.defaultOrderType} />,
                      layout4: <TwoColumnWithoutImageLayout isPopup={isPopup} orderType={parsedMenuData?.defaultOrderType} />,
                      layout5: <TwoColumnWithoutImageRowLayout isPopup={isPopup} orderType={parsedMenuData?.defaultOrderType} />,
                      layout6: <OneColumnCenterLayout isPopup={isPopup} orderType={parsedMenuData?.defaultOrderType} />,
                    }[parsedMenuData?.selectedLayout]
                  )
              }
            </CustomTabs>
          </RestaurantPopupWrapper>
        ) : (
          <div onDoubleClick={onDoubleClickRestaurantMenu} style={{ padding: '20px', backgroundImage: `url(${theme1})`, backgroundRepeat: 'no-repeat', backgroundPosition: "center", backgroundSize: "cover", height: "100%", width: "100%" }}>

            <Row>
              <Col span={24}>
                <Typography type="title" level={2} alignment="center" className="eco-mb-28" color="white_base">Our Menu</Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} justify="space-around">
              {defaultMenuList.map((item) => {
                return (
                  // // Mobile Start
                  <Col span={12} style={{ padding: '10px', }}>
                    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                        <div style={{ padding: '0.5rem' }}>
                          <Typography type="title" level={4} pattern="bold">{item.menuItem}</Typography>
                          <Typography type="title" level={5} style={{ padding: '5px 0' }}>452 Kcal | Serves 2</Typography>
                          <Typography type="text" alignment="left" style={{ padding: '5px 0' }}>{item.menuDescription}</Typography>
                        </div>
                        <img src="https://images.menu.nandos.dev/uk/starters/200x200/take-your-pick-3.Image-1-1.134911.jpg" style={{ height: '10rem', width: '10rem', padding: '5px' }} />
                      </div>
                      <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                        <Typography type="title" level={5} pattern="bold">{item.itemPrice}</Typography>
                        <Typography type="title" level={5} pattern="bold">Halal</Typography>
                      </div>
                    </div>
                  </Col>
                  // // Mobile End
                  // // Tablet Start
                  // <Col span={12} style={{ padding: '10px', }}>
                  //   <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: '8px' }}>
                  //     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0.5rem 1rem' }}>
                  //       <div style={{ padding: '0.5rem' }}>
                  //         <Typography type="title" level={4} pattern="bold">{item.menuItem}</Typography>
                  //         <Typography type="title" level={5} style={{ padding: '5px 0' }}>452 Kcal | Serves 2</Typography>
                  //         <Typography type="text" alignment="left" style={{ padding: '5px 0' }}>{item.menuDescription}</Typography>
                  //       </div>
                  //       <img src="https://images.menu.nandos.dev/uk/starters/200x200/take-your-pick-3.Image-1-1.134911.jpg" style={{ height: '10rem', width: '10rem', padding: '5px' }} />
                  //     </div>
                  //     <div style={{ padding: '0.5rem 1.5rem', justifyContent: 'space-between', display: 'flex' }}>
                  //       <Typography type="title" level={5} pattern="bold">{item.itemPrice}</Typography>
                  //       <Typography type="title" level={5} pattern="bold">Halal</Typography>
                  //     </div>
                  //   </div>
                  // </Col>
                  // // Tablet End
                )
              })}
            </Row>
          </div>
        )
      }

      <Popup
        width={640}
        modalTitle="Menu"
        modalVisible={showPopup}
        onCancel={() => hidePopUp()}
        footer={
          <Space size="middle">
            {activeTab === '1' && <Button variant="primary" className="long-btn" onClick={() => { handleTypePopUp() }}>Next</Button>}
            {activeTab === '2' && <Button variant="primary" className="long-btn" onClick={() => { handleChangePopupsettings() }}>Next</Button>}
            {activeTab === '3' && <Button variant="primary" className={isLoading ? "finsish-button" : "long-btn"} category={isLoading ? "load-text" : "text"} onClick={() => { handleOnSubmit() }}>Finish</Button>}
            <Button onClick={() => hidePopUp()} variant="outline" className="long-btn" >Cancel</Button>
          </Space>
        }
      >
        <Tabs tabContent={menuTabs} activeKey={activeTab} onTabClick={(key, event) => setActiveTab(key)} />
      </Popup>

      {/* ASKING FOR AUTHENTICATION POPUP */}
      <Popup
        width={450}
        modalVisible={showAuthenticationpopup}
        onCancel={() => authentionPopUp()}
        closable={false}
        centered>
        <Stack horizontalAlign="h_center" direction="column">
          <Typography type="title" level={4} pattern="bold" className="eco-mb-24">Authentication Required</Typography>
          <Typography type="text" color="gray_base" className="eco-mb-24" alignment="center">Need <b style={{ color: "#000" }}>WITMEG Restaurant</b> application credentials to upload the menu. Do you have an account?</Typography>
          <Stack horizontalAlign="h_center">
            <Space size="middle">
              <Button variant="primary" className="long-btn" onClick={() => { handleAuthPopup() }}>Yes</Button>
              <Button variant="secondary" onClick={() => handelNavigate()} className="long-btn">No</Button>
            </Space>
          </Stack>
        </Stack>
      </Popup>

      {/* SELECTING A ORDER TYPE POPUP */}
      <Popup
        width={700}
        modalVisible={showOrderTypePopup}
        onCancel={() => hideOrderTypePopUp()}
        centered>
        <div>
          <Typography type="title" level={5} pattern="bold" className="eco-mb-24">Choose a Default Order Type</Typography>
          <div style={{ width: '100%', padding: '40px', borderRadius: '4px', border: '1px solid #C6C7D2', marginBottom: '24px' }}>
            {orderTypeValidation && <div className="eco-mb-24">  <Alert type="error" showIcon message='Please select an order type' /></div>}
            <Stack horizontalAlign="space_evenly">
              <div onClick={() => getOrderType("table")} className={defaultOrderType == "table" ? "order-type-box selected-order-type-box" : "order-type-box"}>
                <Icon name="dine-in" size={44} color={defaultOrderType == "table" ? "white" : ""} />
                <Typography type="text" color={defaultOrderType == "table" ? "white_base" : ""}>Dine - In</Typography>
              </div>
              <div onClick={() => getOrderType("collection")} className={defaultOrderType == "collection" ? "order-type-box selected-order-type-box" : "order-type-box"}>
                <Icon name="collection" size={44} color={defaultOrderType == "collection" ? "white" : ""} />
                <Typography type="text" color={defaultOrderType == "collection" ? "white_base" : ""}>Collection</Typography>
              </div>
              <div onClick={() => getOrderType("delivery")} className={defaultOrderType == "delivery" ? "order-type-box selected-order-type-box" : "order-type-box"}>
                <Icon name="delivery-bike" size={44} color={defaultOrderType == "delivery" ? "white" : ""} />
                <Typography type="text" color={defaultOrderType == "delivery" ? "white_base" : ""}>Delivery</Typography>
              </div>
            </Stack>
          </div>
          <Stack horizontalAlign="h_end">
            <Space size="middle" className="eco-mb-16">
              <Button variant="primary" className="save-btn" onClick={handleOrderPopup}>Next</Button>
              <Button onClick={hideOrderTypePopUp} variant="outline" className="cancel-btn">Cancel</Button>
            </Space>
          </Stack>
        </div>
      </Popup>

      {/* Neutripos Login Pop up */}
      <Popup
        width={500}
        modalVisible={showCredentialsPopup}
        onCancel={() => hideCredentialsPopUp()}
        closable={false}
        centered>
        <AuthenticationPopup
          form={form}
          setForm={setForm}
          autherrors={autherrors}
          authValidation={authValidation}
          setAuthValidation={setAuthValidation}
          setAuthErrors={setAuthErrors} setShowOrderTypePopup={setShowOrderTypePopup} setShowCredentialsPopup={setShowCredentialsPopup} hideCredentialsPopUp={hideCredentialsPopUp} />
      </Popup>
    </>
  )
}

export default RestaurantMenu