import React, { useState, useEffect } from "react";
import {
  async,
  Table,
  Typography,
  Button,
  Space,
  Stack,
  StackItem,
  RightPanel,
  RightPanelHeader,
  InputGroup,
  ActionList,
  Icon,
  Popover,
  Accordion,
  Spin,
  Alert,
  Message,
  Tag,
  Search,
  Popup,
} from "witmeg-ui-system";
import axios from "axios";
import config from "react-global-configuration";
import BasicInformation from "./Forms/BasicInformation";
import DomainInformation from "./Forms/DomainInformation";
import ImageUpload from "./Forms/ImageUpload";
import { Badge, Modal } from "antd";
import moment from "moment";
import "./Dashboard.css";
import UploadItems from "../../imageupload-global";
import {
  createCompany,
  deleteCompany,
  editCompanyById,
  getAllCompaniesData,
  getCompanyByID,
  getCompanyByOrgID,
} from "../utilities/apiRequests/witmegRetailPaxServerRequests";
import apiEndpointPaths from "../utilities/apiEndpointPaths";
import { generateImageUploadServerToken } from "../utilities/tokenGenerators";
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import globalValues from "../../configs/globalValues";
import Resizer from "react-image-file-resizer";
import LogoImageUpload from "./Forms/LogoImageUpload";
import { setCompanyID } from "../../redux/organiserReducer";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TimePicker } from "antd";
import SiteSettings from "./Forms/SiteSettings";

const { confirm } = Modal;
function DashboardTable({ selectedLocation }) {
  const dispatch = useDispatch();

  //WILL OPEN IN NEW Tab
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  function handleDispatch(data) {
    dispatch(
      setCompanyID({
        CompanyID: data.ID,
      })
    );

    openInNewTab(`sites/${data.ID}`);
  }

  const [columnData, setColumnData] = useState([
    {
      title: "Site Name",
      dataIndex: "Name",
      key: "Name",
      // render: (text, row) => <img src={row.Logo} />,
    },
    {
      title: "Customer Domain",
      dataIndex: "CustomerDomain",
      key: "CustomerDomain",
      // render: (text, row) => (
      //   <div>
      //     <p>{row.Name}</p>
      //     <p>{row.CustomerDomain}</p>
      //     <p>{row.SubDomain}</p>
      //   </div>
      // ),
    },
    {
      title: "Sub  Domain",
      dataIndex: "SubDomain",
      key: "SubDomain",
      // render: (text, row) => (
      //   <div>
      //     <p>{row.Name}</p>
      //     <p>{row.CustomerDomain}</p>
      //     <p>{row.SubDomain}</p>
      //   </div>
      // ),
    },
    {
      title: "Editor",
      dataIndex: "ID",
      key: "edit",

      render: (text, record, index) => (
        <Space size="middle">
          {/* <a href={"sites/" + record}> */}
          <Button
            category="icon"
            variant="link"
            children="editor"
            size="small"
            iconSize={16}
            onClick={() => handleDispatch(record)}
          />

          {/* </a> */}
        </Space>
      ),
    },
    {
      title: "Preview",
      dataIndex: "ID",
      key: "ID",
      render: (record) => (
        <Space size="middle">
          <a href={"/preview/" + record}>
            <Button
              category="icon"
              variant="link"
              children="eye"
              size="small"
              iconSize={16}
            />
          </a>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "IsPublished",
      key: "IsPublished",

      render: (record) => (
        <Space size="middle">
          {record === false && <Tag variant="red" label="Unpublished" />}
          {record === true && <Tag variant="green" label="Published" />}
        </Space>
      ),
    },

    {
      title: "Actions",
      // dataIndex: "ID",
      key: "edit",

      render: (record) => (
        <Space size="middle">
          <Button
            category="icon"
            variant="link"
            children="ellipsis-vertical"
            size="small"
            iconSize={16}
            onClick={async (e) => {
              await handleShowCompanyActions(e, record);
            }}
            onMouseLeave={() => toggleActiomMenu(false)}
          />
        </Space>
      ),
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [viewRightPanel, setViewRightPanel] = useState(false);
  const [form, setForm] = useState({});
  const [testForm, setTestForm] = useState({});
  const [errors, setErrors] = useState({
    companyName: false,
    address: false,
    city: false,
    country: false,
    zip: false,
    email: false,
    opening_hours: false,
    subDomain: false,
    customerDomain: false,
    phone: false,
  });
  const [siteDetailsLoading, setSiteDetailsLoading] = useState(false);
  const [imageInfomationValues, setImageInfomationValues] = useState(false);
  const [logoInformationValues, setLogoInfomationValues] = useState(false);
  const [actionMenuPopoverTriggerEvent, setActionMenuPopoverTriggerEvent] =
    useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductActionMenu, setShowProductActionMenu] = useState(false);
  const [showAddOrEditPanel, setShowAddOrEditPanel] = useState(false);
  const [enableProductAddMode, setEnableProductAddMode] = useState(false);
  const [enableProductEditMode, setEnableProductEditMode] = useState(false);
  const [isSavingProduct, setIsSavingProduct] = useState(false);
  const [deletePopup, setDeletePopUp] = useState(false);
  // const [validationPopUp, setValidationPopUp] = useState(false);
  const [missingvalidationFields, setMissingValidationFields] = useState([]);
  const [logoImage, setLogoImage] = useState(null);
  const [faviconLogo, setFaviconLogo] = useState(null);
  const [basicInfoValidationErrors, setbasicInfoValidationErrors] = useState({
    companyName: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    country: false,
    phone: false,
    fax: false,
    email: false,
    opening_hours: false,
  });

  const [phonefaxValidation, setPhoneFaxValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const[showDeleteConfirmPopup,setShowDeleteConfirmPopup] = useState(false);
  const [webSiteType, setWebSiteType] = useState('Standard')


  const orgID = localStorage.getItem("orgID");

  // Form
  const setField = (field, value) => {
    let currentForm = JSON.parse(JSON.stringify(form));
    setForm(Object.assign(currentForm, { [field]: value }));

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  console.log("Time==>> ", form);

  // Toogle Action Menu
  const toggleActiomMenu = (showPrintMenu) => {
    setShowProductActionMenu(showPrintMenu);
  };

  const handleShowEditView = () => {
    // setSelectedProduct(record.id)
    setShowAddOrEditPanel(true);
    setEnableProductAddMode(false);
    setEnableProductEditMode(true);
    setShowProductActionMenu(false);
    setShowProductActionMenu(false);
  };

  //Form Validations
  const findFormErrors = () => {
    //Validation Messages
    const {
      companyName,
      address,
      city,
      state,
      country,
      zip,
      phone,
      email,
      opening_hours,
      subDomain,
      customerDomain,
      fax,
      locationID,
    } = form;
    const newErrors = {};

    const tempArray = [];

    // Basic Information Validations
    if (!companyName || companyName === "") {
      newErrors.companyName = true;
      tempArray.push("Company Name");
    }
    if (!address || address === "") {
      newErrors.address = true;
      tempArray.push("Address");
    }
    if (!state || state === "") {
      newErrors.state = true;
      tempArray.push("State");
    }
    if (!city || city === "") {
      newErrors.city = true;
      tempArray.push("City");
    }

    if (!email || email === "") {
      newErrors.email = true;
      tempArray.push("City");
    }

    if (!country || country === "") {
      newErrors.country = true;
      tempArray.push("Country");
    }
    if (!zip || zip === "") {
      newErrors.zip = true;
      tempArray.push("Zip");
    }
    if (!phone || phone === "") {
      newErrors.phone = true;
      tempArray.push("Phone");
    }

    if (!locationID) {
      newErrors.locationID = true;
      tempArray.push("Location");
    }

    if (!email || email === "") {
      newErrors.email = true;
      tempArray.push("Email");
    }

    if (!opening_hours || opening_hours === "") {
      // newErrors.opening_hours = true;
      // tempArray.push("Opening Hours");
    }

    // Domain Details Validations
    if (!subDomain || subDomain === "") {
      newErrors.subDomain = true;
      tempArray.push("Sub Domain");
    }
    if (!customerDomain || customerDomain === "") {
      newErrors.customerDomain = true;
      tempArray.push("Customer Domain");
    }

    setErrors(newErrors);
    setMissingValidationFields(tempArray);
    return tempArray;
  };

  console.log("TESSST", missingvalidationFields);
  const missingBasicInfoMessage =
    missingvalidationFields.length > 0
      ? `${missingvalidationFields.join(
          ", "
        )} fields are missing in Add New Site.`
      : "";

  console.log("missingBasicInfoMessage", missingBasicInfoMessage);

  const [logoValidation, setLogoValidation] = useState(false);
  const [faviconValidation, setFaviconValidation] = useState(false);

  const onSetFavicon = (data) => {
    setImageInfomationValues(data);
    setFaviconUpdated(true);
  };

  // Submit Button
  async function handleSubmit(e) {
    var regexValidation = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    const startDate = form.opening_hours[0];
    const endDate = form.opening_hours[1];

    // let dateStrings = [moment(startDate), moment(endDate)]

    e.preventDefault();

    let Logo;
    let FaviconLogo;
    if (logoUpdated === true) {
      Logo = await Logoupload(logoInformationValues);
    }
    if (faviconUpdated === true) {
      FaviconLogo = imageInfomationValues;
    }
    setIsSavingProduct(false);

    const newErrors = findFormErrors();
    console.log("newERRORs", newErrors);
    console.log(logoInformationValues,imageInfomationValues)
    //Conditional Logic:
    if (
      newErrors.length > 0 ||
      !imageInfomationValues ||
      !logoInformationValues
    ) {
      //We Got Errors!
      setMissingValidationFields(newErrors);
      // setErrors(true);
      if (!imageInfomationValues) {
        setFaviconValidation(true);
      } else {
        setFaviconValidation(false);
      }
      if (!logoInformationValues) {
        setLogoValidation(true);
      } else {
        setLogoValidation(false);
      }


      if (form.phone && form.fax) {
        if (form.phone === form.fax) {
          setPhoneFaxValidation(true);
        } else {
          setPhoneFaxValidation(false);
        }
      }

      if (form.email !== undefined && form.email != "") {
        if (regexValidation.test(form.email) == false) {
          setEmailValidation(true);
        }
      } else {
        setEmailValidation(false);
      }
      // setValidationPopUp(true);
      setIsSavingProduct(false);
    } else {
      //No Errors
      console.log("FORM", form);
      // setValidationPopUp(false);

      setIsSavingProduct(true);
      setSiteDetailsLoading(true);
      // setLogoImage(await upload(imageInfomationValues.file.selectedFile));
      // setFaviconLogo(await Logoupload(logoInformationValues));
      const createCompanyData = await createCompany({
        Name: form.companyName,
        OrganisationID: selectedLocation?.id
          ? selectedLocation?.id
          : orgID[0].id,
        Logo: Logo,
        LogoCDN: Logo,
        // Favicon: "/images/shankar_favicon.icon",

        Favicon: FaviconLogo,
        AddressLine1: form.address,
        AddressLine2: form.address2,
        City: form.city,
        StateProvinceRegion: form.state,
        PostZipCode: form.zip,
        Country: form.country,
        Phone: form.phone,
        Fax: form.fax,
        Email: form.email,
        OpeningHours: JSON.stringify(form.opening_hours),
        SubDomain: form.subDomain,
        CustomerDomain: form.customerDomain,
        LocationID: form.locationID,
        WorldPayServiceKey: "T_S_343d6a8d-d5ff-4562-ae79-5905d7b6d5d9",
        WorldPayClientKey: "T_C_26967af9-af83-4021-b7f0-8240f0dedf27",
        WishListEnabled: false,
        HelpPage: "ddefeededefefe",
        SocialMedia: "",
        DeliveryMessage: "",
        ShopNowItems: "Pizza, Burger",
        Theme: "Default",
        FBPageID: "",
        FBAppID: "",
        CDNPath: form.companyName
          .replace(/ /g, "")
          .replace("&", "")
          .replace(/(^\&)|,/g, "")
          .toLowerCase(),
        WebSiteType: webSiteType
      }).then((res) => {
        Message.success("Company has been added sucessfully !");
        setForm({
          comapanyName: "",
          subDomain: "",
          customerDomain: "",
          opening_hours: "",
          city: "",
          phone: "",
          fax: "",
          email: "",
          address: "",
          address2: "",
          state: "     ",
          locationID: "",
        });
        setLogoImage(null);
        setFaviconLogo(null);

        fetchData();
        setIsSavingProduct(false);
        setPhoneFaxValidation(false);
        setEmailValidation(false);
        setViewRightPanel(false);
        setLogoInfomationValues();
        setImageInfomationValues();
      });

      setbasicInfoValidationErrors({
        companyName: false,
        address: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        phone: false,
        fax: false,
        email: false,
        opening_hours: false,
      });
    }
  }

  //FAVICON IMAGE UPLOAD STARTS...

  //FAVICON IMAGE UPLOAD ENDS

  // LOGO ICON UPLOAD

  const Logoupload = async (uploadingImage) => {
    const token = await generateImageUploadServerToken();

    const fmData = new FormData();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    const timeStamp =
      Math.floor(Math.random() * 10000 + 1) +
      Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";

    const fileName = `P_${timeStamp}`;
    const filePath = `/${environmentFolderName}/retailpacx/logo-images/`;

    fmData.append("file", uploadingImage);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const res = await axios.post(
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
        fmData,
        config
      );

      // console.log("server res: ", res.data.Result);
      return res.data.Result;
    } catch (err) {
      console.log("Eroor: ", err);
      const error = new Error("Some error");
    }
  };
  // LOGO ICON UPLOAD

  useEffect(async () => {
    await fetchData();
  }, [selectedLocation?.id]);

  const fetchData = async () => {
    setIsLoading(true);

    const companyData = await getCompanyByOrgID({
      ID: "",
      OrganisationID: selectedLocation?.id,
      SubDomain: "",
      CustomerDomain: "",
      Start: 0,

      IsSearchApplicable: false,
      Search: {
        Key: "",
        Value: "",
      },
    });

    if (companyData && companyData.length !== 0) {
      setData(await getTableDataFromResult(companyData));
    } else {
      setData(null);
    }

    setIsLoading(false);
  };

  const getTableDataFromResult = async (data) => {
    data = await sortResultFromCreatedDate(data);

    return data;
  };

  //SORT DATA FROM CREATED DATE
  const sortResultFromCreatedDate = (result) => {
    console.log("result", result);
    return result?.sort((result1, result2) => {
      return new Date(result2.CreatedAt) - new Date(result1.CreatedAt);
    });
  };

  const toggleAddMenu = (showAddMenu) => {
    setShowAddMenu(showAddMenu);
  };

  // RIGHT PANAL
  const showRightPanel = () => {
    console.log(form);

    setShowProductActionMenu(false);
    setForm({
      companyName: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      phone: "",
      fax: "",
      email: "",
      opening_hours: "",
      subDomain: "",
      customerDomain: "",
      logo: "",
      favicon: "",
      locationID: "",
    });
    setSelectedProduct();
    setViewRightPanel(true);
    setFaviconValidation(false);
    setPhoneFaxValidation(false);
    setEmailValidation(false);
    setLogoValidation(false);
    setbasicInfoValidationErrors({});
    setErrors({});
    setMissingValidationFields([]);
    setLogoInfomationValues();
    setImageInfomationValues();
  };
  const closeRightPanel = () => {
    setViewRightPanel(false);
  };

  const handelLogoImage = (data) => {
    setLogoInfomationValues(data);
  };

  //get data by ID
  const getCompanyDetailByID = async (record) => {
    try {
      const reqBody = {
        ID: record.ID,
      };
      const res = await getCompanyByID(reqBody);
      console.log(res);
      setSelectedProduct(res[0]);
      let JSONOpeningHours = "";
      
        JSONOpeningHours = JSON.parse(res[0].OpeningHours);
    

      setTestForm({
        companyName: res[0].Name,
        address: res[0].AddressLine1,
        address2: res[0].AddressLine2,
        city: res[0].City,
        state: res[0].StateProvinceRegion,
        zip: res[0].PostZipCode,
        country: res[0].Country,
        phone: res[0].Phone,
        fax: res[0].Fax,
        email: res[0].Email,
        opening_hours: JSONOpeningHours,
        subDomain: res[0].SubDomain,
        customerDomain: res[0].CustomerDomain,
        logo: res[0].Logo,
        favicon: res[0].Favicon,
        locationID: res[0].LocationID,
      });
      setForm({
        companyName: res[0].Name,
        address: res[0].AddressLine1,
        address2: res[0].AddressLine2,
        city: res[0].City,
        state: res[0].StateProvinceRegion,
        zip: res[0].PostZipCode,
        country: res[0].Country,
        phone: res[0].Phone,
        fax: res[0].Fax,
        email: res[0].Email,
        opening_hours: JSONOpeningHours,
        subDomain: res[0].SubDomain,
        customerDomain: res[0].CustomerDomain,
        logo: res[0].Logo,
        favicon: res[0].Favicon,
        locationID: res[0].LocationID,
      });
      setLogoInfomationValues(res[0].Logo);
      setImageInfomationValues(res[0].Favicon);

    } catch (error) {
      console.log("error fetching ",error)
    }

  };

  const handleShowCompanyActions = async (event, record, row) => {
    await getCompanyDetailByID(record);
    setActionMenuPopoverTriggerEvent(event);

    setFaviconValidation(false);
    setLogoValidation(false);
    setbasicInfoValidationErrors({});
    setErrors({});
    setPhoneFaxValidation(false);
    setEmailValidation(false);
    setMissingValidationFields([]);
    setShowProductActionMenu(true);
  };

  const hideAddOrEditProductPanel = () => {
    // setSelectedProductId(null);
    setShowAddOrEditPanel(false);
    setEnableProductAddMode(false);
    setEnableProductEditMode(false);
  };

  const [faviconUpdated, setFaviconUpdated] = useState(null);
  const [logoUpdated, setLogoUpdated] = useState(null);

  // ISSUE HERE WHEN UPDATED CLEARS THE OTHERS BUT WHEN UPDATED ALL TOGETHER IT WORKS
  async function handleEditSite(e) {
    e.preventDefault();
    let Logo;
    let FaviconLogo;

    if (logoUpdated === true) {
      Logo = await Logoupload(logoInformationValues);
    } else {
      Logo = selectedProduct?.Logo;
    }
    if (faviconUpdated === true) {
      FaviconLogo = imageInfomationValues;
    } else {
      FaviconLogo = selectedProduct?.Favicon;
    }
    setIsSavingProduct(true);

    const newErrors = findFormErrors();

    console.log(logoInformationValues,imageInfomationValues)
    //Conditional Logic :
    if (
      newErrors.length > 0 ||
      !imageInfomationValues ||
      !logoInformationValues
    ) {
      //We got errors !
      setErrors(newErrors);
      if (!imageInfomationValues) {
        setFaviconValidation(true);
      } else {
        setFaviconValidation(false);
      }
      if (!logoInformationValues) {
        setLogoValidation(true);
      } else {
        setLogoValidation(false);
      }
      setIsSavingProduct(false);
    } else {
      const createCompanyData = await editCompanyById({
        ID: selectedProduct.ID,
        Name: form.companyName,
        OrganisationID: selectedLocation?.id
          ? selectedLocation?.id
          : orgID[0].id,
        Logo: Logo,
        LogoCDN: Logo,
        Favicon: FaviconLogo,
        AddressLine1: form.address,
        AddressLine2: form.address2,
        City: form.city,
        StateProvinceRegion: form.state,
        PostZipCode: form.zip,
        Country: form.country,
        Phone: form.phone,
        Fax: form.fax,
        Email: form.email,
        OpeningHours: JSON.stringify(form.opening_hours),
        SubDomain: form.subDomain,
        CustomerDomain: form.customerDomain,
        LocationID: form.locationID,
        WorldPayServiceKey: "T_S_343d6a8d-d5ff-4562-ae79-5905d7b6d5d9",
        WorldPayClientKey: "T_C_26967af9-af83-4021-b7f0-8240f0dedf27",
        WishListEnabled: false,
        HelpPage: "ddefeededefefe",
        SocialMedia: "",
        DeliveryMessage: "",
        ShopNowItems: "Pizza, Burger",
        Theme: "Default",
        FBPageID: "",
        FBAppID: "",
        CDNPath: form.companyName
          .replace(/ /g, "")
          .replace("&", "")
          .replace(/(^\&)|,/g, "")
          .toLowerCase(),
      }).then((res) => {
        setPhoneFaxValidation(false);
        setEmailValidation(false);

        Message.success("Company has been edited sucessfully !");
        setIsSavingProduct(false);
        setShowAddOrEditPanel(false);

        fetchData();
      });
    }
    // console.log("FORM ===> ", imageInfomationValues.file.selectedFile);
  }

  const deleteCompanyHandle = async () => {

    const apiReqBody = { ID: selectedProduct.ID };

    const res = await deleteCompany(apiReqBody)
      .then((res) => {
        setShowDeleteConfirmPopup(false)
        Message.success("Company deleted successfully.")
        fetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // DELETE FUNCTION
  const handleDeleteCompany = () => {
    setShowDeleteConfirmPopup(true);
  };

  const addProductPanelHeader = (
    <RightPanelHeader
      title={enableProductEditMode && "Update Site Details"}
      rightContent={
        enableProductEditMode && (
          <Button
            disabled={isSavingProduct}
            onClick={handleEditSite}
            variant="primary"
          >
            Update
          </Button>
        )
      }
    />
  );

  //Search Function
  const handleSearch = async (tabledata) => {
    console.log("22", tabledata);
    if (tabledata.length > 0) {
      setIsLoading(true);
      const results = data.filter((post) => {
        if (tabledata === "") return data;
        return post.Name.toLowerCase().includes(tabledata.toLowerCase());
      });

      const tableDataFromResult = await getTableDataFromResult(results);
      setData(tableDataFromResult);
      setIsLoading(false);
    } else {
      fetchData();
    }
  };

  const leftOptions = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Site Name",
      value: "siteName",
    },
    {
      label: "Customer Domain",
      value: "customerDomain",
    },
    {
      label: "Sub Domain",
      value: "sub Domain",
    },
  ];

  const [selectedLeftValue, setSelectedLeftValue] = useState({
    label: "All",
    value: "all",
  });

  return (
    <div className="product-page-content" style={{ padding: "20px" }}>
      <div className="product-page-header">
        <Stack horizontalAlign="space_between">
          <StackItem>
            <Stack>
              <Typography
                pattern="bold"
                type="title"
                level={4}
                className="eco-mr-16"
              >
                Site Management
              </Typography>
              <div
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                // style={{ border: "1px solid #cecece", borderRadius: "4px" }}
              >
                {/* <Search icon="right" /> */}
                <InputGroup
                  leftComponent="select"
                  leftOptions={leftOptions}
                  leftValue={selectedLeftValue}
                  leftWidth="50%"
                  onChangeLeftValue={setSelectedLeftValue}
                  rightWidth="50%"
                  rightComponent="search"
                />
              </div>
              <div
                style={{ borderLeft: "1px solid #B3B3B3", marginLeft: "16px" }}
              >
                <Button category="text" variant="subtle" children="Clear All" />
              </div>
            </Stack>
          </StackItem>
          <StackItem>
            <div className="eco-mr-12" style={{ margin: 10 }}>
              <Button
                className="add-new-site-btn"
                category="text-icon"
                variant="primary"
                icon="plus"
                iconSize={16}
                text="Add New Site"
                onClick={
                  //   setAddMenuPopoverTriggerEvent(e);
                  showRightPanel
                }
              />
              {/* <Button
                category='text-icon '
                variant='primary'
                color="white"
                onClick={
                  //   setAddMenuPopoverTriggerEvent(e);
                  showRightPanel
                }
                icon="plus"
                text="Add New Site"
              > */}
              {/* <PlusCircleOutlined className='plus_icon_dash' />
                Add New Site */}
              {/* </Button> */}
            </div>
          </StackItem>
        </Stack>
        <Stack
          horizontalAlign="space_between"
          style={{ alignItems: "flex-end" }}
        ></Stack>
        <Popover
          position="bottom-left"
          popoverTriggerEvent={actionMenuPopoverTriggerEvent}
          active={showProductActionMenu}
          showHidePopover={toggleActiomMenu}
          type="basic"
          customStyles={{ width: "128px" }}
          trigger="click"
        >
          <ActionList
            width={160}
            items={[
              {
                key: 1,
                prefix: <Icon name="pencil" size={16} />,
                content: "Edit",
                onClick: () => handleShowEditView(),
              },
              {
                key: 2,
                prefix: <Icon name="trashcan" size={16} />,
                content: "Delete",
                onClick: () => handleDeleteCompany(),
              },
            ]}
            type="action"
          />
        </Popover>
        {/* EDIT PANEL */}
        {showAddOrEditPanel && (
          <RightPanel
            active={showAddOrEditPanel}
            size="small"
            outsideClose={true}
            handleOnClose={hideAddOrEditProductPanel}
            headerContent={addProductPanelHeader}
          >
            {missingvalidationFields.length > 0 && (
              <div className="eco-mb-20">
                <Alert
                  message="Required Fields Missing!"
                  showIcon
                  description={`${missingvalidationFields.join(
                    ", "
                  )} fields are missing.`}
                  type="error"
                  // closable
                />
              </div>
            )}

            {/* ERROR VALIDATION */}

            {emailValidation && (
              <div className="eco-mb-20">
                <Alert showIcon type="error" message="Email is not valid" />
              </div>
            )}

            {phonefaxValidation && (
              <div className="eco-mb-20">
                <Alert
                  message="Phone and Fax number cannot be same !"
                  showIcon
                  // description='Favicon Image is missing '
                  type="error"
                />
              </div>
            )}

            {faviconValidation && (
              <div className="eco-mb-20">
                <Alert
                  message="Favicon Image is missing !"
                  showIcon
                  // description='Favicon Image is missing '
                  type="error"
                  // closable
                />
              </div>
            )}

            {logoValidation && (
              <div className="eco-mb-20">
                <Alert
                  message="Logo Image is missing !"
                  showIcon
                  // description='Logo Image is missing '
                  type="error"
                  // closable
                />
              </div>
            )}

            <Space direction="vertical" style={{ width: "100%" }}>
              {/* Basic Information  */}
              <Accordion
                theme="default"
                type="content_only"
                title="Basic Information"
                collapsed={false}
              >
                <div style={{ width: "100%" }}>
                  <BasicInformation
                    setField={setField}
                    errors={errors}
                    selectedProduct={selectedProduct}
                    form={form}
                    selectedLocation={selectedLocation}
                  />
                </div>
              </Accordion>

              {/* Logo Details */}
              <Accordion
                theme="default"
                type="content_only"
                title="Logo Information"
                collapsed={true}
              >
                <div style={{ width: "100%" }}>
                  <LogoImageUpload
                    params={{
                      ratiow: 25,
                      ratioh: 25,
                    }}
                    selectedProduct={selectedProduct}
                    handelLogoImage={handelLogoImage}
                    setLogoUpdated={setLogoUpdated}
                  />
                </div>
              </Accordion>

              <Accordion
                theme="default"
                type="content_only"
                title="Favicon Information"
                collapsed={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <ImageUpload
                      selectedProduct={selectedProduct}
                      onSetFavicon={(img) => onSetFavicon(img)}
                    />
                  </div>
                </div>
              </Accordion>

              {/* Domain Details */}
              <Accordion
                theme="default"
                type="content_only"
                title="Domain Information"
                collapsed={true}
              >
                <div style={{ width: "100%" }}>
                  <DomainInformation
                    setField={setField}
                    errors={errors}
                    selectedProduct={selectedProduct}
                  />
                </div>
              </Accordion>
            </Space>
          </RightPanel>
        )}

        {/* EDIT PANEL ENDS */}
        {viewRightPanel && (
          <RightPanel
            active={viewRightPanel}
            size="small"
            outsideClose={true}
            headerContent={
              <RightPanelHeader
                title="Add New Site"
                rightContent={
                  isSavingProduct === true ? (
                    <div className="eco-mt-16 eco-mr-16">
                      <Spin />
                    </div>
                  ) : (
                    <Button
                      variant="primary"
                      //
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  )
                }
              />
            }
            handleOnClose={closeRightPanel}
          >
            {missingvalidationFields.length > 0 && (
              <div className="eco-mb-20">
                <Alert
                  message="Required Fields Missing!"
                  showIcon
                  description={`${missingvalidationFields.join(
                    ", "
                  )} fields are missing.`}
                  type="error"
                  // closable
                />
              </div>
            )}

            {faviconValidation && (
              <div className="eco-mb-20">
                <Alert
                  message="Favicon Image is missing !"
                  showIcon
                  // description='Favicon Image is missing '
                  type="error"
                  // closable
                />
              </div>
            )}

            {emailValidation && (
              <div className="eco-mb-20">
                <Alert showIcon type="error" message="Email is not valid" />
              </div>
            )}

            {phonefaxValidation && (
              <div className="eco-mb-20">
                <Alert
                  message="Phone and Fax number cannot be same !"
                  showIcon
                  // description='Favicon Image is missing '
                  type="error"
                />
              </div>
            )}

            {logoValidation && (
              <div className="eco-mb-20">
                <Alert
                  message="Logo Image is missing !"
                  showIcon
                  // description='Logo Image is missing '
                  type="error"
                  // closable
                />
              </div>
            )}

            <Space
              direction="vertical"
              style={{ width: "100%", marginTop: "10px" }}
            >
              {/* Basic Information  */}
              <Accordion
                theme="default"
                type="content_only"
                title="Basic Information"
                collapsed={false}
              >
                <div style={{ width: "100%" }}>
                  <BasicInformation
                    setField={setField}
                    errors={errors}
                    form={form}
                    selectedLocation={selectedLocation}
                    phonefaxValidation={phonefaxValidation}
                    emailValidation={emailValidation}
                  />
                </div>
              </Accordion>

              <Accordion
                theme="default"
                type="content_only"
                title="Site Settings"
                collapsed={false}
              >
                <div style={{ width: "100%" }}>
                  <SiteSettings webSiteType={webSiteType} setWebSiteType={(val) => setWebSiteType(val)}/>
                </div>
              </Accordion>

              {/* Logo Details */}
              <Accordion
                theme="default"
                type="content_only"
                title="Logo Information"
                collapsed={true}
              >
                <div style={{ width: "100%" }}>
                  <LogoImageUpload
                    params={{
                      ratiow: 25,
                      ratioh: 25,
                    }}
                    selectedProduct={selectedProduct}
                    handelLogoImage={handelLogoImage}
                    setLogoUpdated={setLogoUpdated}
                  />
                </div>
              </Accordion>

              <Accordion
                theme="default"
                type="content_only"
                title="Favicon Information"
                collapsed={true}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <ImageUpload
                      selectedProduct={selectedProduct}
                      onSetFavicon={(img) => onSetFavicon(img)}
                    />
                  </div>
                </div>
              </Accordion>

              {/* Domain Details */}
              <Accordion
                theme="default"
                type="content_only"
                title="Domain Information"
                collapsed={true}
              >
                <div style={{ width: "100%" }}>
                  <DomainInformation setField={setField} errors={errors} />
                </div>
              </Accordion>
            </Space>
          </RightPanel>
        )}
      </div>
      {/* {data && isLoading ? (
        <Spin />
      ) : ( */}
      <Table
        columns={columnData}
        loading={isLoading}
        dataSource={data}
        className="site-listing"
      />
      {/* )} */}
      <Popup 
        modalTitle=""
        modalVisible={showDeleteConfirmPopup}
        onCancel={()=> setShowDeleteConfirmPopup(false)}
        centered
      >
        <div>
          <Stack verticalAlign="v_top" style={{ marginBottom: "62px" }} >
            <Icon name="cancel" type="color" className="eco-mr-16" size={32}/>
            <Stack direction="column" verticalAlign="v_top">
              <Typography type="title" level={5} pattern="bold" className="eco-mb-16">You are about to delete this company!</Typography>
              <Typography type="text" >Do you really want to delete this company? This process cannot be undone.</Typography>
            </Stack>          
          </Stack>
          <Stack horizontalAlign="h_end">
            <Space size="middle">
              <Button variant="primary" onClick={()=> deleteCompanyHandle()}>Confirm</Button>
              <Button variant="outline" onClick={()=> setShowDeleteConfirmPopup(false)}>Cancel</Button>
            </Space>
          </Stack>
        </div> 
      </Popup>
    </div>
  );
}

export default DashboardTable;
