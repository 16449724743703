import React, { useState, useRef } from "react";
import { Alert, Button, Card, Icon, Input, ProgressBar, Space, Spin, Stack, StackItem, Typography } from "witmeg-ui-system";
import { Modal } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import 'react-quill/dist/quill.snow.css';
import "./ImageUploader.css";
import { generateImageUploadServerToken } from "../utilities/tokenGenerators";
import globalValues from "../../configs/globalValues";
import Axios from "axios";
import { useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";
// import ContentGrid from "./ContentGrid";
import WidgetItem from "./WidgetItem";
import DndContainer from "./DndContainer";
import { Layout } from "antd/lib";

function GlobalImageWithContent(props) {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState();
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [testCrop, setTestCrop] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blobFile, setBlobFile] = useState(null);
  const [getImage, setGetImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [imageSizeValidation, setImageSizeValidation] = useState(false);
  const [imageTypeValidation, setImageTypeValidation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [uploadToCloud, setUploadToCloud] = useState(false);
  const [textFields, setTextFields] = useState([])
  const [buttonFields, setButtonFields] = useState([])
  const [quillValue, setQuillValue] = useState('')
  const [layoutData, setLayoutData] = useState(null);
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [fullWidth, setFullWidth] = useState(false)
  const [componentsList, setComponentsList] = useState([])

  const dispatch = useDispatch()

  const widgetList = [{
    id: "wdj_1",
    type: "sidebarItem",
    component: {
      type: "text",
      content: "Some text"
    }
  },
  {
    id: "wdj_2",
    type: "sidebarItem",
    component: {
      type: "button",
      content: "Some Button"
    }
  },
  {
    id: "wdj_3",
    type: "sidebarItem",
    component: {
      type: "image",
      content: "Some image"
    }
  }]

  //File Validation On TYPE
  const isValidFileUploaded = (file) => {
    const validExtensions = ["png", "jpeg", "jpg"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const selectImage = (e) => {
    setImageSizeValidation(false);

    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      image.onload = function () {
        console.log({ width: image.width, height: image.height });
      };
      const MAX_FILE_SIZE = 25000; //25MB
      const fileSizeValidation = image.size / 1024;

      if (fileSizeValidation > MAX_FILE_SIZE) {
        setImageSizeValidation("Image size is greater than 25 MB");
        setIsSuccess(true);
      } else {
        if (isValidFileUploaded(image)) {
          console.log("WIDTH HEIGHT ===>>", image)
          // setImage(image)
          setImageSizeValidation("");
          setIsSuccess("");
          setOutput(null);

          const reader = new FileReader();
          reader.addEventListener("load", () => {
            console.log("WIDTH HEIGHT ===>>", reader)
            setSrc(reader.result)
          });
          reader.readAsDataURL(e.target.files[0]);

        } else {
          setIsSuccess(true);
          setImageTypeValidation("Image should be JPG, PNG OR JPEG");
        }
      }
    }
    // setSrc(URL.createObjectURL(file));
    setIsModalVisible(true);
    props.setImageError(false);
  };



  // function handleClear() {
  //   setSrc(null);
  //   setOutput(null);
  // }

  async function handleUpload() {
    const _image = await handleImageUpload(blobFile);
    props.handelLogoImage(_image);
    setGetImage(true);
  }

  //Upload Image to Cloud
  const handleImageUpload = async (uploadingImage) => {
    setIsLoading(true);
    setUploadToCloud(true)
    const token = await generateImageUploadServerToken();

    const fmData = new FormData();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    const timeStamp =
      Math.floor(Math.random() * 10000 + 1) +
      Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";

    const fileName = `P_${timeStamp}`;
    const filePath = `/${environmentFolderName}/retailpacx/widgets/image-widget/`;

    fmData.append("file", uploadingImage);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const res = await Axios.post(
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
        fmData,
        config
      );

      setIsLoading(false);
      setUploadDone(true)
      setUploadToCloud(false)
      return res.data.Result;
    } catch (err) {
      const error = new Error("Some error");
    }
  };

  function onCancel() {
    props.setImageUpload(false);
    props.setImageError(false);
    setSrc(null);
    setOutput();
    setCrop();
    dispatch(setCommonState({ draggableState: false }))
    setImageTypeValidation(false);
    setImageSizeValidation(false)
    // setCrop({ unit: "%", width: 50, aspect: 16 / 9 });
  }

  const onDoNotCropClick = async () => {
    const _blob = await (await fetch(src)).blob();
    const _image = await handleImageUpload(_blob);

    setGetImage(true);

    props.onClickConfirm(_image);
  }

  const inputFile = useRef(null);

  const onClick = () => {
    inputFile.current.click();
  };

  const addTextField = () => {
    setTextFields([...textFields, ...[{ id: textFields.length, text: `Text ${textFields.length}`, styles: { left: 10, top: 10 } }]])
  }

  const addButtonField = () => {
    setButtonFields([...buttonFields, ...[{ id: buttonFields.length, text: `Text ${buttonFields.length}`, styles: {} }]])
  }

  const setQuillValues = (index, event) => {
    console.log("event===>>", event)
    setTextFields(textFields.map((t, i) => {
      return {
        id: t.id,
        text: index === i ? event : t.text,
        styles: t.styles
      }
    }))
  }


  const onDragStartWidget = (e, widget) => {
    console.log("E===>>", e)
    console.log("widget===>>", widget)
  }

  const updateLayout = async (data, imgFile, useFullWidth, containerFullWidth, components ) => {
    // debugger
    setLayoutData(data)
    if(imgFile) {
      setImage(imgFile)
    } {
      setImage(null)
    }
    setIsFullWidth(useFullWidth)
    setFullWidth(containerFullWidth)
    setComponentsList(components)
  }

  const handleChangeImage = async(data, imgFile, useFullWidth, containerFullWidth, components) => {
    if(imgFile) {

      let imgRes = await handleImageUpload(imgFile)
      let GridData= {
        imageData: imgRes,
        Layout: data,
        isFullWidth: useFullWidth,
        components: components
      }
      // console.log("layoutData ===>>", GridData)
      // props.onClickConfirm(null, true)
      props.handleSavaData(GridData)
    } else {
      let GridData= {
        imageData:'',
        Layout: data,
        isFullWidth: useFullWidth,
        components: components
      }
      // console.log("layoutData ===>>", GridData)
      // props.onClickConfirm(null, true)
      props.handleSavaData(GridData)
  }
  setFullWidth(containerFullWidth)
}

  const handleConfirmLayout = async () => {
    if(image) {

      let imgRes = await handleImageUpload(image)
      let GridData= {
        imageData: imgRes,
        Layout: layoutData,
        isFullWidth: isFullWidth,
        components: componentsList
      }
      // console.log("layoutData ===>>", GridData)
      // props.onClickConfirm(null, true)
      props.handleSavaData(GridData)
    } else {
      let GridData= {
        imageData: props.gridData.imageData,
        Layout: layoutData,
        isFullWidth: isFullWidth,
        components: componentsList
      }
      // console.log("layoutData ===>>", GridData)
      // props.onClickConfirm(null, true)
      props.handleSavaData(GridData)
    }
  }

  return (
    <div>
      <Modal
        maskClosable={false}
        title='Background Image With Content'
        onCancel={onCancel}
        style={{ minWidth: '80%' }}
        visible={props.ImageUpload}
        footer={
          <Space>
            {isLoading && <Spin />}
            {/* {
              src && (
                <Button onClick={onDoNotCropClick} variant="primary" size="small" disabled={uploadToCloud} >
                  Do not Crop & Confirm
                </Button>
              )
            } */}
            <Button 
              // onClick={() => props.onClickConfirm(null, true)} 
              onClick={handleConfirmLayout}
              variant="primary" 
              size="small" 
              disabled={uploadToCloud} 
            >
              Confirm
            </Button>
            <Button onClick={onCancel} variant="secondary" className="eco-mr-8" size="small" >
              Cancel
            </Button>
          </Space>
        }
        confirmLoading={props.confirmLoading}
      >

        <>
          {/* IMAGE VALDATIONS */}
          {props.imageError && (
            <Alert message='Image is not uploaded' type='error' showIcon />
          )}
          {isSuccess && (
            <>
              {imageSizeValidation && (
                <Alert message={imageSizeValidation} type='error' showIcon />
              )}
              {imageTypeValidation && (
                <Alert message={imageTypeValidation} type='error' showIcon />
              )}
            </>
          )}
          {/* {!src ?
            <div className='eco-mt-12'>
              <Stack
                className='image-upload-section imagewithcontent-upload-section'
                direction='column'
                onClick={onClick}
                horizontalAlign='h_center'
                verticalAlign='v_center'
              >
                <input
                  type='file'
                  accept='image/*'
                  onChange={(event) => {
                    selectImage(event);
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  ref={inputFile}
                  style={{ display: "none" }}
                />
                <Icon name='upload-outline' type='filled' />
                <Typography type='text'>Click here to upload a file</Typography>
                <Typography type="text" size="small" color="gray_base">(Max. file size 25MB)</Typography>
              </Stack>
              <input
                type='file'
                accept='image/*'
                onChange={selectImage}
                style={{ display: "none" }}
              />
            </div>
            : null} */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              width: "100%"
            }}
          >

            <div style={{ width: '100%' }}>
                <br />
                <div>
                  <DndProvider backend={HTML5Backend}>
                    <DndContainer updateLayout={updateLayout} handleChangeImage={handleChangeImage} gridData={props.gridData} currentImage={image}/>
                  </DndProvider>
                </div>
                <br />
              </div>
            
          </div>
        </>
      </Modal>
    </div>
  );
}

export default GlobalImageWithContent;
