import React, { useState, useEffect } from 'react'
import { Row, Col, Label, InputBox, Select, ColorPicker, Space, Button, Slider, Alert } from 'witmeg-ui-system'
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import Axios from 'axios';
import FontPicker from "font-picker-react";
function ButtonSettings({ missingvalidationFields, setButtonField, buttonFormData, errors }) {

    const [borderColor, setBorderColor] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [hoverBackgroundColor, setHoverBackgroundColor] = useState("");
    const [buttonFontColor, setButtonFontColor] = useState("");

    const [btnItalic, setBtnItalic] = useState(false)
    const [btnBold, setBtnBold] = useState(false)
    const [btnUnderline, setBtnUnderline] = useState(false)
    const [curvedEdgesInput, setCurvedEdgesInput] = useState("16")
    const [fontSize, setFontSize] = useState(null);
    const [fontList, setFontList] = useState(buttonFormData?.btnFontFamily ? buttonFormData?.btnFontFamily : "Open Sans")
    const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";

    useEffect(() => {
        if (buttonFormData) {
            setBtnBold(buttonFormData?.bold)
            setBtnUnderline(buttonFormData?.underline)
            setBtnItalic(buttonFormData?.italic)
            setFontSize(buttonFormData?.btnFontSize)
            setCurvedEdgesInput(buttonFormData?.curvedEdges)
        }
        // setFontList(buttonFormData?.btnFontFamily)
    }, [buttonFormData])

    // useEffect(() => {
    //      
    //     Axios.get("https://www.googleapis.com/webfonts/v1/webfonts?key=").then((res) => {

    //         let options = [];
    //         res.data.items.map((data, index) => {

    //         })
    //         setFontList(res.data.items)
    //     })
    // }, [])

    console.log(fontList)

    //FONT COLOR VALIDATION
    const handleFontColorChange = (_value) => {
        if(_value && _value !== "") {
            setButtonField("btnFontColor", _value);
        } else {
            setButtonField("btnFontColor", "#000");
            setButtonFontColor("#000");
        }
    }

    //BORDER COLOR VALIDATION 
    const handleBorderColorChange = (_value) => {
        if(_value && _value !== "") {
            setButtonField("borderColor", _value);
        } else {
            setButtonField("borderColor", "#000");
            setBorderColor("#000");
        }
    }

    //BACKGROUND COLOR VALIDATION
    const handleBackgroundColorChange = (_value) => {
        if(_value && _value !== "") {
            setButtonField("backgroundColor", _value);
        } else {
            setButtonField("backgroundColor", "#000");
            setBackgroundColor("#000");
        }
    }

    //HOVER COLOR VALIDATION
    const handleHoverColorChange = (_value) => {
        if(_value && _value !== "") {
            setButtonField("hoverBackgroundColor", _value);
        } else {
            setButtonField("hoverBackgroundColor", "#000");
            setHoverBackgroundColor("#000");
        }
    }

    return (
        <div style={{ width: '100%' }}>

            {missingvalidationFields.length > 0 && (
                <div className="eco-mb-20">
                    <Alert
                        message="Required Fields Missing!"
                        showIcon
                        description={`${missingvalidationFields.join(
                            ", "
                        )} is missing.`}
                        type="error"
                    // closable
                    />
                </div>
            )}
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label required>Button Name</Label></div>
                </Col>
                <Col span={16}>
                    <InputBox allowPaste={false} type="text" error={errors.buttonName} value={buttonFormData?.buttonName} onChange={(e) => setButtonField("buttonName", e.target.value)} noBorder backgroundOnly />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Family</Label></div>
                </Col>
                <Col style={{ zIndex: "999999" }} span={16}>
                    <FontPicker
                        apiKey={API_KEY}
                        activeFontFamily={fontList}
                        onChange={(nextFont) => {

                            setButtonField("btnFontFamily", nextFont.family);
                            setFontList(nextFont.family)
                        }
                        }
                        limit={100}
                        // sort="popularity"
                        pickerId="button"
                    />


                    {/* <Select noBorder onChange={(e) => setButtonField("btnFontFamily", e)} /> */}
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Size</Label></div>
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue="16"
                        value={fontSize}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => { setButtonField("btnFontSize", e); setFontSize(e) }}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox
                        disabled
                        style={{ backgroundColor: "white !important" }}
                        type="text"
                        noBorder
                        allowPaste={false}
                        backgroundOnly
                        value={buttonFormData?.btnFontColor ? buttonFormData?.btnFontColor : buttonFontColor}
                        setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker defaultColor={buttonFontColor} setDefaultColor={setButtonFontColor} onChangeComplete={(color) => handleFontColorChange(color.hex)} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Style</Label></div>
                </Col>
                <Col span={16}>
                    <Space size="middle">
                        <Button category="icon" onClick={() => { btnBold ? setButtonField("bold", false) : setButtonField("bold", true); btnBold ? setBtnBold(false) : setBtnBold(true) }} variant={btnBold ? "primary" : "secondary"} children="bold" color={btnBold ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button category="icon" onClick={() => { btnItalic ? setButtonField("italic", false) : setButtonField("italic", true); btnItalic ? setBtnItalic(false) : setBtnItalic(true) }} variant={btnItalic ? "primary" : "secondary"} children="italic" color={btnItalic ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button category="icon" variant={btnUnderline ? 'primary' : "secondary"} onClick={() => { btnUnderline ? setButtonField("underline", false) : setButtonField("underline", true); btnUnderline ? setBtnUnderline(false) : setBtnUnderline(true) }} children="underline" color={btnUnderline ? "white" : "gray"} className="font-style-btn"></Button>
                    </Space>
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle" justify="space-between">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Curved Edges</Label></div>
                </Col>
                <Col span={12}>
                    <Slider
                        onChange={(e) => { setButtonField("curvedEdges", e); setCurvedEdgesInput(e) }}
                        defaultValue={curvedEdgesInput}
                        value={curvedEdgesInput} />
                </Col>
                <Col span={4}>
                    <Combobox
                        value={curvedEdgesInput}
                        defaultValue={typeof curvedEdgesInput === 'number' ? curvedEdgesInput : 0}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => { setButtonField('curvedEdges', e); setCurvedEdgesInput(e) }}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Border Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} value={buttonFormData?.borderColor ? buttonFormData?.borderColor : borderColor} type="text" noBorder backgroundOnly setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => handleBorderColorChange(color.hex)} defaultColor={borderColor} setDefaultColor={setBorderColor} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Background Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} value={buttonFormData?.backgroundColor ? buttonFormData?.backgroundColor : backgroundColor} type="text" noBorder backgroundOnly setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => handleBackgroundColorChange(color.hex)} defaultColor={backgroundColor} setDefaultColor={setBackgroundColor} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Hover Background Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} type="text" noBorder backgroundOnly value={buttonFormData?.hoverBackgroundColor ? buttonFormData?.hoverBackgroundColor : hoverBackgroundColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker defaultColor={hoverBackgroundColor} onChangeComplete={(color) => handleHoverColorChange(color.hex)} setDefaultColor={setHoverBackgroundColor} />
                </Col>
            </Row>
        </div>
    )
}

export default ButtonSettings