import React, { useRef, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { COLUMN } from "./constants";
import DropZone from "./DndDropZone";
import Component from "./DndComponent";

const style = {};
const Column = ({ data, row, components, handleDrop, path, handleItemDataUpdate, handleItemSizeUpdate }) => {
  const [initialHeight, setInitialHeight] = useState(48)
  const [initialWidth, setIinitialWidth] = useState('100%')

  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: {
      id: data.id,
      children: data.children,
      path
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(ref);

  useEffect(() => {
    console.log("ref.current.clientHeight/ref.current.offsetWidth====>>>>", ref.current.clientHeight, ref.current.clientWidth)
    setInitialHeight(ref.current.clientHeight)
    setIinitialWidth(ref.current.clientWidth)
  },[])



  const renderComponent = (column, component, currentPath) => {
    return (
      <Component
        key={component.id}
        row={row}
        column={column}
        data={component}
        components={components}
        path={currentPath}
        handleItemDataUpdate={handleItemDataUpdate}
        handleItemSizeUpdate={handleItemSizeUpdate}
        initialWidth={initialWidth}
        initialHeight={initialHeight}
        // handleSetSize={handleSetSize}
      />
    );
  };

  return (
    <div
      ref={ref}
      style={{ ...style, opacity }}
      className="base draggable dnd-column"
    >
      {/* {data.id} */}
      {data.children?data.children.map((component, index) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
                data:data
              }}
              onDrop={handleDrop}
            />
            {renderComponent(data, component, currentPath)}
          </React.Fragment>
        );
      }) :null}
      <DropZone
        data={{
          path: `${path}-${data?.children?.length||'1'}`,
          childrenCount: data?.children?.length || 1,
          data:data
        }}
        onDrop={handleDrop}
        isLast
      />
    </div>
  );
};
export default Column;
