import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const dragSlice = createSlice({
    name: "DragSlice",
    initialState: {
        widgetList: {
            WIDGETNAME: "",
            WIDGETHEIGHT: "",
            WIDGETWIDTH: ""
        }
    },

    reducers: {
        setWidgetData: (state, action) => {
            const { widgetList } = action.payload || {}
            state.widgetList.WIDGETNAME = widgetList
            state.widgetList.WIDGETHEIGHT = widgetList
            state.widgetList.WIDGETWIDTH = widgetList
        }
    }
})

export const { setWidgetData } = dragSlice.actions;
export default dragSlice.reducer