const loginRoot = "/";
const dashboardRoot = "/welcome";
const homeRoot = "/home";

export const routePaths = {
  loginRoot,
  dashboardRoot,
  homeRoot,

  // *********HOME -> DESIGN
  home__design: `${homeRoot}`,

  // HOME --> PAGES
  home__pages: `${homeRoot}/pages`,

  // HOME --> PAGES/ADD
  home__pages__add: `${homeRoot}/pages/add`,

  // HOME --> ARTICLES
  home__article: `${homeRoot}/articles`,

  // HOME --> WIDGETS
  home__widgets: `${homeRoot}/widgets`,

  // HOME --> E-COMMERCE
  home__e_commerce: `${homeRoot}/e-commerce`,

  // HOME --> SETTINGS
  home__settings: `/settings`,
};
