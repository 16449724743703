import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import * as NumericInput from "react-numeric-input";

import { Card } from "antd";
const { Meta } = Card;

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row>
        <Col sm={12}>&nbsp; </Col>
        {/* <Col sm={12}><h2>{this.props.params.ItemHeading.toUpperCase()}</h2></Col> */}
        <Col sm={6} xs={12} md={2} lg={2}>
          {/* <img
            src='https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg'
            alt='.'
            style={{ width: "95%" }}
          /> */}

          <Card
            hoverable
            style={{
              width: 240,
            }}
            cover={
              <img
                alt='example'
                src='https://sunbasket.lk/image/catalog/Screen%20Shot%202019-08-21%20at%2011.11.19.png'
              />
            }
          >
            <Meta title='Dummy Product' description='$ 11.00' />
          </Card>
        </Col>

        {/* <Col sm={6} xs={12} md={2} lg={2}>
          <img
            src='https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg'
            alt='.'
            style={{ width: "95%" }}
          />
        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
          <img
            src='https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg'
            alt='.'
            style={{ width: "95%" }}
          />
        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
          <img
            src='https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg'
            alt='.'
            style={{ width: "95%" }}
          />
        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
          <img
            src='https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg'
            alt='.'
            style={{ width: "95%" }}
          />
        </Col>
        <Col sm={6} xs={12} md={2} lg={2}>
          <img
            src='https://cdn.neurolage.com/retailpacx/shankarcolimited/retailpacx-1615452851534.jpeg'
            alt='.'
            style={{ width: "95%" }}
          />
        </Col> */}
      </Row>
    );
  }
}

export default App;
