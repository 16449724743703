import apiEndpointPaths from "../apiEndpointPaths";
import { generateRetailPaxToken, generateToken, generateTokenForlocation, generateTokenOrgRegistry } from "../tokenGenerators";
import axios from "axios";

export async function loginUser(reqBody = {}, options = {}) {

  try {
    const { UserName, Password } = reqBody;

    const data = {
      UserName,
      Password,
    };
    const token = await generateToken(true);

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(
      `${apiEndpointPaths.witmegOrgServer.loginUser}`,
      data,
      options
    );

    const { Status, Result } = resObj.data;

    return Result;
  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}

//Resturnat menu login authentication
export async function NeutriPosLogin(reqBody = {}, options = {}) {

  try {
    const token = await generateTokenOrgRegistry(true);

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(
      `${apiEndpointPaths.witmegOrgServer.loginUserMenuWidget}`,
      reqBody,
      options
    );

    const { Status, Result } = resObj.data;

    return Result;
  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}




//Get Logged in user location details
export async function getLoggedUserLocationDetails(reqBody = {}, options = {}) {

  try {
    const { UserID } = reqBody;

    const data = {
      UserID
    };

    const token = await generateToken(true);

    // This is where the token from tokenservice gets to the header in the login api call
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    };

    const resObj = await axios.post(
      `${apiEndpointPaths.witmegOrgServer.getOrganisationAdmin}`,
      data,
      options
    );

    // console.log('resObj', resObj)
    const { Result } = resObj.data;

    return Result;
  } catch (error) {
    const errMsg = error.message;
    let errorMessage = errMsg || "";

    if (error.customErrType === "APIERROR") {
      switch (true) {
        case errMsg.includes("mongo: no documents in result"): {
          errorMessage =
            "No Account exists for this user detials. Please check again";
          break;
        }
        case errMsg.includes("Password does not match"): {
          errorMessage = "Your Password is Incorrect. Please check again.";
          break;
        }

        default: {
          errorMessage = "Error Occurred. Please try again.";
          break;
        }
      }
    }

    return;
  }
}




