import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Alert,
  async,
  Button,
  Card,
  Icon,
  ProgressBar,
  Space,
  Spin,
  Stack,
  StackItem,
  Typography,
  Tabs
} from "witmeg-ui-system";
import { Modal } from "antd";
import { generateImageUploadServerToken } from "../utilities/tokenGenerators";
import GlobalIconUploader from "../GlobalIconUploader/GlobalIconUploader";
import EditIcon from '../GlobalIconUploader/EditIcon'
import globalValues from "../../configs/globalValues";
import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { setCommonState } from "../../redux/commonReducer";
import { useEffect } from "react";

function IconWidgetPopup(props) {
  const [src, setSrc] = useState();
  const [uploadedIcon, setUploadedIcon] =useState("null")
  const [crop, setCrop] = useState();
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [testCrop, setTestCrop] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [blobFile, setBlobFile] = useState(null);
  const [getImage, setGetImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDone, setUploadDone] = useState(false);
  const [imageSizeValidation, setImageSizeValidation] = useState(false);
  const [imageTypeValidation, setImageTypeValidation] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [uploadToCloud, setUploadToCloud] = useState(true)
  const dispatch = useDispatch()

  // console.log(props);//

  //File Validation On TYPE
  const isValidFileUploaded = (file) => {
    const validExtensions = "svg+xml";
    const fileExtension = file.type.split("/")[1];
    console.log('imgggg ===>>> ', validExtensions.includes(fileExtension), fileExtension)
    return validExtensions.includes(fileExtension);
  };

  const selectImage  = (e) => {
    // console.log("sdgsgs", e);

    setImageSizeValidation(false)
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];

      const MAX_FILE_SIZE = 25000; //25MB
      const fileSizeValidation = image.size / 1024;
      if (fileSizeValidation > MAX_FILE_SIZE) {
        setImageSizeValidation("Image size is greater than 25 MB");
        setIsSuccess(true);
      } else {
        if (isValidFileUploaded(image)) {
          setImageSizeValidation("");
          setIsSuccess("");
          setOutput(null);

          const reader = new FileReader();
          reader.addEventListener("load", () => setSrc(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        } else {
          setIsSuccess(true);
          setImageTypeValidation("Image should be an SVG");
        }
      }
    }
    // setSrc(URL.createObjectURL(file));
    setIsModalVisible(true);
    props.setImageError(false);
  };

console.log("SRC",src)

  function handleClear() {
    setSrc(null);
    setOutput(null);
    // console.log("SASD", props.selectedProduct?.Logo);
  }

  async function handleUpload() {

    //https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#:~:text=toBlob()%20method%20creates%20a,image%20quality%20may%20be%20specified.
    handleImageUpload(src)
  }

  //Upload Image to Cloud
  const handleImageUpload = async (uploadingImage) => {
    console.log(uploadingImage)
    setIsLoading(true);
    setUploadToCloud(true)
    const token = await generateImageUploadServerToken();

    const fmData = new FormData();
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    };

    const timeStamp =
      Math.floor(Math.random() * 10000 + 1) +
      Math.round(new Date().getTime() / 1000);
    const environmentFolderName =
      globalValues.environment.CURRENT_ENVIRONMENT === "production"
        ? "production"
        : "dev";

    const fileName = `P_${timeStamp}`;
    const filePath = `/${environmentFolderName}/retailpacx/widgets/icon-widget/`;

    fmData.append("file", uploadingImage);
    fmData.append("fileName", fileName);
    fmData.append("filePath", filePath);

    try {
      const res = await Axios.post(
        `${globalValues.baseURLS.REACT_APP__WITMEG_IMAGEUPLOADSERVER___BASEURL}/v1/image/uploadImage`,
        fmData,
        config
      );

      // console.log("server res: ", res.data.Result);
      setIsLoading(false);
      setUploadDone(true)
      setUploadToCloud(false)
      return res.data.Result;
    } catch (err) {
      // console.log("Eroor: ", err);
      const error = new Error("Some error");
    }
  };

  function onCancel() {
    props.setImageUpload(false);
    props.setImageError(false);
    setSrc(null);
    setOutput();
    setCrop();
    dispatch(setCommonState({
      draggableState: false
    }))
    setImageTypeValidation(false);
    setImageSizeValidation(false)
    // setCrop({ unit: "%", width: 50, aspect: 16 / 9 });
  }

  const inputFile = useRef(null);

  const onClick = () => {
    inputFile.current.click();
  };
  
  console.log('uploadedIcon==>> ', uploadedIcon)

  const[iconWidgetUploadTab,setIconWidgetUploadTab]= useState([
    {
        tabID:'1',
        tabName:'Upload',
        content: <GlobalIconUploader
                    ImageUpload={props.ImageUpload}
                    setImageUpload={props.setImageUpload}
                    handelLogoImage={props.handelLogoImage}
                    onOk={props.onOk}
                    confirmLoading={props.confirmLoading}
                    imageError={props.imageError}
                    setUploadToCloud={setUploadToCloud}
                    uploadToCloud={uploadToCloud}
                    setImageError={props.setImageError}
                    selectImage={selectImage}
                    uploadedIcon={uploadedIcon}
                    setUploadedIcon={setUploadedIcon}
                    />
    },
    {
        tabID:'2',
        tabName:'Design',
        content: <EditIcon
                    uploadToCloud={uploadToCloud}
                    uploadedIcon={uploadedIcon}
                    value={'hello'}
                    />
    }
]);

useEffect(()=>{
  setIconWidgetUploadTab([    {
    tabID:'1',
    tabName:'Upload',
    content: <GlobalIconUploader
                ImageUpload={props.ImageUpload}
                setImageUpload={props.setImageUpload}
                handelLogoImage={props.handelLogoImage}
                onOk={props.onOk}
                confirmLoading={props.confirmLoading}
                imageError={props.imageError}
                setUploadToCloud={setUploadToCloud}
                uploadToCloud={uploadToCloud}
                setImageError={props.setImageError}
                selectImage={selectImage}
                uploadedIcon={uploadedIcon}
                setUploadedIcon={setUploadedIcon}
                />
},
{
    tabID:'2',
    tabName:'Design',
    content: <EditIcon
                uploadToCloud={uploadToCloud}
                uploadedIcon={uploadedIcon}
                value={'hello'}
                iconText={props.iconText}
                toolTip={props.toolTip}
                setIconText={props.setIconText}
                setToolTip={props.setToolTip}
                textFontSize={props.textFontSize}
                iconBackground={props.iconBackground}
                setIconBackground={props.setIconBackground}
                setTextFontSize={props.setTextFontSize}
                />
}])
}, [uploadedIcon])


  return (
    <div>
      <Modal
        width={644}
        maskClosable={false}
        title='Icon'
        onCancel={onCancel}
        visible={props.ImageUpload}
        footer={
          <Space size="middle">
            <Button onClick={props.onOk} variant="primary" className="save-btn" disabled={uploadToCloud}>Save</Button>
            <Button onClick={onCancel} variant="outline" className="cancel-btn">Cancel</Button>
          </Space>
        }
        confirmLoading={props.confirmLoading}
      >
      <Tabs tabContent={iconWidgetUploadTab} size="small"/>
      </Modal>
    </div>
  );
}

export default IconWidgetPopup;
