import React from "react";
import PropTypes from "prop-types";
import defaultCardIcon from "../../../../assets/img/default-card-icon.png";
import { Stack, StackItem } from "witmeg-ui-system";
import { Typography } from "antd";

function LayoutFive(props) {
  const {
    title = "Title",
    description = "Description",
    image = defaultCardIcon,
    styleData = {},
    designData = {},
  } = props;
  console.log(props.designData);

  return (
    <Stack direction="column" verticalAlign="v_start">
      <StackItem direction="column">
        <h3
          style={{
            fontFamily: designData.titleFontFamily,
            color:designData.titleFontColor,
            fontSize: `${designData.titleFontSize}px`,
            fontStyle: designData.titleStyleItalic?"italic":"",
            textDecoration: designData.titleStyleTextUnderline
              ? "underline"
              : "",
            fontWeight: designData.titleStyleBold ? "bold" : "",
            textAlign: designData.titleFontAlign,
          }}
        >
          {title}
        </h3>
        <div>
          <img src={image} className="card-layout__two-img" />
        </div>
      </StackItem>
    </Stack>
  );
}

LayoutFive.propTypes = {};

export default LayoutFive;
