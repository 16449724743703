import { InputNumber } from 'antd'
import React, { useState, useEffect } from 'react'
import Combobox from "react-widgets/Combobox";
import { Input, Stack, StackItem, Typography, ColorPicker, Button, Row, Col, InputBox, Slider, Label, Space, Select, Checkbox, Alert } from 'witmeg-ui-system'

function ButtonSettings({ 
    buttonColor,
    setButtonColor,
    buttonTxtColor,
    setButtonTxtColor,
    onChangeButtonText,
    onChangeFontSize,
    textSize,
    buttonBorder,
    onChangeButtonBorder,
    textBold,
    textItalic,
    textUnderline,
    onChangeBoldText,
    onChangeItalicText,
    onChangeUnderlineText,
    btnValidation,
    buttonText,
    buttonWebURL,
    onChangeButtonWebURL,
    onCheckedButtonWebURLOpenInNewWindow,
}) {
    const [btnColor, setBtnColor] = useState("");
    const [btnbgColor, setBtnBgColor] = useState("")
    const [curvedEdgesInput, setCurvedEdgesInput] = useState("16")

    useEffect(() => {

        setCurvedEdgesInput(buttonBorder)
    }, [buttonBorder])


    const handleFontColorChange = (e) => {

        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setButtonTxtColor(`#${e}`)
        } else {
            setButtonTxtColor("#000")
            setBtnColor("#000")
        }
    }

    const handleBackgroundColorChange = (e) => {

        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setButtonColor(`#${e}`)
        } else {
            setButtonColor("#000")
            setBtnBgColor("#000")
        }
    }

    return (
        <div style={{ border: '1px solid #C6C7D2', borderRadius: '0px 0px 4px 4px', padding: '16px' }}>
            {btnValidation && <Alert showIcon message="Button Name Cannot be Empty" type="error" />}


            <Row className="eco-mb-16" align="middle" gutter={[16, 0]}>
                <Col span={8}>
                    <div className='form-lbl-wrapper'><Label required>Button Name</Label></div>
                </Col>
                <Col span={16}>
                    <InputBox error={btnValidation} placeholder='Enter Button Name' onChange={(e) => onChangeButtonText(e)} type="text" noBorder backgroundOnly value={buttonText} />
                </Col>
            </Row>
            <Row className="eco-mb-16" align="middle" gutter={[16, 0]}>
                <Col span={8}>
                    <div className='form-lbl-wrapper'><Label>Font Size</Label></div>
                </Col>
                <Col span={5}>
                    <Combobox
                        defaultValue={typeof textSize === 'number' ? textSize : 0}
                        onChange={onChangeFontSize}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true} />
                </Col>
            </Row>
            <Row className="eco-mb-16" align="middle" gutter={[16, 0]}>
                <Col span={8}>
                    <div className='form-lbl-wrapper'><Label>Font Color</Label></div>
                </Col>
                <Col span={8}>
                    <InputBox onChange={(e) => setButtonTxtColor(e.target.value)} type="text" noBorder backgroundOnly value={buttonTxtColor} />
                </Col>
                <Col span={8}>
                    <ColorPicker
                        className="eco-ml-16"
                        defaultColor={buttonTxtColor}
                        setDefaultColor={setBtnColor}
                        onChangeComplete={(color) => setButtonTxtColor(color.hex)}
                    />
                </Col>
            </Row>
            <Row className="eco-mb-16" align="middle" gutter={[16, 0]}>
                <Col span={8}>
                    <div className='form-lbl-wrapper'><Label>Font Style</Label></div>
                </Col>
                <Col span={16}>
                    <Space size="middle">
                        <Button className="font-style-btn" category="icon" children="bold" variant={textBold ? "primary" : "secondary"} color={textBold ? "white" : ""} onClick={onChangeBoldText} />
                        <Button className="font-style-btn" category="icon" children="italic" variant={textItalic ? "primary" : "secondary"} color={textItalic ? "white" : ""} onClick={onChangeItalicText} />
                        <Button className="font-style-btn" category="icon" children="underline" variant={textUnderline ? "primary" : "secondary"} color={textUnderline ? "white" : ""} onClick={onChangeUnderlineText} />
                    </Space>
                </Col>
            </Row>
            <Row className="eco-mb-16" align="middle" gutter={[16, 0]}>
                <Col span={8}>
                    <div className='form-lbl-wrapper'><Label>Curved Edges</Label></div>
                </Col>
                <Col span={12}>
                    <Slider onChange={(e) => onChangeButtonBorder(e)} defaultValue={curvedEdgesInput}
                        value={curvedEdgesInput} />
                </Col>
                <Col span={4}>
                    <Combobox
                        value={curvedEdgesInput}
                        defaultValue={curvedEdgesInput}
                        
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => onChangeButtonBorder(e)}
                    />
                </Col>
            </Row>
            <Row className="eco-mb-16" align="middle" gutter={[16, 0]}>
                <Col span={8}>
                    <div className='form-lbl-wrapper'><Label>Background Color</Label></div>
                </Col>
                <Col span={8}>
                    <InputBox type="text" noBorder backgroundOnly value={buttonColor} />
                </Col>
                <Col span={8}>
                    <ColorPicker
                        defaultColor={btnbgColor}
                        setDefaultColor={setBtnBgColor}

                        onChangeComplete={(color) => setButtonColor(color.hex)}
                    />
                </Col>
            </Row>
            <Row align="middle" gutter={[16, 0]}>
                <Col span={8}>
                    <div className='form-lbl-wrapper'><Label>Web Site URL</Label></div>
                </Col>
                <Col span={16}>
                    <InputBox
                        type="text"
                        value={buttonWebURL.siteURL}
                        onChange={(e) => onChangeButtonWebURL(e.target.value)}
                        noBorder backgroundOnly />
                </Col>
            </Row>
            <Row align="middle" gutter={[16, 0]}>
                <Col span={8}>
                </Col>
                <Col span={16}>
                    <Stack horizontalAlign="h_start">
                        <Checkbox
                            className="eco-mr-16"
                            checked={buttonWebURL.openInNewWindow}
                            onChange={(e) => onCheckedButtonWebURLOpenInNewWindow(e.target.checked)} />
                        <Typography type="text">Open in new window</Typography>
                    </Stack>
                </Col>
            </Row>



            {/* <Row gutter={[16, 16]}>

                <Typography>Text Size</Typography>
                <Col span={8}>

                    <Slider
                        min={1}
                        max={20}
                        defaultValue={typeof textSize === 'number' ? textSize : 0}
                        onChange={onChangeFontSize}

                        value={typeof textSize === 'number' ? textSize : 0}
                    />
                </Col>
                <Col span={8}>

                    <Input
                        type="number"
                        value={textSize}
                        onChange={(e) => onChangeFontSize(e.target.value)}
                        maxValue={20}
                        minValue={1}

                    />
                </Col>
            </Row> */}
            {/* <Row>
                <Typography>Button Border</Typography>
                <Col span={6}>
                    <Slider
                        min={1}
                        max={20}
                        onChange={onChangeButtonBorder}
                        defaultValue={typeof buttonBorder === 'number' ? buttonBorder : 0}
                        value={typeof buttonBorder === 'number' ? buttonBorder : 0}
                    />
                </Col>
                <Col span={10}>

                    <Input
                        type="number"
                        value={buttonBorder}
                        onChange={(e) => onChangeButtonBorder(e.target.value)}
                        maxValue={20}
                        minValue={1}
                    />
                </Col>
            </Row> */}

        </div>
    )
}

export default React.memo(ButtonSettings)