import React from "react";
import {
  Button,
  Form,
  Layout,
  Breadcrumb,
  Input,
  Modal,
  Tag,
  Drawer,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";
import UploadItems from "./imageupload-global";
import axios from "axios";
import config from "react-global-configuration";
import { TweenOneGroup } from "rc-tween-one";
import { PlusOutlined } from "@ant-design/icons";
const { Content } = Layout;
const { CheckableTag } = Tag;

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datax: [],
      pages: [],
      pcontent: "",
      selectedTags: [],
      tags: [],
      listData: [],
      inputVisible: false,
      inputValue: "",
    };
  }

  componentDidMount() {
    document.title = "RetailPacx - Site Editor";

    this.setState({
      author: sessionStorage.getItem("mockedUsername"),
      status: true,
      visibility: true,
      publishon: "",
      menus: "",
      companyid: sessionStorage.getItem("company"),
    });

    axios
      .get(
        config.get("backendURL") +
          "category/" +
          sessionStorage.getItem("company")
      )
      .then((response) => {
        //console.log(response)
        this.setState({ datax: response.data });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        config.get("backendURL") +
          "articles/byslug/" +
          this.props.match.params.id +
          "/" +
          sessionStorage.getItem("company")
      )
      .then((response) => {
        //  console.log(response.data)
        this.setState({
          loading: true,
          listData: response.data,
          selectedTags: response.data.categories,
          tags: response.data.tags,
          loading: true,
        });
        sessionStorage.setItem("uploadedimage", response.data.featuredimage);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  showDrawer = (id, itemheading, itemdata) => {
    this.setState({
      visible: true,
    });
  };

  handleEditorChange = (content) => {
    this.setState({
      pcontent: content,
    });
  };
  getData(val, doid) {
    sessionStorage.setItem("uploadedimage", val.data.imageName);
  }
  goBack = () => {
    //   window.location.href="/articleslist"
  };
  onClose = () => {
    this.setState({
      featuredimage: sessionStorage.getItem("uploadedimage"),
      visible: false,
    });
  };
  handleChange = (tag, checked) => {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    console.log("You are interested in: ", nextSelectedTags);
    this.setState({ selectedTags: nextSelectedTags });
  };

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    console.log(tags);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    //  console.log(tags);
    this.setState({
      tags,
      inputVisible: false,
      inputValue: "",
    });
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          this.handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  render() {
    const {
      tags,
      inputVisible,
      inputValue,
      visible,
      faviconvisible,
      listData,
    } = this.state;
    const tagChild = tags.map(this.forMap);
    const pageSubmit = (values) => {
      const articlesData = {
        title: values.title,
        content: this.state.pcontent,
        categories: this.state.selectedTags,
        featuredimage: sessionStorage.getItem("uploadedimage"),
        slug: values.title.replace(/ /g, "-").toLowerCase(),
        tags: this.state.tags,
        author: this.state.author,
        status: this.state.status,
        visibility: this.state.visibility,
        publishon: this.state.publishon,
        companyid: this.state.companyid,
      };
      console.log(articlesData);

      axios
        .post(
          config.get("backendURL") + "articles/update/" + listData._id,
          articlesData
        )
        .then((res) => console.log(res.data));

      Modal.success({
        title: "Success",
        content: "Article has been added",
        onOk() {
          window.location = "/articleslist/";
        },
      });
    };
    const { selectedTags } = this.state;

    return (
      <Content style={{ margin: "0 16px" }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={this.goBack.bind(this)}>
            Article
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit Article</Breadcrumb.Item>
        </Breadcrumb>
        <h1>Edit Article</h1>
        <div
          className='site-layout-background'
          style={{ padding: 24, minHeight: 360 }}
        >
          {this.state.loading ? (
            <Form onFinish={pageSubmit}>
              <Form.Item
                name='title'
                initialValue={listData.title}
                label='Heading'
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              {console.log(listData)}
              <Form.Item name='article' label='Article'>
                <Editor
                  initialValue={listData.content}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | fontsizeselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                  }}
                  inline=''
                  apiKey='ssv2iarxt5sjvufjszf9lrat3wu395eynq4hpttb0gdyzbnh'
                  onEditorChange={this.handleEditorChange}
                />
              </Form.Item>

              <Form.Item name='categories' label='Categories'>
                {this.state.datax.map((tag) => (
                  <CheckableTag
                    key={tag._id}
                    checked={selectedTags.indexOf(tag._id) > -1}
                    onChange={(checked) => this.handleChange(tag._id, checked)}
                  >
                    {tag.category}
                  </CheckableTag>
                ))}
                (Please click on a category to add)
              </Form.Item>

              <Form.Item label='Tags' rules={[{ required: true }]}>
                <div style={{ marginBottom: 16 }}>
                  <TweenOneGroup
                    enter={{
                      scale: 0.8,
                      opacity: 0,
                      type: "from",
                      duration: 100,
                      onComplete: (e) => {
                        e.target.style = "";
                      },
                    }}
                    leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                    appear={false}
                  >
                    {tagChild}
                  </TweenOneGroup>
                </div>
                {inputVisible && (
                  <Input
                    ref={this.saveInputRef}
                    type='text'
                    size='small'
                    style={{ width: 78 }}
                    value={inputValue}
                    onChange={this.handleInputChange}
                    onBlur={this.handleInputConfirm}
                    onPressEnter={this.handleInputConfirm}
                  />
                )}
                {!inputVisible && (
                  <Tag onClick={this.showInput} className='site-tag-plus'>
                    <PlusOutlined /> New Tag
                  </Tag>
                )}
              </Form.Item>

              <Form.Item name='fimage' label='Featured Image'>
                <Button
                  variant='primary'
                  onClick={this.showDrawer.bind(this)}
                  type='button'
                >
                  Upload
                </Button>
              </Form.Item>

              <Drawer
                title={this.state.itemheading}
                placement='top'
                closable={true}
                height={720}
                onClose={this.onClose}
                visible={visible}
                key='top'
              >
                <UploadItems
                  sendData={this.getData}
                  params={{
                    indexid: 0,
                    itemid: "new",
                    MenuItemId: "",
                    MenuItemData: "",
                    ratiow: 3,
                    ratioh: 2,
                    width: 12,
                    order: 0,
                  }}
                />
              </Drawer>

              <Form.Item>
                <Button onClick={this.goBack.bind(this)} htmlType='button'>
                  Cancel
                </Button>{" "}
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          ) : (
            "Please wait.. Loading.."
          )}
        </div>
      </Content>
    );
  }
}

export default Application;
