import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Stack, StackItem, Typography, Icon, Alert } from 'witmeg-ui-system';
import { setMenuSelectionReducer } from '../../../../redux/resturantWidgetReducer';

const TypeForm = ({ selectedCard, setSelectedCard, orderTypeValidation }) => {
  const dispatch = useDispatch();

  //SELECT CARD POPUP
  const selectCardOnClick = (cardType) => {

    dispatch(
      setMenuSelectionReducer({
        menuSelection: cardType
      })
    )

    setSelectedCard(cardType);

  }

  console.log("selectedCard", selectedCard);
  return (
    <div style={{ margin: '34px 22px' }}>



      <Typography type="text" pattern="bold" alignment="center" className="eco-mb-32">Please choose how you wish the restaurant menu to appear on your page</Typography>
      <Stack horizontalAlign="space_evenly">
        <div style={{ height: '96px', width: '96px', borderRadius: '4px', border: '1px solid #5A5C72', padding: '10px' }} onClick={() => selectCardOnClick("popup")} className={selectedCard === "popup" ? "selected-menu" : ""}>
          <Stack direction="column" verticalAlign="v_center">
            <Icon name="popup" size={44} className="eco-mb-16" color={selectedCard === "popup" ? "white" : ""} />
            <Typography type="text" color={selectedCard === "popup" ? "white_base" : "gray_darkest"}>Pop-up</Typography>
          </Stack>
        </div>
        <div style={{ height: '96px', width: '96px', borderRadius: '4px', border: '1px solid #5A5C72', padding: '10px' }} onClick={() => selectCardOnClick("embedded")} className={selectedCard === "embedded" ? "selected-menu" : ""}>
          <Stack direction="column" verticalAlign="v_center">
            <Icon name="embedded" size={44} className="eco-mb-16" color={selectedCard === "embedded" ? "white" : ""} />
            <Typography type="text" color={selectedCard === "embedded" ? "white_base" : "gray_darkest"}>Embedded</Typography>
          </Stack>
        </div>
      </Stack>

    </div>
  )
}

export default TypeForm;