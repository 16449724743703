import React, { useState } from 'react'

export default function OfferProduct({product}) {
    const [loaded, setLoaded] = useState(false)
    return (
        <div className='list'>
            <div className='card'>
                
                {product?.OnLineImages?.PrimaryImages !== null ? (
                    <div style={{ width: "auto" }}>
                        {/* {this.state.loaded ? null : (
                      <div className='divLoader'>
                        <LoadingOutlined
                          style={{ fontSize: "56px", color: "#08c" }}
                        />
                        <br />
                        Loading
                      </div>
                    )} */}
                        <img
                            src={product?.OnLineImages?.PrimaryImages?.Main}
                            alt={product?.Name}
                            onLoad={() => setLoaded(true)}
                            style={
                                loaded
                                    ? { width: "90%" }
                                    : { display: "none" }
                            }
                        />
                    </div>
                ) : (
                    <div>
                        <img
                            src='/images/noimage.jpg'
                            style={{ width: "90%" }}
                            alt={product?.Name}
                        />
                    </div>
                )}

                <h4>{product.Name}</h4>
                <p>
                    <b>Item Code:</b>
                    <br /> {product.ProductRefNo}
                </p>
            </div>
            <br />
        </div>
    )
}
