import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllRestaurantMenuData, getRestaurantMenuCreatedDate } from '../Pages/utilities/apiRequests/neutriPosServerRequests';
import _ from 'lodash';

export const fetchAllRestaurantMenuData = createAsyncThunk(
  'restaurant-widget/fetch-all-menu-data',
  async (data,  thunkAPI) => {
    const { CloudLocationID, OrganizationID, mainMenuID } = data;
    const _currentState = thunkAPI.getState().resturantWidget;

    try{
      const _isMenuDataAvailable =
        _currentState.menuData &&
        _currentState.menuData.storage &&
        _currentState.menuData.storage.data &&
        _currentState.menuData.storage.data.length > 0 &&
        _currentState.menuData.storage.menuCreatedDate &&
        _currentState.menuData.storage.menuCreatedDate !== ''
          ? true
          : false;

      let _data = {
        menuData: [],
        currency: _currentState.currency,
        mainMenus: [],
        menuCreatedDate: null,
        selectedMenuData: null,
        selectedMainMenuID: mainMenuID ?? null,
      };

      let _isMenuDateValid = false;

      if(_isMenuDataAvailable) {
        const _menuCreatedDateResponse = await getRestaurantMenuCreatedDate({ CloudLocationID, OrganizationID });
        if (_menuCreatedDateResponse && _menuCreatedDateResponse.menuCreatedDate && _menuCreatedDateResponse.menuCreatedDate !== '') {
          const _currentDate = new Date(_currentState.menuData.storage.menuCreatedDate).getTime();
          const _newDate = new Date(_menuCreatedDateResponse.menuCreatedDate).getTime();
          if (_currentDate === _newDate){
            _isMenuDateValid = true;
            _data = { ..._data, menuCreatedDate: _menuCreatedDateResponse.menuCreatedDate};
          }
        }
      }

      if(!_isMenuDateValid) {
        const _response = await getAllRestaurantMenuData({ CloudLocationID, OrganizationID });
  
        if (_response && _response.data && _response.data.length > 0) {
          if(_response.currency && _response.currency !== ""){
            _data = { ..._data, currency: _response.currency };
          }

          const _mainMenuData = _response.data.map(_item => ({
            menuID: _item.ID,
            localMenuID: _item.LocalId,
            menuName: _item.MenuName,
            orderIndex: _item.OrderIndex,
            showMenu: _item.ShowMenu,
            showOnline: _item.ShowOnline,
          }));

          _data = {
            ..._data,
            menuData: _response.data,
            mainMenus: _mainMenuData,
            menuCreatedDate: _response.menuCreatedDate,
          }
        }
      } else {
        _data = { 
          ..._data,
          menuData: _currentState.menuData.storage.data,
          menuCreatedDate: _currentState.menuData.storage.menuCreatedDate,
          mainMenus:  _currentState.menuData.storage.mainMenus,
        }
      }

      if(mainMenuID && mainMenuID !== '') {
        const _selectedMenuData = _.find(_data.menuData, { ID: mainMenuID });
        _data = {
          ..._data,
          selectedMenuData: _selectedMenuData,
        };
      } else {
        _data = {
          ..._data,
          selectedMainMenuID: _data.mainMenus[0].menuID,
          selectedMenuData: _data.menuData[0],
        };
      }

      if(_data.menuData && _data.menuData.length > 0) return _data;

      return thunkAPI.rejectWithValue('Data empty');
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resturantMenuSlice = createSlice({
  name: 'Resturant Widget',
  initialState: {
    menuWidgetData: {
      menuSelection: 'popup',
      userAuthenticated: false,
      orderType: null,
      menuDataAvailable: false,
      currency: 'GBP',
    },
    cloudLocationIDs: [],
    defaultCloudLocationID: null,
    organizationID: null,
    showPopUp: false,

    defaultOrderType: null,

    menuData: {
      storage: {
        data: [],
        menuCreatedDate: null,
        mainMenus: [],
      },
      selectedMainMenuID: null,
      selectedMainMenuData: null,
      isLoading: true,

      mainMenus: {
        isLoading: true,
        data: [],
      },
      selectedMenu: {
        id: null,
        index: 0,
        subMenuData: [],
        isLoading: true,
      },
      errorMessage: null,
      uiCustomizations: {},
      layout: {
        type: null,
        name: null,
      },
    },
  },
  reducers: {
    //Menu Selection Reducer
    setMenuSelectionReducer: (state, action) => {
      const { menuSelection } = action.payload || {};
      state.menuWidgetData.menuSelection = menuSelection;
    },

    //isUserAuthenticated
    setUserAuthenticated: (state, action) => {
      const { userAuthenticated } = action.payload || {};
      state.menuWidgetData.userAuthenticated = userAuthenticated;
    },

    //Order Type
    setOrderType: (state, action) => {
      const { menuOrderType } = action.payload || {};
      state.menuWidgetData.orderType = menuOrderType;
    },

    //Show Pop Up if menu is displyed
    setShowPopupData: (state, action) => {
      const { showPopUp } = action.payload || {};
      state.showPopUp = showPopUp;
    },

    /* Set if data available in the Restaurant Widget */
    setMenuDataAvailability: (state, action) => {
      const { menuDataAvailable } = action.payload || {};
      state.menuWidgetData.menuDataAvailable = menuDataAvailable;
    },

    setOrganizationData: (state, action) => {
      const { CloudLocations, OrganizationID } = action.payload;
      state.cloudLocationIDs = CloudLocations;
      state.defaultCloudLocationID = CloudLocations[0].CloudLocationID;
      state.organizationID = OrganizationID;
    },

    /* Change `Restaurant Menu` widget Currency */
    setRestaurantMenuCurrency: (state, action) => {
      const { currency } = action.payload;
      state.menuWidgetData.currency = currency;
    },

    setRestaurantMainMenuData: (state, action) => {
      const { mainMenuData } = action.payload;
      state.menuData.mainMenus.data = mainMenuData;
      state.menuData.mainMenus.isLoading = false;
      state.menuData.selectedMenu.index = 0;
    },

    changeSelectedRestaurantMainMenu: (state, action) => {
      const { mainMenuID } = action.payload;
      state.menuData.selectedMainMenuID = mainMenuID;
    },

    setRestaurantMenuUICustomizations: (state, action) => {
      const { uiCustomizations } = action.payload;
      state.menuData.uiCustomizations = uiCustomizations;
    },

    setRestaurantDefaultOrderType: (state, action) => {
      const { defaultOrderType } = action.payload;
      state.defaultOrderType = defaultOrderType;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAllRestaurantMenuData.rejected,
        (state, { payload }) => {
          state.menuData.storage.data = [];
          state.menuData.storage.menuCreatedDate = null;
          state.menuData.isLoading = false;
        }
      )
      .addCase(
        fetchAllRestaurantMenuData.pending,
        (state) => {
          state.menuData.isLoading = true;
        }
      )
      .addCase(
        fetchAllRestaurantMenuData.fulfilled,
        (state, { payload }) => {
          state.menuData.isLoading = false;
          state.currency = payload.currency;
          state.menuData.storage = {
            data: payload.menuData,
            mainMenus: payload.mainMenus,
            menuCreatedDate: payload.menuCreatedDate,
          };
          state.menuData.selectedMainMenuData = payload.selectedMenuData;
          state.menuData.selectedMainMenuID = payload.selectedMainMenuID;
        }
      );
  },
});

export const {
  setMenuSelectionReducer,
  setUserAuthenticated,
  setOrderType,
  setShowPopupData,
  setOrganizationData,
  setMenuDataAvailability,
  setRestaurantMenuCurrency,
  setRestaurantMenuUICustomizations,
  setRestaurantMainMenuData,
  changeSelectedRestaurantMainMenu,
  setRestaurantDefaultOrderType
} = resturantMenuSlice.actions;

export default resturantMenuSlice.reducer;
