import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Label, InputBox, Slider, Switch, Button, Stack, Space, ColorPicker } from 'witmeg-ui-system';
import Combobox from "react-widgets/Combobox";
import { Popover } from 'antd';
import "react-widgets/styles.css";
import divider1 from "../../../../images/divider-1.png";
import divider2 from "../../../../images/divider-2.png";
import divider3 from "../../../../images/divider-3.png";
import divider4 from "../../../../images/divider-4.png";
import FontPicker from "font-picker-react";

function CategorySettings({ setCategoriesField, categoriesFormData }) {

    const [categoryFontColor, setCategoryFontColor] = useState("");
    const [catColor, setCatColor] = useState("");

    const [catFontBold, setCatFontBold] = useState(false)
    const [catFontItalic, setCatFontItalic] = useState(false)
    const [catFontUnderline, setCatFontUnderline] = useState(false)
    const [curvedEdgesInput, setCurvedEdgesInput] = useState("12");
    const [selectedCategoryDivider, setSelectedCategoryDivider] = useState("");
    const [fontSize, setFontSize] = useState(null);

    const [catDivider, setCatDivderSwitch] = useState(false)
    const [fontList, setFontList] = useState(categoriesFormData?.catFontFamily ? categoriesFormData?.catFontFamily : "Open Sans")
    const API_KEY = "AIzaSyCrIytz43j1Dxo5ndDYnt57HY_yDjjWG8U";
    const handleCategoryDivider = (divider) => {


        setCategoriesField("catSelectedDivider", divider)
        setSelectedCategoryDivider(divider);
    }

    useEffect(() => {
        setCatFontBold(categoriesFormData?.bold)
        setCatFontUnderline(categoriesFormData?.underline)
        setCatFontItalic(categoriesFormData?.italic)
        setFontSize(categoriesFormData?.catFontSize)
        setCurvedEdgesInput(categoriesFormData?.catDividerStyle)
        setCatDivderSwitch(categoriesFormData?.catDivider)
    }, [categoriesFormData])

    console.log("buttonFormData", categoriesFormData)

    //OnClick Function to get the layout

    const dividerContent = (
        <Stack direction="column" horizontalAlign="h_center">
            <div className={selectedCategoryDivider === "divider1" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => handleCategoryDivider("divider1")}>
                <img src={divider1} />
            </div>
            <div className={selectedCategoryDivider === "divider2" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => handleCategoryDivider("divider2")}>
                <img src={divider2} />
            </div>
            <div className={selectedCategoryDivider === "divider3" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => handleCategoryDivider("divider3")}>
                <img src={divider3} />
            </div>
            <div className={selectedCategoryDivider === "divider4" ? 'eco-mb-24 eco-pa-16 divider selected' : "eco-mb-24 eco-pa-16 divider"} onClick={() => handleCategoryDivider("divider4")}>
                <img src={divider4} />
            </div>
        </Stack>
    )

    const handleFontColorChange = (e) => {

        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setCategoriesField("catFontColor", `#${e}`)
        } else {
            setCategoriesField("catFontColor", "#000")
            setCategoryFontColor("#000")
        }
    }
    const handleFontSelectionColorChange = (e) => {

        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setCategoriesField("catFontColor", `#${e}`)
        } else {
            setCategoriesField("catFontColor", "#000")
            setCategoryFontColor("#000")
        }
    }
    const handleColorChange = (e) => {

        let regex = "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$";

        if (regex.match(e)) {
            setCategoriesField("catColor", `#${e}`)
        } else {
            setCategoriesField("catColor", "#000")
            setCatColor("#000")
        }
    }
    return (
        <div style={{ width: '100%' }}>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Family</Label></div>
                </Col>

                <Col style={{ zIndex: "999999" }} span={16}>
                    <FontPicker
                        apiKey={API_KEY}
                        activeFontFamily={fontList}
                        onChange={(nextFont) => {
                            setCategoriesField("catFontFamily", nextFont.family);
                            setFontList(nextFont.family)
                        }
                        }
                        limit={100}
                        // sort="popularity"
                        pickerId="category"

                    />


                </Col>

            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Size</Label></div>
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue="16"
                        value={fontSize}
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => { setCategoriesField("catFontSize", e); setFontSize(e) }}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} onChange={(e) => handleFontColorChange(e.target.value)} type="text" noBorder backgroundOnly value={categoriesFormData?.catFontColor ? categoriesFormData?.catFontColor : categoryFontColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => setCategoriesField("catFontColor", color.hex)} defaultColor={categoryFontColor} setDefaultColor={setCategoryFontColor} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Selection Color</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox disabled allowPaste={false} onChange={(e) => handleFontColorChange(e.target.value)} type="text" noBorder backgroundOnly value={categoriesFormData?.catFontColor ? categoriesFormData?.catFontColor : categoryFontColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => setCategoriesField("catFontColor", color.hex)} defaultColor={categoryFontColor} setDefaultColor={setCategoryFontColor} />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '32px' }}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Font Style</Label></div>
                </Col>
                <Col span={16}>
                    <Space size="middle">
                        <Button category="icon" onClick={() => { catFontBold ? setCategoriesField('bold', false) : setCategoriesField("bold", true); catFontBold ? setCatFontBold(false) : setCatFontBold(true) }} variant={catFontBold ? "primary" : 'secondary'} children="bold" color={catFontBold ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button category="icon" variant={catFontItalic ? "primary" : 'secondary'} onClick={() => { catFontItalic ? setCategoriesField('italic', false) : setCategoriesField("italic", true); catFontItalic ? setCatFontItalic(false) : setCatFontItalic(true) }} children="italic" color={catFontItalic ? "white" : "gray"} className="font-style-btn"></Button>
                        <Button category="icon" variant={catFontUnderline ? "primary" : 'secondary'} onClick={() => { catFontUnderline ? setCategoriesField('underline', false) : setCategoriesField("underline", true); catFontUnderline ? setCatFontUnderline(false) : setCatFontUnderline(true) }} children="underline" color={catFontUnderline ? "white" : "gray"} className="font-style-btn"></Button>
                    </Space>
                </Col>
            </Row>
            {/* <Row className="form-row eco-mb-16" align="middle" gutter={[16, 32]}>
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Divider</Label></div>
                </Col>
                <Col span={16}>
                    <Switch checked={catDivider} onChange={(e) => setCategoriesField("catDivider", e)} />
                </Col>
                <Col span={24}>
                    <Stack horizontalAlign="h_center">
                        <div className="eco-mr-24">
                            <div style={{ width: '100%', border: '1px solid #DBDCE3', padding: '10px' }}>
                                {selectedCategoryDivider === "" && <img src={divider1} />}
                                {selectedCategoryDivider === "divider1" && <img src={divider1} />}
                                {selectedCategoryDivider === "divider2" && <img src={divider2} />}
                                {selectedCategoryDivider === "divider3" && <img src={divider3} />}
                                {selectedCategoryDivider === "divider4" && <img src={divider4} />}
                            </div>
                        </div>
                        <Popover placement="right" content={dividerContent} trigger="click" getPopupContainer={(triggerNode) => triggerNode} overlayStyle={{ width: '300px' }}>
                            <Button category="icon" children="chevron-right" variant="subtle" className="show-layout-btn" />
                        </Popover>
                    </Stack>
                </Col>

            </Row>
            <Row className="form-row eco-mb-16" align="middle" justify="space-between">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Divider Style</Label></div>
                </Col>
                <Col span={12}>
                    <Slider onChange={(newValue) => { setCategoriesField("catDividerStyle", newValue); setCurvedEdgesInput(newValue) }} value={curvedEdgesInput} />
                </Col>
                <Col span={4}>
                    <Combobox
                        defaultValue="32"
                        data={["10", "11", "12", "13", "14", "15", "16", "20", "24", "32", "36", "40", "48", "64", "96", "100"]}
                        hideEmptyPopup={true}
                        onChange={(e) => { setCategoriesField("catDividerStyle", e); setCurvedEdgesInput(e) }}
                        value={curvedEdgesInput}
                    />
                </Col>
            </Row>
            <Row className="form-row eco-mb-16" align="middle">
                <Col span={8}>
                    <div className="form-lbl-wrapper"><Label>Colour</Label></div>
                </Col>
                <Col span={4}>
                    <InputBox allowPaste={false} onChange={(e) => handleColorChange(e.target.value)} type="text" noBorder backgroundOnly value={categoriesFormData?.catColor ? categoriesFormData?.catColor : catColor} setDefaultColor />
                </Col>
                <Col span={3}>
                    <ColorPicker onChangeComplete={(color) => setCategoriesField("catColor", color.hex)} defaultColor={catColor} setDefaultColor={setCatColor} />
                </Col>
            </Row> */}
        </div>
    )
}

export default CategorySettings