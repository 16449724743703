import React, { useState, useEffect } from 'react'
import { async, Col, Label, Row, Typography } from 'witmeg-ui-system';
import { getAllPages, getPageByMenuID, getSubPagesByParentPageID } from '../utilities/apiRequests/witmegRetailPaxServerRequests';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

function NavigationLinksWidgets() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [treeData, setTreeData] = useState([]);
    const companyData = useSelector((state) => state.companyData.companyData)

    const [activeDropdown, setActiveDropdown] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        fetchAllPages();
    }, []);


    //Navigate to a new page
    async function handleNavigaePage(ID, e) {
        console.log(ID);


        const res = await getPageByMenuID({ ID: ID })

        const pageName = res[0].DraftPageName.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
        console.log("SSSSS", res)

        let path = `/home/${pageName}`;
        await history.push(path);

        window.location.reload();


    }

    async function handleTitleClick(ID, e) {
        if (e.target.id === 'dropdown-title') {
            const res = await getPageByMenuID({ ID: ID })

            const pageName = res[0].DraftPageName.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '')
            console.log("SSSSS", res)

            let path = `/home/${pageName}`;
            await history.push(path);

            window.location.reload();
        }

    }


    const fetchAllPages = async () => {
        try {
            setIsLoading(true);
            const pagesData = await getAllPages({
                ID: "",
                CompanyID: sessionStorage.getItem("company"),
            });



            let yy = pagesData.filter(z => z.DraftMainPagesInfoID === "").map(y => { return { ...y, children: pagesData.filter(a => a.DraftMainPagesInfoID === y.ID) } })
            setTreeData(yy);

        } catch (error) {
            console.log(`fetch data error: ${error}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDropdownToggle = (event, index) => {
        if (activeDropdown === index) {
            setActiveDropdown(null)
            setShowDropdown(false)
        } else {
            setActiveDropdown(index)
            setShowDropdown(!showDropdown)
        }
    };

    function handleDragStart(e) {
        e.preventDefault();
    }

    return (
        <div>


            <Navbar style={{height: "60px"}} bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#home">

                        <img style={{ maxWidth: "320px", maxHeight: "48px", marginRight: '10px', borderRadius: '5px' }} src={companyData?.Logo} draggable='false' />

                     </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav   className='me-auto'> <Nav.Link href="/home">
                            <span style={{ color: "black" }} >Home</span>
                        </Nav.Link></Nav>
                        {treeData.map((pn, index) => (

                            <Nav className="me-auto">
                                {pn.children.length === 0 && pn.DraftShowInMainMenu === true && <Nav.Link onDragStart={handleDragStart} ><span style={{ userSelect: "none" }} onClick={(e) => { e.stopPropagation(); pn.DraftNoLink === false && handleNavigaePage(pn.ID) }}>
                                    {pn.DraftPageName}</span></Nav.Link>}
                                <span>


                                    {pn.children.length > 0 && pn.DraftShowInMainMenu === true && < NavDropdown onDragStart={handleDragStart} onToggle={(event, eventKey) => handleDropdownToggle(event, index)} show={activeDropdown === index && showDropdown} onClick={(e) => { e.stopPropagation(); pn.DraftNoLink === false && handleTitleClick(pn.ID, e) }} title={<span style={{ userSelect: "none" }} id="dropdown-title">{pn?.DraftPageName}</span>} id="basic-nav-dropdown">
                                        <>
                                            {pn.children.map((data, index) => {
                                                return (
                                                    data.DraftShowInMainMenu === true && <NavDropdown.Item onDragStart={handleDragStart}  ><span style={{ userSelect: "none" }} onClick={(e) => { e.stopPropagation(); pn.DraftNoLink === false && handleNavigaePage(data.ID) }}>
                                                        {data.DraftPageName}</span></NavDropdown.Item>

                                                )
                                            })}
                                        </>
                                    </NavDropdown>}


                                </span>


                            </Nav>
                        ))}
                    </Navbar.Collapse>
                </Container>
            </Navbar>


        </div >
    )
}

export default NavigationLinksWidgets