import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CardWidget from "./CardWidget";
import {
  getHomeLayoutByID,
  updateHomeLayout,
} from "../../utilities/apiRequests/witmegRetailPaxServerRequests";

function CardWidgetWrapper(props) {
  const { widgetID, pageID } = props;

  const [widgetData, setWidgetData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [cardData, setCardData] = useState({
    image: "",
    designData: {},
    styleData: {},
    commonData: {},
  });

  const fetchCardData = async () => {
    try {
      setIsFetching(true);
      const response = await getHomeLayoutByID({
        ID: widgetID,
        PageID: "",
        CompanyID: sessionStorage.getItem("company"),
        Start: 0,
        Limit: 1000,
      });
      setWidgetData({
        Width: response[0].Width,
        Height: response[0].Height,
        X: response[0].X,
        Y: response[0].Y,
        Type: response[0].Type,
      });
      const itemData = response[0].ItemData
        ? JSON.parse(response[0].ItemData)
        : {};
      setCardData({
        image: itemData.image,
        designData: itemData.designData,
        styleData: itemData.styleData,
        commonData: itemData.commonData,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  //need to refactor following section
  const prepareUpdateData = (itemData) => {
    return {
      commonData: {
        cardTitle: itemData.cardTitle??cardData.commonData?.cardTitle,
        cardDescription: itemData.cardDescription??cardData.commonData.cardDescription,
        image: itemData.image??cardData.commonData.image
      },
      designData: {
        titleFontFamily: itemData.titleFontFamily ?? cardData.designData?.titleFontFamily??"Open Sans",
        titleFontSize: itemData.titleFontSize ?? cardData.designData?.titleFontSize??0,
        titleStyleItalic: itemData.titleStyleItalic ?? cardData.designData?.titleStyleItalic??false,
        titleStyleTextUnderline: itemData.titleStyleTextUnderline ?? cardData.designData?.titleStyleTextUnderline??false,
        titleStyleBold: itemData.titleStyleBold ?? cardData.designData?.titleStyleBold??false,
        titleFontAlign: itemData.titleFontAlign ?? cardData.designData?.titleFontAlign??"",
        titleFontColor: itemData.titleFontColor??cardData.designData?.titleFontColor??"#cecece",
        layout: itemData.layout??cardData.designData?.layout ??cardData.layout,

        descriptionFontFamily: itemData.descriptionFontFamily ?? cardData.designData?.descriptionFontFamily??"Open Sans",
        descriptionFontSize: itemData.descriptionFontSize ?? cardData.designData?.descriptionFontSize??0,
        descriptionStyleItalic: itemData.descriptionStyleItalic ?? cardData.designData?.descriptionStyleItalic??false,
        descriptionStyleTextUnderline:
          itemData.descriptionStyleTextUnderline ?? cardData.designData?.descriptionStyleTextUnderline??false,
        descriptionStyleBold: itemData.descriptionStyleBold ?? cardData.designData?.descriptionStyleBold??false,
        descriptionFontAlign: itemData.descriptionFontAlign ?? cardData.designData?.descriptionFontAlign??"",
      },
      styleData: {
        cardBorderRadiusTopleft: itemData.cardBorderRadiusTopleft ??cardData.styleData?.cardBorderRadiusTopleft?? 0,
        cardBorderRadiusTopRight: itemData.cardBorderRadiusTopRight ??cardData.styleData?.cardBorderRadiusTopRight?? 0,
        cardBorderRadiusBottomRight: itemData.cardBorderRadiusBottomRight ?? cardData.styleData?.cardBorderRadiusBottomRight?? 0,
        cardBorderRadiusBottomLeft: itemData.cardBorderRadiusBottomLeft ??cardData.styleData?.cardBorderRadiusBottomLeft?? 0,
        cardBorderColor: itemData.cardBorderColor ?? cardData.styleData?.cardBorderColor??"#FFF",
        cardBackgroundColor: itemData.cardBackgroundColor ?? cardData.styleData?.cardBackgroundColor??"#FFF",
        cardHoverBackgroundColor: itemData.cardHoverBackgroundColor ?? cardData.styleData?.cardHoverBackgroundColor??"#FFF",
        cardBackgroundShadow: itemData.cardBackgroundShadow ?? cardData.styleData?.cardBackgroundShadow??false,
      },
    };
  };

  const updateCardData = async (itemData) => {
    try {
      await updateHomeLayout({
        ID: widgetID,
        ItemHeading: "card",
        Item: "card",
        Width: widgetData?.Width,
        Height: widgetData?.Height,
        X: widgetData?.X,
        Y: widgetData?.Y,
        Type: widgetData?.Type,
        ItemData: JSON.stringify(prepareUpdateData(itemData)),
        RatioW: "",
        RatioH: "",
        Link: "",
        PageID: pageID,
        CompanyID: sessionStorage.getItem("company"),
      });

      await fetchCardData();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    async function fetchData() {
      await fetchCardData();
    }
    fetchData();
    return () => {};
  }, []);

  return (
    <CardWidget
      {...cardData}
      onUpdate={updateCardData}
      isFetching={isFetching}
    />
  );
}

CardWidgetWrapper.propTypes = {};

export default CardWidgetWrapper;
